import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { api } from "../../lib/api";
import { useNavigate, useParams } from "react-router-dom";

const VesselInformation = () => {
  const [formData, setFormData] = useState({
    vesselId: "",
    vesselName: "",
    vesselOperator: "",
    imoNumber: "",
    flagState: "",
    callSign: "",
    yearOfBuilt: "",
  });
  const { id } = useParams();
  const validVesselTypes = [
    "Container ship",
    "Multi Purpose Vessel",
    "Bulk carrier",
    "Roll-on/roll-off",
    "Tanker",
    "General Cargo Ship",
  ];
  const countryShortCodes = {
    // Add your country short codes here

    Afghanistan: "AF",
    Albania: "AL",
    Algeria: "DZ",
    "American Samoa": "AS",
    Andorra: "AD",
    Angola: "AO",
    Anguilla: "AI",
    Antarctica: "AQ",
    "Antigua and Barbuda": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Aruba: "AW",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bermuda: "BM",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bonaire, Sint Eustatius and Saba": "BQ",
    "Bosnia and Herzegovina": "BA",
    Botswana: "BW",
    "Bouvet Island": "BV",
    Brazil: "BR",
    "British Indian Ocean Territory": "IO",
    "Brunei Darussalam": "BN",
    Bulgaria: "BG",
    "Burkina Faso": "BF",
    Burundi: "BI",
    "Cabo Verd": "CV",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Cayman Islands": "KY",
    "Central African Republic": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    "Christmas Island": "CX",
    "Cocos (Keeling) Islands": "CC",
    Colombia: "CO",
    Comoros: "KM",
    "Congo (the Democratic Republic of the)": "CD",
    "Congo (the)": "CG",
    "Cook Islands (the)": "CK",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cuba: "CU",
    Curaçao: "CW",
    Cyprus: "CY",
    Czechia: "CZ",
    "Côte d'Ivoire": "CI",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Eswatini: "SZ",
    Ethiopia: "ET",
    "Falkland Islands (the)": "FK",
    "Faroe Islands": "FO",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    "French Guiana": "GF",
    "French Polynesia": "PF",
    "French Southern Territories (the)": "TF",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Gibraltar: "GI",
    Greece: "GR",
    Greenland: "GL",
    Grenada: "GD",
    Guadeloupe: "GP",
    Guam: "GU",
    Guatemala: "GT",
    Guernsey: "GG",
    Guinea: "GN",
    "Guinea-Bissau": "GW",
    Guyana: "GY",
    Haiti: "HT",
    "Heard Island and McDonald Islands": "HM",
    "Holy See (the)": "VA",
    Honduras: "HN",
    "Hong Kong": "HK",
    Hungaryary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    Iran: "IR",
    Iraq: "IQ",
    Ireland: "IE",
    "Isle of Man": "IM",
    Israel: "IL",
    Italy: "IT",
    Jamaica: "JM",
    Japan: "JP",
    Jersey: "JE",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kiribati: "KI",
    "Korea (the Democratic People's Republic of)": "KP",
    "Korea (the Republic of)": "KR",
    Kuwait: "KW",
    Kyrgyzstan: "KG",
    "Lao People's Democratic Republic": "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    Libya: "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Macao: "MO",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    "Marshall Islands": "MH",
    Martinique: "MQ",
    Mauritania: "MR",
    Mauritius: "MU",
    Mayotte: "YT",
    Mexico: "MX",
    Micronesia: "FM",
    "Moldova (the Republic of)": "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Montserrat: "MS",
    Morocco: "MA",
    Mozambique: "MZ",
    Myanmar: "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    Niue: "NU",
    "Norfolk Island": "NF",
    "Northern Mariana Islands": "MP",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    "Palestine, State of": "PS",
    Panama: "PA",
    "Papua New Guinea": "PG",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Pitcairn: "PN",
    Poland: "PL",
    Portugal: "PT",
    "Puerto Rico": "PR",
    Qatar: "QA",
    "Republic of North Macedonia": "MK",
    Romania: "RO",
    "Russian Federation": "RU",
    Rwanda: "RW",
    Réunion: "RE",
    "Saint Barthelemy": "BL",
    "Saint Helena, Ascension and Tristan da Cunha": "SH",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Martin (French part)": "MF",
    "Saint Pierre and Miquelon": "PM",
    "Saint Vincent and the Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    "Sint Maarten (Dutch part)": "SX",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    "South Georgia and the South Sandwich Islands": "GS",
    "South Sudan": "SS",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    "Svalbard and Jan Mayen": "SJ",
    Sweden: "SE",
    Switzerland: "CH",
    Syria: "SY",
    Taiwan: "TW",
    Tajikistan: "TJ",
    "Tanzania, United Republic of": "TZ",
    Thailand: "TH",
    "Timor-Leste": "TL",
    Togo: "TG",
    Tokelau: "TK",
    Tonga: "TO",
    "Trinidad and Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    "Turks and Caicos Islands": "TC",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United Kingdom of Great Britain and Northern Ireland": "GB",
    "United States Minor Outlying Islands": "UM",
    "United States of America": "US",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    "Venezuela (Bolivarian Republic of)": "VE",
    Vietnam: "VN",
    "Virgin Islands (British)": "VG",
    "Virgin Islands (U.S.)": "VI",
    "Wallis and Futuna": "WF",
    "Western Sahara": "EH",
    Yemen: "YE",
    Zambia: "ZM",
    Zimbabwe: "ZW",
    "Aland Islands": "AX",

    // Add more countries as needed
  };

  //get parameter from url react

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is for "Vessel Name/ID"
    if (name === "vesselId") {
      // Validate that the entered value contains only letters
      const validCharactersRegex = /^[A-Za-z0-9\s]*$/;
      if (!validCharactersRegex.test(value)) {
        // Display an error or take appropriate action for invalid input
        return;
      }
    }

    // If the dropdown is for "Vessel Type"
    if (name === "vesselName") {
      // Validate that the selected value is a valid vessel type
      if (!validVesselTypes.includes(value)) {
        // Display an error or take appropriate action for invalid vessel type
        return;
      }
    }

    // Check IMO Number validations
    if (name === "imoNumber") {
      // Check if the entered value is a valid number (optional, depending on your requirements)
      if (!/^\d*$/.test(value)) {
        // Display an error or take appropriate action for invalid input
        return;
      }

      // Check if the entered value has a maximum length of 7
      if (value.length > 7) {
        // Display an error or take appropriate action for exceeding maximum length
        return;
      }

      // Check for duplicates
      if (isDuplicate(value)) {
        // Display an error or take appropriate action for duplicate values
        return;
      }
    }

    // If the dropdown is for "Flag State"
    if (name === "flagState") {
      // Validate that the selected value is a valid country short code
      if (!Object.values(countryShortCodes).includes(value)) {
        // Display an error or take appropriate action for invalid country
        return;
      }
    }
    // If the input is for "Call Sign"
    if (name === "callSign") {
      // Validate alphanumeric, minimum length 3, maximum length 6
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (value.length > 6 || !alphanumericRegex.test(value)) {
        // Display an error or take appropriate action for invalid input
        return;
      }
    }

    if (name === "yearOfBuilt") {
      // Validate that the entered value is a valid year
      const validYearRegex = /^\d{0,4}$/; // Allow up to 4 digits
      if (value && !validYearRegex.test(value)) {
        return;
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
   
  };
  const isDuplicate = (value) => {
    // Replace this with the actual logic to check duplicates based on your data
    const existingIMO = []; // Array or function to get existing IMO numbers from your data
    return existingIMO.includes(value);
  };
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = {};
    if (formData.id !== null && formData.id !== undefined) {
      res = await api.postAndUpdate(`/updateVessel`, formData, "put");
    } else {
       res = await api.postAndUpdate("/createVessel", formData, "post");
    }
    navigate("/vessel-list");
    } catch (error) {
      alert("Duplicate Entry", error);
      
    }
    
  };

  const fetchSingleVessel = async () => {
    const response = await api.get(`/getVesselById/${id}`);

    if (response === null) {
      return;
    }
    setFormData(response);
  };
  useEffect(() => {
    if (id !== "add") {
      fetchSingleVessel();
    }
  }, []);

  return (
    <div className="container">
      <h1 className="text-center mt-5 mb-5">Vessel Information</h1>
      <Form
        onSubmit={handleSubmit}
        className="container rounded pt-5 pb-5 mb-5 border"
      >
        <Form.Group className="mb-3" controlId="vesselNameID">
          <Form.Label>Vessel Name/ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Vessel Name/ID"
            name="vesselId"
            required
            value={formData.vesselId}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="vesselType">
          <Form.Label>Vessel Type</Form.Label>
          <Form.Control
            as={"select"}
            placeholder="Enter Vessel Type"
            name="vesselName"
            required
            value={formData.vesselName}
            onChange={handleChange}
          >
            <option value="">Select Vessel Type</option>
            {/* <option value="Container ship">Container ship</option>
             <option value="Multi Purpose Vessel">Multi Purpose Vessel</option>
             <option value="Bulk carrier">Bulk carrier</option>
             <option value="Roll-on/roll-off">Roll-on/roll-off</option>
             <option value="Tanker">Tanker</option>
             <option value="General Cargo Ship">General Cargo Ship</option> */}
            {validVesselTypes.map((vesselType) => (
              <option key={vesselType} value={vesselType}>
                {vesselType}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="vesselOperator">
          <Form.Label>Vessel Operator</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Vessel Operator"
            name="vesselOperator"
            required
            value={formData.vesselOperator}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="imoNumber">
          <Form.Label>IMO Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter IMO Number"
            name="imoNumber"
            required
            value={formData.imoNumber}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="flagState">
          <Form.Label>Flag State</Form.Label>
          <Form.Control
            as={"select"}
            placeholder="Enter Flag State"
            name="flagState"
            required
            value={formData.flagState}
            onChange={handleChange}
          >
            <option value="">Select Flag State</option>
            {Object.keys(countryShortCodes).map((country) => (
              <option
                key={countryShortCodes[country]}
                value={countryShortCodes[country]}
              >
                {country}({countryShortCodes[country]})
              </option>
            ))}
            {/* <option value="India">India</option>
            <option value="United Arab Emirates">United Arab Emirates</option> */}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="callSign">
          <Form.Label>Call Sign</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Call Sign"
            name="callSign"
            required
            value={formData.callSign}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="yearOfBuilt">
          <Form.Label>Year of Built</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Year of Built"
            name="yearOfBuilt"
            required
            value={formData.yearOfBuilt}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          {formData.id ? "Update" : "Submit"}
        </Button>
      </Form>
    </div>
  );
};

export default VesselInformation;
