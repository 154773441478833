import React, { useEffect, useState } from "react";
import { api } from "../../lib/api";
import { CSVLink } from "react-csv";
import { Form, Table } from "react-bootstrap";
import axios from "axios";

const List = () => {
  const [showLoadListForm, setShowLoadListForm] = useState(false);
  const [showManifestForm, setShowManifestForm] = useState(false);
  const [vesselOptions, setVesselOptions] = useState([]); // Define vesselOptions state
  const [voyageOptions, setVoyageOptions] = useState([]);
  const [lineCodeOptions, setLineCodeOptions] = useState([]);
  const [blDetails, setBlDetails] = useState([]);
  const [exportData, setExportData] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [exportMenifestData, setExportManifestData] = useState();
  const [labelString, setLabelString] = useState("");
  const [selectedBlDetails, setSelectedBlDetails] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvResponse, setCsvResponse] = useState([]);

  console.log("csvData==>", csvData);
  const [formData, setFormData] = useState({
    vesselId: "",
    voyage: "",
    lineCode: "",
    blDetails: [],

    // Add other form fields here
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "vesselId") {
      fetchVoyageByVessal(value);
    }
    if (name === "lineCode" && formData.vesselId && formData.voyage) {
      fetchMatchingBlDetails(formData.vesselId, formData.voyage, value);
    }

    if (type === "checkbox") {
      const { name, value, type, checked } = e.target;
      let bl = blDetails.filter((item) => item.blno == value)[0];
      console.log(bl);
      setSelectedBlDetails([...selectedBlDetails, bl]);
      setTimeout(() => {
        console.log(selectedBlDetails);
      }, 2000);

      const updatedBLDetails = checked
        ? [...formData.blDetails, value]
        : formData.blDetails.filter((bl) => bl !== value);

      setFormData({
        ...formData,
        blDetails: updatedBLDetails,
      });
    } else {
      // New API call
      if (name === "voyage" && formData.vesselId && value) {
        fetchMatchingBlDetails(formData.vesselId, value);
      }

      setFormData({
        ...formData,
        [name]: value,
      });
    }
    setLabelString(`Label for ${value}`);
  };


  const exportToCsv = (data) => {
    console.log("exportToCsv", data);
    const lineDetails = data.lineDetails[0];
    const voyageDetails = data.voyageDetails[0];
    const vessels = data.vessels[0];
  
    // Assuming blDetails, shippingContainerInfo, etc. are arrays
    const blDetails = data.blDetails;
    const csvLines = [];
  
    for (let i = 0; i < blDetails.length; i++) {
      const currentBL = blDetails[i];
      const containerInfos = currentBL.shippingContainerInfo;
  
      // Process each container info for the current BL
      containerInfos.forEach((currentShippingContainerInfo, index) => {
        console.log("currentShippingContainerInfo", currentShippingContainerInfo);
  
        // Extracting the first five characters and remaining numbers from the container number
        const containerNumber = currentShippingContainerInfo.containerNumber;
        const firstChars = containerNumber.substring(0, 10);
        const remainingNumbers = containerNumber.substring(10);
  
        const grossWeight = currentShippingContainerInfo.grossWt
    ? Math.round(parseInt(currentShippingContainerInfo.grossWt) / 100)
    : "";

const vgmValue = currentShippingContainerInfo.vgm
    ? Math.round(parseInt(currentShippingContainerInfo.vgm) / 100)
    : "";

  
        const imcoClass = currentShippingContainerInfo.imcoClass || "";
        const unNumber = currentShippingContainerInfo.unNumber || "";
        const temp = currentShippingContainerInfo.temp || "";
        const tempunit = currentShippingContainerInfo.tempunit || "";
        const oog = currentShippingContainerInfo.oog || "N";
        const oogvalue1 = currentShippingContainerInfo.oogvalue1 || "";
        const oogvalue2 = currentShippingContainerInfo.oogvalue2 || "";
        const oogvalue3 = currentShippingContainerInfo.oogvalue3 || "";
        const oogvalue4 = currentShippingContainerInfo.oogvalue4 || "";
        const oogvalue5 = currentShippingContainerInfo.oogvalue5 || "";
        
  
        const csvLine =
          `${voyageDetails.rotationNumber},${lineDetails.code},${firstChars},${remainingNumbers},` +
          `${currentShippingContainerInfo.containerType},${currentBL.designation}, ,` +
          `${currentShippingContainerInfo.sealNumber},77,${currentBL.por},${currentBL.pol},${currentBL.pod},` +
          `${currentBL.fpod},${currentBL.consigneeName},` +
          `${grossWeight},${imcoClass},${unNumber}, ,  , , ,` +
          `${temp},${tempunit},${oog},${oogvalue1},${oogvalue2},${oogvalue3},${oogvalue4},${oogvalue5},` +
          `,,,,,,${vgmValue}`;
  
        csvLines.push(csvLine);
      });
    }
  
    const csv = csvLines.join("\n");
  
    const blob = new Blob([csv], { type: "text/csv" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.id = "lnkDwnldLnk";
    document.body.appendChild(link);
    link.setAttribute("download", "ExportedData.csv");
    link.setAttribute("href", csvUrl);
    link.click();
    document.body.removeChild(link);
  };

  const handleLoadListSubmit = () => {
    fetchExportData(formData, false);
    setShowLoadListForm(false);
    // Handle form submission logic here
    alert("Load List form submitted!");
    // You can also send the form data to a server if needed
    setIsSubmitted(true);
  };

  const header1 = [
    ...Array.from(
      { length: blDetails[0]?.shippingContainerInfo.length },
      (_, index) => [
        { label: "Rotation Number", key: "rotationNumber" },
        { label: "Line Code ", key: "code" },
        {
          label: `container No ${index + 1}`,
          key: `shippingContainerInfo[${index}].containerNumber`,
        },
        {
          label: `Cheack digit ${index + 1}dg`,
          key: `shippingContainerInfo[${index}].dg`,
        },

        {
          label: ` ${index + 1}containerType`,
          key: `shippingContainerInfo[${index}].containerType`,
        },

        { label: "Designation", key: "designation" },
        {
          label: `vgm${index + 3}vgm`,
          key: `shippingContainerInfo[${index}].vgm`,
        },
        {
          label: "sealNumber",
          key: `shippingContainerInfo[${index}].sealNumber`,
        },
        // { label: `sealNumber${index +1}sealNumber`, key: `shippingContainerInfo[${index}+1].sealNumber` },// ==

        { label: "por", key: "por" },
        { label: "pol", key: "pol" },
        { label: "fpod", key: "fpod" },
        { label: "Destination Port", key: "destinationPort" }, //m
        { label: "consigneeName", key: "consigneeName" },

        {
          label: `grossWt${index + 3}grossWt`,
          key: `shippingContainerInfo[${index}].grossWt`,
        },

        {
          label: `grossWt${index + 3}grossWt`,
          key: `shippingContainerInfo[${index}].grossWt`,
        },
        {
          label: `imco ${index + 1}imco`,
          key: `shippingContainerInfo[${index}].imcoClass`,
        },
        {
          label: `unno ${index + 1}unNumber`,
          key: `shippingContainerInfo[${index}].unNumber`,
        },

        {
          label: `UN NO2 ${index + 1}`,
          key: `shippingContainerInfo[${index + 1}].unNumber`,
        },
        {
          label: `UN NO2 ${index + 1}`,
          key: `shippingContainerInfo[${index + 1}].unNumber`,
        },
        {
          label: `Seal NO ${index + 1}`,
          key: `shippingContainerInfo[${index}].sealNumber`,
        },
        { label: "Content Code", key: "#" },
        { label: "Origin Port", key: "previousPort" },
        { label: "Load Port", key: "intermediatePort" },
        { label: "Discharge Port", key: "#" },
        // Getting Empty value ""
        { label: "Consignee Name", key: "consigneeName" },
        {
          label: `IMCO Code${index + 1}`,
          key: `shippingContainerInfo[${index}].imcoClass`,
        }, //nested structure
        {
          label: `UN No ${index + 1}`,
          key: `shippingContainerInfo[${index}].unNumber`,
        },
        {
          label: `Reffer ${index + 1}`,
          key: `shippingContainerInfo[${index}].reefer`,
        },
        {
          label: `Unit of Temp ${index + 1}`,
          key: `shippingContainerInfo[${index}].tempunit`,
        },

        {
          label: `Out Of Guage${index + 1}oog`,
          key: `shippingContainerInfo[${index}].oog`,
        },
        {
          label: `Out Of Guage${index + 2}oog`,
          key: `shippingContainerInfo[${index}].oog`,
        },
        {
          label: `Out Of Guage${index + 3}oog`,
          key: `shippingContainerInfo[${index}].oog`,
        },
        { label: "OOG in Right", key: "position" },
        {
          label: "OOG in Left",
          key: `shippingContainerInfo[${index}].position`,
        },

        { label: "OOG in Front", key: "#" },
        { label: "OOG in Back", key: "#" },
        {
          label: `Weight${index + 3}grossWt`,
          key: `shippingContainerInfo[${index}].grossWt`,
        },
        {
          label: `Weight${index + 3}grossWt`,
          key: `shippingContainerInfo[${index}].grossWt`,
        },
      ]
    ).flat(),
  ];
  const combineObjectscsv = (array) => {
    return array.reduce((combinedObject, currentObject) => {
      for (const key in currentObject) {
        if (currentObject.hasOwnProperty(key)) {
          combinedObject[key] = currentObject[key];
        }

        const renderedLabels = header1.map(({ label, key }) => (
          <div key={key}>
            if (label===i)
            {77}
          </div>
        ));
      }
      return combinedObject;
    }, {});
  };

  const handleManifestClick = async () => {
    setShowLoadListForm(false);
    setShowManifestForm(true);
  };
  console.log(blDetails);

  const downloadTxtFile = () => {
    let nopfcontainers = 0;
    const bolContainersMap = {}; // Map to store counts of containers per BOL
    const combineObjects = (array) => {
      return array.reduce((combinedObject, currentObject) => {
        for (const key in currentObject) {
          if (currentObject.hasOwnProperty(key)) {
            combinedObject[key] = currentObject[key];
          }
        }
        return combinedObject;
      }, {});
    };

    // Call the function with your array of objects
    const combinedObject = combineObjects(exportMenifestData);
    // Generate the "CON" section for each container
    let containerInfo = "";
    let shippingData = "";
    console.log(selectedBlDetails);
    selectedBlDetails.forEach((container, index) => {
      console.log(container);
      let firstTwoDigitsOfPol = container.pol.slice(0, 2);
      nopfcontainers += container.shippingContainerInfo.length;

        // Increment count for the current BOL in the map or initialize it if not present
        bolContainersMap[container.blno] = (bolContainersMap[container.blno] || 0) + container.shippingContainerInfo.length;
        const containerCountForBOL = bolContainersMap[container.blno];
        
      let containerinfoDetails = "";
      shippingData = container.shippingContainerInfo;
      const totalPackages = container.shippingContainerInfo.reduce((total, info) => total + parseInt(info.no_of_package || 0), 0);
      const totaltarewt = container.shippingContainerInfo.reduce((total, info) => total + parseInt(info.tareWt || 0), 0) / 1000;
      const totalgrosswt = container.shippingContainerInfo.reduce((total, info) => total + parseInt(info.grossWt || 0), 0);
      const totaldimension = container.shippingContainerInfo.reduce((total, info) => total + parseFloat(info.dimension || 0), 0);
      const cLASSValues = container.shippingContainerInfo.map(info => info.cLASS);
      const noofcontainer=[bolContainersMap]; 

      const uniqueCLASSValues = new Set(cLASSValues);
      const printCLASSValue = uniqueCLASSValues.size === 1 ? getCLASSDescription(cLASSValues[0]) : "CLASS";

      function getCLASSDescription(classValue) {
        switch (classValue) {
          case "20":
            return 1 * bolContainersMap[container.blno];
          case "40":
            return 2 * bolContainersMap[container.blno];
          case "45":
            return 2.25 * bolContainersMap[container.blno];
          default:
            return "";
        }
      }



      const packageTypes = container.shippingContainerInfo.map(info => info.packagetype);

      const uniquePackageTypes = new Set(packageTypes);
      const printPackageTypes = uniquePackageTypes.size === 1 ? packageTypes[0] : `PACKAGE","PKG`;  // PACKAGE pkg
      const packagetypeParts = printPackageTypes.split(":");
      const packagetypeFirst = packagetypeParts[0] || "";
      const packagetypeSecond = packagetypeParts[1] || "";

      

      const designationValue =
        container.designation === "EXP"
          ? "D9999"
          : container.designation === "FCL"
            ? ""
            : container.designation === "T/S"
              ? "T9999"
              : "";

      const designationPrefix =
        container.designation === "EXP"
          ? ""
          : container.designation === "FCL"
            ? "I"
            : container.designation === "T/S"
              ? "T"
              : "";
              containerInfo += `"BOL","${container.blno}","${combinedObject.code}","59604","${container.por
              }","${container.pol}","${container.pod}","${container.fpod
              }","","","${designationPrefix}","","","","F","N","","FCL/FCL","${firstTwoDigitsOfPol}","","","","","","","","${container.shipperName
              }","${container.shipperAddress
              }","${firstTwoDigitsOfPol
              }","${designationValue}","${container.consigneeName
              }","${container.consigneeAddress}","","${container.notifyPartyName
              }","${container.notifyPartyAddress
              }","","","","","","","${container.marksAndNumbers
              }","${container.hscode}","${container.goodsDescription
              }","${totalPackages}","${printPackageTypes ? `${packagetypeFirst}","${packagetypeSecond}` : ""}","","","${containerCountForBOL}","${printCLASSValue}","${totaltarewt || ""}","${totalgrosswt || ""}","${totalgrosswt || ""}","${totaldimension || ""
              }","","","","","",""\n`;
              
  container.shippingContainerInfo.forEach((info, index) => {
       let containerNoPrefix = info.containerNumber ? info.containerNumber.substring(0, 10) : '';
      let containerNoSuffix = info.containerNumber ? info.containerNumber.substring(10) : '';
      //let formattedTareWt = (info.tareWt / 1000).toFixed(2);
      let tareWt = Math.floor(info.tareWt / 1000 * 100) / 100;
        containerinfoDetails += `"CTR","${containerNoPrefix}","${containerNoSuffix}","${tareWt}","${info.sealNumber}"\n`;
      });
    container.shippingContainerInfo.map((info, index) => {
      const packagetypeParts = info.packagetype.split(":");
        const packagetypeFirst = packagetypeParts[0] || "";
        const packagetypeSecond = packagetypeParts[1] || "";
     containerinfoDetails += `"CON","${(index < 9 ? '00' : index < 99 ? '0' : '') + (index + 1)}","${container.marksAndNumbers
          }","${container.goodsDescription}","N","${container.hscode
          }","${info.no_of_package}","${packagetypeFirst}","${packagetypeSecond}","","${info.grossWt}","${info.dimension
          }","${info.dg || "N"}","${info.imcoClass || ""}","${info.unNumber || ""
          }","${info.flashPoint || ""}","${info.flashPointUnit || ""
          }","${info.dg || "N"}","${info.reefer || "N"}","${info.temp || ""}","${info.temp || ""}","${info.tempunit || ""
          }" \n`; //"${info.dg || "N"}","${info.reefer || ""}
      });
      containerInfo += containerinfoDetails ;
    });
    console.log(containerInfo);
    // Convert etaDate to a Date object if it's a string
    const etaDateObj = new Date(combinedObject.etaDate);

    // Define a function to format the date as YYYY-MM-DD
    function formatDate(date) {
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

      const year = date.getFullYear();
      const month = months[date.getMonth()];
      const day = String(date.getDate()).padStart(2, '0');

      return `${day}-${month}-${year}`;
    }
    // Format etaDate as YYYY-MM-DD
    const formattedEtaDate = formatDate(etaDateObj);
    const formatstring = `"VOY","${combinedObject.code}","59604","${combinedObject.vesseltype}","${combinedObject.voyageNumber}","${combinedObject.intermediatePort}","${formattedEtaDate}","${combinedObject.rotationNumber}","MFI","1"\n${containerInfo}"END","${nopfcontainers}","${selectedBlDetails.length}"`;
    console.log(combinedObject);
    console.log("output-->>", formatstring);
    // Create a Blob and download the file
    const blob = new Blob([formatstring], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "exported_data.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleManifestSubmit = () => {
    fetchExportData(formData, true);
    // Handle manifest form submission logic here
    alert("Manifest form submitted!");
    // You can also send the form data to a server if needed
    // Reset the form or perform any other necessary actions
    setShowManifestForm(false);
    setIsSubmitted(true);
  };

  const fetchVesselOptions = async () => {
    try {
      // Fetch vessel details
      const vesselResponse = await api.get("/getAllVessel");
      setVesselOptions(vesselResponse);
      console.log("vesselresponse:", vesselResponse);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };
  const fetchVoyageOptions = async () => {
    try {
      const voyageResponse = await api.get("/getAllVoyageDetails");
      setVoyageOptions(voyageResponse); // Assuming the voyage data is in the 'data' property
    } catch (error) {
      console.error("Error fetching voyage options:", error);
    }
  };

  //New Custom API
  const fetchVoyageByVessal = async (value) => {
    try {
      const voyageResponse = await api.get(`/detailsbyvessel/${value}`);
      setVoyageOptions(voyageResponse); // Assuming the voyage data is in the 'data' property
    } catch (error) {
      console.error("Error fetching voyage options:", error);
    }
  };

  const fetchLineCodeOptions = async () => {
    try {
      // Fetch line code options
      const lineCodeResponse = await api.get("/getAllLineDetails");
      setLineCodeOptions(lineCodeResponse);
    } catch (error) {
      console.error("Error fetching line code options:", error);
    }
  };
  // const fetchAllBlDetails = async () => {
  //   try {
  //     const blDetailsResponse = await api.get("/getAllBLDetails");
  //     // console.log("BL Details Response:", blDetailsResponse);
  //     setBlDetails(blDetailsResponse); // Use blDetailsResponse.data
  //   } catch (error) {
  //     console.error("Error fetching all BL details:", error);
  //   }
  // };

  const fetchExportData = async (pathParams, isMenifest) => {
    const {
      blDetails = [],
      lineCode = "",
      vesselId = "",
      voyage = "",
    } = pathParams;

    try {
      let exportDataResponse = await api.post("/rowbyfeild", {
        blnoList: blDetails,
        code: lineCode,
        vesselId: vesselId,
        voyageNumber: voyage,
      });

      console.log("exportDataResponse ===>", exportDataResponse?.data);
      if (isMenifest) {
        const updateTxtData = Object.values(exportDataResponse).reduce(
          (ace, arc) => ace.concat(arc),
          []
        );
        setExportManifestData(updateTxtData);
      } else {
        exportToCsv(exportDataResponse);

        console.log("exportDataResponse ===>", exportDataResponse);

        let updatedData = Object.values(exportDataResponse).reduce(
          (acc, arr) => acc.concat(arr),
          []
        );

        setCsvData(combineObjectscsv(updatedData));
        setExportData(updatedData);
      }
    } catch (error) {
      console.error("Error while fetching Export data deta details:", error);
    }
  };
  console.log("exportData====>", exportData);

  // New Sample API to get BlDetails on the basis of vesal and voyag
  const fetchMatchingBlDetails = async (vesselId, voyage, lineCode) => {
    try {
      const exportDataResponse = await api.get(
        `/join-information/${vesselId}/${voyage}/${lineCode}`
      );
      setBlDetails(exportDataResponse);
    } catch (error) {
      console.error("Error while fetching Export data deta details:", error);
    }
  };

  useEffect(() => {
    fetchVesselOptions();
    fetchLineCodeOptions();
  }, []);

  useEffect(() => {
    if (formData.vesselId) {
      fetchVoyageByVessal(formData.vesselId);
    }
  }, [formData.vesselId]);

  // useEffect(() => {
  //   fetchAllBlDetails(); // Fetch all BL details when the component mounts
  // }, []);

  console.log("formData", formData);
  return (
    <div className="container" style={{ overflowX: "scroll" }}>
      <h1 className="text-center mt-5">Export Data</h1>
      <div className="container mt-3">
        <button
          className="btn btn-primary"
          onClick={() => setShowLoadListForm(true)}
        >
          Load List
        </button>
        <button
          className="btn btn-success"
          style={{ marginLeft: "10px" }}
          onClick={handleManifestClick}
        >
          Manifest
        </button>

        {showLoadListForm && (
          <div>
            <h2>Load List Form</h2>
            <form>
              {/* Add Load List form fields */}
              <Form.Group className="mb-3" controlId="vesselId">
                <Form.Label>Vessel Name</Form.Label>
                <Form.Control
                  as="select"
                  name="vesselId"
                  value={formData.vesselId}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Vessel
                  </option>

                  {vesselOptions &&
                    vesselOptions.map((vessel) => (
                      <option key={vessel.id} value={vessel.vesselId}>
                        {vessel.vesselId}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="voyage">
                <Form.Label>Voyage</Form.Label>
                <Form.Control
                  as="select"
                  name="voyage"
                  value={formData.voyage}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Voyage
                  </option>
                  {voyageOptions.map((voyage) => (
                    <option key={voyage.id} value={voyage.voyageNumber}>
                      {voyage.voyageNumber}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lineCode">
                <Form.Label>Line Code</Form.Label>
                <Form.Control
                  as="select"
                  name="lineCode"
                  value={formData.lineCode}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Line Code
                  </option>
                  {lineCodeOptions.map((lineCode) => (
                    <option key={lineCode.id} value={lineCode.code}>
                      {lineCode.code}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <div>
                <h3>BL Details</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Checkbox</th>
                      <th>BL Number</th>
                      <th>Shipper Name</th>
                      <th>Consignee Name</th>
                      <th>Vessel</th>
                      <th>Voyage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blDetails.map((bl) => (
                      <tr key={bl.id}>
                        <td>
                          <input
                            type="checkbox"
                            id={`blCheckbox-${bl.id}`}
                            label={labelString}
                            value={bl.blno}
                            checked={formData.blDetails.includes(bl.blno)}
                            onChange={handleChange}
                          />
                          <label htmlFor={`blCheckbox-${bl.id}`}></label>
                        </td>
                        <td>{bl.blno}</td>
                        <td>{bl.shipperName}</td>
                        <td>{bl.consigneeName}</td>
                        <td>{bl.vesselId}</td>
                        <td>{bl.voyage}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleLoadListSubmit()}
              >
                Submit
              </button>
            </form>
          </div>
        )}

        {showManifestForm && (
          <div>
            <h2>Manifest Form</h2>
            <form>
              <Form.Group className="mb-3" controlId="vesselId">
                <Form.Label>Vessel Name</Form.Label>
                <Form.Control
                  as="select"
                  name="vesselId"
                  value={formData.vesselId}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Vessel
                  </option>

                  {vesselOptions &&
                    vesselOptions.map((vessel) => (
                      <option key={vessel.id} value={vessel.vesselId}>
                        {vessel.vesselId}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="voyage">
                <Form.Label>Voyage</Form.Label>
                <Form.Control
                  as="select"
                  name="voyage"
                  value={formData.voyage}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Voyage
                  </option>
                  {voyageOptions.map((voyage) => (
                    <option key={voyage.id} value={voyage.voyageNumber}>
                      {voyage.voyageNumber}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="lineCode">
                <Form.Label>Line Code</Form.Label>
                <Form.Control
                  as="select"
                  name="lineCode"
                  value={formData.lineCode}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Line Code
                  </option>
                  {lineCodeOptions.map((lineCode) => (
                    <option key={lineCode.id} value={lineCode.code}>
                      {lineCode.code}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <div>
                <h3>BL Details</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Checkbox</th>
                      <th>BL Number</th>
                      <th>Shipper Name</th>
                      <th>Consignee Name</th>
                      <th>Vessel</th>
                      <th>Voyage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blDetails.map((bl) => (
                      <tr key={bl.id}>
                        <td>
                          <input
                            type="checkbox"
                            id={`blCheckbox-${bl.id}`}
                            label={labelString}
                            value={bl.blno}
                            checked={formData.blDetails.includes(bl.blno)}
                            onChange={handleChange}
                          />
                          <label htmlFor={`blCheckbox-${bl.id}`}></label>
                        </td>
                        <td>{bl.blno}</td>
                        <td>{bl.shipperName}</td>
                        <td>{bl.consigneeName}</td>
                        <td>{bl.vesselId}</td>
                        <td>{bl.voyage}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <button
                type="button"
                className="btn btn-primary"
                // onClick={handleManifestSubmit}
                onClick={() => handleManifestSubmit()}
              >
                Submit Manifest
              </button>

              {isSubmitted && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => downloadTxtFile()}
                >
                  Download Txt
                </button>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default List;
