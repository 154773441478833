import axios from "axios";

const api_url = process.env.REACT_APP_SERVER_URL;
export const api = {

    get: async (url) => {
        // console.log(api_url);
        const response = await axios.get(api_url + url);
        return response.data;
    },
    postAndUpdate: async (url, data, action) => {
        let response;
        if (action === "post") {
             response = await axios.post(api_url + url, data);
           
        }
        else {
             response = await axios.put(api_url + url, data);
           
        }
       
        return response.data;
    },
    delete: async (url) => {
        const response = await axios.delete(api_url + url);
        return response.data;
    }
    , post: async (url, data) => {
        const response = await axios.post(api_url + url, data);
        return response.data;
    }

}