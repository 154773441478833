import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib/api";

const LineDetails = () => {
  const [formData, setFormData] = useState({
    code: "",
    lineName: "",
    contactDetails: "",
  });
  const { id } = useParams();
  const [validationError, setValidationError] = useState("");
  const [lineCodeError, setLineCodeError] = useState("");

  const [lineNameError, setLineNameError] = useState("");
  const [contactDetailsError, setContactDetailsError] = useState("");




  const handleChange = (e) => {
    const { name, value } = e.target;
    // Add validation for Line Code (Alphanumeric, max length 10)
    if (name === "code") {
      const isValid = /^[a-zA-Z0-9]{1,10}$/.test(value);
      setValidationError(isValid ? "" : "Line Code must be alphanumeric and have a max length of 10.");
    }
     // Add validation for Line Name (Characters including space and special characters)
     if (name === "lineName") {
      const isValid = /^[\w\s!@#$%^&*()-_+=<>?/;:'",.]+$/u.test(value);
      setLineNameError(isValid ? "" : "Line Name can contain characters, spaces, and special characters.");
    }
     
   
  


    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
     // Display validation error if Line Code, Line Name, or Contact Details is invalid
    if (lineCodeError || lineNameError || contactDetailsError) {
      console.error("Validation Error:", lineCodeError || lineNameError || contactDetailsError);
      return;
    }
    try {
      let res = {};
      if (formData.id !== null && formData.id !== undefined) {
         res = await api.postAndUpdate(`/updateLineDetails`, formData, "put");
      } else {
         res = await api.postAndUpdate("/createLineDetails", formData, "post");
      }
      console.log(res);
      navigate("/line-list");
      
    } catch (error) {
      alert("Line code already exists:", error);
      console.log(error);
    }
    
   
  };
  

  const fetchSingleLine = async () => {
    const response = await api.get(`/getLineDetailsById/${id}`);
    if (response === null) {
      return;
    }
    setFormData(response);
      
    };
  useEffect(() => {
    if (id !== "add") {
      fetchSingleLine();
    }
  }, []);
  

  return (
    <div className="container">
      <h1 className="text-center mt-5 mb-5">Line Details</h1>
      <Form
        onSubmit={handleSubmit}
        className="container rounded pt-5 pb-5 mb-5 border"
      >
        <Form.Group className="mb-3" controlId="code">
          <Form.Label>Line Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Line Code"
            name="code"
            required
            value={formData.code}
            onChange={handleChange}
            isInvalid={!!validationError}
          />
                    <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>

        </Form.Group>

        <Form.Group className="mb-3" controlId="lineName">
          <Form.Label>Line Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Line Name"
            name="lineName"
            required
            value={formData.lineName}
            onChange={handleChange}
            isInvalid={!!lineNameError}
          />
                    <Form.Control.Feedback type="invalid">{lineNameError}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="contactDetails">
          <Form.Label>Contact Details</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Contact Details"
            name="contactDetails"
            required
            value={formData.contactDetails}
            onChange={handleChange}
           
          />
                    <Form.Control.Feedback type="invalid">{contactDetailsError}</Form.Control.Feedback>
        </Form.Group>

        <Button variant="primary" type="submit">
          {formData.id ? "Update" : "Submit"}
        </Button>
      </Form>
    </div>
  );
};

export default LineDetails;
