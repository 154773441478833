import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { api } from "../../lib/api";
import GrowingSpinner from "../../components/loaders/datalLoading";

const VesselInformationTable = () => {
  const [vessels, setVessels] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState(null);
  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseViewModal = () => setShowViewModal(false);

  const [formData, setFormData] = useState({
    vesselId: "",
    vesselName: "",
    vesselOperator: "",
    imoNumber: "",
    flagState: "",
    callSign: "",
    yearOfBuilt: "",
  });

  const handleAddVessel = () => {
    setVessels([...vessels, formData]);
    setFormData({
      vesselId: "",
      vesselName: "",
      vesselOperator: "",
      imoNumber: "",
      flagState: "",
      callSign: "",
      yearOfBuilt: "",
    });
    handleCloseModal();
  };

  const handleView = (index) => {
    setSelectedVessel(vessels[index]);
    setShowViewModal(true);
  };

  const handleUpdate = (index) => {
    console.log("Update vessel:", vessels[index]);
  };

  const handleDelete = (id) => {
    const response = api.delete(`/deleteVessel/${id}`);
    console.log(response);
    fetchData();
  };

  async function fetchData() {
    const list = await api.get("/getAllVessel");
    setVessels(list);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mt-5" style={{ overflowX: "scroll" }}>
      <h1 className="text-center mb-5">Vessel Information</h1>
      <div className="d-flex justify-content-end">
        <Button variant="success" onClick={() => navigate("/vessel-info/add")}>
          <FontAwesomeIcon icon={faPlus} /> Add Vessel
        </Button>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Vessel ID</th>
            <th>Vessel Type</th>
            <th>Vessel Operator</th>
            <th>IMO Number</th>
            <th>Flag State</th>
            <th>Call Sign</th>
            <th>Year of Built</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vessels.map((vessel, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{vessel.vesselId}</td>
              <td>{vessel.vesselName}</td>
              <td>{vessel.vesselOperator}</td>
              <td>{vessel.imoNumber}</td>
              <td>{vessel.flagState}</td>
              <td>{vessel.callSign}</td>
              <td>{vessel.yearOfBuilt}</td>
              <td>
                <Button
                  variant="outline-primary"
                  onClick={() => handleView(index)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>{" "}
                <Button
                  variant="outline-warning"
                  onClick={() => navigate("/vessel-info/" + vessel.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>{" "}
                <Button
                  variant="outline-danger"
                  onClick={() => handleDelete(vessel.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {vessels.length < 1 && <GrowingSpinner />}

      {/* View Modal */}
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>Vessel Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display details of the selected vessel */}
          {selectedVessel && (
            <div>
              <p>Vessel ID: {selectedVessel.vesselId}</p>
              <p>Vessel Type: {selectedVessel.vesselName}</p>
              <p>Vessel Operator: {selectedVessel.vesselOperator}</p>
              <p>IMO Number: {selectedVessel.imoNumber}</p>
              <p>Flag State: {selectedVessel.flagState}</p>
              <p>Call Sign: {selectedVessel.callSign}</p>
              <p>Year of Built: {selectedVessel.yearOfBuilt}</p>
              


              {/* Add other fields as needed */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Vessel Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Vessel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Include form fields similar to the VesselInformation form */}
            {/* ... */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddVessel}>
            Save Vessel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VesselInformationTable;
