import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthProvider';

export default function Login() {
    const { isLoggedIn,user,login,logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isCaptchaChecked, setIsCaptchaChecked ] = useState(true);  
    const loginHanle = () => {
        if (username === 'demo@expertit' && password === '123456') {
            login( {
                name:'expertit',
                email:'demo@expertit.in',
                avatar:'https://static.vecteezy.com/system/resources/previews/008/442/086/non_2x/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg',
            });
            navigate('/dashboard');
            Swal.fire("login success", '', 'success')
        } else {
            Swal.fire("Username or password is incorrect", '', 'error');
            return;
        }
        
    }
    
   
    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center jumbotron" style={{ height: '65vh' }}>
                    <div className="col-md-5">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header border-bottom text-center">
                                <h2>Login</h2>
                            </div>
                            <div className="card-body">
                                <input value={username} onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Enter Your User Name" className="form-control" />
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Your Password" className="form-control my-3" />
                                {/* <ReCAPTCHA
                                   sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={()=> setIsCaptchaChecked(true)}
                                />  */}
                                <button type="sumit" onClick={loginHanle} className="btn btn-primary mt-2">Login</button>
                                <Link to="/forgot-password"><p>Forgot Password</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}