import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { api } from "../../lib/api";

const BlDetailsTable = () => {
  const [blDetailsList, setBlDetailsList] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedBL, setSelectedBL] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    const list = await api.get("/getAllBLDetails");
    setBlDetailsList(list);
    console.log(list);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    const response = await api.delete(`/deleteBLDetails/${id}`);
    console.log(response);
    fetchData();
  };

  const handleView = (index) => {
    setSelectedBL(blDetailsList[index]);
    setShowViewModal(true);
  };

  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };

  return (
    <div className="container" style={{ overflowX: "scroll" }}>
      <h1 className="text-center mt-5 mb-5">BL Details Table</h1>
      <div className="d-flex justify-content-end">
        <Button variant="success" onClick={() => navigate("/bl-details/add")}>
          <FontAwesomeIcon icon={faPlus} /> Add BL Details
        </Button>

        
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>BL Number</th>
            <th>Shipper Name</th>
            <th>Consignee Name</th>
            <th>Vessel</th>
            <th>Voyage</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {blDetailsList.map((bl, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{bl.blno}</td>
              <td>{bl.shipperName}</td>
              <td>{bl.consigneeName}</td>
              <td>{bl.vessel}</td>
              <td>{bl.voyage}</td>
              <td>
                <Button variant="info" className="me-2" onClick={() => handleView(index)}>
                  <FontAwesomeIcon icon={faEye} />
                </Button>
                <Button variant="warning" onClick={() => navigate("/bl-details/" + bl.id)} className="me-2">
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button variant="danger" onClick={() => handleDelete(bl.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* View Modal */}
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>BL Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display details of the selected BL */}
          {selectedBL && (
            <div>
              <p>BL Number: {selectedBL.blno}</p>
              <p>Designation: {selectedBL.designation}</p>
              <p>Shipper Name: {selectedBL.shipperName}</p>
              <p>Shipper Address: {selectedBL.shipperAddress}</p>
              <p>Consignee Name: {selectedBL.consigneeName}</p>
              <p>Consignee Address: {selectedBL.consigneeAddress}</p>
              <p>Notify Party Name: {selectedBL.notifyPartyName}</p>
              <p>Notify Party Address: {selectedBL.notifyPartyAddress}</p>
              <p>Port Of Receipt: {selectedBL.por}</p>
              <p>Port Of Loading: {selectedBL.pol}</p>
              <p>Port Of Discharge: {selectedBL.pod}</p>
              <p>Port Of Delivery: {selectedBL.fpod}</p>
              <p>select Carrier: {selectedBL.carrierInformation}</p>
              <p>Vessel: {selectedBL.vessel}</p>
              <p>Voyage: {selectedBL.voyage}</p>
              <p>HS Code: {selectedBL.hscode}</p>
              <p>Description: {selectedBL.goodsDescription}</p>


              
      

              {/* Add other fields as needed */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BlDetailsTable;
