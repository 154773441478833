import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { api } from "../../lib/api";

const LineDetailsTable = () => {
  const [lines, setLines] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedLine, setSelectedLine] = useState(null);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    code: "",
    lineName: "",
    contactDetails: "",
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseViewModal = () => setShowViewModal(false);

  const handleAddLine = () => {
    setLines([...lines, formData]);
    setFormData({
      code: "",
      lineName: "",
      contactDetails: "",
    });
    handleCloseModal();
  };

  const handleView = (index) => {
    setSelectedLine(lines[index]);
    setShowViewModal(true);
  };

  const handleUpdate = (index) => {
    console.log("Update line:", lines[index]);
    // Implement update logic here
  };

  const handleDelete = async (id) => {
    const response = await api.delete(`/deleteLineDetails/${id}`);
    console.log(response);
    fetchData();
  };

  async function fetchData() {
    const list = await api.get("/getAllLineDetails");
    setLines(list);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mt-5" style={{ overflowX: "scroll" }}>
      <h3 className="text-center mb-3">Line Details</h3>
      <div className="d-flex justify-content-end">
        <Button variant="success" onClick={() => navigate("/line-details/add")}>
          <FontAwesomeIcon icon={faPlus} /> Add Line
        </Button>
      </div>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>Line Code</th>
            <th>Line Name</th>
            <th>Contact Details</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {lines.map((line, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{line.code}</td>
              <td>{line.lineName}</td>
              <td>{line.contactDetails}</td>
              <td>
                <Button
                  variant="outline-primary"
                  onClick={() => handleView(index)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </Button>{" "}
                <Button
                  variant="outline-warning"
                  onClick={() => navigate("/line-details/" + line.id)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>{" "}
                <Button
                  variant="outline-danger"
                  onClick={() => handleDelete(line.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* View Modal */}
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>Line Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedLine && (
            <div>
              <p>Line Code: {selectedLine.code}</p>
              <p>Line Name: {selectedLine.lineName}</p>
              <p>Contact Details: {selectedLine.contactDetails}</p>
              {/* Add other fields as needed */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Line</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="code">
              <Form.Label>Line Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Line Code"
                name="code"
                value={formData.code}
                onChange={(e) =>
                  setFormData({ ...formData, code: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="lineName">
              <Form.Label>Line Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Line Name"
                name="lineName"
                value={formData.lineName}
                onChange={(e) =>
                  setFormData({ ...formData, lineName: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="contactDetails">
              <Form.Label>Contact Details</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Contact Details"
                name="contactDetails"
                value={formData.contactDetails}
                onChange={(e) =>
                  setFormData({ ...formData, contactDetails: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddLine}>
            Save Line
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LineDetailsTable;
