// AuthContext.js
import React, { createContext } from 'react';


export const AuthContext = createContext();

export const  AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(true);
  const [user, setUser] = React.useState(
    {
        name:'expertit',
        email:'demo@expertit.in',
        avatar:'https://static.vecteezy.com/system/resources/previews/008/442/086/non_2x/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg',
    }
  );

  const login = (user) => {
    setIsLoggedIn(true);
    setUser(user);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
  };

  const contextValue = {
    isLoggedIn,
    user,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};


;