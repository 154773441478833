import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib/api";
import { BrowserRouter as Router } from "react-router-dom";
import { portsData } from "./const/portCodes";

const BlDetailsForm = () => {
  const containerInfo = {
    containerNumber: "",
    containerType: "",
    sealNumber: "",
    grossWt: "",
    dimension: "",
    tareWt: "",
    vgm: "",
    dg: "",
    reefer: "",
    tempRequired: "",
  };
  const designationdata = {
    Import: "FCL",
    Export: "EXP",
    "Trans Shipment": "T/S",
  };

  const packageTypes = {
    BAGS: "BAG",
    BALES: "BAL",
    BUNDLES: "BDL",
    "BULK LIQUID": "BLD",
    BLOCKS: "BLK",
    BOBBIN: "BOB",
    BOATS: "BOT",
    BOXES: "BOX",
    BARREL: "BRL",
    BRIQUETTES: "BRQ",
    "BULK SOLID": "BSD",
    "BULK SOLID/LIQUID": "BUL",
    CANS: "CAN",
    CARBOY: "CBY",
    "CABLE DRUM": "CDR",
    COLLI: "CLI",
    COILS: "COI",
    CRATES: "CRT",
    CASES: "CSE",
    CASKS: "CSK",
    CARTONS: "CTN",
    CONTAINER: "CTR",
    CYLINDERS: "CYL",
    DRUMS: "DRM",
    DOZENS: "DZN",
    "FLEXITANK/CONTAINERCOMBINATION": "FCC",
    "FIBRE DRUM": "FDR",
    "INTERMEDIATE BULK CONTAINER": "IBC",
    ITEM: "ITM",
    JOTTAS: "JOT",
    KEGS: "KEG",
    LOTS: "LOT",
    LOOSE: "LSE",
    "LIFT VAN": "LVN",
    MODULES: "MDL",
    "METAL DRUMS": "MDR",
    "METRIC TON": "MTN",
    NUMBERS: "NOS",
    PAIL: "PAL",
    PARCEL: "PCL",
    PIECES: "PCS",
    "PLASTIC DRUM": "PDR",
    PACKAGES: "PKG",
    PACKET: "PKT",
    PALLET: "PLT",
    REEL: "REL",
    ROLLS: "ROL",
    SACKS: "SAK",
    SETS: "SET",
    SHEETS: "SHT",
    SKID: "SKD",
    "STEEL PACKAGES": "SPK",
    "STEEL PLATES": "SPL",
    "STEEL TRUNKS": "STR",
    "TEA CHEST": "TCH",
    UNIT: "UNT",
  };

  const [shippingContainerRows, setShippingContainerRows] = useState(1);

  const [vesselOptions, setVesselOptions] = useState([]);
  const [voyageOptions, setVoyageOptions] = useState([]);
  const [lineCodeOptions, setLineCodeOptions] = useState([]);
  const [containerOptions, setContainerOptions] = useState([]);
  const [masterContainer, setMasterContainer] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [selectedContainerDetails, setSelectedContainerDetails] = useState([]);
  const [vgmValue, setVgmValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [blDetails, setBlDetails] = useState({
    blno: "",
    shipperName: "",
    designation: "",
    shipperAddress: "",
    consigneeName: "",
    consigneeAddress: "",
    notifyPartyName: "",
    notifyPartyAddress: "",
    por: "",
    pol: "",
    pod: "",
    fpod: "",
    placeOfIssue: "",
    carrierInformation: "",
    vessel: "",
    voyage: "",
    hscode: "",
    goodsDescription: "",
    marksAndNumbers: "",
    shippingContainerInfo: [containerInfo],
  });

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "vesseltype") {
      getvoyagesvessel(value);
    }

    if (name === "goodsDescriptiom") {
      const alphanumericRegex = /^[a-zA-Z0-9]*$/;
      if (!alphanumericRegex.test(value)) {
        return;
      }
    }

    setBlDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(name, value);
  };
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    // console.log("blDetails.id", blDetails.id);
    let data = blDetails;
    data.shippingContainerInfo.map((container) => {
      container.vgm = +container.grossWt + +container.tareWt;
      return container;
    });
    e.preventDefault();
    try {
      if (blDetails.id) {
        const res = await api.postAndUpdate(`/updateBLDetails`, data, "put");
      } else {
        const res = await api.postAndUpdate(
          "/createBLDetails/containerInfo",
          data,
          "post"
        );
      }
      navigate("/bl-list");
    } catch (error) {
      alert("Duplicate Entry", error);
    }

    // console.log("mastercontainerData",masterContainer);

    // Perform validation or submit data to the server
    // console.log("Form data submitted:", blDetails);
  };
  const fetchSingleBLDetails = async () => {
    const response = await api.get(`/getBLDetailsById/${id}`);
    if (response === null) {
      return;
    }
    setShippingContainerRows(response.shippingContainerInfo.length);
    setBlDetails(response);
  };
  useEffect(() => {
    if (id !== "add") {
      fetchSingleBLDetails();
    }
  }, []);

  const handleContainerChange = (index, e) => {
    const { name, value } = e.target;
    
  // Perform validation for alphanumeric characters (capital letters and numbers)
  const validCharactersRegex = /^[A-Z0-9]+$/;
  if (name === "sealNumber" && !validCharactersRegex.test(value)) {
    // Display an error message or take appropriate action for invalid input
    // For example:
    alert("Please enter alphanumeric characters (capital letters and numbers) for the Seal Number.");
    return; // Prevent further execution
  }

    setSearchTerm(value);
    // Check if the entered containerNumber already exists
  const isUnique = blDetails.shippingContainerInfo.every((container, i) => {
    return i === index || container.containerNumber !== value;
  });

  if (!isUnique) {
    // If not unique, display an error message or take appropriate action
    alert("Container number must be unique!");
    return; // Prevent further execution
  }

    setBlDetails((prevData) => {
      const updatedContainers = [...prevData.shippingContainerInfo];

      updatedContainers[index] = {
        ...updatedContainers[index],
        [name]: value,
      };

      return {
        ...prevData,
        shippingContainerInfo: updatedContainers,
      };
    });
    if (name === "containerNumber") {
      // Remove the selected container from containerOptions
      const filteredOptions = containerOptions.filter(option => option.containerNumber !== value);
      setContainerOptions(filteredOptions);
      sample(value, index);
    }
    // console.log("abc", value , index);
  };
  useEffect(() => {
    // Assuming you have some initial container numbers in blDetails.shippingContainerInfo
    blDetails.shippingContainerInfo.forEach((container, index) => {
      if (container.containerNumber) {
        sample(container.containerNumber, index);
      }
    });
  }, []);

  // console.log("blDetails=>>>>", blDetails);

  const generate = (noOfRows) => {
    let updatedContainerInfo = [...blDetails.shippingContainerInfo]; // create a new copy of the array

    if (noOfRows < 1) {
      noOfRows = 1;
    }

    for (let i = 0; i < noOfRows; i++) {
      if (i >= updatedContainerInfo.length) {
        updatedContainerInfo.push({ ...containerInfo }); // push a new copy of containerInfo
      }
    }

    // Trim the array if the new number of rows is less than the current length
    if (noOfRows < updatedContainerInfo.length) {
      updatedContainerInfo = updatedContainerInfo.slice(0, noOfRows);
    }

    setShippingContainerRows(noOfRows);
    setBlDetails((prevData) => ({
      ...prevData,
      shippingContainerInfo: updatedContainerInfo,
    }));
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch vessel details
        const vesselResponse = await api.get("/getAllVessel");
        setVesselOptions(vesselResponse);
        // console.log(      "vesselresponse:", vesselResponse);

        // Fetch voyage details
        const voyageResponse = await api.get("/getAllVoyageDetails");
        setVoyageOptions(voyageResponse);
        // console.log("voyageResponse:", voyageResponse);
        const containerResponse = await api.get("/getAllContainerDetails");
        setContainerOptions(containerResponse);
        const lineresponse = await api.get("/getAllLineDetails");
        setLineCodeOptions(lineresponse);
        // console.log("lineresponse:", lineresponse);
      } catch (error) {
        // console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  // const sample = async (containerNumber) => {
  //   const containerResponse = await api.get(`/getcontainer/${containerNumber}`);
  //   setMasterContainer(containerResponse);
  // };
  const sample = async (containerNumber, index) => {
    try {
      const containerResponse = await api.get(
        `/getcontainer/${containerNumber}`
      );
      console.log("Container Response:", containerResponse);

      if (containerResponse) {
        const updatedMasterContainer = [...masterContainer];
        updatedMasterContainer[index] = containerResponse[0];
        // console.log("Updated Master Container:", updatedMasterContainer);

        // Update the state with the selected container details
        setMasterContainer(updatedMasterContainer);
        setBlDetails((prevData) => {
          const updatedContainers = [...prevData.shippingContainerInfo];

          updatedContainers[index] = {
            ...updatedContainers[index],
            containerType: `${containerResponse?.[0]?.containerType || ""}`,
            tareWt: `${containerResponse?.[0]?.tareWeight || ""}`,
            cLASS: `${containerResponse?.[0]?.containerSize || ""}`,
          };
          

          return {
            ...prevData,
            shippingContainerInfo: updatedContainers,
          };
        });
        // setBlDetails((prevData) => ({
        //   ...prevData,
        //   shippingContainerInfo: prevData.shippingContainerInfo.map(
        //     (container, i) =>
        //       i === index
        //         ? {
        //             ...container,
        //             containerType: containerResponse[i]?.containerType || "", // Replace with your property names
        //             tareWt: containerResponse[i]?.tareWeight ,
        //           }
        //         : container
        //   ),
        // }));
      }
    } catch (error) {}
  };
  const getvoyagesvessel = async (vesselId) => {
    const voyageResponse = await api.get(`/detailsbyvessel/${vesselId}`);
    setVoyageOptions(voyageResponse);
  };

  console.log(
    "blDetails.shippingContainerInfo====>",
    blDetails.shippingContainerInfo
  );

  return (
    <div className="">
      <h1 className="text-center mt-5 mb-5">BL Details</h1>
      <Form onSubmit={handleSubmit} className=" rounded pt-5 pb-5 mb-5 border">
        <Form.Group className="mb-3" controlId="blNumber">
          <Form.Label>BL Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter BL Number"
            name="blno"
            value={blDetails.blno}
            onChange={handleChange}
            maxLength={20} // Set maximum length
            pattern="[A-Za-z0-9]+" // Set pattern for alpha-numeric
            title="Please enter alpha-numeric characters only." // Set title for pattern mismatch
            required // Make the field required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="designation">
          <Form.Label>Designation</Form.Label>
          <Form.Control
            as="select"
            name="designation"
            value={blDetails.designation}
            onChange={handleChange}
          >
            <option value=" ">Select designation</option>

            {Object.keys(designationdata).map((country) => (
              <option
                key={designationdata[country]}
                value={designationdata[country]}
              >
                {country}({designationdata[country]})
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="shipperName">
          <Form.Label>Shipper Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Shipper Name"
            name="shipperName"
            value={blDetails.shipperName}
            onChange={handleChange}
            pattern="[A-Za-z\s]+"
            title="Please enter characters, including space."
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="shipperAddress">
          <Form.Label>Shipper Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Shipper Address"
            name="shipperAddress"
            value={blDetails.shipperAddress}
            onChange={handleChange}
            pattern="[A-Za-z0-9\s\.,#-]+"
            title="Please enter characters, including space, numbers, and common special characters (.,#-)."
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="consigneeName">
          <Form.Label>Consignee Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Consignee Name"
            name="consigneeName"
            value={blDetails.consigneeName}
            onChange={handleChange}
            pattern="[A-Za-z\s]+"
            title="Please enter characters, including space."
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="consigneeAddress">
          <Form.Label>Consignee Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Consignee Address"
            name="consigneeAddress"
            value={blDetails.consigneeAddress}
            onChange={handleChange}
            pattern="[A-Za-z0-9\s\.,#-]+"
            title="Please enter characters, including space, numbers, and common special characters (.,#-)."
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="notifyPartyName">
          <Form.Label>Notify Party Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Notify Party Name"
            name="notifyPartyName"
            value={blDetails.notifyPartyName}
            onChange={handleChange}
            pattern="[A-Za-z\s]+"
            title="Please enter characters, including space."
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="notifyPartyAddress">
          <Form.Label>Notify Party Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Notify Party Address"
            name="notifyPartyAddress"
            value={blDetails.notifyPartyAddress}
            onChange={handleChange}
            pattern="[A-Za-z0-9\s\.,#-]+"
            title="Please enter characters, including space, numbers, and common special characters (.,#-)."
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="POR">
          <Form.Label>Port Of Receipt</Form.Label>
          <Form.Control
            as="select"
            placeholder="Enter POR"
            name="por"
            value={blDetails.por}
            onChange={handleChange}
            required
          >
            <option value="">Select port of receipt</option>
            {portsData.map((port) => (
              <option key={port.CITY_CODE} value={port.CITY_CODE}>
                {port.CITY_CODE}-{port.CITY_NAME} ({port.CITY_CODE})
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="POL">
          <Form.Label>Port Of Loading</Form.Label>
          <Form.Control
            as="select"
            placeholder="Enter POL"
            name="pol"
            value={blDetails.pol}
            onChange={handleChange}
            required
          >
            <option value="">Select port of loading</option>
            {portsData.map((port) => (
              <option key={port.CITY_CODE} value={port.CITY_CODE}>
                {port.CITY_CODE}-{port.CITY_NAME} ({port.DPA_PORT_CODE})
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="pod">
          <Form.Label>Port Of Discharge</Form.Label>
          <Form.Control
            as="select"
            placeholder="Enter pod"
            name="pod"
            value={blDetails.pod}
            onChange={handleChange}
            required
          >
            <option value="">Select port of discharge</option>
            {portsData.map((port) => (
              <option key={port.CITY_CODE} value={port.CITY_CODE}>
                {port.CITY_CODE}-{port.CITY_NAME} ({port.DPA_PORT_CODE})
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="fpod">
          <Form.Label>Port Of Delivery</Form.Label>
          <Form.Control
            as="select"
            placeholder="Enter Port of Delivery"
            name="fpod"
            value={blDetails.fpod}
            onChange={handleChange}
            required
          >
            <option value="">Select fpod</option>
            {portsData.map((port) => (
              <option key={port.CITY_CODE} value={port.CITY_CODE}>
                {port.CITY_CODE}-{port.CITY_NAME} ({port.DPA_PORT_CODE})
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="carrierInformation">
          <Form.Label>Select Carrier Information</Form.Label>
          <Form.Control
            as="select"
            name="carrierInformation"
            value={blDetails.carrierInformation}
            onChange={handleChange}
            required
          >
            <option value="">Select Carrier Information</option>
            {lineCodeOptions.map((lineCodeOption) => (
              <option key={lineCodeOption.id} value={lineCodeOption.code}>
                {lineCodeOption.code}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="vessel">
          <Form.Label>Select Vessel</Form.Label>
          <Form.Control
            as="select"
            name="vesseltype"
            value={blDetails.vesseltype}
            onChange={handleChange}
            required
          >
            <option value="">Select Vessel</option>
            {vesselOptions &&
              vesselOptions.map((vessel) => (
                <option key={vessel.id} value={vessel.vesselId}>
                  {vessel.vesselId}
                </option>
              ))}
            {/* Add dynamic options for vessels */}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="voyage">
          <Form.Label>Select Voyage</Form.Label>
          <Form.Control
            as="select"
            name="voyage"
            value={blDetails.voyage}
            onChange={handleChange}
            required
          >
            <option value="">Select Voyage</option>
            {voyageOptions &&
              voyageOptions.map((voyage) => (
                <option key={voyage.id} value={voyage.voyageNumber}>
                  {voyage.voyageNumber}
                </option>
              ))}
            {/* Add dynamic options for voyages */}
          </Form.Control>
        </Form.Group>

        {/* add new chnages by atul start*/}

        <Form.Group className="mb-3" controlId="notifyPartyAddress">
          <Form.Label>HS Code</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter HS Code"
            name="hscode"
            value={blDetails.hscode}
            onChange={handleChange}
            pattern="\d{8}"
            title="Please enter an 8-digit number for the HS Code."
            required
          />
        </Form.Group>

        {/* add new chnages by atul end */}

        <Form.Group className="mb-3" controlId="goodsDescription">
          <Form.Label>Description of Goods</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter Description of Goods"
            name="goodsDescription"
            value={blDetails.goodsDescription}
            onChange={handleChange}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="marksAndNumbers">
          <Form.Label>Marks & Numbers</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Marks & Numbers"
            name="marksAndNumbers"
            value={blDetails.marksAndNumbers}
            onChange={handleChange}
            pattern="[A-Za-z0-9\s]+"
            title="Please enter alphanumeric characters, including spaces."
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="shippingContainerInfo">
          <Form.Label>Shipping Container Information</Form.Label>
          <Form.Control
            as="input"
            type="number"
            rows={1}
            placeholder="Enter Shipping Container Information"
            name="shippingContainerInfo"
            value={blDetails.shippingContainerCount}
            onChange={(e) => generate(e.target.value)}
          />
          {/* Container Information Table */}
          <table className="table mt-3">
            <thead>
              <tr>
                <th>Co No.</th>
                <th>ISO Code</th>
                <th>S. No.</th>
                <th>No. Packg</th>
                <th>Packg Type</th>
                <th>Gr. Wt</th>
                <th>Dimen</th>
                <th>Tare Wt</th>
                <th>VGM</th>
                <th>DG</th>
                <th>REEFER</th>
                {/* <th>Temp Req</th> */}
                <th>OOG</th>
              </tr>
            </thead>
            <tbody>
              {blDetails.shippingContainerInfo.map((container, index) => (
                <tr key={index}>
                <td>
                    <Form.Control
                      as="input"
                      list={`containerNumbers-${index}`}
                      placeholder="Search container number"
                      value={container.containerNumber}
                      name="containerNumber"
                      onChange={(e) => handleContainerChange(index, e)}
                    />
                    <datalist  id={`containerNumbers-${index}`}>
                      {containerOptions &&
                        containerOptions
                          .filter((option) =>
                            option.containerNumber
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((containerOption , optionIndex) => (
                            <option
                              key={optionIndex}
                              value={containerOption.containerNumber}
                            />
                          ))}
                    </datalist>
                  </td>
                  <td>
  <Form.Control
    type="text"
    placeholder="ISO Code"
    name="containerType"
    value={container?.containerType}
    onChange={(e) => handleContainerChange(index, e)}
  />
  {/* Hidden input field for containerSize */}
  <input
    type="hidden"
    name="cLASS"
    value={container?.containerSize || ""}   
    onChange={(e) => handleContainerChange(index, e)}
  />
</td>

                  
                  

<td>
  <Form.Control
    type="text"
    value={container.sealNumber}
    placeholder="Seal Number"
    name="sealNumber"
    onChange={(e) => handleContainerChange(index, e)}
    maxLength="15"
    required
  />
</td>
                  <td>
                    <Form.Control
                      type="text"
                      value={container.no_of_package}
                      placeholder="no_of_package"
                      name="no_of_package"
                      onChange={(e) => handleContainerChange(index, e)}
                    />
                  </td>

                  <td>
                    {/* <Form.Control
                      type="text"
                      value={container.packagetype}
                      placeholder="Package Type"
                      name="packagetype"
                      onChange={(e) => handleContainerChange(index, e)}
                    /> */}
                    <Form.Control
        as="select"
        name="packagetype"
        value={container.packagetype}
        onChange={(e) => handleContainerChange(index, e)}
      >
        <option value="">Select packagetype</option>
        {Object.entries(packageTypes).map(([packagetype, shortcode]) => (
          <option
            key={shortcode}
            value={`${packagetype}:${shortcode}`}
          >
            {packagetype} ({shortcode})
          </option>
        ))}
      </Form.Control>
                  </td>

                  <td>
                    <Form.Control
                      type="text"
                      placeholder="gross Wt"
                      name="grossWt"
                      value={container.grossWt}
                      onChange={(e) => handleContainerChange(index, e)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Dimensions"
                      name="dimension"
                      value={container.dimension}
                      onChange={(e) => handleContainerChange(index, e)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      placeholder="Tare wt"
                      name="tareWt"
                      value={container?.tareWt || ""}
                      onChange={(e) => handleContainerChange(index, e)}
                    />
                  </td>

                  <td>
                    <Form.Control
                      type="text"
                      placeholder="VGM"
                      name="vgm"
                      disabled
                      value={
                        (parseFloat(masterContainer[index]?.tareWeight) || 0) +
                        (parseFloat(container.grossWt) || 0)
                      }
                      onChange={(e) => handleContainerChange(index, e)}
                    />
                  </td>

                  <td>
                    <Form.Check
                      type="checkbox"
                      label="DG"
                      checked={container.dg === "Y"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleContainerChange(index, {
                          ...e,
                          target: {
                            name: "dg",
                            value: isChecked ? "Y" : "N",
                          },
                        });

                        // If the checkbox is unchecked, clear related fields
                        if (!isChecked) {
                          handleContainerChange(index, {
                            target: { name: "imcoClass", value: " " },
                          });
                          handleContainerChange(index, {
                            target: { name: "unNumber", value: " " },
                          });
                          handleContainerChange(index, {
                            target: { name: "flashPoint", value: " " },
                          });
                          handleContainerChange(index, {
                            target: { name: "flashPointUnit", value: " " },
                          });
                        }
                      }}
                    />
                    {container.dg === "Y" && (
                      <div>
                        <label>IMCO</label>
                        <div>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="IMCO"
                              name="imcoClass"
                              value={container.imcoClass}
                              onChange={(e) => handleContainerChange(index, e)}
                            />
                          </td>
                          <div>
                            <label htmlFor="unNumber">UN No.</label>
                          </div>
                          <div>
                            <input
                              type="number"
                              id="unNumber"
                              name="unNumber"
                              placeholder="Enter UN Number"
                              value={container.unNumber}
                              onChange={(e) => handleContainerChange(index, e)}
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="flashPoint">Flash Po.</label>
                          <div>
                            <input
                              type="number"
                              id="flashPoint"
                              name="flashPoint"
                              step="0.1"
                              min="-999.9"
                              max="999.9"
                              placeholder="Enter Flash Point"
                              value={container.flashPoint}
                              onChange={(e) => handleContainerChange(index, e)}
                              required
                            />
                          </div>
                          <div>
                            <select
                              id="flashPointUnit"
                              name="flashPointUnit"
                              value={container.flashPointUnit}
                              onChange={(e) => handleContainerChange(index, e)}
                            >
                              <option>select flash point</option>
                              <option value="C">C</option>
                              <option value="F">F</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>

                  <td>
                    <Form.Check
                      type="checkbox"
                      label="Reefer"
                      name={"reefer"}
                      checked={container.reefer === "Y"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleContainerChange(index, {
                          ...e,
                          target: {
                            name: "reefer",
                            value: isChecked ? "Y" : "N",
                          },
                        });

                        // If the checkbox is unchecked, update related fields to null
                        if (!isChecked) {
                          handleContainerChange(index, {
                            target: { name: "temp", value: " " },
                          });
                          handleContainerChange(index, {
                            target: { name: "tempunit", value: " " },
                          });
                        }
                      }}
                    />
                    {container.reefer === "Y" && (
                      <div>
                        <div>
                          <div></div>
                        </div>

                        <div>
                          <label htmlFor="temp">Temp</label>

                          <div>
                            <input
                              type="number"
                              id="temp"
                              name="temp"
                              step="0.1"
                              min="-99"
                              max="99"
                              value={container.temp}
                              placeholder="Enter temperature"
                              onChange={(e) => handleContainerChange(index, e)}
                              required
                            />
                          </div>

                          <div>
                            <select
                              value={container.tempunit}
                              id="tempunit"
                              name="tempunit"
                              onChange={(e) => handleContainerChange(index, e)}
                            >
                              <option>select temp unit</option>
                              <option value="C">C</option>
                              <option value="F">F</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </td>

                  <td>
                    <Form.Check
                      type="checkbox"
                      label="OOG"
                      checked={container.oog === "Y"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleContainerChange(index, {
                          ...e,
                          target: {
                            name: "oog",
                            value: isChecked ? "Y" : "N",
                          },
                        });

                        if (!isChecked) {
                          // Set oogvalue fields to null when isChecked is false
                          for (let i = 1; i <= 5; i++) {
                            handleContainerChange(index, {
                              target: { name: `oogvalue${i}`, value: "" },
                            });
                            handleContainerChange(index, {
                              target: { name: `position${i}`, value: "" },
                            });
                          }
                        }
                      }}
                    />
                    {container.oog === "Y" && (
                      <table>
                        <thead className="table mt-3">
                          <tr>
                            <th>Position</th>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label="Top"
                                name="position1"
                                checked={container?.position1 === "top"} // Check if the position is "top"
                                onChange={(e) =>
                                  handleContainerChange(index, {
                                    ...e,
                                    target: {
                                      name: "position1",
                                      value: e.target.checked ? "top" : "", // Set the value to "top" if checked, otherwise an empty string
                                    },
                                  })
                                }
                              />
                            </td>
                            <input
                              type="text"
                              name="oogvalue1"
                              value={container.oogvalue1}
                              onChange={(e) =>
                                handleContainerChange(index, {
                                  ...e,
                                  target: {
                                    name: "oogvalue1",
                                    value: container?.position1
                                      ? e.target.value.replace(/\D/g, "")
                                      : "", // Accept only numbers
                                  },
                                })
                              }
                              disabled={!container?.position1} // Disable if position1 is not checked
                            />
                          </tr>
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label="Right"
                                checked={container?.position2 === "Right"}
                                onChange={(e) =>
                                  handleContainerChange(index, {
                                    ...e,
                                    target: {
                                      name: "position2",
                                      value: e.target.checked ? "Right" : "",
                                    },
                                  })
                                }
                              />
                            </td>
                            <input
                              type="text"
                              name="oogvalue2"
                              value={container.oogvalue2}
                              onChange={(e) =>
                                handleContainerChange(index, {
                                  ...e,
                                  target: {
                                    name: "oogvalue2",
                                    value: container?.position2
                                      ? e.target.value.replace(/\D/g, "")
                                      : "", // Accept only numbers
                                  },
                                })
                              }
                              disabled={!container?.position2} // Disable if position1 is not checked
                            />
                          </tr>
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label="Left"
                                checked={container?.position3 === "Left"}
                                onChange={(e) =>
                                  handleContainerChange(index, {
                                    ...e,
                                    target: {
                                      name: "position3",
                                      value: e.target.checked ? "Left" : "",
                                    },
                                  })
                                }
                              />
                            </td>
                            <input
                              type="text"
                              name="oogvalue3"
                              value={container.oogvalue3}
                              onChange={(e) =>
                                handleContainerChange(index, {
                                  ...e,
                                  target: {
                                    name: "oogvalue3",
                                    value: container?.position3
                                      ? e.target.value.replace(/\D/g, "")
                                      : "", // Accept only numbers
                                  },
                                })
                              }
                              disabled={!container?.position3} // Disable if position1 is not checked
                            />
                          </tr>
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label="Front"
                                checked={container?.position4 === "Front"}
                                onChange={(e) =>
                                  handleContainerChange(index, {
                                    ...e,
                                    target: {
                                      name: "position4",
                                      value: e.target.checked ? "Front" : "",
                                    },
                                  })
                                }
                              />
                            </td>
                            <input
                              type="text"
                              name="oogvalue4"
                              value={container.oogvalue4}
                              onChange={(e) =>
                                handleContainerChange(index, {
                                  ...e,
                                  target: {
                                    name: "oogvalue4",
                                    value: container?.position4
                                      ? e.target.value.replace(/\D/g, "")
                                      : "", // Accept only numbers
                                  },
                                })
                              }
                              disabled={!container?.position4} // Disable if position1 is not checked
                            />
                          </tr>
                          <tr>
                            <td>
                              <Form.Check
                                type="checkbox"
                                label="Back"
                                checked={container?.position5 === "Back"}
                                onChange={(e) =>
                                  handleContainerChange(index, {
                                    ...e,
                                    target: {
                                      name: "position5",
                                      value: e.target.checked ? "Back" : "",
                                    },
                                  })
                                }
                              />
                            </td>
                            <input
                              type="text"
                              name="oogvalue5"
                              value={container.oogvalue5}
                              onChange={(e) =>
                                handleContainerChange(index, {
                                  ...e,
                                  target: {
                                    name: "oogvalue5",
                                    value: container?.position5
                                      ? e.target.value.replace(/\D/g, "")
                                      : "", // Accept only numbers
                                  },
                                })
                              }
                              disabled={!container?.position5} // Disable if position1 is not checked
                            />
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Form.Group>

        <Button variant="primary" type="submit">
          {blDetails.id ? "Update" : "Submit"}
        </Button>
      </Form>
    </div>
  );
};

export default BlDetailsForm;
