import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { api } from "../../lib/api";

const ContainerDetailsTable = () => {
  const [containers, setContainers] = useState([]);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [formData, setFormData] = useState({
    containerNumber: "",
    containerSize: "",
    containerType: "",
    tareWeight: "",
    maxPayload: "",
    owner: "",
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseViewModal = () => setShowViewModal(false);

  const handleAddContainer = () => {
    setContainers([...containers, formData]);
    setFormData({
      containerNumber: "",
      containerSize: "",
      containerType: "",
      tareWeight: "",
      maxPayload: "",
      owner: "",
    });
    handleCloseModal();
  };

  const handleView = (index) => {
    setSelectedContainer(containers[index]);
    setShowViewModal(true);
  };

  const handleUpdate = (index) => {
    console.log("Update container:", containers[index]);
    // Implement update logic here
  };

  const handleDelete = async (id) => {
    const response = await api.delete(`/deleteContainerDetails/${id}`);
    console.log(response);
    fetchData();
  };

  async function fetchData() {
    const list = await api.get("/getAllContainerDetails");
    setContainers(list);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mt-5" style={{ overflowX: "scroll" }}>
      <h1 className="text-center mb-5">Container Details</h1>
      <div className="d-flex justify-content-end">
        <Button variant="success" onClick={() => navigate("/container-details/add")}>
          <FontAwesomeIcon icon={faPlus} /> Add Container
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Container Number</th>
            <th>Container Size</th>
            <th>ISO Code</th>
            <th>Tare Weight</th>
            <th>Max Payload</th>
            <th>Owner</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {containers.map((container, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{container.containerNumber}</td>
              <td>{container.containerSize}</td>
              <td>{container.containerType}</td>
              <td>{container.tareWeight}</td>
              <td>{container.maxPayload}</td>
              <td>{container.owner}</td>
              <td>
                <Button variant="info" className="me-2" onClick={() => handleView(index)}>
                  <FontAwesomeIcon icon={faEye} />
                </Button>
                <Button variant="warning" onClick={() => navigate("/container-details/" + container.id)} className="me-2">
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button variant="danger" onClick={() => handleDelete(container.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* View Modal */}
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>Container Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedContainer && (
            <div>
              <p>Container Number: {selectedContainer.containerNumber}</p>
              <p>Container Size: {selectedContainer.containerSize}</p>
              <p>Container Type: {selectedContainer.containerType}</p>
              <p>Tare Weight: {selectedContainer.tareWeight}</p>
              <p>Max Payload: {selectedContainer.maxPayload}</p>
              <p>Owner: {selectedContainer.owner}</p>
              {/* Add other fields as needed */}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Container</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* ... existing form controls ... */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddContainer}>
            Add Container
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContainerDetailsTable;
