import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { api } from "../../lib/api";

const VoyageDetailsTable = () => {
  const [voyages, setVoyages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedVoyage, setSelectedVoyage] = useState(null);
  const navigate = useNavigate();

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseViewModal = () => setShowViewModal(false);

  const [formData, setFormData] = useState({
    voyageNumber: "",
    rotationNumber: "",
    etaDate: "",
    etdDate: "",
    previousPort: "",
    destinationPort: "",
    intermediatePorts: "",
    routeItinerary: "",
  });

  const handleAddVoyage = () => {
    setVoyages([...voyages, formData]);
    setFormData({
      voyageNumber: "",
      rotationNumber: "",
      etaDate: "",
      etdDate: "",
      previousPort: "",
      destinationPort: "",
      intermediatePorts: "",
      routeItinerary: "",
    });
    handleCloseModal();
  };

  const handleView = (index) => {
    setSelectedVoyage(voyages[index]);
    setShowViewModal(true);
  };

  const handleUpdate = (index) => {
    console.log("Update voyage:", voyages[index]);
  };

  const handleDelete = async (id) => {
    const response = await api.delete(`/deleteVoyageDetails/${id}`);
    console.log(response);
    fetchData();
  };

  async function fetchData() {
    const list = await api.get("/getAllVoyageDetails");
    setVoyages(list);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <div className="container mt-5" style={{ overflowX: "scroll" }}>
      <h1 className="text-center mb-5">Voyage Details</h1>
      <div className="d-flex justify-content-end">
        <Button variant="success" onClick={() => navigate("/voyage-details/add")}>
          <FontAwesomeIcon icon={faPlus} /> Add Voyage
        </Button>
      </div>
      <div className="container mt-5" style={{ overflowX: "scroll" }}>
        <Table striped bordered hover className="mt-3 overflow-scroll">
          <thead>
            <tr>
              <th>#</th>
              <th>Voyage Number</th>
              <th>Rotation Number</th>
              <th>Voyage ETA Date</th>
              <th>Voyage ETD Date</th>
              <th>Previous Port</th>
              <th>Destination Port</th>
              <th>Intermediate Ports</th>
              <th>Route/Itinerary</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {voyages.map((voyage, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{voyage.voyageNumber}</td>
                <td>{voyage.rotationNumber}</td>
                <td>{voyage.etaDate}</td>
                <td>{voyage.etdDate}</td>
                <td>{voyage.previousPort}</td>
                <td>{voyage.destinationPort}</td>
                <td>{voyage.intermediatePort}</td>
                <td>{voyage.route}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleView(index)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>{" "}
                  <Button
                    variant="outline-warning"
                    onClick={() => navigate("/voyage-details/" + voyage.id)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>{" "}
                  <Button
                    variant="outline-danger"
                    onClick={() => handleDelete(voyage.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* View Modal */}
        <Modal show={showViewModal} onHide={handleCloseViewModal}>
          <Modal.Header closeButton>
            <Modal.Title>Voyage Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Display details of the selected voyage */}
            {selectedVoyage && (
              <div>
                <p>Voyage Number: {selectedVoyage.voyageNumber}</p>
                <p>Rotation Number: {selectedVoyage.rotationNumber}</p>
                <p>Voyage ETA Date: {selectedVoyage.etaDate}</p>
                <p>Voyage ETD Date: {selectedVoyage.etdDate}</p>
                <p>Previous Port: {selectedVoyage.previousPort}</p>
                <p>Destination Port: {selectedVoyage.destinationPort}</p>
                <p>Intermediate Ports: {selectedVoyage.intermediatePort}</p>
                <p>Route/Itinerary: {selectedVoyage.route}</p>
                
                {/* Add other fields as needed */}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseViewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Voyage Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
          {/* ... (other code) */}
        </Modal>
      </div>
      </div>
    </>
  );
};

export default VoyageDetailsTable;
