export const portsData = [
    {
      CITY_CODE: "AEAJM",
      CITY_NAME: "AJMAN",
      DPA_PORT_CODE: "AJM",
    },
    {
      CITY_CODE: "AEAUH",
      CITY_NAME: "ABU DHABI",
      DPA_PORT_CODE: "AUH",
    },
    {
      CITY_CODE: "AEDHF",
      CITY_NAME: "AL DHAFRA",
      DPA_PORT_CODE: "DHF",
    },
    {
      CITY_CODE: "AEDWC",
      CITY_NAME: "DUBAI WORLD CENTRAL",
      DPA_PORT_CODE: "DWC",
    },
    {
      CITY_CODE: "AEDXB",
      CITY_NAME: "DUBAI",
      DPA_PORT_CODE: "DXB",
    },
    {
      CITY_CODE: "AEFJR",
      CITY_NAME: "FUJAIRAH",
      DPA_PORT_CODE: "FUJ",
    },
    {
      CITY_CODE: "AEHAM",
      CITY_NAME: "HAMRIYA",
      DPA_PORT_CODE: "HMY",
    },
    {
      CITY_CODE: "AEJEA",
      CITY_NAME: "JEBEL ALI",
      DPA_PORT_CODE: "JEA",
    },
    {
      CITY_CODE: "AEJEB",
      CITY_NAME: "JEBEL ALI JEB",
      DPA_PORT_CODE: "JEB",
    },
    {
      CITY_CODE: "AEJEI",
      CITY_NAME: "JEBEL ALI T THREE",
      DPA_PORT_CODE: "JEI",
    },
    {
      CITY_CODE: "AEKHL",
      CITY_NAME: "MINA KHALIFA",
      DPA_PORT_CODE: "KHF",
    },
    {
      CITY_CODE: "AEKLF",
      CITY_NAME: "KHOR AL FAKKAN",
      DPA_PORT_CODE: "KLF",
    },
    {
      CITY_CODE: "AEMKH",
      CITY_NAME: "MINA KHALID",
      DPA_PORT_CODE: "MKF",
    },
    {
      CITY_CODE: "AEMSA",
      CITY_NAME: "MINA SAQR",
      DPA_PORT_CODE: "MSA",
    },
    {
      CITY_CODE: "AEMZD",
      CITY_NAME: "MINA ZAYED",
      DPA_PORT_CODE: "MZD",
    },
    {
      CITY_CODE: "AEPRA",
      CITY_NAME: "PORT RASHID",
      DPA_PORT_CODE: "PRA",
    },
    {
      CITY_CODE: "AEQIW",
      CITY_NAME: "UMM AL QUAIWAIN",
      DPA_PORT_CODE: "QIW",
    },
    {
      CITY_CODE: "AERKT",
      CITY_NAME: "RAS AL KHAIMAH",
      DPA_PORT_CODE: "RKT",
    },
    {
      CITY_CODE: "AERUW",
      CITY_NAME: "RUWAIS",
      DPA_PORT_CODE: "RUW",
    },
    {
      CITY_CODE: "AESHJ",
      CITY_NAME: "SHARJAH",
      DPA_PORT_CODE: "SHJ",
    },
    {
      CITY_CODE: "AEZZZ",
      CITY_NAME: "PORT RASID",
      DPA_PORT_CODE: "ZZZ",
    },
    {
      CITY_CODE: "AFGZI",
      CITY_NAME: "GHAZNI",
      DPA_PORT_CODE: "GHA",
    },
    {
      CITY_CODE: "AFSHK",
      CITY_NAME: "FOB SHANK",
      DPA_PORT_CODE: "SNK",
    },
    {
      CITY_CODE: "AFSRO",
      CITY_NAME: "SHARONA",
      DPA_PORT_CODE: "SRA",
    },
    {
      CITY_CODE: "AGANU",
      CITY_NAME: "ST JOHNS",
      DPA_PORT_CODE: "ANU",
    },
    {
      CITY_CODE: "AGBBQ",
      CITY_NAME: "BARBUDA",
      DPA_PORT_CODE: "BBQ",
    },
    {
      CITY_CODE: "AGSJO",
      CITY_NAME: "SAINT JOHNS",
      DPA_PORT_CODE: "SHF",
    },
    {
      CITY_CODE: "AIROA",
      CITY_NAME: "THE ROAD",
      DPA_PORT_CODE: "ROA",
    },
    {
      CITY_CODE: "ALDRZ",
      CITY_NAME: "DURRES",
      DPA_PORT_CODE: "DRZ",
    },
    {
      CITY_CODE: "ALSHG",
      CITY_NAME: "SHENGJIN",
      DPA_PORT_CODE: "SGJ",
    },
    {
      CITY_CODE: "ALVOA",
      CITY_NAME: "VALONA",
      DPA_PORT_CODE: "VOA",
    },
    {
      CITY_CODE: "ANAUA",
      CITY_NAME: "ARUBA",
      DPA_PORT_CODE: "AUA",
    },
    {
      CITY_CODE: "ANBAR",
      CITY_NAME: "BARCADERA",
      DPA_PORT_CODE: "BAC",
    },
    {
      CITY_CODE: "ANCUR",
      CITY_NAME: "CURACAO",
      DPA_PORT_CODE: "CUR",
    },
    {
      CITY_CODE: "ANFUI",
      CITY_NAME: "FUIKBAAI",
      DPA_PORT_CODE: "FUI",
    },
    {
      CITY_CODE: "ANPHI",
      CITY_NAME: "PHILIPSBURG",
      DPA_PORT_CODE: "PHI",
    },
    {
      CITY_CODE: "ANSXM",
      CITY_NAME: "ST. MAARTEN",
      DPA_PORT_CODE: "SXM",
    },
    {
      CITY_CODE: "ANWIL",
      CITY_NAME: "WILLEMSTAD",
      DPA_PORT_CODE: "WIL",
    },
    {
      CITY_CODE: "AOAZZ",
      CITY_NAME: "AMBRIZ",
      DPA_PORT_CODE: "AMX",
    },
    {
      CITY_CODE: "AOCAB",
      CITY_NAME: "CABINDA",
      DPA_PORT_CODE: "CAB",
    },
    {
      CITY_CODE: "AODGR",
      CITY_NAME: "DOMBE GRANDE",
      DPA_PORT_CODE: "DGR",
    },
    {
      CITY_CODE: "AOLAD",
      CITY_NAME: "LUANDA",
      DPA_PORT_CODE: "LAD",
    },
    {
      CITY_CODE: "AOLOB",
      CITY_NAME: "LOBITO",
      DPA_PORT_CODE: "LOB",
    },
    {
      CITY_CODE: "AOPBN",
      CITY_NAME: "PORTO AMBOIM",
      DPA_PORT_CODE: "PBN",
    },
    {
      CITY_CODE: "AOSAZ",
      CITY_NAME: "SAN ANTONIO DO ZAIRE",
      DPA_PORT_CODE: "SAZ",
    },
    {
      CITY_CODE: "AOSOQ",
      CITY_NAME: "SOYO QUINFUQUENA TERMINAL",
      DPA_PORT_CODE: "SOQ",
    },
    {
      CITY_CODE: "AOSZA",
      CITY_NAME: "SOYO",
      DPA_PORT_CODE: "SZA",
    },
    {
      CITY_CODE: "ARBHI",
      CITY_NAME: "BAHIA BLANCA",
      DPA_PORT_CODE: "BHI",
    },
    {
      CITY_CODE: "ARBUE",
      CITY_NAME: "BUENOS AIRES",
      DPA_PORT_CODE: "BUE",
    },
    {
      CITY_CODE: "ARCMP",
      CITY_NAME: "CAMPANA",
      DPA_PORT_CODE: "CMP",
    },
    {
      CITY_CODE: "ARCNQ",
      CITY_NAME: "CORRIENTES",
      DPA_PORT_CODE: "CNQ",
    },
    {
      CITY_CODE: "ARCOL",
      CITY_NAME: "COLON",
      DPA_PORT_CODE: "COL",
    },
    {
      CITY_CODE: "ARFMA",
      CITY_NAME: "FORMOSA",
      DPA_PORT_CODE: "FMA",
    },
    {
      CITY_CODE: "ARIBY",
      CITY_NAME: "IBICUY",
      DPA_PORT_CODE: "IBY",
    },
    {
      CITY_CODE: "ARLPG",
      CITY_NAME: "LA PLATA",
      DPA_PORT_CODE: "LPG",
    },
    {
      CITY_CODE: "ARMDQ",
      CITY_NAME: "MAR DEL PLATA",
      DPA_PORT_CODE: "MDQ",
    },
    {
      CITY_CODE: "ARNEC",
      CITY_NAME: "NECOCHEA",
      DPA_PORT_CODE: "NEC",
    },
    {
      CITY_CODE: "AROYA",
      CITY_NAME: "GOYA",
      DPA_PORT_CODE: "OYA",
    },
    {
      CITY_CODE: "ARPNL",
      CITY_NAME: "PUERTO NACIONAL",
      DPA_PORT_CODE: "PNL",
    },
    {
      CITY_CODE: "ARPUD",
      CITY_NAME: "PUERTO DESEADO",
      DPA_PORT_CODE: "PUD",
    },
    {
      CITY_CODE: "ARQQN",
      CITY_NAME: "QUEQUEN",
      DPA_PORT_CODE: "QQN",
    },
    {
      CITY_CODE: "ARRGA",
      CITY_NAME: "RIO GRANDE",
      DPA_PORT_CODE: "RGA",
    },
    {
      CITY_CODE: "ARROS",
      CITY_NAME: "ROSARIO",
      DPA_PORT_CODE: "ROS",
    },
    {
      CITY_CODE: "ARRZA",
      CITY_NAME: "SANTA CRUZ",
      DPA_PORT_CODE: "RZA",
    },
    {
      CITY_CODE: "ARSAE",
      CITY_NAME: "SAN ANTONIO ESTE",
      DPA_PORT_CODE: "SAE",
    },
    {
      CITY_CODE: "ARTUC",
      CITY_NAME: "TUCUMAN",
      DPA_PORT_CODE: "TUC",
    },
    {
      CITY_CODE: "ARULA",
      CITY_NAME: "SAN JULIAN",
      DPA_PORT_CODE: "ULA",
    },
    {
      CITY_CODE: "ARUSH",
      CITY_NAME: "USHUAIA",
      DPA_PORT_CODE: "USH",
    },
    {
      CITY_CODE: "ARVCN",
      CITY_NAME: "VILLA CONSTITUCION",
      DPA_PORT_CODE: "VCN",
    },
    {
      CITY_CODE: "ARXPD",
      CITY_NAME: "SAN PEDRO",
      DPA_PORT_CODE: "XPD",
    },
    {
      CITY_CODE: "ARZAE",
      CITY_NAME: "ZARATE",
      DPA_PORT_CODE: "ZAE",
    },
    {
      CITY_CODE: "ASPPG",
      CITY_NAME: "PAGO PAGO",
      DPA_PORT_CODE: "OPP",
    },
    {
      CITY_CODE: "ATENA",
      CITY_NAME: "ENNS",
      DPA_PORT_CODE: "ENX",
    },
    {
      CITY_CODE: "ATGRZ",
      CITY_NAME: "GRAZ",
      DPA_PORT_CODE: "GQZ",
    },
    {
      CITY_CODE: "ATGUN",
      CITY_NAME: "GUNTRAMSDORF",
      DPA_PORT_CODE: "GUN",
    },
    {
      CITY_CODE: "ATVIE",
      CITY_NAME: "WIEN",
      DPA_PORT_CODE: "VIE",
    },
    {
      CITY_CODE: "ATWFT",
      CITY_NAME: "WOLFURT",
      DPA_PORT_CODE: "WFU",
    },
    {
      CITY_CODE: "AUADL",
      CITY_NAME: "ADELAIDE",
      DPA_PORT_CODE: "ADL",
    },
    {
      CITY_CODE: "AUALX",
      CITY_NAME: "ALEXANDRIA",
      DPA_PORT_CODE: "AXA",
    },
    {
      CITY_CODE: "AUBDB",
      CITY_NAME: "BUNDABERG",
      DPA_PORT_CODE: "BDB",
    },
    {
      CITY_CODE: "AUBEL",
      CITY_NAME: "BELL BAY",
      DPA_PORT_CODE: "BEB",
    },
    {
      CITY_CODE: "AUBEO",
      CITY_NAME: "BELMONT",
      DPA_PORT_CODE: "BEO",
    },
    {
      CITY_CODE: "AUBNE",
      CITY_NAME: "BRISBANE",
      DPA_PORT_CODE: "BNE",
    },
    {
      CITY_CODE: "AUBWB",
      CITY_NAME: "BARROW ISLAND",
      DPA_PORT_CODE: "BWB",
    },
    {
      CITY_CODE: "AUBWT",
      CITY_NAME: "BURNIE",
      DPA_PORT_CODE: "BWT",
    },
    {
      CITY_CODE: "AUCBR",
      CITY_NAME: "CANBERRA",
      DPA_PORT_CODE: "CBR",
    },
    {
      CITY_CODE: "AUCFS",
      CITY_NAME: "COFFS HARBOUR",
      DPA_PORT_CODE: "CFS",
    },
    {
      CITY_CODE: "AUCNS",
      CITY_NAME: "CAIRNS",
      DPA_PORT_CODE: "CNS",
    },
    {
      CITY_CODE: "AUCQP",
      CITY_NAME: "CAPE FLATTERY",
      DPA_PORT_CODE: "CQP",
    },
    {
      CITY_CODE: "AUCVQ",
      CITY_NAME: "CARNARVON",
      DPA_PORT_CODE: "CVQ",
    },
    {
      CITY_CODE: "AUDAA",
      CITY_NAME: "DARRA",
      DPA_PORT_CODE: "DAA",
    },
    {
      CITY_CODE: "AUDAM",
      CITY_NAME: "DAMPIER",
      DPA_PORT_CODE: "DMR",
    },
    {
      CITY_CODE: "AUDND",
      CITY_NAME: "DANDENONG",
      DPA_PORT_CODE: "DNU",
    },
    {
      CITY_CODE: "AUDPO",
      CITY_NAME: "DEVONPORT",
      DPA_PORT_CODE: "DPO",
    },
    {
      CITY_CODE: "AUDRW",
      CITY_NAME: "DARWIN",
      DPA_PORT_CODE: "DRW",
    },
    {
      CITY_CODE: "AUEDB",
      CITY_NAME: "EDITHBURGH",
      DPA_PORT_CODE: "EDB",
    },
    {
      CITY_CODE: "AUEPR",
      CITY_NAME: "ESPERANCE",
      DPA_PORT_CODE: "EPR",
    },
    {
      CITY_CODE: "AUFRE",
      CITY_NAME: "FREMANTLE",
      DPA_PORT_CODE: "FRE",
    },
    {
      CITY_CODE: "AUGEX",
      CITY_NAME: "GEELONG",
      DPA_PORT_CODE: "GEX",
    },
    {
      CITY_CODE: "AUGLT",
      CITY_NAME: "GLADSTONE",
      DPA_PORT_CODE: "GLT",
    },
    {
      CITY_CODE: "AUGOI",
      CITY_NAME: "GOODE ISLAND",
      DPA_PORT_CODE: "GOI",
    },
    {
      CITY_CODE: "AUGOV",
      CITY_NAME: "GOVE",
      DPA_PORT_CODE: "GOV",
    },
    {
      CITY_CODE: "AUGTE",
      CITY_NAME: "GROOTE EYLANDT",
      DPA_PORT_CODE: "GTE",
    },
    {
      CITY_CODE: "AUHBA",
      CITY_NAME: "HOBART",
      DPA_PORT_CODE: "HBA",
    },
    {
      CITY_CODE: "AUHNK",
      CITY_NAME: "HINCHINBROOKE ISLAND",
      DPA_PORT_CODE: "HNK",
    },
    {
      CITY_CODE: "AUHPT",
      CITY_NAME: "HAYPOINT",
      DPA_PORT_CODE: "HPT",
    },
    {
      CITY_CODE: "AUKBY",
      CITY_NAME: "KING BAY",
      DPA_PORT_CODE: "KBY",
    },
    {
      CITY_CODE: "AUKLP",
      CITY_NAME: "KLEIN POINT",
      DPA_PORT_CODE: "KLP",
    },
    {
      CITY_CODE: "AUKTR",
      CITY_NAME: "KATHERINE",
      DPA_PORT_CODE: "KTR",
    },
    {
      CITY_CODE: "AUKUR",
      CITY_NAME: "KURNELL",
      DPA_PORT_CODE: "KUR",
    },
    {
      CITY_CODE: "AULST",
      CITY_NAME: "LAUNCESTON",
      DPA_PORT_CODE: "LST",
    },
    {
      CITY_CODE: "AUMEL",
      CITY_NAME: "MELBOURNE",
      DPA_PORT_CODE: "MEL",
    },
    {
      CITY_CODE: "AUMKY",
      CITY_NAME: "MACKAY",
      DPA_PORT_CODE: "MKY",
    },
    {
      CITY_CODE: "AUMLR",
      CITY_NAME: "MELROSE",
      DPA_PORT_CODE: "MLR",
    },
    {
      CITY_CODE: "AUOSB",
      CITY_NAME: "OSBORNE ISLAND",
      DPA_PORT_CODE: "OSB",
    },
    {
      CITY_CODE: "AUPAE",
      CITY_NAME: "PORT ADELAIDE",
      DPA_PORT_CODE: "PAE",
    },
    {
      CITY_CODE: "AUPAU",
      CITY_NAME: "PORT ARTHUR",
      DPA_PORT_CODE: "PAU",
    },
    {
      CITY_CODE: "AUPBT",
      CITY_NAME: "PORT BOTANY",
      DPA_PORT_CODE: "PBT",
    },
    {
      CITY_CODE: "AUPGI",
      CITY_NAME: "PORT GILLS",
      DPA_PORT_CODE: "PGI",
    },
    {
      CITY_CODE: "AUPHE",
      CITY_NAME: "PORT HEDLAND",
      DPA_PORT_CODE: "PHE",
    },
    {
      CITY_CODE: "AUPHU",
      CITY_NAME: "PORT HUON",
      DPA_PORT_CODE: "PHU",
    },
    {
      CITY_CODE: "AUPKL",
      CITY_NAME: "PORT KEMBLA",
      DPA_PORT_CODE: "KEM",
    },
    {
      CITY_CODE: "AUPLA",
      CITY_NAME: "PORT LATTA",
      DPA_PORT_CODE: "PLA",
    },
    {
      CITY_CODE: "AUPLO",
      CITY_NAME: "PORT LINCOLN",
      DPA_PORT_CODE: "PLO",
    },
    {
      CITY_CODE: "AUPQQ",
      CITY_NAME: "PORT MACQUARIE",
      DPA_PORT_CODE: "PQQ",
    },
    {
      CITY_CODE: "AUPRC",
      CITY_NAME: "PRICE",
      DPA_PORT_CODE: "PRC",
    },
    {
      CITY_CODE: "AUPTD",
      CITY_NAME: "PORT DALRYMPLE",
      DPA_PORT_CODE: "PTD",
    },
    {
      CITY_CODE: "AUPTJ",
      CITY_NAME: "PORT PORTLAND",
      DPA_PORT_CODE: "PTJ",
    },
    {
      CITY_CODE: "AUPWI",
      CITY_NAME: "POINT WILSON",
      DPA_PORT_CODE: "PWI",
    },
    {
      CITY_CODE: "AUPWL",
      CITY_NAME: "PORT WALCOTT",
      DPA_PORT_CODE: "PWL",
    },
    {
      CITY_CODE: "AUPWR",
      CITY_NAME: "PORT WARRENDER",
      DPA_PORT_CODE: "PWR",
    },
    {
      CITY_CODE: "AUQDN",
      CITY_NAME: "EDEN",
      DPA_PORT_CODE: "QDN",
    },
    {
      CITY_CODE: "AURCM",
      CITY_NAME: "RICHMOND",
      DPA_PORT_CODE: "RCM",
    },
    {
      CITY_CODE: "AUROK",
      CITY_NAME: "ROCKHAMPTON",
      DPA_PORT_CODE: "ROK",
    },
    {
      CITY_CODE: "AUSWR",
      CITY_NAME: "SOUTH WEST ROCKS",
      DPA_PORT_CODE: "SWR",
    },
    {
      CITY_CODE: "AUSYD",
      CITY_NAME: "SYDNEY",
      DPA_PORT_CODE: "SYD",
    },
    {
      CITY_CODE: "AUTRB",
      CITY_NAME: "TRIAL BAY",
      DPA_PORT_CODE: "TRB",
    },
    {
      CITY_CODE: "AUTSV",
      CITY_NAME: "TOWNSVILLE",
      DPA_PORT_CODE: "TSV",
    },
    {
      CITY_CODE: "AUVAR",
      CITY_NAME: "VARANUS ISLAND",
      DPA_PORT_CODE: "VRD",
    },
    {
      CITY_CODE: "AUWDK",
      CITY_NAME: "WEBB DOCK",
      DPA_PORT_CODE: "WDK",
    },
    {
      CITY_CODE: "AUWEP",
      CITY_NAME: "WESTERNPORT",
      DPA_PORT_CODE: "WEP",
    },
    {
      CITY_CODE: "AUWMB",
      CITY_NAME: "WARRNAMBOOL",
      DPA_PORT_CODE: "WMB",
    },
    {
      CITY_CODE: "AUZBO",
      CITY_NAME: "BOWEN",
      DPA_PORT_CODE: "ZBO",
    },
    {
      CITY_CODE: "AWORJ",
      CITY_NAME: "ORANJESTAD",
      DPA_PORT_CODE: "NJE",
    },
    {
      CITY_CODE: "AWSNL",
      CITY_NAME: "SINT NICOLAAS",
      DPA_PORT_CODE: "SNL",
    },
    {
      CITY_CODE: "AZBAK",
      CITY_NAME: "BAKU",
      DPA_PORT_CODE: "BAK",
    },
    {
      CITY_CODE: "BBBGI",
      CITY_NAME: "BRIDGETOWN",
      DPA_PORT_CODE: "BGI",
    },
    {
      CITY_CODE: "BDBZL",
      CITY_NAME: "BARISAL",
      DPA_PORT_CODE: "BZL",
    },
    {
      CITY_CODE: "BDCGP",
      CITY_NAME: "CHITTAGONG",
      DPA_PORT_CODE: "CGP",
    },
    {
      CITY_CODE: "BDCHL",
      CITY_NAME: "CHALNA",
      DPA_PORT_CODE: "CHL",
    },
    {
      CITY_CODE: "BDDAC",
      CITY_NAME: "DHAKA",
      DPA_PORT_CODE: "DAC",
    },
    {
      CITY_CODE: "BDMGL",
      CITY_NAME: "MONGLA",
      DPA_PORT_CODE: "MGL",
    },
    {
      CITY_CODE: "BDMON",
      CITY_NAME: "CHALNAMONGLA",
      DPA_PORT_CODE: "MON",
    },
    {
      CITY_CODE: "BDPGN",
      CITY_NAME: "PANGAON",
      DPA_PORT_CODE: "PGJ",
    },
    {
      CITY_CODE: "BEANR",
      CITY_NAME: "ANTWERP",
      DPA_PORT_CODE: "ANR",
    },
    {
      CITY_CODE: "BEBGS",
      CITY_NAME: "BRUGES",
      DPA_PORT_CODE: "BGS",
    },
    {
      CITY_CODE: "BEBRU",
      CITY_NAME: "BRUSSELS",
      DPA_PORT_CODE: "BRU",
    },
    {
      CITY_CODE: "BEDEZ",
      CITY_NAME: "DEINZE",
      DPA_PORT_CODE: "DEU",
    },
    {
      CITY_CODE: "BEKON",
      CITY_NAME: "KONTICH",
      DPA_PORT_CODE: "KON",
    },
    {
      CITY_CODE: "BEKOU",
      CITY_NAME: "KALLO",
      DPA_PORT_CODE: "KOU",
    },
    {
      CITY_CODE: "BENIE",
      CITY_NAME: "NIEUWPOORT",
      DPA_PORT_CODE: "NIE",
    },
    {
      CITY_CODE: "BERUI",
      CITY_NAME: "RUISBROEK",
      DPA_PORT_CODE: "RUI",
    },
    {
      CITY_CODE: "BEWLB",
      CITY_NAME: "WILLEBROEK",
      DPA_PORT_CODE: "WLB",
    },
    {
      CITY_CODE: "BEYZO",
      CITY_NAME: "LAAKDAL",
      DPA_PORT_CODE: "YZO",
    },
    {
      CITY_CODE: "BEZEE",
      CITY_NAME: "ZEEBRUGGE",
      DPA_PORT_CODE: "ZEE",
    },
    {
      CITY_CODE: "BEZEL",
      CITY_NAME: "ZELZATE",
      DPA_PORT_CODE: "ZEL",
    },
    {
      CITY_CODE: "BFOUA",
      CITY_NAME: "OUAGADOUGOU",
      DPA_PORT_CODE: "OUA",
    },
    {
      CITY_CODE: "BGBOJ",
      CITY_NAME: "BURGAS",
      DPA_PORT_CODE: "BOJ",
    },
    {
      CITY_CODE: "BGDLC",
      CITY_NAME: "DELCHEVO",
      DPA_PORT_CODE: "DLO",
    },
    {
      CITY_CODE: "BGDLV",
      CITY_NAME: "DULOVO",
      DPA_PORT_CODE: "DLV",
    },
    {
      CITY_CODE: "BGDNJ",
      CITY_NAME: "DEVNYA",
      DPA_PORT_CODE: "DNJ",
    },
    {
      CITY_CODE: "BGKVO",
      CITY_NAME: "KARLOVO",
      DPA_PORT_CODE: "KVO",
    },
    {
      CITY_CODE: "BGORE",
      CITY_NAME: "OREHOVO",
      DPA_PORT_CODE: "ORE",
    },
    {
      CITY_CODE: "BGRDU",
      CITY_NAME: "RUSE",
      DPA_PORT_CODE: "RDU",
    },
    {
      CITY_CODE: "BGSML",
      CITY_NAME: "SMOLYAN",
      DPA_PORT_CODE: "SML",
    },
    {
      CITY_CODE: "BGSVI",
      CITY_NAME: "SVISTOV",
      DPA_PORT_CODE: "SVI",
    },
    {
      CITY_CODE: "BGVAR",
      CITY_NAME: "VARNA",
      DPA_PORT_CODE: "VAR",
    },
    {
      CITY_CODE: "BGYAM",
      CITY_NAME: "YAMBOL",
      DPA_PORT_CODE: "YAM",
    },
    {
      CITY_CODE: "BHBAH",
      CITY_NAME: "BAHRAIN",
      DPA_PORT_CODE: "BAH",
    },
    {
      CITY_CODE: "BHKBS",
      CITY_NAME: "KHALIFA BIN SALMAN P",
      DPA_PORT_CODE: "KBX",
    },
    {
      CITY_CODE: "BHMAN",
      CITY_NAME: "MANAMA",
      DPA_PORT_CODE: "MAM",
    },
    {
      CITY_CODE: "BHMIN",
      CITY_NAME: "MINA SULMAN",
      DPA_PORT_CODE: "MIN",
    },
    {
      CITY_CODE: "BHSIT",
      CITY_NAME: "SITRA",
      DPA_PORT_CODE: "SIT",
    },
    {
      CITY_CODE: "BINGZ",
      CITY_NAME: "NGOZI",
      DPA_PORT_CODE: "GOZ",
    },
    {
      CITY_CODE: "BJCOO",
      CITY_NAME: "COTONOU",
      DPA_PORT_CODE: "COO",
    },
    {
      CITY_CODE: "BMFPT",
      CITY_NAME: "FREEPORT",
      DPA_PORT_CODE: "FPT",
    },
    {
      CITY_CODE: "BMHBI",
      CITY_NAME: "HARBOR ISLAND",
      DPA_PORT_CODE: "HBI",
    },
    {
      CITY_CODE: "BNBTN",
      CITY_NAME: "BRUNEI TOWN",
      DPA_PORT_CODE: "BTN",
    },
    {
      CITY_CODE: "BNBWN",
      CITY_NAME: "BANDAR SERI BEGAWAN",
      DPA_PORT_CODE: "BWN",
    },
    {
      CITY_CODE: "BNKUB",
      CITY_NAME: "KUALA BELAIT",
      DPA_PORT_CODE: "KUB",
    },
    {
      CITY_CODE: "BNMUA",
      CITY_NAME: "MUARA",
      DPA_PORT_CODE: "MUA",
    },
    {
      CITY_CODE: "BOLPB",
      CITY_NAME: "LA PAZ",
      DPA_PORT_CODE: "LPB",
    },
    {
      CITY_CODE: "BOPBU",
      CITY_NAME: "PUERTO BUSCH",
      DPA_PORT_CODE: "PBU",
    },
    {
      CITY_CODE: "BOPEY",
      CITY_NAME: "RURRENABAQUE",
      DPA_PORT_CODE: "PEY",
    },
    {
      CITY_CODE: "BOPSZ",
      CITY_NAME: "PUERTO SUAREZ",
      DPA_PORT_CODE: "PSZ",
    },
    {
      CITY_CODE: "BORIB",
      CITY_NAME: "RIBERALTA",
      DPA_PORT_CODE: "RIB",
    },
    {
      CITY_CODE: "BOTDD",
      CITY_NAME: "TRINIDAD",
      DPA_PORT_CODE: "TDD",
    },
    {
      CITY_CODE: "BRAJU",
      CITY_NAME: "ARACAJU",
      DPA_PORT_CODE: "AJU",
    },
    {
      CITY_CODE: "BRBEL",
      CITY_NAME: "BELEM",
      DPA_PORT_CODE: "LEM",
    },
    {
      CITY_CODE: "BRBNU",
      CITY_NAME: "BLUMENAU",
      DPA_PORT_CODE: "BNU",
    },
    {
      CITY_CODE: "BRBVM",
      CITY_NAME: "BELMONTE",
      DPA_PORT_CODE: "BVM",
    },
    {
      CITY_CODE: "BRCDO",
      CITY_NAME: "CABEDELO",
      DPA_PORT_CODE: "CDO",
    },
    {
      CITY_CODE: "BRFOR",
      CITY_NAME: "FORTALEZA",
      DPA_PORT_CODE: "FOR",
    },
    {
      CITY_CODE: "BRIBB",
      CITY_NAME: "IMBITUBA",
      DPA_PORT_CODE: "IBB",
    },
    {
      CITY_CODE: "BRIGI",
      CITY_NAME: "ITAGUAI",
      DPA_PORT_CODE: "IGI",
    },
    {
      CITY_CODE: "BRIOA",
      CITY_NAME: "ITAPOA",
      DPA_PORT_CODE: "ITP",
    },
    {
      CITY_CODE: "BRIOS",
      CITY_NAME: "ILHEUS",
      DPA_PORT_CODE: "IOS",
    },
    {
      CITY_CODE: "BRITJ",
      CITY_NAME: "ITAJAI",
      DPA_PORT_CODE: "ITJ",
    },
    {
      CITY_CODE: "BRITQ",
      CITY_NAME: "ITAQUI",
      DPA_PORT_CODE: "ITQ",
    },
    {
      CITY_CODE: "BRMAO",
      CITY_NAME: "MANAUS",
      DPA_PORT_CODE: "MAO",
    },
    {
      CITY_CODE: "BRMCU",
      CITY_NAME: "MACAU",
      DPA_PORT_CODE: "MCU",
    },
    {
      CITY_CODE: "BRMCZ",
      CITY_NAME: "MACEIO",
      DPA_PORT_CODE: "MCZ",
    },
    {
      CITY_CODE: "BRNAT",
      CITY_NAME: "NATAL",
      DPA_PORT_CODE: "NAT",
    },
    {
      CITY_CODE: "BRNTR",
      CITY_NAME: "NITEROI",
      DPA_PORT_CODE: "NTR",
    },
    {
      CITY_CODE: "BRNVT",
      CITY_NAME: "NAVEGANTES",
      DPA_PORT_CODE: "NVT",
    },
    {
      CITY_CODE: "BRPHB",
      CITY_NAME: "PARNAIBA",
      DPA_PORT_CODE: "PHB",
    },
    {
      CITY_CODE: "BRPNG",
      CITY_NAME: "PARANAGUA",
      DPA_PORT_CODE: "PNG",
    },
    {
      CITY_CODE: "BRQGR",
      CITY_NAME: "POUSO ALEGRE",
      DPA_PORT_CODE: "QGR",
    },
    {
      CITY_CODE: "BRQJD",
      CITY_NAME: "JUAZEIRO DO NORTE",
      DPA_PORT_CODE: "QJD",
    },
    {
      CITY_CODE: "BRQOB",
      CITY_NAME: "OBIDOS",
      DPA_PORT_CODE: "QOB",
    },
    {
      CITY_CODE: "BRQTO",
      CITY_NAME: "TOLEDO",
      DPA_PORT_CODE: "QTO",
    },
    {
      CITY_CODE: "BRREC",
      CITY_NAME: "RECIFE",
      DPA_PORT_CODE: "REC",
    },
    {
      CITY_CODE: "BRRIG",
      CITY_NAME: "RIO GRANDE",
      DPA_PORT_CODE: "RIG",
    },
    {
      CITY_CODE: "BRRIO",
      CITY_NAME: "RIO DE JANEIRO",
      DPA_PORT_CODE: "RIO",
    },
    {
      CITY_CODE: "BRSFS",
      CITY_NAME: "SAO FRANCISCO DO SUL",
      DPA_PORT_CODE: "SFD",
    },
    {
      CITY_CODE: "BRSPB",
      CITY_NAME: "BAHIA DE SEPETIBA",
      DPA_PORT_CODE: "SPB",
    },
    {
      CITY_CODE: "BRSSA",
      CITY_NAME: "SALVADOR",
      DPA_PORT_CODE: "SSA",
    },
    {
      CITY_CODE: "BRSSZ",
      CITY_NAME: "SANTOS",
      DPA_PORT_CODE: "SSZ",
    },
    {
      CITY_CODE: "BRSUA",
      CITY_NAME: "SUAPE",
      DPA_PORT_CODE: "SUA",
    },
    {
      CITY_CODE: "BRTUB",
      CITY_NAME: "TUBARAO",
      DPA_PORT_CODE: "TUB",
    },
    {
      CITY_CODE: "BRVIX",
      CITY_NAME: "VITORIA",
      DPA_PORT_CODE: "VIX",
    },
    {
      CITY_CODE: "BRVLC",
      CITY_NAME: "VILA DO CONDE",
      DPA_PORT_CODE: "VLE",
    },
    {
      CITY_CODE: "BSCCZ",
      CITY_NAME: "CHUB CAY",
      DPA_PORT_CODE: "CCZ",
    },
    {
      CITY_CODE: "BSCEL",
      CITY_NAME: "CAPE ELEUTHERA",
      DPA_PORT_CODE: "CEL",
    },
    {
      CITY_CODE: "BSCOX",
      CITY_NAME: "CONGO TOWN",
      DPA_PORT_CODE: "COX",
    },
    {
      CITY_CODE: "BSFPO",
      CITY_NAME: "FREEPORT",
      DPA_PORT_CODE: "FPO",
    },
    {
      CITY_CODE: "BSGHB",
      CITY_NAME: "GOVERNORS HARBOUR",
      DPA_PORT_CODE: "GHB",
    },
    {
      CITY_CODE: "BSGHC",
      CITY_NAME: "GREAT HARBOUR CAY",
      DPA_PORT_CODE: "GHC",
    },
    {
      CITY_CODE: "BSIGA",
      CITY_NAME: "INAGUA",
      DPA_PORT_CODE: "IGA",
    },
    {
      CITY_CODE: "BSMYG",
      CITY_NAME: "MAYAGUANA ISLAND",
      DPA_PORT_CODE: "MYG",
    },
    {
      CITY_CODE: "BSNAS",
      CITY_NAME: "NASSAU",
      DPA_PORT_CODE: "NAS",
    },
    {
      CITY_CODE: "BSRSD",
      CITY_NAME: "ROCK SOUND",
      DPA_PORT_CODE: "RSD",
    },
    {
      CITY_CODE: "BSSAQ",
      CITY_NAME: "SAN ANDROS",
      DPA_PORT_CODE: "SAQ",
    },
    {
      CITY_CODE: "BSTCB",
      CITY_NAME: "TREASURE CAY",
      DPA_PORT_CODE: "TCB",
    },
    {
      CITY_CODE: "BSWKR",
      CITY_NAME: "WALKER'S CAY",
      DPA_PORT_CODE: "WKR",
    },
    {
      CITY_CODE: "BSZSA",
      CITY_NAME: "SAN SALVADOR ISLAND",
      DPA_PORT_CODE: "ZSA",
    },
    {
      CITY_CODE: "BWPKW",
      CITY_NAME: "SELEBI-PHIKWE",
      DPA_PORT_CODE: "PKW",
    },
    {
      CITY_CODE: "BZBZE",
      CITY_NAME: "BELIZE CITY",
      DPA_PORT_CODE: "BZE",
    },
    {
      CITY_CODE: "CAAJU",
      CITY_NAME: "ANJOU",
      DPA_PORT_CODE: "AOU",
    },
    {
      CITY_CODE: "CAALK",
      CITY_NAME: "ADLAVIK",
      DPA_PORT_CODE: "AFK",
    },
    {
      CITY_CODE: "CAAVO",
      CITY_NAME: "AVONDALE",
      DPA_PORT_CODE: "AVO",
    },
    {
      CITY_CODE: "CABCK",
      CITY_NAME: "BROCKVILLE",
      DPA_PORT_CODE: "BCK",
    },
    {
      CITY_CODE: "CABEA",
      CITY_NAME: "BEAVER COVER",
      DPA_PORT_CODE: "BEA",
    },
    {
      CITY_CODE: "CABGT",
      CITY_NAME: "BAGOTVILLE",
      DPA_PORT_CODE: "BGT",
    },
    {
      CITY_CODE: "CABLV",
      CITY_NAME: "BELLEVILLE",
      DPA_PORT_CODE: "EEL",
    },
    {
      CITY_CODE: "CABRM",
      CITY_NAME: "BRAMALEA",
      DPA_PORT_CODE: "BZU",
    },
    {
      CITY_CODE: "CABWD",
      CITY_NAME: "BOTWOOD",
      DPA_PORT_CODE: "BWD",
    },
    {
      CITY_CODE: "CACAL",
      CITY_NAME: "CALGARY",
      DPA_PORT_CODE: "CAY",
    },
    {
      CITY_CODE: "CACAM",
      CITY_NAME: "CAMPBELL RIVER",
      DPA_PORT_CODE: "CAM",
    },
    {
      CITY_CODE: "CACAQ",
      CITY_NAME: "CARAQUET",
      DPA_PORT_CODE: "CAQ",
    },
    {
      CITY_CODE: "CACBK",
      CITY_NAME: "CORNER BROOK",
      DPA_PORT_CODE: "CBK",
    },
    {
      CITY_CODE: "CACCB",
      CITY_NAME: "COWICHAN BAY",
      DPA_PORT_CODE: "CCB",
    },
    {
      CITY_CODE: "CACHV",
      CITY_NAME: "CHURCHILL",
      DPA_PORT_CODE: "CHV",
    },
    {
      CITY_CODE: "CACOC",
      CITY_NAME: "CONTRECOEUR",
      DPA_PORT_CODE: "COC",
    },
    {
      CITY_CODE: "CACOM",
      CITY_NAME: "CAMPBELLTON",
      DPA_PORT_CODE: "COM",
    },
    {
      CITY_CODE: "CACOP",
      CITY_NAME: "COPPERMINE",
      DPA_PORT_CODE: "COP",
    },
    {
      CITY_CODE: "CACOU",
      CITY_NAME: "COURTENAY",
      DPA_PORT_CODE: "COU",
    },
    {
      CITY_CODE: "CACWL",
      CITY_NAME: "CORNWALL",
      DPA_PORT_CODE: "CWL",
    },
    {
      CITY_CODE: "CADEL",
      CITY_NAME: "DELTA",
      DPA_PORT_CODE: "DDX",
    },
    {
      CITY_CODE: "CADHS",
      CITY_NAME: "DALHOUSIE",
      DPA_PORT_CODE: "DHS",
    },
    {
      CITY_CODE: "CADRU",
      CITY_NAME: "DRUMMONDVILLE",
      DPA_PORT_CODE: "DRU",
    },
    {
      CITY_CODE: "CADWL",
      CITY_NAME: "DINGWALL",
      DPA_PORT_CODE: "DWL",
    },
    {
      CITY_CODE: "CAENG",
      CITY_NAME: "ENGELWOOD",
      DPA_PORT_CODE: "ENG",
    },
    {
      CITY_CODE: "CAFBB",
      CITY_NAME: "FROBISHER BAY",
      DPA_PORT_CODE: "FBB",
    },
    {
      CITY_CODE: "CAFCM",
      CITY_NAME: "FORT CHIMO",
      DPA_PORT_CODE: "FCM",
    },
    {
      CITY_CODE: "CAFFS",
      CITY_NAME: "FORT FRANCES",
      DPA_PORT_CODE: "FFS",
    },
    {
      CITY_CODE: "CAFRM",
      CITY_NAME: "FRASER MILLS",
      DPA_PORT_CODE: "FRM",
    },
    {
      CITY_CODE: "CAFSJ",
      CITY_NAME: "FORT ST JOHN",
      DPA_PORT_CODE: "FSJ",
    },
    {
      CITY_CODE: "CAGDE",
      CITY_NAME: "GRANDEMERE",
      DPA_PORT_CODE: "GDE",
    },
    {
      CITY_CODE: "CAGGW",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GGW",
    },
    {
      CITY_CODE: "CAGOL",
      CITY_NAME: "GOLD RIVER",
      DPA_PORT_CODE: "GOL",
    },
    {
      CITY_CODE: "CAGOO",
      CITY_NAME: "GOOSE BAY",
      DPA_PORT_CODE: "GOO",
    },
    {
      CITY_CODE: "CAGPE",
      CITY_NAME: "GASPE",
      DPA_PORT_CODE: "GPE",
    },
    {
      CITY_CODE: "CAHAL",
      CITY_NAME: "HALIFAX",
      DPA_PORT_CODE: "HAL",
    },
    {
      CITY_CODE: "CAHAM",
      CITY_NAME: "HAMILTON",
      DPA_PORT_CODE: "HYN",
    },
    {
      CITY_CODE: "CAHAT",
      CITY_NAME: "HATCH POINT",
      DPA_PORT_CODE: "HAT",
    },
    {
      CITY_CODE: "CAHMC",
      CITY_NAME: "HARMAC",
      DPA_PORT_CODE: "HMC",
    },
    {
      CITY_CODE: "CAHSG",
      CITY_NAME: "HAUTSAGUENAY",
      DPA_PORT_CODE: "HSG",
    },
    {
      CITY_CODE: "CAKIN",
      CITY_NAME: "KINGSTON",
      DPA_PORT_CODE: "KNI",
    },
    {
      CITY_CODE: "CAKOV",
      CITY_NAME: "KOVIK BAY",
      DPA_PORT_CODE: "KOV",
    },
    {
      CITY_CODE: "CAKTC",
      CITY_NAME: "KITCHENER",
      DPA_PORT_CODE: "KTC",
    },
    {
      CITY_CODE: "CAKTM",
      CITY_NAME: "KITIMAT",
      DPA_PORT_CODE: "KTM",
    },
    {
      CITY_CODE: "CALMD",
      CITY_NAME: "LOMOND",
      DPA_PORT_CODE: "LMD",
    },
    {
      CITY_CODE: "CALOU",
      CITY_NAME: "LOUISBOURG",
      DPA_PORT_CODE: "LOU",
    },
    {
      CITY_CODE: "CALTW",
      CITY_NAME: "LISTOWEL",
      DPA_PORT_CODE: "LTW",
    },
    {
      CITY_CODE: "CAMIR",
      CITY_NAME: "MIRAMICHI BAY",
      DPA_PORT_CODE: "MIR",
    },
    {
      CITY_CODE: "CAMIS",
      CITY_NAME: "MISSISSAUGA",
      DPA_PORT_CODE: "MIS",
    },
    {
      CITY_CODE: "CAMON",
      CITY_NAME: "MONKTON",
      DPA_PORT_CODE: "MKT",
    },
    {
      CITY_CODE: "CAMOU",
      CITY_NAME: "MOOSONEE",
      DPA_PORT_CODE: "MOU",
    },
    {
      CITY_CODE: "CAMTR",
      CITY_NAME: "MONTREAL",
      DPA_PORT_CODE: "MTR",
    },
    {
      CITY_CODE: "CAMUL",
      CITY_NAME: "MULGRAVE",
      DPA_PORT_CODE: "MUE",
    },
    {
      CITY_CODE: "CANAI",
      CITY_NAME: "NAIN",
      DPA_PORT_CODE: "NAI",
    },
    {
      CITY_CODE: "CANCT",
      CITY_NAME: "NEWCASTLE",
      DPA_PORT_CODE: "NCT",
    },
    {
      CITY_CODE: "CANNE",
      CITY_NAME: "NANOOSE",
      DPA_PORT_CODE: "NNE",
    },
    {
      CITY_CODE: "CANNO",
      CITY_NAME: "NANAIMO",
      DPA_PORT_CODE: "NNO",
    },
    {
      CITY_CODE: "CANWE",
      CITY_NAME: "NEW WESTMINSTER",
      DPA_PORT_CODE: "NWE",
    },
    {
      CITY_CODE: "CANWM",
      CITY_NAME: "NEWMARKET",
      DPA_PORT_CODE: "NWM",
    },
    {
      CITY_CODE: "CANWP",
      CITY_NAME: "ARGENTIA",
      DPA_PORT_CODE: "NWP",
    },
    {
      CITY_CODE: "CAOAK",
      CITY_NAME: "OAKVILLE",
      DPA_PORT_CODE: "OVI",
    },
    {
      CITY_CODE: "CAOFA",
      CITY_NAME: "OCEAN FALLS",
      DPA_PORT_CODE: "OFA",
    },
    {
      CITY_CODE: "CAOTT",
      CITY_NAME: "OTTAWA",
      DPA_PORT_CODE: "OWA",
    },
    {
      CITY_CODE: "CAPAB",
      CITY_NAME: "PORT ALBERNI",
      DPA_PORT_CODE: "PAB",
    },
    {
      CITY_CODE: "CAPAC",
      CITY_NAME: "PORT ALICE",
      DPA_PORT_CODE: "PAC",
    },
    {
      CITY_CODE: "CAPAF",
      CITY_NAME: "PORT ALFRED",
      DPA_PORT_CODE: "PAF",
    },
    {
      CITY_CODE: "CAPBH",
      CITY_NAME: "PETERBOROUGH",
      DPA_PORT_CODE: "PBH",
    },
    {
      CITY_CODE: "CAPBQ",
      CITY_NAME: "PORT AUX BASQUES",
      DPA_PORT_CODE: "PBQ",
    },
    {
      CITY_CODE: "CAPCA",
      CITY_NAME: "PORT CARTIER",
      DPA_PORT_CODE: "PCA",
    },
    {
      CITY_CODE: "CAPDV",
      CITY_NAME: "PORT DOVER",
      DPA_PORT_CODE: "PDV",
    },
    {
      CITY_CODE: "CAPHY",
      CITY_NAME: "PORT HARDY",
      DPA_PORT_CODE: "PHY",
    },
    {
      CITY_CODE: "CAPIC",
      CITY_NAME: "PICTON",
      DPA_PORT_CODE: "PIC",
    },
    {
      CITY_CODE: "CAPML",
      CITY_NAME: "PORT MELLON",
      DPA_PORT_CODE: "PML",
    },
    {
      CITY_CODE: "CAPNE",
      CITY_NAME: "PORT NELSON",
      DPA_PORT_CODE: "PNE",
    },
    {
      CITY_CODE: "CAPOW",
      CITY_NAME: "POWELL RIVER",
      DPA_PORT_CODE: "POW",
    },
    {
      CITY_CODE: "CAPRR",
      CITY_NAME: "PRINCE RUPERT",
      DPA_PORT_CODE: "PRR",
    },
    {
      CITY_CODE: "CAPRS",
      CITY_NAME: "PARRY SOUND",
      DPA_PORT_CODE: "PRS",
    },
    {
      CITY_CODE: "CAPSI",
      CITY_NAME: "PORT SIMPSON",
      DPA_PORT_CODE: "PSI",
    },
    {
      CITY_CODE: "CAPST",
      CITY_NAME: "PORT STANLEY",
      DPA_PORT_CODE: "PST",
    },
    {
      CITY_CODE: "CAPTM",
      CITY_NAME: "PORT MAITLAND",
      DPA_PORT_CODE: "PTM",
    },
    {
      CITY_CODE: "CAPWE",
      CITY_NAME: "PORT WELLER",
      DPA_PORT_CODE: "PWE",
    },
    {
      CITY_CODE: "CARBC",
      CITY_NAME: "RICHMOND",
      DPA_PORT_CODE: "RBC",
    },
    {
      CITY_CODE: "CARDL",
      CITY_NAME: "RIVIEREDULOUP",
      DPA_PORT_CODE: "RDL",
    },
    {
      CITY_CODE: "CARIM",
      CITY_NAME: "RIMOUSKI",
      DPA_PORT_CODE: "RIM",
    },
    {
      CITY_CODE: "CASBR",
      CITY_NAME: "SHERBROOKE",
      DPA_PORT_CODE: "SBR",
    },
    {
      CITY_CODE: "CASEI",
      CITY_NAME: "SEVEN IS.",
      DPA_PORT_CODE: "SEI",
    },
    {
      CITY_CODE: "CASIC",
      CITY_NAME: "SIMCOE",
      DPA_PORT_CODE: "SIC",
    },
    {
      CITY_CODE: "CASJB",
      CITY_NAME: "SAINT JOHN",
      DPA_PORT_CODE: "SJB",
    },
    {
      CITY_CODE: "CASJF",
      CITY_NAME: "ST JOHNS",
      DPA_PORT_CODE: "SJF",
    },
    {
      CITY_CODE: "CASNI",
      CITY_NAME: "SARNIA",
      DPA_PORT_CODE: "SNI",
    },
    {
      CITY_CODE: "CASOR",
      CITY_NAME: "SOREL",
      DPA_PORT_CODE: "SOR",
    },
    {
      CITY_CODE: "CASTW",
      CITY_NAME: "STEWART",
      DPA_PORT_CODE: "STW",
    },
    {
      CITY_CODE: "CATOQ",
      CITY_NAME: "TOQUART BAY",
      DPA_PORT_CODE: "TOQ",
    },
    {
      CITY_CODE: "CATOR",
      CITY_NAME: "TORONTO",
      DPA_PORT_CODE: "TOR",
    },
    {
      CITY_CODE: "CATWI",
      CITY_NAME: "TWILLINGGATE",
      DPA_PORT_CODE: "TWI",
    },
    {
      CITY_CODE: "CAVAN",
      CITY_NAME: "VANCOUVER",
      DPA_PORT_CODE: "VAN",
    },
    {
      CITY_CODE: "CAVER",
      CITY_NAME: "VERNON",
      DPA_PORT_CODE: "VRN",
    },
    {
      CITY_CODE: "CAVIC",
      CITY_NAME: "VICTORIA",
      DPA_PORT_CODE: "VRA",
    },
    {
      CITY_CODE: "CAVLF",
      CITY_NAME: "VALLEYFIELD",
      DPA_PORT_CODE: "VLF",
    },
    {
      CITY_CODE: "CAWDB",
      CITY_NAME: "WOODBRIDGE",
      DPA_PORT_CODE: "WDB",
    },
    {
      CITY_CODE: "CAWNP",
      CITY_NAME: "WINNIPEG",
      DPA_PORT_CODE: "WNP",
    },
    {
      CITY_CODE: "CAYEA",
      CITY_NAME: "EDMONTON APT",
      DPA_PORT_CODE: "YEA",
    },
    {
      CITY_CODE: "CAYJT",
      CITY_NAME: "STEPHENVILLE",
      DPA_PORT_CODE: "YJT",
    },
    {
      CITY_CODE: "CAYQI",
      CITY_NAME: "YARMOUTH",
      DPA_PORT_CODE: "YQI",
    },
    {
      CITY_CODE: "CAYTO",
      CITY_NAME: "GUILDWOOD",
      DPA_PORT_CODE: "YTO",
    },
    {
      CITY_CODE: "CAZEB",
      CITY_NAME: "ZEBALLOS",
      DPA_PORT_CODE: "ZEB",
    },
    {
      CITY_CODE: "CDBKY",
      CITY_NAME: "BUKAVU",
      DPA_PORT_CODE: "BKB",
    },
    {
      CITY_CODE: "CDBNW",
      CITY_NAME: "BANANA",
      DPA_PORT_CODE: "BAN",
    },
    {
      CITY_CODE: "CDBOA",
      CITY_NAME: "BOMA",
      DPA_PORT_CODE: "AMO",
    },
    {
      CITY_CODE: "CDFBM",
      CITY_NAME: "LUBUMBASHI",
      DPA_PORT_CODE: "FBM",
    },
    {
      CITY_CODE: "CDFIH",
      CITY_NAME: "KINSHASA",
      DPA_PORT_CODE: "FIH",
    },
    {
      CITY_CODE: "CDGOM",
      CITY_NAME: "GOMA",
      DPA_PORT_CODE: "GOF",
    },
    {
      CITY_CODE: "CDLKS",
      CITY_NAME: "LIKASI",
      DPA_PORT_CODE: "LFS",
    },
    {
      CITY_CODE: "CDMAT",
      CITY_NAME: "MATADI",
      DPA_PORT_CODE: "MTD",
    },
    {
      CITY_CODE: "CGOUE",
      CITY_NAME: "OUESSO",
      DPA_PORT_CODE: "OUE",
    },
    {
      CITY_CODE: "CGOYO",
      CITY_NAME: "OYO",
      DPA_PORT_CODE: "OYO",
    },
    {
      CITY_CODE: "CGPNR",
      CITY_NAME: "POINTE NOIRE",
      DPA_PORT_CODE: "PNR",
    },
    {
      CITY_CODE: "CHBSL",
      CITY_NAME: "BASEL",
      DPA_PORT_CODE: "BSL",
    },
    {
      CITY_CODE: "CHVIS",
      CITY_NAME: "VSIP",
      DPA_PORT_CODE: "VIP",
    },
    {
      CITY_CODE: "CIABJ",
      CITY_NAME: "ABIDJAN",
      DPA_PORT_CODE: "ABJ",
    },
    {
      CITY_CODE: "CISPY",
      CITY_NAME: "SAN PEDRO",
      DPA_PORT_CODE: "SPD",
    },
    {
      CITY_CODE: "CIZSS",
      CITY_NAME: "SASSANDRA",
      DPA_PORT_CODE: "ZSS",
    },
    {
      CITY_CODE: "CKAIT",
      CITY_NAME: "AITUTAKI",
      DPA_PORT_CODE: "AIT",
    },
    {
      CITY_CODE: "CKAIU",
      CITY_NAME: "ATIU",
      DPA_PORT_CODE: "AIU",
    },
    {
      CITY_CODE: "CKMGS",
      CITY_NAME: "MANGAIA",
      DPA_PORT_CODE: "MGS",
    },
    {
      CITY_CODE: "CKRAR",
      CITY_NAME: "RAROTONGA",
      DPA_PORT_CODE: "RAX",
    },
    {
      CITY_CODE: "CLANF",
      CITY_NAME: "ANTOFAGASTA",
      DPA_PORT_CODE: "ANF",
    },
    {
      CITY_CODE: "CLARI",
      CITY_NAME: "ARICA",
      DPA_PORT_CODE: "ARI",
    },
    {
      CITY_CODE: "CLCCP",
      CITY_NAME: "CONCEPCION",
      DPA_PORT_CODE: "CCP",
    },
    {
      CITY_CODE: "CLCHB",
      CITY_NAME: "CHACABUCO",
      DPA_PORT_CODE: "CHB",
    },
    {
      CITY_CODE: "CLCNL",
      CITY_NAME: "CORONEL",
      DPA_PORT_CODE: "CNL",
    },
    {
      CITY_CODE: "CLCNR",
      CITY_NAME: "CHANARAL",
      DPA_PORT_CODE: "CNR",
    },
    {
      CITY_CODE: "CLCQQ",
      CITY_NAME: "COQUIMBO",
      DPA_PORT_CODE: "CQQ",
    },
    {
      CITY_CODE: "CLIPC",
      CITY_NAME: "ISLA DE PASCUA",
      DPA_PORT_CODE: "IPC",
    },
    {
      CITY_CODE: "CLIQQ",
      CITY_NAME: "IQUIQUE",
      DPA_PORT_CODE: "IQQ",
    },
    {
      CITY_CODE: "CLLQN",
      CITY_NAME: "LIRQUEN",
      DPA_PORT_CODE: "LQN",
    },
    {
      CITY_CODE: "CLLSQ",
      CITY_NAME: "LOS ANGELES",
      DPA_PORT_CODE: "LSQ",
    },
    {
      CITY_CODE: "CLOVL",
      CITY_NAME: "OVALLE",
      DPA_PORT_CODE: "OVL",
    },
    {
      CITY_CODE: "CLPAG",
      CITY_NAME: "PUERTO ANGAMOS",
      DPA_PORT_CODE: "PGS",
    },
    {
      CITY_CODE: "CLPPY",
      CITY_NAME: "PUERTO PERCY",
      DPA_PORT_CODE: "PPY",
    },
    {
      CITY_CODE: "CLPUQ",
      CITY_NAME: "PUNTA ARENAS",
      DPA_PORT_CODE: "PUQ",
    },
    {
      CITY_CODE: "CLQMC",
      CITY_NAME: "QUEMCHI",
      DPA_PORT_CODE: "QMC",
    },
    {
      CITY_CODE: "CLQRC",
      CITY_NAME: "RANCAGUA",
      DPA_PORT_CODE: "QRC",
    },
    {
      CITY_CODE: "CLQTV",
      CITY_NAME: "QUINTERO",
      DPA_PORT_CODE: "QTV",
    },
    {
      CITY_CODE: "CLSAI",
      CITY_NAME: "SAN ANTONIO",
      DPA_PORT_CODE: "SAI",
    },
    {
      CITY_CODE: "CLSVE",
      CITY_NAME: "SAN VICENTE",
      DPA_PORT_CODE: "SVE",
    },
    {
      CITY_CODE: "CLTAL",
      CITY_NAME: "TALCAHUANO",
      DPA_PORT_CODE: "TAL",
    },
    {
      CITY_CODE: "CLTLX",
      CITY_NAME: "TALCA",
      DPA_PORT_CODE: "TLX",
    },
    {
      CITY_CODE: "CLTTC",
      CITY_NAME: "TALTAL",
      DPA_PORT_CODE: "TTC",
    },
    {
      CITY_CODE: "CLVAP",
      CITY_NAME: "VALPARAISO",
      DPA_PORT_CODE: "VAP",
    },
    {
      CITY_CODE: "CLWPU",
      CITY_NAME: "PUERTO WILLIAMS",
      DPA_PORT_CODE: "WPU",
    },
    {
      CITY_CODE: "CLZAL",
      CITY_NAME: "VALDIVIA",
      DPA_PORT_CODE: "ZAL",
    },
    {
      CITY_CODE: "CLZCO",
      CITY_NAME: "TEMUCO",
      DPA_PORT_CODE: "ZCO",
    },
    {
      CITY_CODE: "CLZUD",
      CITY_NAME: "ANCUD",
      DPA_PORT_CODE: "ZUD",
    },
    {
      CITY_CODE: "CMDLA",
      CITY_NAME: "DOUALA",
      DPA_PORT_CODE: "DLA",
    },
    {
      CITY_CODE: "CMKBI",
      CITY_NAME: "KRIBI",
      DPA_PORT_CODE: "KBI",
    },
    {
      CITY_CODE: "CMTKC",
      CITY_NAME: "TIKO",
      DPA_PORT_CODE: "TKC",
    },
    {
      CITY_CODE: "CMVCC",
      CITY_NAME: "VICTORIA",
      DPA_PORT_CODE: "VCC",
    },
    {
      CITY_CODE: "CNBAY",
      CITY_NAME: "BAYUQUAN",
      DPA_PORT_CODE: "BBY",
    },
    {
      CITY_CODE: "CNBHY",
      CITY_NAME: "BEIHAI",
      DPA_PORT_CODE: "BHY",
    },
    {
      CITY_CODE: "CNBJO",
      CITY_NAME: "BEIJIAO",
      DPA_PORT_CODE: "BJO",
    },
    {
      CITY_CODE: "CNCAN",
      CITY_NAME: "GUANGZHOU",
      DPA_PORT_CODE: "CAN",
    },
    {
      CITY_CODE: "CNCFD",
      CITY_NAME: "CAOFEIDIAN PT",
      DPA_PORT_CODE: "CJD",
    },
    {
      CITY_CODE: "CNCGJ",
      CITY_NAME: "CHONGJIN",
      DPA_PORT_CODE: "CGI",
    },
    {
      CITY_CODE: "CNCHA",
      CITY_NAME: "CHANGLE",
      DPA_PORT_CODE: "HCL",
    },
    {
      CITY_CODE: "CNCHG",
      CITY_NAME: "CHENGHAI",
      DPA_PORT_CODE: "CHG",
    },
    {
      CITY_CODE: "CNCKG",
      CITY_NAME: "CHONGQING",
      DPA_PORT_CODE: "CKG",
    },
    {
      CITY_CODE: "CNCLJ",
      CITY_NAME: "CHENGLINJI",
      DPA_PORT_CODE: "CLJ",
    },
    {
      CITY_CODE: "CNCTU",
      CITY_NAME: "CHENGDU",
      DPA_PORT_CODE: "CTU",
    },
    {
      CITY_CODE: "CNCUA",
      CITY_NAME: "CHUNHUA",
      DPA_PORT_CODE: "CUA",
    },
    {
      CITY_CODE: "CNCWN",
      CITY_NAME: "Chiwan",
      DPA_PORT_CODE: "CWN",
    },
    {
      CITY_CODE: "CNDAI",
      CITY_NAME: "DAI MING",
      DPA_PORT_CODE: "DAI",
    },
    {
      CITY_CODE: "CNDAN",
      CITY_NAME: "DAAN",
      DPA_PORT_CODE: "DAN",
    },
    {
      CITY_CODE: "CNDCB",
      CITY_NAME: "DA CHAN BAY",
      DPA_PORT_CODE: "DHB",
    },
    {
      CITY_CODE: "CNDGG",
      CITY_NAME: "DONGGUAN",
      DPA_PORT_CODE: "DGG",
    },
    {
      CITY_CODE: "CNDLC",
      CITY_NAME: "DALIAN",
      DPA_PORT_CODE: "DLC",
    },
    {
      CITY_CODE: "CNDLN",
      CITY_NAME: "DALINGSHAN",
      DPA_PORT_CODE: "DLN",
    },
    {
      CITY_CODE: "CNDOU",
      CITY_NAME: "DOUMEN",
      DPA_PORT_CODE: "DOU",
    },
    {
      CITY_CODE: "CNDXG",
      CITY_NAME: "DALIANXINGANG",
      DPA_PORT_CODE: "DXG",
    },
    {
      CITY_CODE: "CNENP",
      CITY_NAME: "ENPING",
      DPA_PORT_CODE: "ENP",
    },
    {
      CITY_CODE: "CNFAN",
      CITY_NAME: "FANGCHENG",
      DPA_PORT_CODE: "FAN",
    },
    {
      CITY_CODE: "CNFOC",
      CITY_NAME: "FUZHOU",
      DPA_PORT_CODE: "FOC",
    },
    {
      CITY_CODE: "CNFOS",
      CITY_NAME: "FOSHAN",
      DPA_PORT_CODE: "FHN",
    },
    {
      CITY_CODE: "CNFRT",
      CITY_NAME: "FOSHAN NEW PORT",
      DPA_PORT_CODE: "FRT",
    },
    {
      CITY_CODE: "CNFUG",
      CITY_NAME: "FUQING",
      DPA_PORT_CODE: "FUG",
    },
    {
      CITY_CODE: "CNFUJ",
      CITY_NAME: "FUJIN",
      DPA_PORT_CODE: "FJN",
    },
    {
      CITY_CODE: "CNGAL",
      CITY_NAME: "GALOLAN",
      DPA_PORT_CODE: "GLN",
    },
    {
      CITY_CODE: "CNGAS",
      CITY_NAME: "GAOSHA",
      DPA_PORT_CODE: "GAS",
    },
    {
      CITY_CODE: "CNGAY",
      CITY_NAME: "GAOYAO",
      DPA_PORT_CODE: "GAY",
    },
    {
      CITY_CODE: "CNGGY",
      CITY_NAME: "GONGYI",
      DPA_PORT_CODE: "GGI",
    },
    {
      CITY_CODE: "CNGLN",
      CITY_NAME: "GAOLAN",
      DPA_PORT_CODE: "OLN",
    },
    {
      CITY_CODE: "CNGNS",
      CITY_NAME: "NANSHA(CNSC)",
      DPA_PORT_CODE: "GNS",
    },
    {
      CITY_CODE: "CNGOM",
      CITY_NAME: "GAOMING",
      DPA_PORT_CODE: "GOM",
    },
    {
      CITY_CODE: "CNGON",
      CITY_NAME: "GAOLAN",
      DPA_PORT_CODE: "ZGO",
    },
    {
      CITY_CODE: "CNGUA",
      CITY_NAME: "GUANGDONG",
      DPA_PORT_CODE: "GDG",
    },
    {
      CITY_CODE: "CNHAK",
      CITY_NAME: "HAIKOU",
      DPA_PORT_CODE: "HAK",
    },
    {
      CITY_CODE: "CNHAN",
      CITY_NAME: "HANJIANG",
      DPA_PORT_CODE: "HNJ",
    },
    {
      CITY_CODE: "CNHDU",
      CITY_NAME: "HUADU",
      DPA_PORT_CODE: "HDU",
    },
    {
      CITY_CODE: "CNHME",
      CITY_NAME: "HAIMEN",
      DPA_PORT_CODE: "HME",
    },
    {
      CITY_CODE: "CNHOP",
      CITY_NAME: "HUANGPU OLD PORT",
      DPA_PORT_CODE: "HOP",
    },
    {
      CITY_CODE: "CNHSI",
      CITY_NAME: "HUANGSHI",
      DPA_PORT_CODE: "HWI",
    },
    {
      CITY_CODE: "CNHUA",
      CITY_NAME: "HUANGPU",
      DPA_PORT_CODE: "HUA",
    },
    {
      CITY_CODE: "CNHUD",
      CITY_NAME: "HUA DU",
      DPA_PORT_CODE: "HUD",
    },
    {
      CITY_CODE: "CNHUI",
      CITY_NAME: "HUIZHOU",
      DPA_PORT_CODE: "HUI",
    },
    {
      CITY_CODE: "CNHUN",
      CITY_NAME: "HUANGPU NEW PORT",
      DPA_PORT_CODE: "HUN",
    },
    {
      CITY_CODE: "CNHZU",
      CITY_NAME: "HOUZHU",
      DPA_PORT_CODE: "HZU",
    },
    {
      CITY_CODE: "CNJHG",
      CITY_NAME: "JINGHONG",
      DPA_PORT_CODE: "JHG",
    },
    {
      CITY_CODE: "CNJIA",
      CITY_NAME: "JIANGYIN",
      DPA_PORT_CODE: "JIA",
    },
    {
      CITY_CODE: "CNJIN",
      CITY_NAME: "JINGTANG",
      DPA_PORT_CODE: "JGT",
    },
    {
      CITY_CODE: "CNJIU",
      CITY_NAME: "JIUJIANG",
      DPA_PORT_CODE: "JIU",
    },
    {
      CITY_CODE: "CNJIX",
      CITY_NAME: "JIAXING",
      DPA_PORT_CODE: "JIX",
    },
    {
      CITY_CODE: "CNJXN",
      CITY_NAME: "JIAO XIN",
      DPA_PORT_CODE: "JXN",
    },
    {
      CITY_CODE: "CNJZU",
      CITY_NAME: "JIUZHOU",
      DPA_PORT_CODE: "JZU",
    },
    {
      CITY_CODE: "CNKHN",
      CITY_NAME: "NANCHANG",
      DPA_PORT_CODE: "KHN",
    },
    {
      CITY_CODE: "CNKPN",
      CITY_NAME: "KAIPING",
      DPA_PORT_CODE: "KPN",
    },
    {
      CITY_CODE: "CNKUS",
      CITY_NAME: "KUNSHAN",
      DPA_PORT_CODE: "KUS",
    },
    {
      CITY_CODE: "CNLIH",
      CITY_NAME: "LIANHUASHAN",
      DPA_PORT_CODE: "LIH",
    },
    {
      CITY_CODE: "CNLKU",
      CITY_NAME: "LONGKOU",
      DPA_PORT_CODE: "LKU",
    },
    {
      CITY_CODE: "CNLSN",
      CITY_NAME: "LANSHAN",
      DPA_PORT_CODE: "LSZ",
    },
    {
      CITY_CODE: "CNLUD",
      CITY_NAME: "LIUDU",
      DPA_PORT_CODE: "LUO",
    },
    {
      CITY_CODE: "CNLYG",
      CITY_NAME: "LIANYUNGANG",
      DPA_PORT_CODE: "LYG",
    },
    {
      CITY_CODE: "CNLZU",
      CITY_NAME: "LUZHOU",
      DPA_PORT_CODE: "LZU",
    },
    {
      CITY_CODE: "CNMFG",
      CITY_NAME: "MAFANG",
      DPA_PORT_CODE: "MFG",
    },
    {
      CITY_CODE: "CNMMI",
      CITY_NAME: "MAOMING",
      DPA_PORT_CODE: "MMI",
    },
    {
      CITY_CODE: "CNNAH",
      CITY_NAME: "NANHAI",
      DPA_PORT_CODE: "NAH",
    },
    {
      CITY_CODE: "CNNBG",
      CITY_NAME: "NINGBO PT",
      DPA_PORT_CODE: "NPB",
    },
    {
      CITY_CODE: "CNNBO",
      CITY_NAME: "NINGBO",
      DPA_PORT_CODE: "GBO",
    },
    {
      CITY_CODE: "CNNGB",
      CITY_NAME: "NINGBO LISHE INTERNATIONAL APT",
      DPA_PORT_CODE: "NGB",
    },
    {
      CITY_CODE: "CNNKG",
      CITY_NAME: "NANJING",
      DPA_PORT_CODE: "NKG",
    },
    {
      CITY_CODE: "CNNSA",
      CITY_NAME: "NANSHA ",
      DPA_PORT_CODE: "NSA",
    },
    {
      CITY_CODE: "CNNTG",
      CITY_NAME: "NANTONG",
      DPA_PORT_CODE: "NTG",
    },
    {
      CITY_CODE: "CNNTU",
      CITY_NAME: "NANTOU",
      DPA_PORT_CODE: "NTU",
    },
    {
      CITY_CODE: "CNQGY",
      CITY_NAME: "QINGYUAN",
      DPA_PORT_CODE: "QGY",
    },
    {
      CITY_CODE: "CNQHA",
      CITY_NAME: "QINHUANGDAO",
      DPA_PORT_CODE: "QHA",
    },
    {
      CITY_CODE: "CNQZH",
      CITY_NAME: "QINZHOU",
      DPA_PORT_CODE: "QZH",
    },
    {
      CITY_CODE: "CNQZJ",
      CITY_NAME: "QUANZHOU",
      DPA_PORT_CODE: "QZJ",
    },
    {
      CITY_CODE: "CNSBU",
      CITY_NAME: "SANBU",
      DPA_PORT_CODE: "SBU",
    },
    {
      CITY_CODE: "CNSDG",
      CITY_NAME: "SHUIDONG",
      DPA_PORT_CODE: "SDG",
    },
    {
      CITY_CODE: "CNSHA",
      CITY_NAME: "SHANGHAI",
      DPA_PORT_CODE: "SHA",
    },
    {
      CITY_CODE: "CNSHG",
      CITY_NAME: "SANSHAN ",
      DPA_PORT_CODE: "SHG",
    },
    {
      CITY_CODE: "CNSHK",
      CITY_NAME: "SHEKOU",
      DPA_PORT_CODE: "SHK",
    },
    {
      CITY_CODE: "CNSHN",
      CITY_NAME: "SHIWAN",
      DPA_PORT_CODE: "SWN",
    },
    {
      CITY_CODE: "CNSHU",
      CITY_NAME: "SHIHU",
      DPA_PORT_CODE: "SHU",
    },
    {
      CITY_CODE: "CNSIN",
      CITY_NAME: "SHATIAN",
      DPA_PORT_CODE: "STI",
    },
    {
      CITY_CODE: "CNSJQ",
      CITY_NAME: "SANSHUI",
      DPA_PORT_CODE: "SJQ",
    },
    {
      CITY_CODE: "CNSNW",
      CITY_NAME: "SHENWAN",
      DPA_PORT_CODE: "HWA",
    },
    {
      CITY_CODE: "CNSRG",
      CITY_NAME: "SANRONG",
      DPA_PORT_CODE: "SNG",
    },
    {
      CITY_CODE: "CNSSN",
      CITY_NAME: "SANSHAN",
      DPA_PORT_CODE: "SSN",
    },
    {
      CITY_CODE: "CNSSX",
      CITY_NAME: "SHA SHI",
      DPA_PORT_CODE: "SSX",
    },
    {
      CITY_CODE: "CNSTI",
      CITY_NAME: "SHATIAN",
      DPA_PORT_CODE: "TDI",
    },
    {
      CITY_CODE: "CNSTN",
      CITY_NAME: "SHANTIAN",
      DPA_PORT_CODE: "STN",
    },
    {
      CITY_CODE: "CNSUD",
      CITY_NAME: "SHUNDE",
      DPA_PORT_CODE: "SND",
    },
    {
      CITY_CODE: "CNSWA",
      CITY_NAME: "SHANTOU",
      DPA_PORT_CODE: "SWX",
    },
    {
      CITY_CODE: "CNSZP",
      CITY_NAME: "SHENZHEN",
      DPA_PORT_CODE: "SZP",
    },
    {
      CITY_CODE: "CNTAO",
      CITY_NAME: "QINGDAO",
      DPA_PORT_CODE: "TAO",
    },
    {
      CITY_CODE: "CNTGA",
      CITY_NAME: "TANGGU",
      DPA_PORT_CODE: "TGA",
    },
    {
      CITY_CODE: "CNTOL",
      CITY_NAME: "TONGLING",
      DPA_PORT_CODE: "TOG",
    },
    {
      CITY_CODE: "CNTSH",
      CITY_NAME: "TAISHAN",
      DPA_PORT_CODE: "TSH",
    },
    {
      CITY_CODE: "CNTSN",
      CITY_NAME: "TIANJIN",
      DPA_PORT_CODE: "TSN",
    },
    {
      CITY_CODE: "CNTXG",
      CITY_NAME: "Tianjinxingang",
      DPA_PORT_CODE: "XGG",
    },
    {
      CITY_CODE: "CNWAI",
      CITY_NAME: "WAIHAI",
      DPA_PORT_CODE: "WAI",
    },
    {
      CITY_CODE: "CNWGQ",
      CITY_NAME: "WAI GAO QIAO",
      DPA_PORT_CODE: "WGQ",
    },
    {
      CITY_CODE: "CNWHI",
      CITY_NAME: "WUHU",
      DPA_PORT_CODE: "WHI",
    },
    {
      CITY_CODE: "CNWNZ",
      CITY_NAME: "WENZHOU",
      DPA_PORT_CODE: "WNZ",
    },
    {
      CITY_CODE: "CNWTU",
      CITY_NAME: "WEITOU",
      DPA_PORT_CODE: "WTU",
    },
    {
      CITY_CODE: "CNWUH",
      CITY_NAME: "WUHAN",
      DPA_PORT_CODE: "WUH",
    },
    {
      CITY_CODE: "CNWUX",
      CITY_NAME: "WUXI",
      DPA_PORT_CODE: "WUX",
    },
    {
      CITY_CODE: "CNWUZ",
      CITY_NAME: "WUZHOU",
      DPA_PORT_CODE: "WUZ",
    },
    {
      CITY_CODE: "CNWXI",
      CITY_NAME: "WANXIAN",
      DPA_PORT_CODE: "WXI",
    },
    {
      CITY_CODE: "CNWZH",
      CITY_NAME: "WANZHOU",
      DPA_PORT_CODE: "WZH",
    },
    {
      CITY_CODE: "CNXAO",
      CITY_NAME: "XIAOLAN",
      DPA_PORT_CODE: "XAO",
    },
    {
      CITY_CODE: "CNXGZ",
      CITY_NAME: "XIANGZHOU",
      DPA_PORT_CODE: "XUZ",
    },
    {
      CITY_CODE: "CNXIG",
      CITY_NAME: "XINGANG",
      DPA_PORT_CODE: "XJG",
    },
    {
      CITY_CODE: "CNXIN",
      CITY_NAME: "XINHUI",
      DPA_PORT_CODE: "XIH",
    },
    {
      CITY_CODE: "CNXIZ",
      CITY_NAME: "XIANGZHOU",
      DPA_PORT_CODE: "XOZ",
    },
    {
      CITY_CODE: "CNXMN",
      CITY_NAME: "XIAMEN",
      DPA_PORT_CODE: "XMN",
    },
    {
      CITY_CODE: "CNXNA",
      CITY_NAME: "XINSHA",
      DPA_PORT_CODE: "XNA",
    },
    {
      CITY_CODE: "CNXNG",
      CITY_NAME: "XINJIANG",
      DPA_PORT_CODE: "XNG",
    },
    {
      CITY_CODE: "CNXZU",
      CITY_NAME: "XIANGZHOU",
      DPA_PORT_CODE: "XZU",
    },
    {
      CITY_CODE: "CNYAN",
      CITY_NAME: "YANGSHAN",
      DPA_PORT_CODE: "YAN",
    },
    {
      CITY_CODE: "CNYIC",
      CITY_NAME: "YICHANG",
      DPA_PORT_CODE: "YIC",
    },
    {
      CITY_CODE: "CNYNT",
      CITY_NAME: "YANTAI",
      DPA_PORT_CODE: "YNT",
    },
    {
      CITY_CODE: "CNYPG",
      CITY_NAME: "YANGPU",
      DPA_PORT_CODE: "YPG",
    },
    {
      CITY_CODE: "CNYTN",
      CITY_NAME: "Yantian",
      DPA_PORT_CODE: "YTN",
    },
    {
      CITY_CODE: "CNYYA",
      CITY_NAME: "YUEYANG",
      DPA_PORT_CODE: "YYA",
    },
    {
      CITY_CODE: "CNYZH",
      CITY_NAME: "YANGZHOU",
      DPA_PORT_CODE: "YZH",
    },
    {
      CITY_CODE: "CNZAP",
      CITY_NAME: "ZHAPU",
      DPA_PORT_CODE: "ZAP",
    },
    {
      CITY_CODE: "CNZHA",
      CITY_NAME: "ZHANJIANG",
      DPA_PORT_CODE: "ZHA",
    },
    {
      CITY_CODE: "CNZHE",
      CITY_NAME: "ZHENJIANG",
      DPA_PORT_CODE: "ZHE",
    },
    {
      CITY_CODE: "CNZHZ",
      CITY_NAME: "ZHUOZHOU",
      DPA_PORT_CODE: "ZHZ",
    },
    {
      CITY_CODE: "CNZJG",
      CITY_NAME: "ZHANGJIAGANG",
      DPA_PORT_CODE: "ZJG",
    },
    {
      CITY_CODE: "CNZQG",
      CITY_NAME: "ZHAOQING",
      DPA_PORT_CODE: "ZQG",
    },
    {
      CITY_CODE: "CNZSN",
      CITY_NAME: "Zhongshan",
      DPA_PORT_CODE: "ZSN",
    },
    {
      CITY_CODE: "CNZUH",
      CITY_NAME: "ZHUHAI",
      DPA_PORT_CODE: "ZUH",
    },
    {
      CITY_CODE: "CNZZU",
      CITY_NAME: "ZHANGZHOU",
      DPA_PORT_CODE: "ZZU",
    },
    {
      CITY_CODE: "COBAQ",
      CITY_NAME: "BARRANQUILLA",
      DPA_PORT_CODE: "BAQ",
    },
    {
      CITY_CODE: "COBUN",
      CITY_NAME: "BUENAVENTURA",
      DPA_PORT_CODE: "BNV",
    },
    {
      CITY_CODE: "COCTG",
      CITY_NAME: "CARTAGENA",
      DPA_PORT_CODE: "CTG",
    },
    {
      CITY_CODE: "COEJA",
      CITY_NAME: "BARRANCABERMEJA",
      DPA_PORT_CODE: "EJA",
    },
    {
      CITY_CODE: "COLET",
      CITY_NAME: "LETICIA",
      DPA_PORT_CODE: "LET",
    },
    {
      CITY_CODE: "COPBE",
      CITY_NAME: "PUERTO BERRIO",
      DPA_PORT_CODE: "PBE",
    },
    {
      CITY_CODE: "COPUC",
      CITY_NAME: "PUERTO COLOMBIA",
      DPA_PORT_CODE: "PUC",
    },
    {
      CITY_CODE: "COSMR",
      CITY_NAME: "SANTA MARTA",
      DPA_PORT_CODE: "SMR",
    },
    {
      CITY_CODE: "COTCO",
      CITY_NAME: "TUMACO",
      DPA_PORT_CODE: "TCO",
    },
    {
      CITY_CODE: "COTLU",
      CITY_NAME: "TOLU",
      DPA_PORT_CODE: "TLU",
    },
    {
      CITY_CODE: "CRCAL",
      CITY_NAME: "CALDERA",
      DPA_PORT_CODE: "CLD",
    },
    {
      CITY_CODE: "CRCPL",
      CITY_NAME: "GUAPILES",
      DPA_PORT_CODE: "CJP",
    },
    {
      CITY_CODE: "CRGLF",
      CITY_NAME: "GOLFITO",
      DPA_PORT_CODE: "GLF",
    },
    {
      CITY_CODE: "CRHER",
      CITY_NAME: "HEREDIA",
      DPA_PORT_CODE: "HED",
    },
    {
      CITY_CODE: "CRLIO",
      CITY_NAME: "PUERTO LIMON",
      DPA_PORT_CODE: "LIO",
    },
    {
      CITY_CODE: "CRMOB",
      CITY_NAME: "MOIN BAY",
      DPA_PORT_CODE: "MBI",
    },
    {
      CITY_CODE: "CRNAR",
      CITY_NAME: "NARANJO",
      DPA_PORT_CODE: "NJO",
    },
    {
      CITY_CODE: "CRPAS",
      CITY_NAME: "PUNTARENAS",
      DPA_PORT_CODE: "PNS",
    },
    {
      CITY_CODE: "CRPMN",
      CITY_NAME: "PUERTO MOIN",
      DPA_PORT_CODE: "PNU",
    },
    {
      CITY_CODE: "CRPTC",
      CITY_NAME: "PUERTO CALDERA",
      DPA_PORT_CODE: "PJC",
    },
    {
      CITY_CODE: "CRSJO",
      CITY_NAME: "SAN JOSE",
      DPA_PORT_CODE: "SJO",
    },
    {
      CITY_CODE: "CRXQP",
      CITY_NAME: "QUEPOS",
      DPA_PORT_CODE: "XQP",
    },
    {
      CITY_CODE: "CUANT",
      CITY_NAME: "ANTILLA",
      DPA_PORT_CODE: "ANT",
    },
    {
      CITY_CODE: "CUCFG",
      CITY_NAME: "CIENFUEGOS",
      DPA_PORT_CODE: "CFG",
    },
    {
      CITY_CODE: "CUGYB",
      CITY_NAME: "GUAYABAL",
      DPA_PORT_CODE: "GYB",
    },
    {
      CITY_CODE: "CUHAV",
      CITY_NAME: "HABANA",
      DPA_PORT_CODE: "HAV",
    },
    {
      CITY_CODE: "CUMAR",
      CITY_NAME: "MARIEL",
      DPA_PORT_CODE: "CXU",
    },
    {
      CITY_CODE: "CUMZO",
      CITY_NAME: "MANZANILLO",
      DPA_PORT_CODE: "MNZ",
    },
    {
      CITY_CODE: "CUNIQ",
      CITY_NAME: "NIQUERO",
      DPA_PORT_CODE: "NIQ",
    },
    {
      CITY_CODE: "CUPIL",
      CITY_NAME: "PILON",
      DPA_PORT_CODE: "PIL",
    },
    {
      CITY_CODE: "CUQMA",
      CITY_NAME: "MATANZAS",
      DPA_PORT_CODE: "QMA",
    },
    {
      CITY_CODE: "CUSCU",
      CITY_NAME: "SANTIAGO DE CUBA",
      DPA_PORT_CODE: "SCU",
    },
    {
      CITY_CODE: "CUSNU",
      CITY_NAME: "SANTA CLARA",
      DPA_PORT_CODE: "SNU",
    },
    {
      CITY_CODE: "CVRAI",
      CITY_NAME: "PRAIA",
      DPA_PORT_CODE: "RAI",
    },
    {
      CITY_CODE: "CVVXE",
      CITY_NAME: "SAO VICENTE",
      DPA_PORT_CODE: "VXE",
    },
    {
      CITY_CODE: "CWCUR",
      CITY_NAME: "CURACAO",
      DPA_PORT_CODE: "CXR",
    },
    {
      CITY_CODE: "CYAKT",
      CITY_NAME: "AKROTIRI",
      DPA_PORT_CODE: "AKT",
    },
    {
      CITY_CODE: "CYFMG",
      CITY_NAME: "FAMAGUSTA",
      DPA_PORT_CODE: "FMG",
    },
    {
      CITY_CODE: "CYKYR",
      CITY_NAME: "KYRENIA",
      DPA_PORT_CODE: "KYR",
    },
    {
      CITY_CODE: "CYLCA",
      CITY_NAME: "LARNACA",
      DPA_PORT_CODE: "LCA",
    },
    {
      CITY_CODE: "CYLMS",
      CITY_NAME: "LIMASSOL",
      DPA_PORT_CODE: "LMS",
    },
    {
      CITY_CODE: "CYMPB",
      CITY_NAME: "MORPHOU BAY",
      DPA_PORT_CODE: "MPB",
    },
    {
      CITY_CODE: "CYNIC",
      CITY_NAME: "NICOSIA",
      DPA_PORT_CODE: "ICO",
    },
    {
      CITY_CODE: "CYPFO",
      CITY_NAME: "PAPHOS",
      DPA_PORT_CODE: "PFO",
    },
    {
      CITY_CODE: "CYVAS",
      CITY_NAME: "VASILIKOS",
      DPA_PORT_CODE: "VKS",
    },
    {
      CITY_CODE: "CYZYY",
      CITY_NAME: "ZYYI",
      DPA_PORT_CODE: "ZYY",
    },
    {
      CITY_CODE: "CZDCB",
      CITY_NAME: "DECIN",
      DPA_PORT_CODE: "DCB",
    },
    {
      CITY_CODE: "CZHBC",
      CITY_NAME: "HORNI BECVA",
      DPA_PORT_CODE: "HBC",
    },
    {
      CITY_CODE: "CZJNH",
      CITY_NAME: "JINDRICHUV HRADEC",
      DPA_PORT_CODE: "JNH",
    },
    {
      CITY_CODE: "CZKTV",
      CITY_NAME: "KLATOVY",
      DPA_PORT_CODE: "KTV",
    },
    {
      CITY_CODE: "CZOLO",
      CITY_NAME: "OLOMOUC",
      DPA_PORT_CODE: "OLK",
    },
    {
      CITY_CODE: "CZOPA",
      CITY_NAME: "OPAVA",
      DPA_PORT_CODE: "OPA",
    },
    {
      CITY_CODE: "CZPEL",
      CITY_NAME: "PELHRIMOV",
      DPA_PORT_CODE: "PEQ",
    },
    {
      CITY_CODE: "CZPLZ",
      CITY_NAME: "PLZEN",
      DPA_PORT_CODE: "PZN",
    },
    {
      CITY_CODE: "CZPRB",
      CITY_NAME: "PARDUBICE",
      DPA_PORT_CODE: "PRB",
    },
    {
      CITY_CODE: "CZPRV",
      CITY_NAME: "PREROV",
      DPA_PORT_CODE: "PRV",
    },
    {
      CITY_CODE: "CZSKN",
      CITY_NAME: "STRAKONICE",
      DPA_PORT_CODE: "SKC",
    },
    {
      CITY_CODE: "CZTBR",
      CITY_NAME: "TABOR",
      DPA_PORT_CODE: "TBE",
    },
    {
      CITY_CODE: "CZVEA",
      CITY_NAME: "CESKE VELENICE",
      DPA_PORT_CODE: "VEA",
    },
    {
      CITY_CODE: "DEAGB",
      CITY_NAME: "AUGSBURG",
      DPA_PORT_CODE: "AGB",
    },
    {
      CITY_CODE: "DEAND",
      CITY_NAME: "ANDERNACH",
      DPA_PORT_CODE: "AND",
    },
    {
      CITY_CODE: "DEASC",
      CITY_NAME: "ASCHAFFENBURG",
      DPA_PORT_CODE: "ASC",
    },
    {
      CITY_CODE: "DEASS",
      CITY_NAME: "ASSEL",
      DPA_PORT_CODE: "ASS",
    },
    {
      CITY_CODE: "DEAUG",
      CITY_NAME: "AUGUSTFEHN",
      DPA_PORT_CODE: "AUG",
    },
    {
      CITY_CODE: "DEAUR",
      CITY_NAME: "AURICH",
      DPA_PORT_CODE: "AUR",
    },
    {
      CITY_CODE: "DEAWR",
      CITY_NAME: "ACHTERWEHR RINGKANAL",
      DPA_PORT_CODE: "AWR",
    },
    {
      CITY_CODE: "DEBAM",
      CITY_NAME: "BAMBERG",
      DPA_PORT_CODE: "BAM",
    },
    {
      CITY_CODE: "DEBBI",
      CITY_NAME: "BIETIGHEIMBISSINGEN",
      DPA_PORT_CODE: "BBS",
    },
    {
      CITY_CODE: "DEBBL",
      CITY_NAME: "BIBLIS",
      DPA_PORT_CODE: "BIB",
    },
    {
      CITY_CODE: "DEBDU",
      CITY_NAME: "BAD DUERKHEIM",
      DPA_PORT_CODE: "BDU",
    },
    {
      CITY_CODE: "DEBEI",
      CITY_NAME: "BEIDENFLETH",
      DPA_PORT_CODE: "BEI",
    },
    {
      CITY_CODE: "DEBER",
      CITY_NAME: "BERLIN",
      DPA_PORT_CODE: "BER",
    },
    {
      CITY_CODE: "DEBFH",
      CITY_NAME: "BAD FRIEDRICHSHALL",
      DPA_PORT_CODE: "BFH",
    },
    {
      CITY_CODE: "DEBGE",
      CITY_NAME: "BERGEN",
      DPA_PORT_CODE: "BGE",
    },
    {
      CITY_CODE: "DEBGL",
      CITY_NAME: "BERGISHGLADBACH",
      DPA_PORT_CODE: "BGL",
    },
    {
      CITY_CODE: "DEBIT",
      CITY_NAME: "BITBURG",
      DPA_PORT_CODE: "BIT",
    },
    {
      CITY_CODE: "DEBKF",
      CITY_NAME: "BIEDENKOPF",
      DPA_PORT_CODE: "BKF",
    },
    {
      CITY_CODE: "DEBMK",
      CITY_NAME: "BORKUM",
      DPA_PORT_CODE: "BMK",
    },
    {
      CITY_CODE: "DEBOK",
      CITY_NAME: "BOKEL",
      DPA_PORT_CODE: "BOK",
    },
    {
      CITY_CODE: "DEBON",
      CITY_NAME: "BONN",
      DPA_PORT_CODE: "BNN",
    },
    {
      CITY_CODE: "DEBOT",
      CITY_NAME: "BOTTROP",
      DPA_PORT_CODE: "BTO",
    },
    {
      CITY_CODE: "DEBRB",
      CITY_NAME: "BRUNSBUETTEL",
      DPA_PORT_CODE: "BRB",
    },
    {
      CITY_CODE: "DEBRE",
      CITY_NAME: "BREMEN",
      DPA_PORT_CODE: "BRE",
    },
    {
      CITY_CODE: "DEBRM",
      CITY_NAME: "BRAMSCHE",
      DPA_PORT_CODE: "BRM",
    },
    {
      CITY_CODE: "DEBRV",
      CITY_NAME: "BREMERHAVEN",
      DPA_PORT_CODE: "BRV",
    },
    {
      CITY_CODE: "DEBSH",
      CITY_NAME: "BREISACH",
      DPA_PORT_CODE: "BSH",
    },
    {
      CITY_CODE: "DEBUL",
      CITY_NAME: "BUEHL",
      DPA_PORT_CODE: "BUL",
    },
    {
      CITY_CODE: "DEBUX",
      CITY_NAME: "BUXTEHUDE",
      DPA_PORT_CODE: "BUX",
    },
    {
      CITY_CODE: "DEBUZ",
      CITY_NAME: "BUETZFLETH",
      DPA_PORT_CODE: "BUJ",
    },
    {
      CITY_CODE: "DECGN",
      CITY_NAME: "KOELN",
      DPA_PORT_CODE: "CGN",
    },
    {
      CITY_CODE: "DECUX",
      CITY_NAME: "CUXHAVEN",
      DPA_PORT_CODE: "CUX",
    },
    {
      CITY_CODE: "DEDEG",
      CITY_NAME: "DEGGENDORF",
      DPA_PORT_CODE: "DEG",
    },
    {
      CITY_CODE: "DEDIN",
      CITY_NAME: "DINSLAKEN",
      DPA_PORT_CODE: "DIN",
    },
    {
      CITY_CODE: "DEDIT",
      CITY_NAME: "DITZUM",
      DPA_PORT_CODE: "DIT",
    },
    {
      CITY_CODE: "DEDMG",
      CITY_NAME: "DORMAGEN",
      DPA_PORT_CODE: "DMG",
    },
    {
      CITY_CODE: "DEDOB",
      CITY_NAME: "DORNBUSCH",
      DPA_PORT_CODE: "DOB",
    },
    {
      CITY_CODE: "DEDRP",
      CITY_NAME: "DOERPEN",
      DPA_PORT_CODE: "DRP",
    },
    {
      CITY_CODE: "DEDTM",
      CITY_NAME: "DORTMUND",
      DPA_PORT_CODE: "DTM",
    },
    {
      CITY_CODE: "DEDUI",
      CITY_NAME: "DUISBURG",
      DPA_PORT_CODE: "DUI",
    },
    {
      CITY_CODE: "DEDUS",
      CITY_NAME: "DUESSELDORF",
      DPA_PORT_CODE: "DUS",
    },
    {
      CITY_CODE: "DEEBT",
      CITY_NAME: "EIBELSTADT",
      DPA_PORT_CODE: "EBT",
    },
    {
      CITY_CODE: "DEEBX",
      CITY_NAME: "ESSENBACH",
      DPA_PORT_CODE: "EBX",
    },
    {
      CITY_CODE: "DEECK",
      CITY_NAME: "ECKERNFOERDE",
      DPA_PORT_CODE: "ECK",
    },
    {
      CITY_CODE: "DEEHI",
      CITY_NAME: "EHINGEN",
      DPA_PORT_CODE: "EHI",
    },
    {
      CITY_CODE: "DEEHS",
      CITY_NAME: "EISENHUETTENSTADT",
      DPA_PORT_CODE: "EHS",
    },
    {
      CITY_CODE: "DEELM",
      CITY_NAME: "ELMSHORN",
      DPA_PORT_CODE: "ELM",
    },
    {
      CITY_CODE: "DEEME",
      CITY_NAME: "EMDEN",
      DPA_PORT_CODE: "EME",
    },
    {
      CITY_CODE: "DEEMM",
      CITY_NAME: "EMMERICH",
      DPA_PORT_CODE: "EMM",
    },
    {
      CITY_CODE: "DEESS",
      CITY_NAME: "ESSEN",
      DPA_PORT_CODE: "ESS",
    },
    {
      CITY_CODE: "DEEUT",
      CITY_NAME: "EUTIN",
      DPA_PORT_CODE: "EUT",
    },
    {
      CITY_CODE: "DEEWH",
      CITY_NAME: "ECKWARDERHOERNE",
      DPA_PORT_CODE: "EWH",
    },
    {
      CITY_CODE: "DEEWS",
      CITY_NAME: "ECKWARDERSIEL",
      DPA_PORT_CODE: "EWS",
    },
    {
      CITY_CODE: "DEFDH",
      CITY_NAME: "FRIEDRICHSHAFEN",
      DPA_PORT_CODE: "FDH",
    },
    {
      CITY_CODE: "DEFEH",
      CITY_NAME: "FEHMARN",
      DPA_PORT_CODE: "FEH",
    },
    {
      CITY_CODE: "DEFFO",
      CITY_NAME: "FRANKFURT/ODER",
      DPA_PORT_CODE: "FFO",
    },
    {
      CITY_CODE: "DEFLF",
      CITY_NAME: "FLENSBURG",
      DPA_PORT_CODE: "FLF",
    },
    {
      CITY_CODE: "DEFRA",
      CITY_NAME: "FRANKFURT/MAIN",
      DPA_PORT_CODE: "FRA",
    },
    {
      CITY_CODE: "DEGEL",
      CITY_NAME: "GELTING",
      DPA_PORT_CODE: "GEL",
    },
    {
      CITY_CODE: "DEGER",
      CITY_NAME: "GERMERSHEIM",
      DPA_PORT_CODE: "GER",
    },
    {
      CITY_CODE: "DEGEV",
      CITY_NAME: "GEVERSDORF",
      DPA_PORT_CODE: "GEV",
    },
    {
      CITY_CODE: "DEGHM",
      CITY_NAME: "GERNSHEIM",
      DPA_PORT_CODE: "GHM",
    },
    {
      CITY_CODE: "DEGIG",
      CITY_NAME: "GINSHEIMGUSTAVSBURG",
      DPA_PORT_CODE: "GIG",
    },
    {
      CITY_CODE: "DEGLU",
      CITY_NAME: "GLUECKSTADT",
      DPA_PORT_CODE: "GLU",
    },
    {
      CITY_CODE: "DEGMA",
      CITY_NAME: "GRIMMA",
      DPA_PORT_CODE: "GHG",
    },
    {
      CITY_CODE: "DEGRD",
      CITY_NAME: "GREIFSWALD",
      DPA_PORT_CODE: "GRD",
    },
    {
      CITY_CODE: "DEGRE",
      CITY_NAME: "GREETSIEL",
      DPA_PORT_CODE: "GRE",
    },
    {
      CITY_CODE: "DEGRH",
      CITY_NAME: "GREFRATH",
      DPA_PORT_CODE: "GRH",
    },
    {
      CITY_CODE: "DEGRO",
      CITY_NAME: "GROSSENBRODE",
      DPA_PORT_CODE: "GRO",
    },
    {
      CITY_CODE: "DEHAJ",
      CITY_NAME: "HANNOVER",
      DPA_PORT_CODE: "HAJ",
    },
    {
      CITY_CODE: "DEHAM",
      CITY_NAME: "HAMBURG",
      DPA_PORT_CODE: "HAM",
    },
    {
      CITY_CODE: "DEHAN",
      CITY_NAME: "HAMELN",
      DPA_PORT_CODE: "HEN",
    },
    {
      CITY_CODE: "DEHAS",
      CITY_NAME: "HASSFURT",
      DPA_PORT_CODE: "HAS",
    },
    {
      CITY_CODE: "DEHAU",
      CITY_NAME: "HANAU",
      DPA_PORT_CODE: "HAA",
    },
    {
      CITY_CODE: "DEHBO",
      CITY_NAME: "HOMBERG",
      DPA_PORT_CODE: "HBO",
    },
    {
      CITY_CODE: "DEHBR",
      CITY_NAME: "HERBRUM",
      DPA_PORT_CODE: "HBR",
    },
    {
      CITY_CODE: "DEHBU",
      CITY_NAME: "HAMBURGHARBURG",
      DPA_PORT_CODE: "HBU",
    },
    {
      CITY_CODE: "DEHEE",
      CITY_NAME: "HERNE",
      DPA_PORT_CODE: "HEE",
    },
    {
      CITY_CODE: "DEHGL",
      CITY_NAME: "HELGOLAND I.",
      DPA_PORT_CODE: "HGL",
    },
    {
      CITY_CODE: "DEHHF",
      CITY_NAME: "HEILIGENHAFEN",
      DPA_PORT_CODE: "HHF",
    },
    {
      CITY_CODE: "DEHHS",
      CITY_NAME: "HOHENHORN",
      DPA_PORT_CODE: "HHS",
    },
    {
      CITY_CODE: "DEHMM",
      CITY_NAME: "HAMM",
      DPA_PORT_CODE: "HMM",
    },
    {
      CITY_CODE: "DEHMN",
      CITY_NAME: "HEIMERDINGEN",
      DPA_PORT_CODE: "HMG",
    },
    {
      CITY_CODE: "DEHMO",
      CITY_NAME: "HEMMOOR",
      DPA_PORT_CODE: "HMO",
    },
    {
      CITY_CODE: "DEHOO",
      CITY_NAME: "HOOKSIEL",
      DPA_PORT_CODE: "HOO",
    },
    {
      CITY_CODE: "DEHOS",
      CITY_NAME: "HORUMERSIEL",
      DPA_PORT_CODE: "HOS",
    },
    {
      CITY_CODE: "DEHOX",
      CITY_NAME: "HOEXTER",
      DPA_PORT_CODE: "HOX",
    },
    {
      CITY_CODE: "DEHRB",
      CITY_NAME: "HORNEBURG",
      DPA_PORT_CODE: "HRB",
    },
    {
      CITY_CODE: "DEHRM",
      CITY_NAME: "HOERNUM/SYLT",
      DPA_PORT_CODE: "HRM",
    },
    {
      CITY_CODE: "DEHRN",
      CITY_NAME: "HAREN/EMS",
      DPA_PORT_CODE: "HRN",
    },
    {
      CITY_CODE: "DEHSA",
      CITY_NAME: "HOHENSAATEN",
      DPA_PORT_CODE: "HSA",
    },
    {
      CITY_CODE: "DEHUG",
      CITY_NAME: "HUNGEN",
      DPA_PORT_CODE: "UGE",
    },
    {
      CITY_CODE: "DEHZN",
      CITY_NAME: "HOLZMINDEN",
      DPA_PORT_CODE: "HZN",
    },
    {
      CITY_CODE: "DEILN",
      CITY_NAME: "ILLERTISSEN",
      DPA_PORT_CODE: "ILN",
    },
    {
      CITY_CODE: "DEING",
      CITY_NAME: "INGOLSTADT",
      DPA_PORT_CODE: "ING",
    },
    {
      CITY_CODE: "DEINM",
      CITY_NAME: "INGELHEIM",
      DPA_PORT_CODE: "INM",
    },
    {
      CITY_CODE: "DEITZ",
      CITY_NAME: "ITZEHOE",
      DPA_PORT_CODE: "ITZ",
    },
    {
      CITY_CODE: "DEJEM",
      CITY_NAME: "JEMGUM",
      DPA_PORT_CODE: "JEM",
    },
    {
      CITY_CODE: "DEJUI",
      CITY_NAME: "JUIST",
      DPA_PORT_CODE: "JUI",
    },
    {
      CITY_CODE: "DEKAE",
      CITY_NAME: "KARLSRUHE",
      DPA_PORT_CODE: "KAE",
    },
    {
      CITY_CODE: "DEKAI",
      CITY_NAME: "KAISERMUEHLE/EIFEL",
      DPA_PORT_CODE: "KAI",
    },
    {
      CITY_CODE: "DEKAP",
      CITY_NAME: "KAPPELN",
      DPA_PORT_CODE: "KAP",
    },
    {
      CITY_CODE: "DEKAR",
      CITY_NAME: "KARNIN",
      DPA_PORT_CODE: "KAR",
    },
    {
      CITY_CODE: "DEKEB",
      CITY_NAME: "KELSTERBACH",
      DPA_PORT_CODE: "KEB",
    },
    {
      CITY_CODE: "DEKEH",
      CITY_NAME: "KEHL",
      DPA_PORT_CODE: "KEH",
    },
    {
      CITY_CODE: "DEKEL",
      CITY_NAME: "KIEL",
      DPA_PORT_CODE: "KIL",
    },
    {
      CITY_CODE: "DEKIE",
      CITY_NAME: "KIETZ",
      DPA_PORT_CODE: "KIE",
    },
    {
      CITY_CODE: "DEKIT",
      CITY_NAME: "KITZINGEN",
      DPA_PORT_CODE: "KIT",
    },
    {
      CITY_CODE: "DEKOB",
      CITY_NAME: "KOBLENZ",
      DPA_PORT_CODE: "KOB",
    },
    {
      CITY_CODE: "DEKOW",
      CITY_NAME: "KOENIGSWINTER",
      DPA_PORT_CODE: "KOW",
    },
    {
      CITY_CODE: "DEKRD",
      CITY_NAME: "KIRCHARDT",
      DPA_PORT_CODE: "KRD",
    },
    {
      CITY_CODE: "DEKSG",
      CITY_NAME: "KISSING",
      DPA_PORT_CODE: "KSN",
    },
    {
      CITY_CODE: "DEKTT",
      CITY_NAME: "KATTERBACH",
      DPA_PORT_CODE: "KBC",
    },
    {
      CITY_CODE: "DELAB",
      CITY_NAME: "LABOE",
      DPA_PORT_CODE: "LAB",
    },
    {
      CITY_CODE: "DELAM",
      CITY_NAME: "LAMPERTHEIM",
      DPA_PORT_CODE: "LAM",
    },
    {
      CITY_CODE: "DELBC",
      CITY_NAME: "LUEBECK",
      DPA_PORT_CODE: "LBC",
    },
    {
      CITY_CODE: "DELDH",
      CITY_NAME: "LANDSHUT",
      DPA_PORT_CODE: "LDH",
    },
    {
      CITY_CODE: "DELEE",
      CITY_NAME: "LEER",
      DPA_PORT_CODE: "LEE",
    },
    {
      CITY_CODE: "DELEV",
      CITY_NAME: "LEVERKUSEN",
      DPA_PORT_CODE: "LKN",
    },
    {
      CITY_CODE: "DELEW",
      CITY_NAME: "LEMWERDER",
      DPA_PORT_CODE: "LEW",
    },
    {
      CITY_CODE: "DELGO",
      CITY_NAME: "LANGEROOG",
      DPA_PORT_CODE: "LGO",
    },
    {
      CITY_CODE: "DELIG",
      CITY_NAME: "LINGEN",
      DPA_PORT_CODE: "LIG",
    },
    {
      CITY_CODE: "DELIU",
      CITY_NAME: "LINDAU",
      DPA_PORT_CODE: "LIU",
    },
    {
      CITY_CODE: "DELNU",
      CITY_NAME: "LAUENBURG/ELBE",
      DPA_PORT_CODE: "LNU",
    },
    {
      CITY_CODE: "DELRN",
      CITY_NAME: "LINZ/RHEIN",
      DPA_PORT_CODE: "LRN",
    },
    {
      CITY_CODE: "DELUH",
      CITY_NAME: "LUDWIGSHAFEN",
      DPA_PORT_CODE: "LSN",
    },
    {
      CITY_CODE: "DELWG",
      CITY_NAME: "LUDWIGSBURG",
      DPA_PORT_CODE: "LWU",
    },
    {
      CITY_CODE: "DEMAI",
      CITY_NAME: "MAINZ",
      DPA_PORT_CODE: "AIZ",
    },
    {
      CITY_CODE: "DEMBP",
      CITY_NAME: "MERING",
      DPA_PORT_CODE: "MBP",
    },
    {
      CITY_CODE: "DEMEI",
      CITY_NAME: "MEISSEN",
      DPA_PORT_CODE: "MFN",
    },
    {
      CITY_CODE: "DEMHG",
      CITY_NAME: "MANNHEIM",
      DPA_PORT_CODE: "MHG",
    },
    {
      CITY_CODE: "DEMID",
      CITY_NAME: "MINDEN",
      DPA_PORT_CODE: "MND",
    },
    {
      CITY_CODE: "DEMLH",
      CITY_NAME: "MALSCH",
      DPA_PORT_CODE: "NFU",
    },
    {
      CITY_CODE: "DEMUH",
      CITY_NAME: "MUELHEIM/RUHR",
      DPA_PORT_CODE: "MUH",
    },
    {
      CITY_CODE: "DEMUN",
      CITY_NAME: "MUNSTER",
      DPA_PORT_CODE: "MSR",
    },
    {
      CITY_CODE: "DENED",
      CITY_NAME: "NEUWIED",
      DPA_PORT_CODE: "NED",
    },
    {
      CITY_CODE: "DENES",
      CITY_NAME: "NESSMERSIEL",
      DPA_PORT_CODE: "NES",
    },
    {
      CITY_CODE: "DENEV",
      CITY_NAME: "NEUENRADE",
      DPA_PORT_CODE: "EUE",
    },
    {
      CITY_CODE: "DENHA",
      CITY_NAME: "NORDENHAM",
      DPA_PORT_CODE: "NHM",
    },
    {
      CITY_CODE: "DENOD",
      CITY_NAME: "NORDEN",
      DPA_PORT_CODE: "NOD",
    },
    {
      CITY_CODE: "DENOT",
      CITY_NAME: "NORDERSTEDT",
      DPA_PORT_CODE: "NOF",
    },
    {
      CITY_CODE: "DENRD",
      CITY_NAME: "NORDERNEY",
      DPA_PORT_CODE: "NRD",
    },
    {
      CITY_CODE: "DENSS",
      CITY_NAME: "NEUSS",
      DPA_PORT_CODE: "NSS",
    },
    {
      CITY_CODE: "DENTD",
      CITY_NAME: "NEUSTADT AN DER DONAU",
      DPA_PORT_CODE: "NDK",
    },
    {
      CITY_CODE: "DENUE",
      CITY_NAME: "NUERNBERG",
      DPA_PORT_CODE: "NUE",
    },
    {
      CITY_CODE: "DENUL",
      CITY_NAME: "NEUULM",
      DPA_PORT_CODE: "NUL",
    },
    {
      CITY_CODE: "DENUQ",
      CITY_NAME: "NAUEN",
      DPA_PORT_CODE: "NUQ",
    },
    {
      CITY_CODE: "DEOFF",
      CITY_NAME: "OFFENBACH",
      DPA_PORT_CODE: "OFF",
    },
    {
      CITY_CODE: "DEOSN",
      CITY_NAME: "OSNABRUECK",
      DPA_PORT_CODE: "OSN",
    },
    {
      CITY_CODE: "DEOTT",
      CITY_NAME: "OTTERNDORF",
      DPA_PORT_CODE: "OTT",
    },
    {
      CITY_CODE: "DEPIR",
      CITY_NAME: "PIRMASENS",
      DPA_PORT_CODE: "PRM",
    },
    {
      CITY_CODE: "DEREG",
      CITY_NAME: "REGENSBURG",
      DPA_PORT_CODE: "RGB",
    },
    {
      CITY_CODE: "DEREN",
      CITY_NAME: "RENDSBURG",
      DPA_PORT_CODE: "REN",
    },
    {
      CITY_CODE: "DERHE",
      CITY_NAME: "RHEINE",
      DPA_PORT_CODE: "RHE",
    },
    {
      CITY_CODE: "DERKN",
      CITY_NAME: "REISKIRCHEN",
      DPA_PORT_CODE: "RKN",
    },
    {
      CITY_CODE: "DERSK",
      CITY_NAME: "ROSTOCK",
      DPA_PORT_CODE: "RSK",
    },
    {
      CITY_CODE: "DERUE",
      CITY_NAME: "RUEDESHEIM",
      DPA_PORT_CODE: "RUE",
    },
    {
      CITY_CODE: "DERUM",
      CITY_NAME: "RUESSELSHEIM",
      DPA_PORT_CODE: "RUM",
    },
    {
      CITY_CODE: "DESDF",
      CITY_NAME: "SCHORNDORF",
      DPA_PORT_CODE: "DFS",
    },
    {
      CITY_CODE: "DESIZ",
      CITY_NAME: "SIMMERN",
      DPA_PORT_CODE: "SIZ",
    },
    {
      CITY_CODE: "DESPE",
      CITY_NAME: "SPEYER",
      DPA_PORT_CODE: "SPY",
    },
    {
      CITY_CODE: "DESRO",
      CITY_NAME: "SULZBACHROSENBERG",
      DPA_PORT_CODE: "SDH",
    },
    {
      CITY_CODE: "DESTB",
      CITY_NAME: "STRAUBING",
      DPA_PORT_CODE: "STB",
    },
    {
      CITY_CODE: "DESWK",
      CITY_NAME: "SCHWAIKHEIM",
      DPA_PORT_CODE: "SWZ",
    },
    {
      CITY_CODE: "DETBG",
      CITY_NAME: "TROSTBERG",
      DPA_PORT_CODE: "TBX",
    },
    {
      CITY_CODE: "DETOE",
      CITY_NAME: "TOENNING",
      DPA_PORT_CODE: "TOE",
    },
    {
      CITY_CODE: "DEUEB",
      CITY_NAME: "UEBERLINGEN",
      DPA_PORT_CODE: "UEB",
    },
    {
      CITY_CODE: "DEUPS",
      CITY_NAME: "UPSCHOERT",
      DPA_PORT_CODE: "UPS",
    },
    {
      CITY_CODE: "DEVOE",
      CITY_NAME: "VOELKLINGEN",
      DPA_PORT_CODE: "VOE",
    },
    {
      CITY_CODE: "DEWAC",
      CITY_NAME: "WESTERACCUMERSIEL",
      DPA_PORT_CODE: "WAC",
    },
    {
      CITY_CODE: "DEWDA",
      CITY_NAME: "WERDAU",
      DPA_PORT_CODE: "WDA",
    },
    {
      CITY_CODE: "DEWEE",
      CITY_NAME: "WEENER",
      DPA_PORT_CODE: "WEE",
    },
    {
      CITY_CODE: "DEWIB",
      CITY_NAME: "WIESBADEN",
      DPA_PORT_CODE: "WIB",
    },
    {
      CITY_CODE: "DEWIF",
      CITY_NAME: "WISCHHAFEN",
      DPA_PORT_CODE: "WIF",
    },
    {
      CITY_CODE: "DEWIT",
      CITY_NAME: "WITTLAGE",
      DPA_PORT_CODE: "WIT",
    },
    {
      CITY_CODE: "DEWOB",
      CITY_NAME: "WOLFSBURG",
      DPA_PORT_CODE: "WOB",
    },
    {
      CITY_CODE: "DEWOE",
      CITY_NAME: "WOERTH",
      DPA_PORT_CODE: "WOE",
    },
    {
      CITY_CODE: "DEWOR",
      CITY_NAME: "WORMS",
      DPA_PORT_CODE: "WOR",
    },
    {
      CITY_CODE: "DEWPR",
      CITY_NAME: "WIPPERFURTH",
      DPA_PORT_CODE: "WPR",
    },
    {
      CITY_CODE: "DEWUE",
      CITY_NAME: "WUERZBURG",
      DPA_PORT_CODE: "WUE",
    },
    {
      CITY_CODE: "DEWUK",
      CITY_NAME: "WUSTERMARG",
      DPA_PORT_CODE: "WUK",
    },
    {
      CITY_CODE: "DEWVA",
      CITY_NAME: "WILHELMSHAVEN",
      DPA_PORT_CODE: "WVA",
    },
    {
      CITY_CODE: "DEWVH",
      CITY_NAME: "WILHELMSHAVEN",
      DPA_PORT_CODE: "WVH",
    },
    {
      CITY_CODE: "DEWVN",
      CITY_NAME: "WILHELMSHAVEN",
      DPA_PORT_CODE: "WVC",
    },
    {
      CITY_CODE: "DEWWS",
      CITY_NAME: "WEISSWASSER",
      DPA_PORT_CODE: "WWS",
    },
    {
      CITY_CODE: "DEWYK",
      CITY_NAME: "WYK AUF FOEHR",
      DPA_PORT_CODE: "WYK",
    },
    {
      CITY_CODE: "DEXFW",
      CITY_NAME: "FINKENWERDER",
      DPA_PORT_CODE: "XFW",
    },
    {
      CITY_CODE: "DJCLA",
      CITY_NAME: "CAMP LEMONIER",
      DPA_PORT_CODE: "DJC",
    },
    {
      CITY_CODE: "DJJIB",
      CITY_NAME: "DJIBOUTI",
      DPA_PORT_CODE: "JIB",
    },
    {
      CITY_CODE: "DKAAB",
      CITY_NAME: "AABENRAA",
      DPA_PORT_CODE: "AAB",
    },
    {
      CITY_CODE: "DKAAL",
      CITY_NAME: "AALBORG",
      DPA_PORT_CODE: "AAL",
    },
    {
      CITY_CODE: "DKAAR",
      CITY_NAME: "AARHUS",
      DPA_PORT_CODE: "AAR",
    },
    {
      CITY_CODE: "DKASS",
      CITY_NAME: "ASNAS",
      DPA_PORT_CODE: "AXS",
    },
    {
      CITY_CODE: "DKBDX",
      CITY_NAME: "BANDHOLM",
      DPA_PORT_CODE: "BDX",
    },
    {
      CITY_CODE: "DKCPH",
      CITY_NAME: "KOBENHAVN",
      DPA_PORT_CODE: "CPH",
    },
    {
      CITY_CODE: "DKEBJ",
      CITY_NAME: "ESBJERG",
      DPA_PORT_CODE: "EBJ",
    },
    {
      CITY_CODE: "DKFAA",
      CITY_NAME: "FAABORG",
      DPA_PORT_CODE: "FAA",
    },
    {
      CITY_CODE: "DKFDS",
      CITY_NAME: "FREDERIKSUND",
      DPA_PORT_CODE: "FDS",
    },
    {
      CITY_CODE: "DKFDV",
      CITY_NAME: "FREDERIKSVARK",
      DPA_PORT_CODE: "FDV",
    },
    {
      CITY_CODE: "DKFRC",
      CITY_NAME: "FREDERICIA",
      DPA_PORT_CODE: "FRC",
    },
    {
      CITY_CODE: "DKFYH",
      CITY_NAME: "FYNSHAVN",
      DPA_PORT_CODE: "FYH",
    },
    {
      CITY_CODE: "DKHED",
      CITY_NAME: "HEDENSTED",
      DPA_PORT_CODE: "HEW",
    },
    {
      CITY_CODE: "DKHSB",
      CITY_NAME: "HOLSTEBRO",
      DPA_PORT_CODE: "HSB",
    },
    {
      CITY_CODE: "DKLGR",
      CITY_NAME: "LOGSTOR",
      DPA_PORT_CODE: "LGR",
    },
    {
      CITY_CODE: "DKMID",
      CITY_NAME: "MIDDELFART",
      DPA_PORT_CODE: "MFX",
    },
    {
      CITY_CODE: "DKNAK",
      CITY_NAME: "NAKSKOV",
      DPA_PORT_CODE: "NAK",
    },
    {
      CITY_CODE: "DKNBG",
      CITY_NAME: "NYBORG",
      DPA_PORT_CODE: "NBG",
    },
    {
      CITY_CODE: "DKNEX",
      CITY_NAME: "NEKSO",
      DPA_PORT_CODE: "NEX",
    },
    {
      CITY_CODE: "DKNSD",
      CITY_NAME: "NORRE SNEDE",
      DPA_PORT_CODE: "NSD",
    },
    {
      CITY_CODE: "DKNYF",
      CITY_NAME: "NYKOBING FALSTER",
      DPA_PORT_CODE: "NYF",
    },
    {
      CITY_CODE: "DKNYM",
      CITY_NAME: "NYKOBING MORS",
      DPA_PORT_CODE: "NYM",
    },
    {
      CITY_CODE: "DKODE",
      CITY_NAME: "ODENSE",
      DPA_PORT_CODE: "ODE",
    },
    {
      CITY_CODE: "DKRAN",
      CITY_NAME: "RANDERS",
      DPA_PORT_CODE: "RND",
    },
    {
      CITY_CODE: "DKRKB",
      CITY_NAME: "RUDKOBING",
      DPA_PORT_CODE: "RKB",
    },
    {
      CITY_CODE: "DKRNN",
      CITY_NAME: "RONNE",
      DPA_PORT_CODE: "RNN",
    },
    {
      CITY_CODE: "DKSLB",
      CITY_NAME: "SILKEBORG",
      DPA_PORT_CODE: "SLB",
    },
    {
      CITY_CODE: "DKSVN",
      CITY_NAME: "SVENSTRUP",
      DPA_PORT_CODE: "SVS",
    },
    {
      CITY_CODE: "DKVDP",
      CITY_NAME: "VAMDRUP",
      DPA_PORT_CODE: "VDP",
    },
    {
      CITY_CODE: "DKVEJ",
      CITY_NAME: "VEJLE",
      DPA_PORT_CODE: "VEJ",
    },
    {
      CITY_CODE: "DKVNR",
      CITY_NAME: "VINDERUP",
      DPA_PORT_CODE: "VNR",
    },
    {
      CITY_CODE: "DMPOR",
      CITY_NAME: "PORTSMOUTH",
      DPA_PORT_CODE: "PTS",
    },
    {
      CITY_CODE: "DMRSU",
      CITY_NAME: "ROSEAU",
      DPA_PORT_CODE: "RSU",
    },
    {
      CITY_CODE: "DOCAU",
      CITY_NAME: "CAUCEDO",
      DPA_PORT_CODE: "CAO",
    },
    {
      CITY_CODE: "DOHAI",
      CITY_NAME: "RIO HAINA",
      DPA_PORT_CODE: "HAI",
    },
    {
      CITY_CODE: "DOPOP",
      CITY_NAME: "PUERTO PLATA",
      DPA_PORT_CODE: "POP",
    },
    {
      CITY_CODE: "DOPUO",
      CITY_NAME: "PUERTO LIBERTADOR",
      DPA_PORT_CODE: "PUO",
    },
    {
      CITY_CODE: "DZAAE",
      CITY_NAME: "ANNABA",
      DPA_PORT_CODE: "AAE",
    },
    {
      CITY_CODE: "DZALG",
      CITY_NAME: "ALGER",
      DPA_PORT_CODE: "ALG",
    },
    {
      CITY_CODE: "DZAZW",
      CITY_NAME: "ARZEW",
      DPA_PORT_CODE: "AZW",
    },
    {
      CITY_CODE: "DZBJA",
      CITY_NAME: "BEJAIA",
      DPA_PORT_CODE: "BJA",
    },
    {
      CITY_CODE: "DZDJE",
      CITY_NAME: "DJEN-DJEN",
      DPA_PORT_CODE: "DJL",
    },
    {
      CITY_CODE: "DZDJI",
      CITY_NAME: "DJIDJELLI",
      DPA_PORT_CODE: "DJI",
    },
    {
      CITY_CODE: "DZGHA",
      CITY_NAME: "GHAZAOUET",
      DPA_PORT_CODE: "GET",
    },
    {
      CITY_CODE: "DZGHZ",
      CITY_NAME: "GHAZAOUET",
      DPA_PORT_CODE: "GHJ",
    },
    {
      CITY_CODE: "DZMOS",
      CITY_NAME: "MOSTAGANEM",
      DPA_PORT_CODE: "MOS",
    },
    {
      CITY_CODE: "DZORN",
      CITY_NAME: "ORAN",
      DPA_PORT_CODE: "ORN",
    },
    {
      CITY_CODE: "DZROU",
      CITY_NAME: "ROUIBA",
      DPA_PORT_CODE: "OUB",
    },
    {
      CITY_CODE: "DZSKI",
      CITY_NAME: "SKIKDA",
      DPA_PORT_CODE: "SKI",
    },
    {
      CITY_CODE: "ECAYO",
      CITY_NAME: "PUERTO AYORA",
      DPA_PORT_CODE: "AYO",
    },
    {
      CITY_CODE: "ECESM",
      CITY_NAME: "ESMERALDAS",
      DPA_PORT_CODE: "EXY",
    },
    {
      CITY_CODE: "ECGYE",
      CITY_NAME: "GUAYAQUIL",
      DPA_PORT_CODE: "GYE",
    },
    {
      CITY_CODE: "EEMUG",
      CITY_NAME: "MUUGA",
      DPA_PORT_CODE: "MQG",
    },
    {
      CITY_CODE: "EEPLA",
      CITY_NAME: "PALDISKI",
      DPA_PORT_CODE: "PDK",
    },
    {
      CITY_CODE: "EETLL",
      CITY_NAME: "TALLINN",
      DPA_PORT_CODE: "TLL",
    },
    {
      CITY_CODE: "EGADA",
      CITY_NAME: "ADABIYA",
      DPA_PORT_CODE: "ADA",
    },
    {
      CITY_CODE: "EGAIS",
      CITY_NAME: "AIN SUKHNA",
      DPA_PORT_CODE: "AIS",
    },
    {
      CITY_CODE: "EGALY",
      CITY_NAME: "ALEXANDRIA",
      DPA_PORT_CODE: "ALY",
    },
    {
      CITY_CODE: "EGAQU",
      CITY_NAME: "AL QUSAYR",
      DPA_PORT_CODE: "AQU",
    },
    {
      CITY_CODE: "EGASW",
      CITY_NAME: "ASWAN",
      DPA_PORT_CODE: "ASW",
    },
    {
      CITY_CODE: "EGDAM",
      CITY_NAME: "DAMIETTA",
      DPA_PORT_CODE: "DAM",
    },
    {
      CITY_CODE: "EGEDK",
      CITY_NAME: "EL-DEKHELIA",
      DPA_PORT_CODE: "EDK",
    },
    {
      CITY_CODE: "EGHRG",
      CITY_NAME: "HURGHADA",
      DPA_PORT_CODE: "HRG",
    },
    {
      CITY_CODE: "EGPSD",
      CITY_NAME: "PORT SAID",
      DPA_PORT_CODE: "PSD",
    },
    {
      CITY_CODE: "EGPSE",
      CITY_NAME: "PORT SAID EAST",
      DPA_PORT_CODE: "PTX",
    },
    {
      CITY_CODE: "EGSGA",
      CITY_NAME: "SAFAGA",
      DPA_PORT_CODE: "SGA",
    },
    {
      CITY_CODE: "EGSKT",
      CITY_NAME: "SIDI KERIR TERMINAL",
      DPA_PORT_CODE: "SKL",
    },
    {
      CITY_CODE: "EGSOK",
      CITY_NAME: "SOKHNA PORT",
      DPA_PORT_CODE: "SOK",
    },
    {
      CITY_CODE: "EGSUZ",
      CITY_NAME: "SUEZ",
      DPA_PORT_CODE: "SUZ",
    },
    {
      CITY_CODE: "ERASA",
      CITY_NAME: "ASSAB",
      DPA_PORT_CODE: "ASA",
    },
    {
      CITY_CODE: "ERMSW",
      CITY_NAME: "MASSAWA",
      DPA_PORT_CODE: "MSW",
    },
    {
      CITY_CODE: "ESACE",
      CITY_NAME: "ARRECIFE DE LANZAROTE",
      DPA_PORT_CODE: "ACE",
    },
    {
      CITY_CODE: "ESACO",
      CITY_NAME: "AGONCILLO",
      DPA_PORT_CODE: "ACI",
    },
    {
      CITY_CODE: "ESADF",
      CITY_NAME: "AGUILAR DE LA FRONTERA",
      DPA_PORT_CODE: "ACF",
    },
    {
      CITY_CODE: "ESAFU",
      CITY_NAME: "ALFAHUIR",
      DPA_PORT_CODE: "AFU",
    },
    {
      CITY_CODE: "ESAGP",
      CITY_NAME: "MALAGA",
      DPA_PORT_CODE: "AGP",
    },
    {
      CITY_CODE: "ESAGU",
      CITY_NAME: "AGUILAS",
      DPA_PORT_CODE: "AGU",
    },
    {
      CITY_CODE: "ESAGZ",
      CITY_NAME: "ALGUAZAS",
      DPA_PORT_CODE: "AGZ",
    },
    {
      CITY_CODE: "ESAIJ",
      CITY_NAME: "ALGEMESI",
      DPA_PORT_CODE: "AFI",
    },
    {
      CITY_CODE: "ESAJS",
      CITY_NAME: "AZUQUECA DE HENARES",
      DPA_PORT_CODE: "AJS",
    },
    {
      CITY_CODE: "ESALC",
      CITY_NAME: "ALICANTE",
      DPA_PORT_CODE: "ALC",
    },
    {
      CITY_CODE: "ESALG",
      CITY_NAME: "ALGECIRAS",
      DPA_PORT_CODE: "ALR",
    },
    {
      CITY_CODE: "ESALJ",
      CITY_NAME: "ALJUCEN",
      DPA_PORT_CODE: "ALJ",
    },
    {
      CITY_CODE: "ESALS",
      CITY_NAME: "ALDEASECA",
      DPA_PORT_CODE: "ECA",
    },
    {
      CITY_CODE: "ESARG",
      CITY_NAME: "ARGANDA DEL REY",
      DPA_PORT_CODE: "AFH",
    },
    {
      CITY_CODE: "ESAVS",
      CITY_NAME: "AVILES",
      DPA_PORT_CODE: "AVS",
    },
    {
      CITY_CODE: "ESBCN",
      CITY_NAME: "BARCELONA",
      DPA_PORT_CODE: "BCN",
    },
    {
      CITY_CODE: "ESBIO",
      CITY_NAME: "BILBAO",
      DPA_PORT_CODE: "BIO",
    },
    {
      CITY_CODE: "ESCAD",
      CITY_NAME: "CADIZ",
      DPA_PORT_CODE: "CAD",
    },
    {
      CITY_CODE: "ESCAR",
      CITY_NAME: "CARTAGENA",
      DPA_PORT_CODE: "CAR",
    },
    {
      CITY_CODE: "ESCAS",
      CITY_NAME: "CASTELLON DE LA PLANA",
      DPA_PORT_CODE: "CAL",
    },
    {
      CITY_CODE: "ESCEU",
      CITY_NAME: "CEUTA",
      DPA_PORT_CODE: "CEU",
    },
    {
      CITY_CODE: "ESCXQ",
      CITY_NAME: "COSLADA",
      DPA_PORT_CODE: "CXQ",
    },
    {
      CITY_CODE: "ESDHM",
      CITY_NAME: "DOS HERMANAS",
      DPA_PORT_CODE: "DHM",
    },
    {
      CITY_CODE: "ESECE",
      CITY_NAME: "ELCHE",
      DPA_PORT_CODE: "ECE",
    },
    {
      CITY_CODE: "ESFAM",
      CITY_NAME: "FUENTE ALAMO DE MURCIA",
      DPA_PORT_CODE: "FAP",
    },
    {
      CITY_CODE: "ESFUE",
      CITY_NAME: "FUERTEVENTURA",
      DPA_PORT_CODE: "FUE",
    },
    {
      CITY_CODE: "ESGIJ",
      CITY_NAME: "GIJON",
      DPA_PORT_CODE: "GIJ",
    },
    {
      CITY_CODE: "ESGJV",
      CITY_NAME: "GAVA",
      DPA_PORT_CODE: "GJV",
    },
    {
      CITY_CODE: "ESGUZ",
      CITY_NAME: "GUILLENA",
      DPA_PORT_CODE: "GUZ",
    },
    {
      CITY_CODE: "ESHUV",
      CITY_NAME: "HUELVA",
      DPA_PORT_CODE: "HUV",
    },
    {
      CITY_CODE: "ESIBZ",
      CITY_NAME: "IBIZA",
      DPA_PORT_CODE: "IBZ",
    },
    {
      CITY_CODE: "ESJRZ",
      CITY_NAME: "JEREZ DE LA FRONTERA",
      DPA_PORT_CODE: "JRZ",
    },
    {
      CITY_CODE: "ESLCG",
      CITY_NAME: "LA CORUNA",
      DPA_PORT_CODE: "LCG",
    },
    {
      CITY_CODE: "ESLEI",
      CITY_NAME: "ALMERIA",
      DPA_PORT_CODE: "LEA",
    },
    {
      CITY_CODE: "ESLPA",
      CITY_NAME: "LAS PALMAS, GRAN CANARIA",
      DPA_PORT_CODE: "LPA",
    },
    {
      CITY_CODE: "ESMAD",
      CITY_NAME: "MADRID",
      DPA_PORT_CODE: "MAD",
    },
    {
      CITY_CODE: "ESMDC",
      CITY_NAME: "MOTA DEL CUERVO",
      DPA_PORT_CODE: "MOC",
    },
    {
      CITY_CODE: "ESMDR",
      CITY_NAME: "MEDINA DE RIOSECO",
      DPA_PORT_CODE: "MDR",
    },
    {
      CITY_CODE: "ESMLN",
      CITY_NAME: "MELILLA",
      DPA_PORT_CODE: "MLN",
    },
    {
      CITY_CODE: "ESMPG",
      CITY_NAME: "MARIN PONTEVEDRA",
      DPA_PORT_CODE: "MPG",
    },
    {
      CITY_CODE: "ESMQE",
      CITY_NAME: "MARBELLA",
      DPA_PORT_CODE: "MQE",
    },
    {
      CITY_CODE: "ESMRG",
      CITY_NAME: "SANTA MARGARIDA DE MONTBUI",
      DPA_PORT_CODE: "RGR",
    },
    {
      CITY_CODE: "ESMTV",
      CITY_NAME: "MONTAVERNER",
      DPA_PORT_CODE: "MTG",
    },
    {
      CITY_CODE: "ESNFO",
      CITY_NAME: "NAVALMORAL DE LA MATA",
      DPA_PORT_CODE: "NFO",
    },
    {
      CITY_CODE: "ESNUL",
      CITY_NAME: "NULES",
      DPA_PORT_CODE: "NCJ",
    },
    {
      CITY_CODE: "ESONA",
      CITY_NAME: "ONDA",
      DPA_PORT_CODE: "OAV",
    },
    {
      CITY_CODE: "ESOSU",
      CITY_NAME: "OSUNA",
      DPA_PORT_CODE: "UNA",
    },
    {
      CITY_CODE: "ESPGQ",
      CITY_NAME: "PALAU DE PLEGAMANS",
      DPA_PORT_CODE: "PGQ",
    },
    {
      CITY_CODE: "ESPMI",
      CITY_NAME: "PALMA MALLORCA",
      DPA_PORT_CODE: "PMI",
    },
    {
      CITY_CODE: "ESPMN",
      CITY_NAME: "PALMONES",
      DPA_PORT_CODE: "PMK",
    },
    {
      CITY_CODE: "ESPSC",
      CITY_NAME: "PLASENCIA",
      DPA_PORT_CODE: "PSC",
    },
    {
      CITY_CODE: "ESSCB",
      CITY_NAME: "SANTA CRUZ DE BEZAN",
      DPA_PORT_CODE: "SCB",
    },
    {
      CITY_CODE: "ESSCT",
      CITY_NAME: "SANTA CRUZ DE TENERIFE",
      DPA_PORT_CODE: "SCD",
    },
    {
      CITY_CODE: "ESSDR",
      CITY_NAME: "SANTANDER",
      DPA_PORT_CODE: "SDR",
    },
    {
      CITY_CODE: "ESSFG",
      CITY_NAME: "SAN FULGENCIO",
      DPA_PORT_CODE: "TDL",
    },
    {
      CITY_CODE: "ESSMP",
      CITY_NAME: "SANTA MAGDALENA DE PULPIS",
      DPA_PORT_CODE: "NDE",
    },
    {
      CITY_CODE: "ESSNR",
      CITY_NAME: "SANTURCE",
      DPA_PORT_CODE: "SNR",
    },
    {
      CITY_CODE: "ESSPC",
      CITY_NAME: "SANTA CRUZ DE LA PALMA",
      DPA_PORT_CODE: "SPC",
    },
    {
      CITY_CODE: "ESSVQ",
      CITY_NAME: "SEVILLA",
      DPA_PORT_CODE: "SVQ",
    },
    {
      CITY_CODE: "ESTAR",
      CITY_NAME: "TARRAGONA",
      DPA_PORT_CODE: "TAR",
    },
    {
      CITY_CODE: "ESTGY",
      CITY_NAME: "TARREGA",
      DPA_PORT_CODE: "TGY",
    },
    {
      CITY_CODE: "ESTIO",
      CITY_NAME: "TORELLO",
      DPA_PORT_CODE: "TIO",
    },
    {
      CITY_CODE: "ESUSU",
      CITY_NAME: "USURBIL",
      DPA_PORT_CODE: "USU",
    },
    {
      CITY_CODE: "ESVDA",
      CITY_NAME: "VALENCIA DE ALCANTARA",
      DPA_PORT_CODE: "VDA",
    },
    {
      CITY_CODE: "ESVDU",
      CITY_NAME: "VALL DE UXO",
      DPA_PORT_CODE: "VDU",
    },
    {
      CITY_CODE: "ESVGO",
      CITY_NAME: "VIGO",
      DPA_PORT_CODE: "VGO",
    },
    {
      CITY_CODE: "ESVIL",
      CITY_NAME: "VILLAGARCIA",
      DPA_PORT_CODE: "VIL",
    },
    {
      CITY_CODE: "ESVLC",
      CITY_NAME: "VALENCIA",
      DPA_PORT_CODE: "VLC",
    },
    {
      CITY_CODE: "ESVSV",
      CITY_NAME: "VILLA DE SALVARES",
      DPA_PORT_CODE: "VSV",
    },
    {
      CITY_CODE: "ESVVC",
      CITY_NAME: "VILLANUEVA DE CASTELLON",
      DPA_PORT_CODE: "VVC",
    },
    {
      CITY_CODE: "ESZAZ",
      CITY_NAME: "ZARAGOZA",
      DPA_PORT_CODE: "ZAZ",
    },
    {
      CITY_CODE: "ESZJQ",
      CITY_NAME: "PULPI",
      DPA_PORT_CODE: "ZJQ",
    },
    {
      CITY_CODE: "ESZLP",
      CITY_NAME: "SANTAELLA",
      DPA_PORT_CODE: "ZLP",
    },
    {
      CITY_CODE: "ETDIR",
      CITY_NAME: "DIRE DAWA",
      DPA_PORT_CODE: "DIR",
    },
    {
      CITY_CODE: "FIAHV",
      CITY_NAME: "AHVENANMAA MUUT",
      DPA_PORT_CODE: "AHV",
    },
    {
      CITY_CODE: "FIAJO",
      CITY_NAME: "AJOS",
      DPA_PORT_CODE: "AJO",
    },
    {
      CITY_CODE: "FIDLS",
      CITY_NAME: "TAALINTEHDAS",
      DPA_PORT_CODE: "DLS",
    },
    {
      CITY_CODE: "FIEJO",
      CITY_NAME: "EURAJOKI",
      DPA_PORT_CODE: "EJO",
    },
    {
      CITY_CODE: "FIENK",
      CITY_NAME: "ENONKOSKI",
      DPA_PORT_CODE: "ENK",
    },
    {
      CITY_CODE: "FIFOG",
      CITY_NAME: "FOGLO",
      DPA_PORT_CODE: "FOG",
    },
    {
      CITY_CODE: "FIGDB",
      CITY_NAME: "GODBY",
      DPA_PORT_CODE: "GDB",
    },
    {
      CITY_CODE: "FIGUL",
      CITY_NAME: "GULLO",
      DPA_PORT_CODE: "GUL",
    },
    {
      CITY_CODE: "FIHEL",
      CITY_NAME: "HELSINKI",
      DPA_PORT_CODE: "HEL",
    },
    {
      CITY_CODE: "FIHIT",
      CITY_NAME: "HIITINEN",
      DPA_PORT_CODE: "HIT",
    },
    {
      CITY_CODE: "FIHKO",
      CITY_NAME: "HANKO",
      DPA_PORT_CODE: "HKO",
    },
    {
      CITY_CODE: "FIHLL",
      CITY_NAME: "HELLNAS",
      DPA_PORT_CODE: "HLL",
    },
    {
      CITY_CODE: "FIHMN",
      CITY_NAME: "HAMINA",
      DPA_PORT_CODE: "HNA",
    },
    {
      CITY_CODE: "FIHNU",
      CITY_NAME: "HANKINUDAS",
      DPA_PORT_CODE: "HNU",
    },
    {
      CITY_CODE: "FIHOG",
      CITY_NAME: "HOGSARA JUNGFRUSUND",
      DPA_PORT_CODE: "HOG",
    },
    {
      CITY_CODE: "FIHPS",
      CITY_NAME: "MARTINIEMI",
      DPA_PORT_CODE: "HPS",
    },
    {
      CITY_CODE: "FIIMA",
      CITY_NAME: "IMATRA",
      DPA_PORT_CODE: "IMA",
    },
    {
      CITY_CODE: "FIINK",
      CITY_NAME: "INKOO",
      DPA_PORT_CODE: "INK",
    },
    {
      CITY_CODE: "FIJOU",
      CITY_NAME: "JOUTSENO",
      DPA_PORT_CODE: "JOU",
    },
    {
      CITY_CODE: "FIJUS",
      CITY_NAME: "JUSSARO",
      DPA_PORT_CODE: "JUS",
    },
    {
      CITY_CODE: "FIJYV",
      CITY_NAME: "JYVASKYLA",
      DPA_PORT_CODE: "JYV",
    },
    {
      CITY_CODE: "FIKAO",
      CITY_NAME: "KUUSAMO",
      DPA_PORT_CODE: "KAO",
    },
    {
      CITY_CODE: "FIKAS",
      CITY_NAME: "KASKINEN",
      DPA_PORT_CODE: "KAS",
    },
    {
      CITY_CODE: "FIKAU",
      CITY_NAME: "KAUKOPAA",
      DPA_PORT_CODE: "KAU",
    },
    {
      CITY_CODE: "FIKEM",
      CITY_NAME: "KEMI",
      DPA_PORT_CODE: "KMI",
    },
    {
      CITY_CODE: "FIKJO",
      CITY_NAME: "KALAJOKI",
      DPA_PORT_CODE: "KJO",
    },
    {
      CITY_CODE: "FIKKR",
      CITY_NAME: "KOKAR",
      DPA_PORT_CODE: "KKR",
    },
    {
      CITY_CODE: "FIKNT",
      CITY_NAME: "KANTVIK",
      DPA_PORT_CODE: "KNT",
    },
    {
      CITY_CODE: "FIKOK",
      CITY_NAME: "KOKKOLA",
      DPA_PORT_CODE: "KKL",
    },
    {
      CITY_CODE: "FIKRA",
      CITY_NAME: "KRAKSKARSSUND",
      DPA_PORT_CODE: "KRA",
    },
    {
      CITY_CODE: "FIKTK",
      CITY_NAME: "KOTKA",
      DPA_PORT_CODE: "KTK",
    },
    {
      CITY_CODE: "FIKTQ",
      CITY_NAME: "KITEE",
      DPA_PORT_CODE: "KTQ",
    },
    {
      CITY_CODE: "FIKTT",
      CITY_NAME: "KITTILA",
      DPA_PORT_CODE: "KTT",
    },
    {
      CITY_CODE: "FIKUO",
      CITY_NAME: "KUOPIO",
      DPA_PORT_CODE: "KUO",
    },
    {
      CITY_CODE: "FILAK",
      CITY_NAME: "LAKULAHTI",
      DPA_PORT_CODE: "LAK",
    },
    {
      CITY_CODE: "FILAP",
      CITY_NAME: "LAPPOHJA",
      DPA_PORT_CODE: "LAP",
    },
    {
      CITY_CODE: "FILLO",
      CITY_NAME: "LAPALUOTO",
      DPA_PORT_CODE: "LLO",
    },
    {
      CITY_CODE: "FILPP",
      CITY_NAME: "LAPPEENRANTA",
      DPA_PORT_CODE: "LPP",
    },
    {
      CITY_CODE: "FILUV",
      CITY_NAME: "LUVIA",
      DPA_PORT_CODE: "LUV",
    },
    {
      CITY_CODE: "FIMHQ",
      CITY_NAME: "MARIEHAMN",
      DPA_PORT_CODE: "MHQ",
    },
    {
      CITY_CODE: "FIMKI",
      CITY_NAME: "MARINKI",
      DPA_PORT_CODE: "MKI",
    },
    {
      CITY_CODE: "FIMTL",
      CITY_NAME: "MANTYLUOTO",
      DPA_PORT_CODE: "MTL",
    },
    {
      CITY_CODE: "FINAU",
      CITY_NAME: "NAUVO",
      DPA_PORT_CODE: "NAU",
    },
    {
      CITY_CODE: "FINKL",
      CITY_NAME: "NY KARLEBY",
      DPA_PORT_CODE: "NKL",
    },
    {
      CITY_CODE: "FINLI",
      CITY_NAME: "NAANTALI",
      DPA_PORT_CODE: "NLI",
    },
    {
      CITY_CODE: "FINUR",
      CITY_NAME: "NURMES",
      DPA_PORT_CODE: "NUR",
    },
    {
      CITY_CODE: "FIOLM",
      CITY_NAME: "OLMOSVIK",
      DPA_PORT_CODE: "OLM",
    },
    {
      CITY_CODE: "FIOLU",
      CITY_NAME: "OULU",
      DPA_PORT_CODE: "OUU",
    },
    {
      CITY_CODE: "FIOUL",
      CITY_NAME: "OULU",
      DPA_PORT_CODE: "OUL",
    },
    {
      CITY_CODE: "FIPOH",
      CITY_NAME: "POHJANKURU",
      DPA_PORT_CODE: "POH",
    },
    {
      CITY_CODE: "FIPOR",
      CITY_NAME: "PORI",
      DPA_PORT_CODE: "POJ",
    },
    {
      CITY_CODE: "FIPUU",
      CITY_NAME: "PUUMALA",
      DPA_PORT_CODE: "PUU",
    },
    {
      CITY_CODE: "FIRAA",
      CITY_NAME: "RAAHE",
      DPA_PORT_CODE: "RAA",
    },
    {
      CITY_CODE: "FIRAF",
      CITY_NAME: "REPOSAARI",
      DPA_PORT_CODE: "RAF",
    },
    {
      CITY_CODE: "FIRAU",
      CITY_NAME: "RAUMA",
      DPA_PORT_CODE: "RAU",
    },
    {
      CITY_CODE: "FIRII",
      CITY_NAME: "RIISTIINA",
      DPA_PORT_CODE: "RII",
    },
    {
      CITY_CODE: "FIRKA",
      CITY_NAME: "ROSKA",
      DPA_PORT_CODE: "RKA",
    },
    {
      CITY_CODE: "FIROO",
      CITY_NAME: "ROOTILA",
      DPA_PORT_CODE: "ROO",
    },
    {
      CITY_CODE: "FIROY",
      CITY_NAME: "ROYTTA",
      DPA_PORT_CODE: "ROY",
    },
    {
      CITY_CODE: "FIRTR",
      CITY_NAME: "RAUTARUUKKI",
      DPA_PORT_CODE: "RTR",
    },
    {
      CITY_CODE: "FIRUO",
      CITY_NAME: "RUOTSINPYTHAA",
      DPA_PORT_CODE: "RUO",
    },
    {
      CITY_CODE: "FIRYM",
      CITY_NAME: "RYMATTYLA",
      DPA_PORT_CODE: "RYM",
    },
    {
      CITY_CODE: "FISAR",
      CITY_NAME: "SARKISALO",
      DPA_PORT_CODE: "SAR",
    },
    {
      CITY_CODE: "FISII",
      CITY_NAME: "SIILINJARVI",
      DPA_PORT_CODE: "SII",
    },
    {
      CITY_CODE: "FISKV",
      CITY_NAME: "SKOLDVIK",
      DPA_PORT_CODE: "SKV",
    },
    {
      CITY_CODE: "FISPJ",
      CITY_NAME: "SPJUTSUND",
      DPA_PORT_CODE: "SPJ",
    },
    {
      CITY_CODE: "FITEI",
      CITY_NAME: "TEIJO",
      DPA_PORT_CODE: "TEI",
    },
    {
      CITY_CODE: "FITES",
      CITY_NAME: "TESSVAR",
      DPA_PORT_CODE: "TES",
    },
    {
      CITY_CODE: "FITHK",
      CITY_NAME: "TAHKOLUOTO",
      DPA_PORT_CODE: "THK",
    },
    {
      CITY_CODE: "FITMP",
      CITY_NAME: "TAMPERE",
      DPA_PORT_CODE: "TMP",
    },
    {
      CITY_CODE: "FITOJ",
      CITY_NAME: "TOJBY",
      DPA_PORT_CODE: "TOJ",
    },
    {
      CITY_CODE: "FITOR",
      CITY_NAME: "TORNIO",
      DPA_PORT_CODE: "TON",
    },
    {
      CITY_CODE: "FITOT",
      CITY_NAME: "TOTTESUND",
      DPA_PORT_CODE: "TOT",
    },
    {
      CITY_CODE: "FITVA",
      CITY_NAME: "TVARMINNE",
      DPA_PORT_CODE: "TVA",
    },
    {
      CITY_CODE: "FIUIM",
      CITY_NAME: "UIMAHARJU",
      DPA_PORT_CODE: "UIM",
    },
    {
      CITY_CODE: "FIUKI",
      CITY_NAME: "UUSIKAUPUNKI",
      DPA_PORT_CODE: "UKI",
    },
    {
      CITY_CODE: "FIUKP",
      CITY_NAME: "UUSIKAARLEPYY",
      DPA_PORT_CODE: "UKP",
    },
    {
      CITY_CODE: "FIUSK",
      CITY_NAME: "USKELA",
      DPA_PORT_CODE: "USK",
    },
    {
      CITY_CODE: "FIVAS",
      CITY_NAME: "VASTLAX",
      DPA_PORT_CODE: "VAS",
    },
    {
      CITY_CODE: "FIVEI",
      CITY_NAME: "VEITSILUOTO",
      DPA_PORT_CODE: "VEI",
    },
    {
      CITY_CODE: "FIVES",
      CITY_NAME: "VESSO",
      DPA_PORT_CODE: "VES",
    },
    {
      CITY_CODE: "FIVIR",
      CITY_NAME: "VIRPINIEMI",
      DPA_PORT_CODE: "VIR",
    },
    {
      CITY_CODE: "FIVRK",
      CITY_NAME: "VARKAUS",
      DPA_PORT_CODE: "VRK",
    },
    {
      CITY_CODE: "FIWKO",
      CITY_NAME: "WALKOM",
      DPA_PORT_CODE: "WKO",
    },
    {
      CITY_CODE: "FIYXP",
      CITY_NAME: "YXPILA",
      DPA_PORT_CODE: "YXP",
    },
    {
      CITY_CODE: "FJLTK",
      CITY_NAME: "LAUTOKA",
      DPA_PORT_CODE: "LKA",
    },
    {
      CITY_CODE: "FJSUV",
      CITY_NAME: "SUVA",
      DPA_PORT_CODE: "SUV",
    },
    {
      CITY_CODE: "FJSVU",
      CITY_NAME: "SAVUSAVU",
      DPA_PORT_CODE: "SVU",
    },
    {
      CITY_CODE: "FJVUD",
      CITY_NAME: "VUDA",
      DPA_PORT_CODE: "VUD",
    },
    {
      CITY_CODE: "FKPSY",
      CITY_NAME: "PORT STANLEY",
      DPA_PORT_CODE: "PSY",
    },
    {
      CITY_CODE: "FMPNI",
      CITY_NAME: "POHNPEI",
      DPA_PORT_CODE: "PNI",
    },
    {
      CITY_CODE: "FMTKK",
      CITY_NAME: "CHUUK (EX TRUK)",
      DPA_PORT_CODE: "CCH",
    },
    {
      CITY_CODE: "FMYAP",
      CITY_NAME: "YAP",
      DPA_PORT_CODE: "YAP",
    },
    {
      CITY_CODE: "FOHUS",
      CITY_NAME: "HUSEVIG",
      DPA_PORT_CODE: "HUS",
    },
    {
      CITY_CODE: "FOKVI",
      CITY_NAME: "KLAKSVIK",
      DPA_PORT_CODE: "KVI",
    },
    {
      CITY_CODE: "FOLOP",
      CITY_NAME: "LOPRA",
      DPA_PORT_CODE: "LOP",
    },
    {
      CITY_CODE: "FOSMJ",
      CITY_NAME: "SOLMUNDEFJORD",
      DPA_PORT_CODE: "SMJ",
    },
    {
      CITY_CODE: "FOSRV",
      CITY_NAME: "SORVAGUR",
      DPA_PORT_CODE: "SRV",
    },
    {
      CITY_CODE: "FOTHO",
      CITY_NAME: "THORSHAVN",
      DPA_PORT_CODE: "THO",
    },
    {
      CITY_CODE: "FOVAZ",
      CITY_NAME: "VAAG",
      DPA_PORT_CODE: "VAZ",
    },
    {
      CITY_CODE: "FOVID",
      CITY_NAME: "VADAIR",
      DPA_PORT_CODE: "VID",
    },
    {
      CITY_CODE: "FRARC",
      CITY_NAME: "ARCACHON",
      DPA_PORT_CODE: "ARC",
    },
    {
      CITY_CODE: "FRARR",
      CITY_NAME: "ARRAS",
      DPA_PORT_CODE: "AQS",
    },
    {
      CITY_CODE: "FRAUD",
      CITY_NAME: "AUDIERNE PORT",
      DPA_PORT_CODE: "AUD",
    },
    {
      CITY_CODE: "FRBAI",
      CITY_NAME: "BASSEINDRE",
      DPA_PORT_CODE: "BAI",
    },
    {
      CITY_CODE: "FRBAS",
      CITY_NAME: "BASSENS",
      DPA_PORT_CODE: "BSN",
    },
    {
      CITY_CODE: "FRBCU",
      CITY_NAME: "BOUCAU",
      DPA_PORT_CODE: "BCU",
    },
    {
      CITY_CODE: "FRBEC",
      CITY_NAME: "BEC D'AMBES",
      DPA_PORT_CODE: "BEC",
    },
    {
      CITY_CODE: "FRBES",
      CITY_NAME: "BREST",
      DPA_PORT_CODE: "BES",
    },
    {
      CITY_CODE: "FRBIQ",
      CITY_NAME: "BIARRITZ",
      DPA_PORT_CODE: "BIQ",
    },
    {
      CITY_CODE: "FRBOD",
      CITY_NAME: "BORDEAUX",
      DPA_PORT_CODE: "BOD",
    },
    {
      CITY_CODE: "FRBOL",
      CITY_NAME: "BOULOGNE SUR MER",
      DPA_PORT_CODE: "BEU",
    },
    {
      CITY_CODE: "FRBYC",
      CITY_NAME: "BALEYCOURT",
      DPA_PORT_CODE: "BYC",
    },
    {
      CITY_CODE: "FRBYE",
      CITY_NAME: "BLAYE",
      DPA_PORT_CODE: "BYE",
    },
    {
      CITY_CODE: "FRCEQ",
      CITY_NAME: "CANNES",
      DPA_PORT_CODE: "CEQ",
    },
    {
      CITY_CODE: "FRCER",
      CITY_NAME: "CHERBOURG",
      DPA_PORT_CODE: "CER",
    },
    {
      CITY_CODE: "FRCFR",
      CITY_NAME: "CAEN",
      DPA_PORT_CODE: "CFR",
    },
    {
      CITY_CODE: "FRCHN",
      CITY_NAME: "CHANAZ",
      DPA_PORT_CODE: "CHN",
    },
    {
      CITY_CODE: "FRCLY",
      CITY_NAME: "CALVI",
      DPA_PORT_CODE: "CLY",
    },
    {
      CITY_CODE: "FRCOS",
      CITY_NAME: "CARROS",
      DPA_PORT_CODE: "COS",
    },
    {
      CITY_CODE: "FRDKK",
      CITY_NAME: "DUNKERQUE",
      DPA_PORT_CODE: "DKK",
    },
    {
      CITY_CODE: "FRDOL",
      CITY_NAME: "DEAUVILLE",
      DPA_PORT_CODE: "DOL",
    },
    {
      CITY_CODE: "FRESO",
      CITY_NAME: "ERAGNY",
      DPA_PORT_CODE: "ERA",
    },
    {
      CITY_CODE: "FRFEC",
      CITY_NAME: "FECAMP",
      DPA_PORT_CODE: "FEC",
    },
    {
      CITY_CODE: "FRFOS",
      CITY_NAME: "FOSSURMER",
      DPA_PORT_CODE: "FOS",
    },
    {
      CITY_CODE: "FRGEN",
      CITY_NAME: "GENAS",
      DPA_PORT_CODE: "GEN",
    },
    {
      CITY_CODE: "FRGNF",
      CITY_NAME: "GONFREVILLE",
      DPA_PORT_CODE: "GNF",
    },
    {
      CITY_CODE: "FRGOU",
      CITY_NAME: "GOURIN",
      DPA_PORT_CODE: "GOU",
    },
    {
      CITY_CODE: "FRGRC",
      CITY_NAME: "GRAND-COURONNE",
      DPA_PORT_CODE: "GRC",
    },
    {
      CITY_CODE: "FRGSV",
      CITY_NAME: "GOUSSAINVILLE",
      DPA_PORT_CODE: "GSV",
    },
    {
      CITY_CODE: "FRILR",
      CITY_NAME: "ILE ROUSSE",
      DPA_PORT_CODE: "ILR",
    },
    {
      CITY_CODE: "FRLAV",
      CITY_NAME: "LAVERA",
      DPA_PORT_CODE: "LRA",
    },
    {
      CITY_CODE: "FRLBO",
      CITY_NAME: "LE BOUCAU",
      DPA_PORT_CODE: "LBO",
    },
    {
      CITY_CODE: "FRLEH",
      CITY_NAME: "LE HAVRE",
      DPA_PORT_CODE: "LEH",
    },
    {
      CITY_CODE: "FRLEV",
      CITY_NAME: "LES PAVILLONS SOUS BOIS",
      DPA_PORT_CODE: "LES",
    },
    {
      CITY_CODE: "FRLME",
      CITY_NAME: "LA MEDE",
      DPA_PORT_CODE: "LME",
    },
    {
      CITY_CODE: "FRLPE",
      CITY_NAME: "LA PALLICE",
      DPA_PORT_CODE: "LPE",
    },
    {
      CITY_CODE: "FRLRH",
      CITY_NAME: "LA ROCHELLE",
      DPA_PORT_CODE: "LRH",
    },
    {
      CITY_CODE: "FRLRT",
      CITY_NAME: "LORIENT",
      DPA_PORT_CODE: "LRT",
    },
    {
      CITY_CODE: "FRLSO",
      CITY_NAME: "LES SABLES D'OLONNE",
      DPA_PORT_CODE: "LSO",
    },
    {
      CITY_CODE: "FRLSR",
      CITY_NAME: "LESTREM",
      DPA_PORT_CODE: "LSR",
    },
    {
      CITY_CODE: "FRLTR",
      CITY_NAME: "LE TREPORT",
      DPA_PORT_CODE: "LTR",
    },
    {
      CITY_CODE: "FRLVE",
      CITY_NAME: "LE VERDON",
      DPA_PORT_CODE: "LEV",
    },
    {
      CITY_CODE: "FRMER",
      CITY_NAME: "MERCUREY",
      DPA_PORT_CODE: "MCY",
    },
    {
      CITY_CODE: "FRMNO",
      CITY_NAME: "MONTROTTIER",
      DPA_PORT_CODE: "MCX",
    },
    {
      CITY_CODE: "FRMRS",
      CITY_NAME: "MARSEILLE",
      DPA_PORT_CODE: "MRS",
    },
    {
      CITY_CODE: "FRMSN",
      CITY_NAME: "MESNIL SAINT NICAISE",
      DPA_PORT_CODE: "MNI",
    },
    {
      CITY_CODE: "FRMTX",
      CITY_NAME: "MONTOIR DE BRETAGNE",
      DPA_PORT_CODE: "MTX",
    },
    {
      CITY_CODE: "FRMXN",
      CITY_NAME: "MORLAIX",
      DPA_PORT_CODE: "MXN",
    },
    {
      CITY_CODE: "FRNCE",
      CITY_NAME: "NICE",
      DPA_PORT_CODE: "NCE",
    },
    {
      CITY_CODE: "FRNTE",
      CITY_NAME: "NANTES",
      DPA_PORT_CODE: "NTE",
    },
    {
      CITY_CODE: "FROBE",
      CITY_NAME: "OBERNAI",
      DPA_PORT_CODE: "OBE",
    },
    {
      CITY_CODE: "FRORA",
      CITY_NAME: "ORANGE",
      DPA_PORT_CODE: "ORA",
    },
    {
      CITY_CODE: "FROTM",
      CITY_NAME: "OTTMARSHEIM",
      DPA_PORT_CODE: "OTM",
    },
    {
      CITY_CODE: "FRPAR",
      CITY_NAME: "PARIS",
      DPA_PORT_CODE: "PAR",
    },
    {
      CITY_CODE: "FRPDB",
      CITY_NAME: "PORT DE BOUC",
      DPA_PORT_CODE: "PDB",
    },
    {
      CITY_CODE: "FRPIG",
      CITY_NAME: "L'ESPIGUETTE",
      DPA_PORT_CODE: "PIG",
    },
    {
      CITY_CODE: "FRPJE",
      CITY_NAME: "PORT JEROME",
      DPA_PORT_CODE: "PJE",
    },
    {
      CITY_CODE: "FRPRP",
      CITY_NAME: "PROPRIANO",
      DPA_PORT_CODE: "PRP",
    },
    {
      CITY_CODE: "FRRCO",
      CITY_NAME: "ROCHEFORT",
      DPA_PORT_CODE: "RCO",
    },
    {
      CITY_CODE: "FRRYN",
      CITY_NAME: "ROYAN",
      DPA_PORT_CODE: "RYN",
    },
    {
      CITY_CODE: "FRSET",
      CITY_NAME: "SETE",
      DPA_PORT_CODE: "SET",
    },
    {
      CITY_CODE: "FRSJV",
      CITY_NAME: "ST JEANDEVEDAS",
      DPA_PORT_CODE: "SJV",
    },
    {
      CITY_CODE: "FRSMA",
      CITY_NAME: "ST MAUR",
      DPA_PORT_CODE: "STM",
    },
    {
      CITY_CODE: "FRSRA",
      CITY_NAME: "ST RAMBERT-D'ALBON",
      DPA_PORT_CODE: "SRB",
    },
    {
      CITY_CODE: "FRSTP",
      CITY_NAME: "SAINT TROPEZ",
      DPA_PORT_CODE: "TQZ",
    },
    {
      CITY_CODE: "FRSXB",
      CITY_NAME: "STRASBOURG",
      DPA_PORT_CODE: "SXB",
    },
    {
      CITY_CODE: "FRTJE",
      CITY_NAME: "TROUVILLE",
      DPA_PORT_CODE: "TJE",
    },
    {
      CITY_CODE: "FRTLN",
      CITY_NAME: "TOULON",
      DPA_PORT_CODE: "TLN",
    },
    {
      CITY_CODE: "FRTLS",
      CITY_NAME: "TOULOUSE",
      DPA_PORT_CODE: "TUS",
    },
    {
      CITY_CODE: "FRUIP",
      CITY_NAME: "QUIMPER",
      DPA_PORT_CODE: "UIP",
    },
    {
      CITY_CODE: "FRURO",
      CITY_NAME: "ROUEN",
      DPA_PORT_CODE: "URO",
    },
    {
      CITY_CODE: "FRVNE",
      CITY_NAME: "VANNES",
      DPA_PORT_CODE: "VNE",
    },
    {
      CITY_CODE: "FRVOV",
      CITY_NAME: "VOLVIC",
      DPA_PORT_CODE: "VOV",
    },
    {
      CITY_CODE: "GAEKU",
      CITY_NAME: "EKUATA",
      DPA_PORT_CODE: "EKU",
    },
    {
      CITY_CODE: "GAGAX",
      CITY_NAME: "GAMBA",
      DPA_PORT_CODE: "GAX",
    },
    {
      CITY_CODE: "GALBV",
      CITY_NAME: "LIBREVILLE",
      DPA_PORT_CODE: "LBV",
    },
    {
      CITY_CODE: "GALUC",
      CITY_NAME: "LUCINA",
      DPA_PORT_CODE: "LUC",
    },
    {
      CITY_CODE: "GAOWE",
      CITY_NAME: "OWENDO",
      DPA_PORT_CODE: "OWE",
    },
    {
      CITY_CODE: "GAPOG",
      CITY_NAME: "PORT GENTIL",
      DPA_PORT_CODE: "POG",
    },
    {
      CITY_CODE: "GBABD",
      CITY_NAME: "ABERDEEN",
      DPA_PORT_CODE: "ABD",
    },
    {
      CITY_CODE: "GBADZ",
      CITY_NAME: "ASHBY DE LA ZOUCH",
      DPA_PORT_CODE: "AIJ",
    },
    {
      CITY_CODE: "GBAGN",
      CITY_NAME: "ABINGDON",
      DPA_PORT_CODE: "AIG",
    },
    {
      CITY_CODE: "GBALN",
      CITY_NAME: "ALTON",
      DPA_PORT_CODE: "AQN",
    },
    {
      CITY_CODE: "GBASC",
      CITY_NAME: "ASCOT",
      DPA_PORT_CODE: "AFC",
    },
    {
      CITY_CODE: "GBASF",
      CITY_NAME: "ASHFORD",
      DPA_PORT_CODE: "ASZ",
    },
    {
      CITY_CODE: "GBASH",
      CITY_NAME: "ASHBOURNE",
      DPA_PORT_CODE: "AOH",
    },
    {
      CITY_CODE: "GBAVO",
      CITY_NAME: "AVONMOUTH",
      DPA_PORT_CODE: "AVH",
    },
    {
      CITY_CODE: "GBAWL",
      CITY_NAME: "ATTLEBOROUGH",
      DPA_PORT_CODE: "AWL",
    },
    {
      CITY_CODE: "GBAYF",
      CITY_NAME: "AYCLIFFE",
      DPA_PORT_CODE: "AYF",
    },
    {
      CITY_CODE: "GBBAD",
      CITY_NAME: "BARRY DOCK",
      DPA_PORT_CODE: "BDY",
    },
    {
      CITY_CODE: "GBBAS",
      CITY_NAME: "BASELDON (MISSPELT)",
      DPA_PORT_CODE: "BSD",
    },
    {
      CITY_CODE: "GBBEF",
      CITY_NAME: "BEDFORD",
      DPA_PORT_CODE: "BFJ",
    },
    {
      CITY_CODE: "GBBEL",
      CITY_NAME: "BELFAST",
      DPA_PORT_CODE: "BEL",
    },
    {
      CITY_CODE: "GBBEY",
      CITY_NAME: "BROMLEY",
      DPA_PORT_CODE: "BWC",
    },
    {
      CITY_CODE: "GBBGK",
      CITY_NAME: "BASINGSTOKE",
      DPA_PORT_CODE: "BGK",
    },
    {
      CITY_CODE: "GBBGS",
      CITY_NAME: "BAGSHOT",
      DPA_PORT_CODE: "BQT",
    },
    {
      CITY_CODE: "GBBHM",
      CITY_NAME: "BIRMINGHAM",
      DPA_PORT_CODE: "BRH",
    },
    {
      CITY_CODE: "GBBHW",
      CITY_NAME: "BILLINGHAM",
      DPA_PORT_CODE: "BHW",
    },
    {
      CITY_CODE: "GBBIF",
      CITY_NAME: "BARROW IN FURNESS",
      DPA_PORT_CODE: "BIF",
    },
    {
      CITY_CODE: "GBBIR",
      CITY_NAME: "BIRTLEY",
      DPA_PORT_CODE: "BIR",
    },
    {
      CITY_CODE: "GBBKN",
      CITY_NAME: "BRACKNELL",
      DPA_PORT_CODE: "BCW",
    },
    {
      CITY_CODE: "GBBLS",
      CITY_NAME: "BRIGHTLINGSEA",
      DPA_PORT_CODE: "BPS",
    },
    {
      CITY_CODE: "GBBNH",
      CITY_NAME: "BARTON ON HUMBER",
      DPA_PORT_CODE: "BNH",
    },
    {
      CITY_CODE: "GBBNW",
      CITY_NAME: "BURTONWOOD",
      DPA_PORT_CODE: "BNF",
    },
    {
      CITY_CODE: "GBBOC",
      CITY_NAME: "BURNHAM ON CROUCH",
      DPA_PORT_CODE: "BOC",
    },
    {
      CITY_CODE: "GBBOH",
      CITY_NAME: "BOURNEMOUTH",
      DPA_PORT_CODE: "BQH",
    },
    {
      CITY_CODE: "GBBRF",
      CITY_NAME: "BRADFORD",
      DPA_PORT_CODE: "BRF",
    },
    {
      CITY_CODE: "GBBRH",
      CITY_NAME: "BRIGHOUSE",
      DPA_PORT_CODE: "BFK",
    },
    {
      CITY_CODE: "GBBRK",
      CITY_NAME: "BIRKENHEAD",
      DPA_PORT_CODE: "BRK",
    },
    {
      CITY_CODE: "GBBRN",
      CITY_NAME: "BURNLEY",
      DPA_PORT_CODE: "BRY",
    },
    {
      CITY_CODE: "GBBRP",
      CITY_NAME: "BRIDPORT",
      DPA_PORT_CODE: "BRP",
    },
    {
      CITY_CODE: "GBBRS",
      CITY_NAME: "BRISTOL",
      DPA_PORT_CODE: "BRS",
    },
    {
      CITY_CODE: "GBBSE",
      CITY_NAME: "BURY ST EDMUNDS",
      DPA_PORT_CODE: "BSE",
    },
    {
      CITY_CODE: "GBBSY",
      CITY_NAME: "BARNSLEY",
      DPA_PORT_CODE: "BSY",
    },
    {
      CITY_CODE: "GBBTR",
      CITY_NAME: "BURTON UPON TRENT",
      DPA_PORT_CODE: "BTW",
    },
    {
      CITY_CODE: "GBBYX",
      CITY_NAME: "BURNLEY",
      DPA_PORT_CODE: "BYX",
    },
    {
      CITY_CODE: "GBCAE",
      CITY_NAME: "CAERPHILLY",
      DPA_PORT_CODE: "CAE",
    },
    {
      CITY_CODE: "GBCAX",
      CITY_NAME: "CARLISLE",
      DPA_PORT_CODE: "CRI",
    },
    {
      CITY_CODE: "GBCBT",
      CITY_NAME: "CAMPBELTOWN PORT",
      DPA_PORT_CODE: "CBT",
    },
    {
      CITY_CODE: "GBCDD",
      CITY_NAME: "CHADDERTON",
      DPA_PORT_CODE: "CDD",
    },
    {
      CITY_CODE: "GBCDF",
      CITY_NAME: "CARDIFF",
      DPA_PORT_CODE: "CDF",
    },
    {
      CITY_CODE: "GBCEG",
      CITY_NAME: "CHESTER",
      DPA_PORT_CODE: "CSO",
    },
    {
      CITY_CODE: "GBCGV",
      CITY_NAME: "CHALGROVE",
      DPA_PORT_CODE: "CJV",
    },
    {
      CITY_CODE: "GBCHD",
      CITY_NAME: "CHARLESTON",
      DPA_PORT_CODE: "CHD",
    },
    {
      CITY_CODE: "GBCHR",
      CITY_NAME: "CHORLEY",
      DPA_PORT_CODE: "CHR",
    },
    {
      CITY_CODE: "GBCKM",
      CITY_NAME: "COCKERMOUTH",
      DPA_PORT_CODE: "CKM",
    },
    {
      CITY_CODE: "GBCLR",
      CITY_NAME: "COLERAINE",
      DPA_PORT_CODE: "CLR",
    },
    {
      CITY_CODE: "GBCNV",
      CITY_NAME: "CAERNARVON",
      DPA_PORT_CODE: "CNV",
    },
    {
      CITY_CODE: "GBCOA",
      CITY_NAME: "COALVILLE",
      DPA_PORT_CODE: "COA",
    },
    {
      CITY_CODE: "GBCOB",
      CITY_NAME: "COBHAM",
      DPA_PORT_CODE: "CJM",
    },
    {
      CITY_CODE: "GBCOR",
      CITY_NAME: "CORPACH",
      DPA_PORT_CODE: "COR",
    },
    {
      CITY_CODE: "GBCRN",
      CITY_NAME: "CARRINGTON",
      DPA_PORT_CODE: "CRN",
    },
    {
      CITY_CODE: "GBCRO",
      CITY_NAME: "CROYDON",
      DPA_PORT_CODE: "CYW",
    },
    {
      CITY_CODE: "GBCSH",
      CITY_NAME: "CHESHAM",
      DPA_PORT_CODE: "CSH",
    },
    {
      CITY_CODE: "GBCSN",
      CITY_NAME: "CARSTON",
      DPA_PORT_CODE: "CSN",
    },
    {
      CITY_CODE: "GBCTF",
      CITY_NAME: "CASTLEFORD",
      DPA_PORT_CODE: "CTF",
    },
    {
      CITY_CODE: "GBCVT",
      CITY_NAME: "COVENTRY",
      DPA_PORT_CODE: "CVT",
    },
    {
      CITY_CODE: "GBCWB",
      CITY_NAME: "WREXHAM",
      DPA_PORT_CODE: "CWB",
    },
    {
      CITY_CODE: "GBDAG",
      CITY_NAME: "DAGENHAM",
      DPA_PORT_CODE: "DAQ",
    },
    {
      CITY_CODE: "GBDBT",
      CITY_NAME: "DUMBARTON",
      DPA_PORT_CODE: "DBT",
    },
    {
      CITY_CODE: "GBDES",
      CITY_NAME: "DESFORD",
      DPA_PORT_CODE: "DSD",
    },
    {
      CITY_CODE: "GBDGS",
      CITY_NAME: "DOUGLAS",
      DPA_PORT_CODE: "DGS",
    },
    {
      CITY_CODE: "GBDHR",
      CITY_NAME: "DORCHESTER",
      DPA_PORT_CODE: "DSR",
    },
    {
      CITY_CODE: "GBDKG",
      CITY_NAME: "DORKING",
      DPA_PORT_CODE: "DKG",
    },
    {
      CITY_CODE: "GBDON",
      CITY_NAME: "DONCASTER",
      DPA_PORT_CODE: "DCR",
    },
    {
      CITY_CODE: "GBDOW",
      CITY_NAME: "DOWNHARM MARKET",
      DPA_PORT_CODE: "DOT",
    },
    {
      CITY_CODE: "GBDRH",
      CITY_NAME: "DURHAM",
      DPA_PORT_CODE: "DRH",
    },
    {
      CITY_CODE: "GBDRL",
      CITY_NAME: "DARLINGTON",
      DPA_PORT_CODE: "DRL",
    },
    {
      CITY_CODE: "GBDRO",
      CITY_NAME: "DROITWICH",
      DPA_PORT_CODE: "DLM",
    },
    {
      CITY_CODE: "GBDSE",
      CITY_NAME: "DEESIDE",
      DPA_PORT_CODE: "DSE",
    },
    {
      CITY_CODE: "GBDTE",
      CITY_NAME: "DUNSTABLE",
      DPA_PORT_CODE: "DTE",
    },
    {
      CITY_CODE: "GBDUN",
      CITY_NAME: "DUNDEE",
      DPA_PORT_CODE: "DUN",
    },
    {
      CITY_CODE: "GBDVR",
      CITY_NAME: "DOVER",
      DPA_PORT_CODE: "DVR",
    },
    {
      CITY_CODE: "GBDVY",
      CITY_NAME: "DAVENTRY",
      DPA_PORT_CODE: "DVY",
    },
    {
      CITY_CODE: "GBDWY",
      CITY_NAME: "DEWSBURY",
      DPA_PORT_CODE: "DWY",
    },
    {
      CITY_CODE: "GBDXY",
      CITY_NAME: "DERBY",
      DPA_PORT_CODE: "DXY",
    },
    {
      CITY_CODE: "GBEAM",
      CITY_NAME: "EASTHAM",
      DPA_PORT_CODE: "EAM",
    },
    {
      CITY_CODE: "GBECS",
      CITY_NAME: "ECCLES",
      DPA_PORT_CODE: "ECS",
    },
    {
      CITY_CODE: "GBEDI",
      CITY_NAME: "EDINBURGH",
      DPA_PORT_CODE: "EDI",
    },
    {
      CITY_CODE: "GBEKB",
      CITY_NAME: "EAST KILBRIDE",
      DPA_PORT_CODE: "EKB",
    },
    {
      CITY_CODE: "GBELL",
      CITY_NAME: "ELLESMERE PORT",
      DPA_PORT_CODE: "ELL",
    },
    {
      CITY_CODE: "GBELY",
      CITY_NAME: "ELY",
      DPA_PORT_CODE: "EKY",
    },
    {
      CITY_CODE: "GBENF",
      CITY_NAME: "ENFIELD",
      DPA_PORT_CODE: "ENF",
    },
    {
      CITY_CODE: "GBEXM",
      CITY_NAME: "EXMOUTH",
      DPA_PORT_CODE: "EXM",
    },
    {
      CITY_CODE: "GBFAV",
      CITY_NAME: "FAVERSHAM",
      DPA_PORT_CODE: "FAV",
    },
    {
      CITY_CODE: "GBFEM",
      CITY_NAME: "FELTHAM",
      DPA_PORT_CODE: "FTM",
    },
    {
      CITY_CODE: "GBFHM",
      CITY_NAME: "FAREHAM",
      DPA_PORT_CODE: "FHM",
    },
    {
      CITY_CODE: "GBFLE",
      CITY_NAME: "FLEETWOOD",
      DPA_PORT_CODE: "FLE",
    },
    {
      CITY_CODE: "GBFLH",
      CITY_NAME: "FLOTTA",
      DPA_PORT_CODE: "FLH",
    },
    {
      CITY_CODE: "GBFLI",
      CITY_NAME: "FLINT",
      DPA_PORT_CODE: "FLI",
    },
    {
      CITY_CODE: "GBFNT",
      CITY_NAME: "FINNART",
      DPA_PORT_CODE: "FNT",
    },
    {
      CITY_CODE: "GBFXT",
      CITY_NAME: "FELIXSTOWE",
      DPA_PORT_CODE: "FXT",
    },
    {
      CITY_CODE: "GBFYS",
      CITY_NAME: "FOYNES",
      DPA_PORT_CODE: "FYS",
    },
    {
      CITY_CODE: "GBGAI",
      CITY_NAME: "GAINSBOROUGH",
      DPA_PORT_CODE: "GAZ",
    },
    {
      CITY_CODE: "GBGAW",
      CITY_NAME: "GAERWEN",
      DPA_PORT_CODE: "GAK",
    },
    {
      CITY_CODE: "GBGCI",
      CITY_NAME: "GUERNSEY",
      DPA_PORT_CODE: "GCI",
    },
    {
      CITY_CODE: "GBGIL",
      CITY_NAME: "GILLINGHAM",
      DPA_PORT_CODE: "GIL",
    },
    {
      CITY_CODE: "GBGIR",
      CITY_NAME: "GIRVAN",
      DPA_PORT_CODE: "GIR",
    },
    {
      CITY_CODE: "GBGIS",
      CITY_NAME: "GARIESTON",
      DPA_PORT_CODE: "GIS",
    },
    {
      CITY_CODE: "GBGLD",
      CITY_NAME: "GLASSON DOCK",
      DPA_PORT_CODE: "GLD",
    },
    {
      CITY_CODE: "GBGLW",
      CITY_NAME: "GLASGOW",
      DPA_PORT_CODE: "GLW",
    },
    {
      CITY_CODE: "GBGNW",
      CITY_NAME: "GREENWICH",
      DPA_PORT_CODE: "GNC",
    },
    {
      CITY_CODE: "GBGRG",
      CITY_NAME: "GRANGEMOUTH",
      DPA_PORT_CODE: "GRG",
    },
    {
      CITY_CODE: "GBGRK",
      CITY_NAME: "GREENOCK",
      DPA_PORT_CODE: "GRK",
    },
    {
      CITY_CODE: "GBGSY",
      CITY_NAME: "GRIMSBY",
      DPA_PORT_CODE: "GSY",
    },
    {
      CITY_CODE: "GBGTN",
      CITY_NAME: "GARSTON",
      DPA_PORT_CODE: "GTM",
    },
    {
      CITY_CODE: "GBGTY",
      CITY_NAME: "GREAT YARMOUTH",
      DPA_PORT_CODE: "GTK",
    },
    {
      CITY_CODE: "GBGYS",
      CITY_NAME: "GRAYS",
      DPA_PORT_CODE: "GYS",
    },
    {
      CITY_CODE: "GBHAF",
      CITY_NAME: "HEREFORD",
      DPA_PORT_CODE: "HAF",
    },
    {
      CITY_CODE: "GBHBO",
      CITY_NAME: "HONEYBOURNE",
      DPA_PORT_CODE: "HBJ",
    },
    {
      CITY_CODE: "GBHCE",
      CITY_NAME: "HUCCLECOTE",
      DPA_PORT_CODE: "HCE",
    },
    {
      CITY_CODE: "GBHCN",
      CITY_NAME: "HITCHIN",
      DPA_PORT_CODE: "HJN",
    },
    {
      CITY_CODE: "GBHDD",
      CITY_NAME: "HOWDENDYKE",
      DPA_PORT_CODE: "HDD",
    },
    {
      CITY_CODE: "GBHDF",
      CITY_NAME: "HUDDERSFIELD",
      DPA_PORT_CODE: "HDJ",
    },
    {
      CITY_CODE: "GBHEF",
      CITY_NAME: "HERTFORD",
      DPA_PORT_CODE: "HEF",
    },
    {
      CITY_CODE: "GBHFX",
      CITY_NAME: "HALIFAX",
      DPA_PORT_CODE: "HFX",
    },
    {
      CITY_CODE: "GBHHE",
      CITY_NAME: "HEMEL HEMPSTEAD",
      DPA_PORT_CODE: "HHD",
    },
    {
      CITY_CODE: "GBHIL",
      CITY_NAME: "HINCKLEY",
      DPA_PORT_CODE: "HIL",
    },
    {
      CITY_CODE: "GBHTP",
      CITY_NAME: "HARTLEPOOL",
      DPA_PORT_CODE: "HTP",
    },
    {
      CITY_CODE: "GBHUL",
      CITY_NAME: "HULL",
      DPA_PORT_CODE: "HUL",
    },
    {
      CITY_CODE: "GBHWC",
      CITY_NAME: "HAWICK",
      DPA_PORT_CODE: "HWC",
    },
    {
      CITY_CODE: "GBHWY",
      CITY_NAME: "HIGH WYCOMBE",
      DPA_PORT_CODE: "HBW",
    },
    {
      CITY_CODE: "GBHYM",
      CITY_NAME: "HEYSHAM",
      DPA_PORT_CODE: "HYM",
    },
    {
      CITY_CODE: "GBHYW",
      CITY_NAME: "HEYWOOD",
      DPA_PORT_CODE: "HYW",
    },
    {
      CITY_CODE: "GBIFD",
      CITY_NAME: "ILFORD",
      DPA_PORT_CODE: "IFD",
    },
    {
      CITY_CODE: "GBIKS",
      CITY_NAME: "ILKESTON",
      DPA_PORT_CODE: "IKS",
    },
    {
      CITY_CODE: "GBIMM",
      CITY_NAME: "IMMINGHAM",
      DPA_PORT_CODE: "IMM",
    },
    {
      CITY_CODE: "GBINV",
      CITY_NAME: "INVERNESS",
      DPA_PORT_CODE: "INV",
    },
    {
      CITY_CODE: "GBIOG",
      CITY_NAME: "ISLE OF GRAIN",
      DPA_PORT_CODE: "IOG",
    },
    {
      CITY_CODE: "GBIOM",
      CITY_NAME: "ISLE OF MAN",
      DPA_PORT_CODE: "IOM",
    },
    {
      CITY_CODE: "GBIPS",
      CITY_NAME: "IPSWICH",
      DPA_PORT_CODE: "IPS",
    },
    {
      CITY_CODE: "GBIRL",
      CITY_NAME: "IRLAM",
      DPA_PORT_CODE: "IRL",
    },
    {
      CITY_CODE: "GBIRV",
      CITY_NAME: "IRVINE",
      DPA_PORT_CODE: "IRV",
    },
    {
      CITY_CODE: "GBITC",
      CITY_NAME: "ITCHENOR",
      DPA_PORT_CODE: "ITC",
    },
    {
      CITY_CODE: "GBIVG",
      CITY_NAME: "INVERGORDON",
      DPA_PORT_CODE: "IVG",
    },
    {
      CITY_CODE: "GBIYP",
      CITY_NAME: "ISLAY PORT",
      DPA_PORT_CODE: "IYP",
    },
    {
      CITY_CODE: "GBJER",
      CITY_NAME: "JERSEY",
      DPA_PORT_CODE: "JER",
    },
    {
      CITY_CODE: "GBKDM",
      CITY_NAME: "KIDDERMINSTER",
      DPA_PORT_CODE: "KDM",
    },
    {
      CITY_CODE: "GBKEI",
      CITY_NAME: "KEIGHLEY",
      DPA_PORT_CODE: "KEI",
    },
    {
      CITY_CODE: "GBKGH",
      CITY_NAME: "KILLINGHOLME",
      DPA_PORT_CODE: "KGH",
    },
    {
      CITY_CODE: "GBKKB",
      CITY_NAME: "KIRKBY",
      DPA_PORT_CODE: "KKB",
    },
    {
      CITY_CODE: "GBKKD",
      CITY_NAME: "KIRKCALDY",
      DPA_PORT_CODE: "KKD",
    },
    {
      CITY_CODE: "GBKLN",
      CITY_NAME: "KINGS LYNN",
      DPA_PORT_CODE: "KJN",
    },
    {
      CITY_CODE: "GBKNF",
      CITY_NAME: "KNUTSFORD",
      DPA_PORT_CODE: "KNF",
    },
    {
      CITY_CODE: "GBLAI",
      CITY_NAME: "LAINDON",
      DPA_PORT_CODE: "LAI",
    },
    {
      CITY_CODE: "GBLAN",
      CITY_NAME: "LANCASTER",
      DPA_PORT_CODE: "LAN",
    },
    {
      CITY_CODE: "GBLBK",
      CITY_NAME: "BARKING",
      DPA_PORT_CODE: "LBK",
    },
    {
      CITY_CODE: "GBLBR",
      CITY_NAME: "LAMBOURN",
      DPA_PORT_CODE: "LBJ",
    },
    {
      CITY_CODE: "GBLBZ",
      CITY_NAME: "LEIGHTON BUZZARD",
      DPA_PORT_CODE: "LBZ",
    },
    {
      CITY_CODE: "GBLCN",
      CITY_NAME: "LINCOLN",
      DPA_PORT_CODE: "LCN",
    },
    {
      CITY_CODE: "GBLCS",
      CITY_NAME: "LEICESTER",
      DPA_PORT_CODE: "LCS",
    },
    {
      CITY_CODE: "GBLDY",
      CITY_NAME: "LONDONDERRY",
      DPA_PORT_CODE: "LDY",
    },
    {
      CITY_CODE: "GBLEQ",
      CITY_NAME: "LANDS END",
      DPA_PORT_CODE: "LEQ",
    },
    {
      CITY_CODE: "GBLGP",
      CITY_NAME: "LONDON GATEWAY PORT",
      DPA_PORT_CODE: "LGQ",
    },
    {
      CITY_CODE: "GBLHP",
      CITY_NAME: "LONGHOPE",
      DPA_PORT_CODE: "LHP",
    },
    {
      CITY_CODE: "GBLIV",
      CITY_NAME: "LIVERPOOL",
      DPA_PORT_CODE: "LRL",
    },
    {
      CITY_CODE: "GBLLD",
      CITY_NAME: "LLANDDULAS",
      DPA_PORT_CODE: "LLD",
    },
    {
      CITY_CODE: "GBLLN",
      CITY_NAME: "LLANELLI",
      DPA_PORT_CODE: "LLN",
    },
    {
      CITY_CODE: "GBLON",
      CITY_NAME: "LONDON",
      DPA_PORT_CODE: "LON",
    },
    {
      CITY_CODE: "GBLTL",
      CITY_NAME: "LITTLEBROOK",
      DPA_PORT_CODE: "LTL",
    },
    {
      CITY_CODE: "GBLYL",
      CITY_NAME: "LEYLAND",
      DPA_PORT_CODE: "LYL",
    },
    {
      CITY_CODE: "GBLYM",
      CITY_NAME: "LYMINGTON",
      DPA_PORT_CODE: "LYM",
    },
    {
      CITY_CODE: "GBMAN",
      CITY_NAME: "RINGWAY APT",
      DPA_PORT_CODE: "MAN",
    },
    {
      CITY_CODE: "GBMBY",
      CITY_NAME: "MELTON MOWBRAY",
      DPA_PORT_CODE: "MBY",
    },
    {
      CITY_CODE: "GBMCL",
      CITY_NAME: "MACCLESFIELD",
      DPA_PORT_CODE: "MCL",
    },
    {
      CITY_CODE: "GBMDN",
      CITY_NAME: "MIDDLETON",
      DPA_PORT_CODE: "IDD",
    },
    {
      CITY_CODE: "GBMDT",
      CITY_NAME: "MAIDSTONE",
      DPA_PORT_CODE: "MDT",
    },
    {
      CITY_CODE: "GBMDW",
      CITY_NAME: "MIDDLEWICH",
      DPA_PORT_CODE: "MDW",
    },
    {
      CITY_CODE: "GBMID",
      CITY_NAME: "MIDDLESBOROUGH",
      DPA_PORT_CODE: "MDL",
    },
    {
      CITY_CODE: "GBMIK",
      CITY_NAME: "MILTON KEYNES",
      DPA_PORT_CODE: "MTK",
    },
    {
      CITY_CODE: "GBMIR",
      CITY_NAME: "MIRFIELD",
      DPA_PORT_CODE: "IEL",
    },
    {
      CITY_CODE: "GBMLM",
      CITY_NAME: "MILLOM",
      DPA_PORT_CODE: "MLM",
    },
    {
      CITY_CODE: "GBMNC",
      CITY_NAME: "MANCHESTER",
      DPA_PORT_CODE: "MCH",
    },
    {
      CITY_CODE: "GBMNH",
      CITY_NAME: "MANCHACTION",
      DPA_PORT_CODE: "MNH",
    },
    {
      CITY_CODE: "GBMRT",
      CITY_NAME: "MORETON",
      DPA_PORT_CODE: "MEW",
    },
    {
      CITY_CODE: "GBNBY",
      CITY_NAME: "NEWBURY",
      DPA_PORT_CODE: "NRX",
    },
    {
      CITY_CODE: "GBNCL",
      CITY_NAME: "NEWCASTLE UPON TYNE",
      DPA_PORT_CODE: "NXL",
    },
    {
      CITY_CODE: "GBNEH",
      CITY_NAME: "NEAP HOUSE",
      DPA_PORT_CODE: "NEH",
    },
    {
      CITY_CODE: "GBNFT",
      CITY_NAME: "NORTHFLEET",
      DPA_PORT_CODE: "NFT",
    },
    {
      CITY_CODE: "GBNHO",
      CITY_NAME: "NEW HOLLAND",
      DPA_PORT_CODE: "NHO",
    },
    {
      CITY_CODE: "GBNHP",
      CITY_NAME: "NORTHAMPTON",
      DPA_PORT_CODE: "NHT",
    },
    {
      CITY_CODE: "GBNHT",
      CITY_NAME: "NORTHOLT",
      DPA_PORT_CODE: "NHC",
    },
    {
      CITY_CODE: "GBNHV",
      CITY_NAME: "NEWHAVEN",
      DPA_PORT_CODE: "NHV",
    },
    {
      CITY_CODE: "GBNLN",
      CITY_NAME: "NELSON",
      DPA_PORT_CODE: "NLB",
    },
    {
      CITY_CODE: "GBNLY",
      CITY_NAME: "NEWLYN",
      DPA_PORT_CODE: "NLY",
    },
    {
      CITY_CODE: "GBNPT",
      CITY_NAME: "NEWPORT",
      DPA_PORT_CODE: "NPT",
    },
    {
      CITY_CODE: "GBNRF",
      CITY_NAME: "NORTH FERRIBY",
      DPA_PORT_CODE: "NRF",
    },
    {
      CITY_CODE: "GBNRW",
      CITY_NAME: "NORWICH",
      DPA_PORT_CODE: "NRW",
    },
    {
      CITY_CODE: "GBNSH",
      CITY_NAME: "NORTH SHIELDS",
      DPA_PORT_CODE: "NSH",
    },
    {
      CITY_CODE: "GBNTG",
      CITY_NAME: "NOTTINGHAM",
      DPA_PORT_CODE: "NOM",
    },
    {
      CITY_CODE: "GBNTH",
      CITY_NAME: "NORTHWICH",
      DPA_PORT_CODE: "NTH",
    },
    {
      CITY_CODE: "GBNTN",
      CITY_NAME: "NUNEATON",
      DPA_PORT_CODE: "NDU",
    },
    {
      CITY_CODE: "GBNTW",
      CITY_NAME: "NANTWICH",
      DPA_PORT_CODE: "NTW",
    },
    {
      CITY_CODE: "GBNWT",
      CITY_NAME: "NEWTOWN",
      DPA_PORT_CODE: "NWO",
    },
    {
      CITY_CODE: "GBOBA",
      CITY_NAME: "OBAN",
      DPA_PORT_CODE: "OBA",
    },
    {
      CITY_CODE: "GBODH",
      CITY_NAME: "OLDHAM",
      DPA_PORT_CODE: "ODH",
    },
    {
      CITY_CODE: "GBOKK",
      CITY_NAME: "OLD KILPATRICK",
      DPA_PORT_CODE: "OKK",
    },
    {
      CITY_CODE: "GBOPG",
      CITY_NAME: "ORPINGTON",
      DPA_PORT_CODE: "OON",
    },
    {
      CITY_CODE: "GBOSS",
      CITY_NAME: "OSSETT",
      DPA_PORT_CODE: "OSS",
    },
    {
      CITY_CODE: "GBOWR",
      CITY_NAME: "OSWESTRY",
      DPA_PORT_CODE: "OWR",
    },
    {
      CITY_CODE: "GBPAD",
      CITY_NAME: "PADSTOW",
      DPA_PORT_CODE: "PAD",
    },
    {
      CITY_CODE: "GBPFT",
      CITY_NAME: "PURFLEET",
      DPA_PORT_CODE: "PFT",
    },
    {
      CITY_CODE: "GBPKL",
      CITY_NAME: "POCKLINGTON",
      DPA_PORT_CODE: "PKL",
    },
    {
      CITY_CODE: "GBPLY",
      CITY_NAME: "PLYMOUTH",
      DPA_PORT_CODE: "PLY",
    },
    {
      CITY_CODE: "GBPME",
      CITY_NAME: "PORTSMOUTH",
      DPA_PORT_CODE: "PME",
    },
    {
      CITY_CODE: "GBPMM",
      CITY_NAME: "PENMAENMAWR",
      DPA_PORT_CODE: "PMM",
    },
    {
      CITY_CODE: "GBPMT",
      CITY_NAME: "PLYMPTON",
      DPA_PORT_CODE: "PNO",
    },
    {
      CITY_CODE: "GBPNC",
      CITY_NAME: "PONTYCLUN",
      DPA_PORT_CODE: "PNC",
    },
    {
      CITY_CODE: "GBPOB",
      CITY_NAME: "POTTERS BAR",
      DPA_PORT_CODE: "POB",
    },
    {
      CITY_CODE: "GBPOO",
      CITY_NAME: "POOLE",
      DPA_PORT_CODE: "POO",
    },
    {
      CITY_CODE: "GBPRE",
      CITY_NAME: "PRESTON",
      DPA_PORT_CODE: "PRE",
    },
    {
      CITY_CODE: "GBPRU",
      CITY_NAME: "PORTBURY",
      DPA_PORT_CODE: "PRU",
    },
    {
      CITY_CODE: "GBPTH",
      CITY_NAME: "PORTISHEAD",
      DPA_PORT_CODE: "PTH",
    },
    {
      CITY_CODE: "GBPTP",
      CITY_NAME: "PONTYPOOL",
      DPA_PORT_CODE: "PTP",
    },
    {
      CITY_CODE: "GBPTR",
      CITY_NAME: "PORTRUSH",
      DPA_PORT_CODE: "PTR",
    },
    {
      CITY_CODE: "GBQUB",
      CITY_NAME: "QUEENBOROUGH",
      DPA_PORT_CODE: "QUB",
    },
    {
      CITY_CODE: "GBQWH",
      CITY_NAME: "SWADLINCOTE",
      DPA_PORT_CODE: "QWH",
    },
    {
      CITY_CODE: "GBRAY",
      CITY_NAME: "ROTHESAY",
      DPA_PORT_CODE: "RAY",
    },
    {
      CITY_CODE: "GBRCH",
      CITY_NAME: "RICHMOND",
      DPA_PORT_CODE: "RMH",
    },
    {
      CITY_CODE: "GBRCS",
      CITY_NAME: "ROCHESTER",
      DPA_PORT_CODE: "RCS",
    },
    {
      CITY_CODE: "GBRCU",
      CITY_NAME: "RICHMOND UPON THAMES",
      DPA_PORT_CODE: "RCV",
    },
    {
      CITY_CODE: "GBRDN",
      CITY_NAME: "READING",
      DPA_PORT_CODE: "RDJ",
    },
    {
      CITY_CODE: "GBREB",
      CITY_NAME: "REDCLIFFE BAY",
      DPA_PORT_CODE: "REB",
    },
    {
      CITY_CODE: "GBREH",
      CITY_NAME: "REDHILL",
      DPA_PORT_CODE: "RDX",
    },
    {
      CITY_CODE: "GBRHY",
      CITY_NAME: "RHYL",
      DPA_PORT_CODE: "RHY",
    },
    {
      CITY_CODE: "GBRID",
      CITY_NAME: "RIDHAM DOCK",
      DPA_PORT_CODE: "RID",
    },
    {
      CITY_CODE: "GBRMB",
      CITY_NAME: "RAMSBOTTOM",
      DPA_PORT_CODE: "RMB",
    },
    {
      CITY_CODE: "GBRMF",
      CITY_NAME: "ROMFORD",
      DPA_PORT_CODE: "RMF",
    },
    {
      CITY_CODE: "GBRMG",
      CITY_NAME: "RAMSGATE",
      DPA_PORT_CODE: "RMG",
    },
    {
      CITY_CODE: "GBRSY",
      CITY_NAME: "RAMSEY",
      DPA_PORT_CODE: "RSY",
    },
    {
      CITY_CODE: "GBRTH",
      CITY_NAME: "ROTHERHAM",
      DPA_PORT_CODE: "RTH",
    },
    {
      CITY_CODE: "GBRUI",
      CITY_NAME: "RUISLIP",
      DPA_PORT_CODE: "RUP",
    },
    {
      CITY_CODE: "GBRUN",
      CITY_NAME: "RUNCORN",
      DPA_PORT_CODE: "RUC",
    },
    {
      CITY_CODE: "GBRYE",
      CITY_NAME: "RYE",
      DPA_PORT_CODE: "RYE",
    },
    {
      CITY_CODE: "GBRYY",
      CITY_NAME: "RHYMNEY",
      DPA_PORT_CODE: "RYY",
    },
    {
      CITY_CODE: "GBSED",
      CITY_NAME: "SALT END",
      DPA_PORT_CODE: "SED",
    },
    {
      CITY_CODE: "GBSFD",
      CITY_NAME: "STRETFORD",
      DPA_PORT_CODE: "TCY",
    },
    {
      CITY_CODE: "GBSFY",
      CITY_NAME: "SALFORD",
      DPA_PORT_CODE: "SFY",
    },
    {
      CITY_CODE: "GBSHE",
      CITY_NAME: "SHEFFIELD",
      DPA_PORT_CODE: "SHD",
    },
    {
      CITY_CODE: "GBSHY",
      CITY_NAME: "ST HELENS",
      DPA_PORT_CODE: "SHY",
    },
    {
      CITY_CODE: "GBSIN",
      CITY_NAME: "SINGLETON",
      DPA_PORT_CODE: "SON",
    },
    {
      CITY_CODE: "GBSKD",
      CITY_NAME: "SKELMERSDALE",
      DPA_PORT_CODE: "SKJ",
    },
    {
      CITY_CODE: "GBSLE",
      CITY_NAME: "SLEAFORD",
      DPA_PORT_CODE: "AOD",
    },
    {
      CITY_CODE: "GBSLL",
      CITY_NAME: "SOUTHALL",
      DPA_PORT_CODE: "SLW",
    },
    {
      CITY_CODE: "GBSLO",
      CITY_NAME: "SLOUGH",
      DPA_PORT_CODE: "SLH",
    },
    {
      CITY_CODE: "GBSME",
      CITY_NAME: "SMETHWICK",
      DPA_PORT_CODE: "TDT",
    },
    {
      CITY_CODE: "GBSOH",
      CITY_NAME: "SOUTHAM",
      DPA_PORT_CODE: "TOH",
    },
    {
      CITY_CODE: "GBSOT",
      CITY_NAME: "STOKE ON TRENT",
      DPA_PORT_CODE: "SOT",
    },
    {
      CITY_CODE: "GBSOU",
      CITY_NAME: "SOUTHAMPTON",
      DPA_PORT_CODE: "SOU",
    },
    {
      CITY_CODE: "GBSRT",
      CITY_NAME: "SOUTHPORT",
      DPA_PORT_CODE: "SRT",
    },
    {
      CITY_CODE: "GBSSH",
      CITY_NAME: "SOUTH SHIELDS",
      DPA_PORT_CODE: "SSH",
    },
    {
      CITY_CODE: "GBSTL",
      CITY_NAME: "ST AUSTELL",
      DPA_PORT_CODE: "SXK",
    },
    {
      CITY_CODE: "GBSTY",
      CITY_NAME: "STALYBRIDGE",
      DPA_PORT_CODE: "STY",
    },
    {
      CITY_CODE: "GBSUD",
      CITY_NAME: "SUDBURY",
      DPA_PORT_CODE: "SUD",
    },
    {
      CITY_CODE: "GBSVT",
      CITY_NAME: "SILVERTOWN",
      DPA_PORT_CODE: "SVT",
    },
    {
      CITY_CODE: "GBSWA",
      CITY_NAME: "SWANSEA",
      DPA_PORT_CODE: "SWA",
    },
    {
      CITY_CODE: "GBSWY",
      CITY_NAME: "SCALLOWAY",
      DPA_PORT_CODE: "SWY",
    },
    {
      CITY_CODE: "GBSYR",
      CITY_NAME: "STOURTON LEEDS",
      DPA_PORT_CODE: "SFQ",
    },
    {
      CITY_CODE: "GBTAW",
      CITY_NAME: "TAMWORTH",
      DPA_PORT_CODE: "TMW",
    },
    {
      CITY_CODE: "GBTBG",
      CITY_NAME: "TROWBRIDGE",
      DPA_PORT_CODE: "TBG",
    },
    {
      CITY_CODE: "GBTEE",
      CITY_NAME: "TEESPORT",
      DPA_PORT_CODE: "TEE",
    },
    {
      CITY_CODE: "GBTEF",
      CITY_NAME: "TELFORD",
      DPA_PORT_CODE: "TEF",
    },
    {
      CITY_CODE: "GBTFD",
      CITY_NAME: "THETFORD",
      DPA_PORT_CODE: "TFD",
    },
    {
      CITY_CODE: "GBTHP",
      CITY_NAME: "Thames Port",
      DPA_PORT_CODE: "THP",
    },
    {
      CITY_CODE: "GBTHS",
      CITY_NAME: "THAMES HAVEN",
      DPA_PORT_CODE: "THS",
    },
    {
      CITY_CODE: "GBTIL",
      CITY_NAME: "TILBURY",
      DPA_PORT_CODE: "TIL",
    },
    {
      CITY_CODE: "GBTNB",
      CITY_NAME: "TONBRIDGE",
      DPA_PORT_CODE: "TNB",
    },
    {
      CITY_CODE: "GBTNM",
      CITY_NAME: "TEIGNMOUTH",
      DPA_PORT_CODE: "TNM",
    },
    {
      CITY_CODE: "GBTPN",
      CITY_NAME: "TIPTON",
      DPA_PORT_CODE: "TPX",
    },
    {
      CITY_CODE: "GBTSC",
      CITY_NAME: "TRESCO",
      DPA_PORT_CODE: "TSC",
    },
    {
      CITY_CODE: "GBTYY",
      CITY_NAME: "TRINITY",
      DPA_PORT_CODE: "TYY",
    },
    {
      CITY_CODE: "GBULL",
      CITY_NAME: "ULLAPOOL",
      DPA_PORT_CODE: "ULL",
    },
    {
      CITY_CODE: "GBURS",
      CITY_NAME: "URMSTON",
      DPA_PORT_CODE: "URM",
    },
    {
      CITY_CODE: "GBUTX",
      CITY_NAME: "UTTOXETER",
      DPA_PORT_CODE: "UTX",
    },
    {
      CITY_CODE: "GBWAC",
      CITY_NAME: "WALTHAM CROSS",
      DPA_PORT_CODE: "WOC",
    },
    {
      CITY_CODE: "GBWAF",
      CITY_NAME: "WATFORD",
      DPA_PORT_CODE: "WTF",
    },
    {
      CITY_CODE: "GBWAY",
      CITY_NAME: "WALLASEY",
      DPA_PORT_CODE: "WAY",
    },
    {
      CITY_CODE: "GBWBY",
      CITY_NAME: "WEMBLEY",
      DPA_PORT_CODE: "WBY",
    },
    {
      CITY_CODE: "GBWDN",
      CITY_NAME: "WIDNES",
      DPA_PORT_CODE: "WDN",
    },
    {
      CITY_CODE: "GBWEY",
      CITY_NAME: "WEYMOUTH",
      DPA_PORT_CODE: "WEY",
    },
    {
      CITY_CODE: "GBWFD",
      CITY_NAME: "WAKEFIELD",
      DPA_PORT_CODE: "WFD",
    },
    {
      CITY_CODE: "GBWGN",
      CITY_NAME: "WIGAN",
      DPA_PORT_CODE: "WGN",
    },
    {
      CITY_CODE: "GBWHB",
      CITY_NAME: "WHITEBOOTH ROADS",
      DPA_PORT_CODE: "WHB",
    },
    {
      CITY_CODE: "GBWHV",
      CITY_NAME: "WHITEHAVEN",
      DPA_PORT_CODE: "WHV",
    },
    {
      CITY_CODE: "GBWIM",
      CITY_NAME: "WIMBORNE MINSTER",
      DPA_PORT_CODE: "WIM",
    },
    {
      CITY_CODE: "GBWIV",
      CITY_NAME: "WIVENHOE",
      DPA_PORT_CODE: "WIV",
    },
    {
      CITY_CODE: "GBWLE",
      CITY_NAME: "WALTON ON THAMES",
      DPA_PORT_CODE: "WTX",
    },
    {
      CITY_CODE: "GBWOK",
      CITY_NAME: "WORKINGHAM",
      DPA_PORT_CODE: "WOK",
    },
    {
      CITY_CODE: "GBWOL",
      CITY_NAME: "NORTH WOOLWICH",
      DPA_PORT_CODE: "WOL",
    },
    {
      CITY_CODE: "GBWRD",
      CITY_NAME: "WOODFORD GREEN",
      DPA_PORT_CODE: "WRW",
    },
    {
      CITY_CODE: "GBWRN",
      CITY_NAME: "WARRINGTON",
      DPA_PORT_CODE: "WRN",
    },
    {
      CITY_CODE: "GBWSW",
      CITY_NAME: "WILMSLOW",
      DPA_PORT_CODE: "WSW",
    },
    {
      CITY_CODE: "GDSTG",
      CITY_NAME: "SAINT GEORGE'S",
      DPA_PORT_CODE: "STG",
    },
    {
      CITY_CODE: "GEBUS",
      CITY_NAME: "BATUMI",
      DPA_PORT_CODE: "BUS",
    },
    {
      CITY_CODE: "GEPTI",
      CITY_NAME: "POTI",
      DPA_PORT_CODE: "PTI",
    },
    {
      CITY_CODE: "GFDDC",
      CITY_NAME: "DEGRAD DES CANNES",
      DPA_PORT_CODE: "DCE",
    },
    {
      CITY_CODE: "GHAXI",
      CITY_NAME: "AXIM",
      DPA_PORT_CODE: "AXI",
    },
    {
      CITY_CODE: "GHSEK",
      CITY_NAME: "SEKONDI",
      DPA_PORT_CODE: "SEK",
    },
    {
      CITY_CODE: "GHTEM",
      CITY_NAME: "TEMA",
      DPA_PORT_CODE: "TEM",
    },
    {
      CITY_CODE: "GHTKD",
      CITY_NAME: "TAKORADI",
      DPA_PORT_CODE: "TKD",
    },
    {
      CITY_CODE: "GHWEA",
      CITY_NAME: "WINNEBA",
      DPA_PORT_CODE: "WEA",
    },
    {
      CITY_CODE: "GIGIB",
      CITY_NAME: "GIBRALTAR",
      DPA_PORT_CODE: "GIB",
    },
    {
      CITY_CODE: "GLAGM",
      CITY_NAME: "ANGMAGSSALIK",
      DPA_PORT_CODE: "AGM",
    },
    {
      CITY_CODE: "GLGOH",
      CITY_NAME: "NUUK",
      DPA_PORT_CODE: "GOH",
    },
    {
      CITY_CODE: "GLIUT",
      CITY_NAME: "IVIGTUT",
      DPA_PORT_CODE: "IUT",
    },
    {
      CITY_CODE: "GLJAV",
      CITY_NAME: "ILULISSAT",
      DPA_PORT_CODE: "JAV",
    },
    {
      CITY_CODE: "GLJCH",
      CITY_NAME: "QASIGIANNGUIT",
      DPA_PORT_CODE: "JCH",
    },
    {
      CITY_CODE: "GLJEG",
      CITY_NAME: "AUSIAIT",
      DPA_PORT_CODE: "JEG",
    },
    {
      CITY_CODE: "GLJFR",
      CITY_NAME: "PAAMIUT",
      DPA_PORT_CODE: "JFR",
    },
    {
      CITY_CODE: "GLJGO",
      CITY_NAME: "QEQERTARSUAQ",
      DPA_PORT_CODE: "JGO",
    },
    {
      CITY_CODE: "GLJHS",
      CITY_NAME: "SISIMIUT",
      DPA_PORT_CODE: "JHS",
    },
    {
      CITY_CODE: "GLJNN",
      CITY_NAME: "NANORTALIK",
      DPA_PORT_CODE: "JNN",
    },
    {
      CITY_CODE: "GLJSU",
      CITY_NAME: "MANITSOQ",
      DPA_PORT_CODE: "JSU",
    },
    {
      CITY_CODE: "GLTHU",
      CITY_NAME: "QARNAQ",
      DPA_PORT_CODE: "THU",
    },
    {
      CITY_CODE: "GLTOV",
      CITY_NAME: "TOVKUSOAK",
      DPA_PORT_CODE: "TOV",
    },
    {
      CITY_CODE: "GLUAK",
      CITY_NAME: "NARSARSUAQ",
      DPA_PORT_CODE: "UAK",
    },
    {
      CITY_CODE: "GLUMD",
      CITY_NAME: "UUMMANNAQ",
      DPA_PORT_CODE: "UMD",
    },
    {
      CITY_CODE: "GMBJL",
      CITY_NAME: "BANJUL",
      DPA_PORT_CODE: "BJL",
    },
    {
      CITY_CODE: "GNBTY",
      CITY_NAME: "BENTY",
      DPA_PORT_CODE: "BTY",
    },
    {
      CITY_CODE: "GNCKY",
      CITY_NAME: "CONAKRY",
      DPA_PORT_CODE: "CKY",
    },
    {
      CITY_CODE: "GNKMR",
      CITY_NAME: "PORT KAMSAR",
      DPA_PORT_CODE: "KMR",
    },
    {
      CITY_CODE: "GPBBR",
      CITY_NAME: "BASSE TERRE",
      DPA_PORT_CODE: "BBR",
    },
    {
      CITY_CODE: "GPGRB",
      CITY_NAME: "GRAND BOURG",
      DPA_PORT_CODE: "GRB",
    },
    {
      CITY_CODE: "GPGUS",
      CITY_NAME: "GUSTAVIA",
      DPA_PORT_CODE: "GDS",
    },
    {
      CITY_CODE: "GPPTP",
      CITY_NAME: "POINTE A PITRE",
      DPA_PORT_CODE: "PEP",
    },
    {
      CITY_CODE: "GQBSG",
      CITY_NAME: "BATA",
      DPA_PORT_CODE: "BSG",
    },
    {
      CITY_CODE: "GQSSG",
      CITY_NAME: "MALABO",
      DPA_PORT_CODE: "SSG",
    },
    {
      CITY_CODE: "GRAGT",
      CITY_NAME: "AGIO THEODOROI",
      DPA_PORT_CODE: "IOT",
    },
    {
      CITY_CODE: "GRCFU",
      CITY_NAME: "CORFU",
      DPA_PORT_CODE: "CFU",
    },
    {
      CITY_CODE: "GRDOS",
      CITY_NAME: "DELOS",
      DPA_PORT_CODE: "DOS",
    },
    {
      CITY_CODE: "GRDPA",
      CITY_NAME: "DRAPETZONA",
      DPA_PORT_CODE: "DPA",
    },
    {
      CITY_CODE: "GREEU",
      CITY_NAME: "ELEUSIS",
      DPA_PORT_CODE: "EEU",
    },
    {
      CITY_CODE: "GREPI",
      CITY_NAME: "EPIDAURO",
      DPA_PORT_CODE: "EPI",
    },
    {
      CITY_CODE: "GRGPA",
      CITY_NAME: "PATRAS",
      DPA_PORT_CODE: "GPA",
    },
    {
      CITY_CODE: "GRGYT",
      CITY_NAME: "GYTHION",
      DPA_PORT_CODE: "GYT",
    },
    {
      CITY_CODE: "GRHER",
      CITY_NAME: "HERAKLION",
      DPA_PORT_CODE: "HER",
    },
    {
      CITY_CODE: "GRIGO",
      CITY_NAME: "IGOUMENITSA",
      DPA_PORT_CODE: "IGO",
    },
    {
      CITY_CODE: "GRIOA",
      CITY_NAME: "IOANNINA",
      DPA_PORT_CODE: "IOA",
    },
    {
      CITY_CODE: "GRITA",
      CITY_NAME: "ITEA",
      DPA_PORT_CODE: "ITA",
    },
    {
      CITY_CODE: "GRITH",
      CITY_NAME: "ITHAKI",
      DPA_PORT_CODE: "ITH",
    },
    {
      CITY_CODE: "GRITM",
      CITY_NAME: "ISTHMIA",
      DPA_PORT_CODE: "ITM",
    },
    {
      CITY_CODE: "GRJKH",
      CITY_NAME: "CHIOS",
      DPA_PORT_CODE: "JKH",
    },
    {
      CITY_CODE: "GRJMK",
      CITY_NAME: "MIKONOS",
      DPA_PORT_CODE: "JMK",
    },
    {
      CITY_CODE: "GRJNX",
      CITY_NAME: "NAXOS",
      DPA_PORT_CODE: "JNX",
    },
    {
      CITY_CODE: "GRJSH",
      CITY_NAME: "SITIA",
      DPA_PORT_CODE: "JSH",
    },
    {
      CITY_CODE: "GRJSY",
      CITY_NAME: "SYRA",
      DPA_PORT_CODE: "JSY",
    },
    {
      CITY_CODE: "GRKAB",
      CITY_NAME: "KABALIA",
      DPA_PORT_CODE: "KAB",
    },
    {
      CITY_CODE: "GRKEA",
      CITY_NAME: "KEA ISLAND",
      DPA_PORT_CODE: "KEA",
    },
    {
      CITY_CODE: "GRKGS",
      CITY_NAME: "KOS",
      DPA_PORT_CODE: "KGS",
    },
    {
      CITY_CODE: "GRKLL",
      CITY_NAME: "KALILIMENES",
      DPA_PORT_CODE: "KLL",
    },
    {
      CITY_CODE: "GRKLX",
      CITY_NAME: "KALAMATA",
      DPA_PORT_CODE: "KLX",
    },
    {
      CITY_CODE: "GRKVA",
      CITY_NAME: "KAVALA",
      DPA_PORT_CODE: "KVA",
    },
    {
      CITY_CODE: "GRKYM",
      CITY_NAME: "KYMASSI",
      DPA_PORT_CODE: "KYM",
    },
    {
      CITY_CODE: "GRKZI",
      CITY_NAME: "KOZANI",
      DPA_PORT_CODE: "KZI",
    },
    {
      CITY_CODE: "GRLAV",
      CITY_NAME: "LAURIUM",
      DPA_PORT_CODE: "LAV",
    },
    {
      CITY_CODE: "GRLII",
      CITY_NAME: "LIMNI",
      DPA_PORT_CODE: "LII",
    },
    {
      CITY_CODE: "GRLIX",
      CITY_NAME: "LIXURI",
      DPA_PORT_CODE: "LIX",
    },
    {
      CITY_CODE: "GRLRS",
      CITY_NAME: "LARMES",
      DPA_PORT_CODE: "LRS",
    },
    {
      CITY_CODE: "GRNEA",
      CITY_NAME: "NEAPOLIS",
      DPA_PORT_CODE: "NEA",
    },
    {
      CITY_CODE: "GRNPY",
      CITY_NAME: "NEA PLAYA",
      DPA_PORT_CODE: "NPY",
    },
    {
      CITY_CODE: "GRPIR",
      CITY_NAME: "PIRAEUS",
      DPA_PORT_CODE: "PIR",
    },
    {
      CITY_CODE: "GRPPG",
      CITY_NAME: "PALLINI",
      DPA_PORT_CODE: "PLI",
    },
    {
      CITY_CODE: "GRPYL",
      CITY_NAME: "PYLOS",
      DPA_PORT_CODE: "PYL",
    },
    {
      CITY_CODE: "GRPYR",
      CITY_NAME: "PYRGOS",
      DPA_PORT_CODE: "PYR",
    },
    {
      CITY_CODE: "GRRET",
      CITY_NAME: "RETHYMNON",
      DPA_PORT_CODE: "RET",
    },
    {
      CITY_CODE: "GRSER",
      CITY_NAME: "SERIPHOS ISLAND",
      DPA_PORT_CODE: "SER",
    },
    {
      CITY_CODE: "GRSKA",
      CITY_NAME: "SKARAMANGA",
      DPA_PORT_CODE: "SMA",
    },
    {
      CITY_CODE: "GRSKG",
      CITY_NAME: "THESSALONIKI",
      DPA_PORT_CODE: "SKG",
    },
    {
      CITY_CODE: "GRSYS",
      CITY_NAME: "STYLIS",
      DPA_PORT_CODE: "SYS",
    },
    {
      CITY_CODE: "GRTHR",
      CITY_NAME: "THERMI",
      DPA_PORT_CODE: "THM",
    },
    {
      CITY_CODE: "GRTSO",
      CITY_NAME: "THASOS",
      DPA_PORT_CODE: "TSO",
    },
    {
      CITY_CODE: "GRVOL",
      CITY_NAME: "VOLOS",
      DPA_PORT_CODE: "VOL",
    },
    {
      CITY_CODE: "GRVTH",
      CITY_NAME: "PORT VATHY",
      DPA_PORT_CODE: "VTH",
    },
    {
      CITY_CODE: "GRYER",
      CITY_NAME: "YERAKINI",
      DPA_PORT_CODE: "YER",
    },
    {
      CITY_CODE: "GRZTH",
      CITY_NAME: "ZAKINTHOS IS",
      DPA_PORT_CODE: "ZTH",
    },
    {
      CITY_CODE: "GTGUA",
      CITY_NAME: "GUATEMALA CITY",
      DPA_PORT_CODE: "GUA",
    },
    {
      CITY_CODE: "GTPBR",
      CITY_NAME: "PUERTO BARRIOS",
      DPA_PORT_CODE: "PBR",
    },
    {
      CITY_CODE: "GTPRQ",
      CITY_NAME: "PUERTO QUETZAL",
      DPA_PORT_CODE: "PRQ",
    },
    {
      CITY_CODE: "GTSNJ",
      CITY_NAME: "SAN JOSE",
      DPA_PORT_CODE: "SNJ",
    },
    {
      CITY_CODE: "GTSTC",
      CITY_NAME: "SANTO TOMAS DE CASTILLA",
      DPA_PORT_CODE: "STC",
    },
    {
      CITY_CODE: "GUAPR",
      CITY_NAME: "APRA",
      DPA_PORT_CODE: "APR",
    },
    {
      CITY_CODE: "GUGUM",
      CITY_NAME: "GUAM",
      DPA_PORT_CODE: "GUM",
    },
    {
      CITY_CODE: "GWBOL",
      CITY_NAME: "BOLAMA",
      DPA_PORT_CODE: "BOL",
    },
    {
      CITY_CODE: "GWOXB",
      CITY_NAME: "BISSAU",
      DPA_PORT_CODE: "OXB",
    },
    {
      CITY_CODE: "GYGEO",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GEO",
    },
    {
      CITY_CODE: "HKHKG",
      CITY_NAME: "HONG KONG",
      DPA_PORT_CODE: "HKG",
    },
    {
      CITY_CODE: "HKKWN",
      CITY_NAME: "KOWLOON",
      DPA_PORT_CODE: "KWN",
    },
    {
      CITY_CODE: "HNAMP",
      CITY_NAME: "AMAPALA",
      DPA_PORT_CODE: "AMP",
    },
    {
      CITY_CODE: "HNLCE",
      CITY_NAME: "LA CEIBA",
      DPA_PORT_CODE: "LCE",
    },
    {
      CITY_CODE: "HNPCR",
      CITY_NAME: "PUERTO CORTES",
      DPA_PORT_CODE: "PCR",
    },
    {
      CITY_CODE: "HNSAP",
      CITY_NAME: "SAN PEDRO SULA",
      DPA_PORT_CODE: "SLA",
    },
    {
      CITY_CODE: "HNSLO",
      CITY_NAME: "SAN LORENZO",
      DPA_PORT_CODE: "SDV",
    },
    {
      CITY_CODE: "HNTEA",
      CITY_NAME: "TELA",
      DPA_PORT_CODE: "TEA",
    },
    {
      CITY_CODE: "HNTGU",
      CITY_NAME: "TEGUCIGALPA",
      DPA_PORT_CODE: "TGU",
    },
    {
      CITY_CODE: "HRDBV",
      CITY_NAME: "DUBROVNIK",
      DPA_PORT_CODE: "DBV",
    },
    {
      CITY_CODE: "HRPLE",
      CITY_NAME: "PLOCE",
      DPA_PORT_CODE: "PLE",
    },
    {
      CITY_CODE: "HRRJK",
      CITY_NAME: "RIJEKA BAKAR",
      DPA_PORT_CODE: "RJK",
    },
    {
      CITY_CODE: "HRSPU",
      CITY_NAME: "SPLIT",
      DPA_PORT_CODE: "SPU",
    },
    {
      CITY_CODE: "HRUMG",
      CITY_NAME: "UMG",
      DPA_PORT_CODE: "UMG",
    },
    {
      CITY_CODE: "HRZAD",
      CITY_NAME: "ZADAR",
      DPA_PORT_CODE: "ZAD",
    },
    {
      CITY_CODE: "HTCAP",
      CITY_NAME: "CAP HAITIEN",
      DPA_PORT_CODE: "CAP",
    },
    {
      CITY_CODE: "HTGVS",
      CITY_NAME: "GONAIVES",
      DPA_PORT_CODE: "GVS",
    },
    {
      CITY_CODE: "HTJEE",
      CITY_NAME: "JEREMIE",
      DPA_PORT_CODE: "JEE",
    },
    {
      CITY_CODE: "HTLFF",
      CITY_NAME: "LAFFITEAU",
      DPA_PORT_CODE: "LFF",
    },
    {
      CITY_CODE: "HTPAP",
      CITY_NAME: "PORT AU PRINCE",
      DPA_PORT_CODE: "PAP",
    },
    {
      CITY_CODE: "HTSMC",
      CITY_NAME: "ST MARC",
      DPA_PORT_CODE: "SMC",
    },
    {
      CITY_CODE: "HUBAA",
      CITY_NAME: "BAJA",
      DPA_PORT_CODE: "BAA",
    },
    {
      CITY_CODE: "HUBUD",
      CITY_NAME: "BUDAPEST",
      DPA_PORT_CODE: "BUD",
    },
    {
      CITY_CODE: "HUDEB",
      CITY_NAME: "DEBRECEN",
      DPA_PORT_CODE: "DEB",
    },
    {
      CITY_CODE: "HUEST",
      CITY_NAME: "ESZTERGOM",
      DPA_PORT_CODE: "EST",
    },
    {
      CITY_CODE: "HUFEZ",
      CITY_NAME: "FERTRSZENTMIKLOS",
      DPA_PORT_CODE: "FEK",
    },
    {
      CITY_CODE: "HUGYL",
      CITY_NAME: "GYAL",
      DPA_PORT_CODE: "GYL",
    },
    {
      CITY_CODE: "HUGYO",
      CITY_NAME: "GYOR",
      DPA_PORT_CODE: "GYO",
    },
    {
      CITY_CODE: "HUHAB",
      CITY_NAME: "HALASZTELEK",
      DPA_PORT_CODE: "HZT",
    },
    {
      CITY_CODE: "HUKIT",
      CITY_NAME: "KISTARCSA",
      DPA_PORT_CODE: "WFF",
    },
    {
      CITY_CODE: "HUKOM",
      CITY_NAME: "KOMAROM",
      DPA_PORT_CODE: "KOM",
    },
    {
      CITY_CODE: "HUMCQ",
      CITY_NAME: "MISKOLC",
      DPA_PORT_CODE: "MCQ",
    },
    {
      CITY_CODE: "HUMNM",
      CITY_NAME: "MOSONMAGYAROVAR",
      DPA_PORT_CODE: "MNM",
    },
    {
      CITY_CODE: "HUNGH",
      CITY_NAME: "NYIREGYHAZA",
      DPA_PORT_CODE: "NGY",
    },
    {
      CITY_CODE: "HUPTY",
      CITY_NAME: "PATY",
      DPA_PORT_CODE: "PTT",
    },
    {
      CITY_CODE: "HUSZB",
      CITY_NAME: "SZAZHALOMBATTA",
      DPA_PORT_CODE: "WFG",
    },
    {
      CITY_CODE: "HUSZL",
      CITY_NAME: "SZOLNOK",
      DPA_PORT_CODE: "ZSL",
    },
    {
      CITY_CODE: "HUTAA",
      CITY_NAME: "TATABANYA",
      DPA_PORT_CODE: "TAA",
    },
    {
      CITY_CODE: "IDANR",
      CITY_NAME: "ANYER KIDUL",
      DPA_PORT_CODE: "AYK",
    },
    {
      CITY_CODE: "IDBDJ",
      CITY_NAME: "BANJARMASIN",
      DPA_PORT_CODE: "BDJ",
    },
    {
      CITY_CODE: "IDBIK",
      CITY_NAME: "BIAK",
      DPA_PORT_CODE: "BIK",
    },
    {
      CITY_CODE: "IDBIT",
      CITY_NAME: "BITUNG",
      DPA_PORT_CODE: "BIJ",
    },
    {
      CITY_CODE: "IDBJU",
      CITY_NAME: "BANJUWANGI",
      DPA_PORT_CODE: "BJU",
    },
    {
      CITY_CODE: "IDBKU",
      CITY_NAME: "BENGKULU",
      DPA_PORT_CODE: "BKU",
    },
    {
      CITY_CODE: "IDBLI",
      CITY_NAME: "BLINJU",
      DPA_PORT_CODE: "BLI",
    },
    {
      CITY_CODE: "IDBLV",
      CITY_NAME: "BELILING",
      DPA_PORT_CODE: "BLV",
    },
    {
      CITY_CODE: "IDBLW",
      CITY_NAME: "BELAWAN",
      DPA_PORT_CODE: "BLW",
    },
    {
      CITY_CODE: "IDBNG",
      CITY_NAME: "BONGGALA",
      DPA_PORT_CODE: "BNG",
    },
    {
      CITY_CODE: "IDBOA",
      CITY_NAME: "BENOA",
      DPA_PORT_CODE: "BOA",
    },
    {
      CITY_CODE: "IDBPN",
      CITY_NAME: "BALIKPAPAN",
      DPA_PORT_CODE: "BPN",
    },
    {
      CITY_CODE: "IDBTH",
      CITY_NAME: "BATU BESAR",
      DPA_PORT_CODE: "BTH",
    },
    {
      CITY_CODE: "IDBUR",
      CITY_NAME: "BATU AMPAL",
      DPA_PORT_CODE: "BCR",
    },
    {
      CITY_CODE: "IDBXT",
      CITY_NAME: "BONTHAN BAY",
      DPA_PORT_CODE: "BXT",
    },
    {
      CITY_CODE: "IDCBN",
      CITY_NAME: "CIREBON",
      DPA_PORT_CODE: "CBN",
    },
    {
      CITY_CODE: "IDCIG",
      CITY_NAME: "CIGADING",
      DPA_PORT_CODE: "CJG",
    },
    {
      CITY_CODE: "IDCIN",
      CITY_NAME: "CINTA",
      DPA_PORT_CODE: "CIN",
    },
    {
      CITY_CODE: "IDCIW",
      CITY_NAME: "CIWANDAN",
      DPA_PORT_CODE: "CJW",
    },
    {
      CITY_CODE: "IDCLM",
      CITY_NAME: "COLOMADU",
      DPA_PORT_CODE: "CXZ",
    },
    {
      CITY_CODE: "IDCSA",
      CITY_NAME: "CAPE SAGO",
      DPA_PORT_CODE: "CSA",
    },
    {
      CITY_CODE: "IDCTK",
      CITY_NAME: "CITANGKIL",
      DPA_PORT_CODE: "CXK",
    },
    {
      CITY_CODE: "IDCXP",
      CITY_NAME: "CILACAP",
      DPA_PORT_CODE: "CXP",
    },
    {
      CITY_CODE: "IDDAS",
      CITY_NAME: "DABO",
      DPA_PORT_CODE: "DAS",
    },
    {
      CITY_CODE: "IDDJA",
      CITY_NAME: "DJANKAR",
      DPA_PORT_CODE: "DJA",
    },
    {
      CITY_CODE: "IDDJB",
      CITY_NAME: "JAMBI",
      DPA_PORT_CODE: "DJB",
    },
    {
      CITY_CODE: "IDDJJ",
      CITY_NAME: "JAYAPURA",
      DPA_PORT_CODE: "DJJ",
    },
    {
      CITY_CODE: "IDDUM",
      CITY_NAME: "DUMAI",
      DPA_PORT_CODE: "DUM",
    },
    {
      CITY_CODE: "IDFKQ",
      CITY_NAME: "FAK FAK",
      DPA_PORT_CODE: "FKQ",
    },
    {
      CITY_CODE: "IDGTO",
      CITY_NAME: "GORONTALO",
      DPA_PORT_CODE: "GTO",
    },
    {
      CITY_CODE: "IDJKT",
      CITY_NAME: "JAKARTA",
      DPA_PORT_CODE: "JKT",
    },
    {
      CITY_CODE: "IDKAH",
      CITY_NAME: "KAHAYAN BAY",
      DPA_PORT_CODE: "KAH",
    },
    {
      CITY_CODE: "IDKAM",
      CITY_NAME: "KAMBUNONG",
      DPA_PORT_CODE: "KAM",
    },
    {
      CITY_CODE: "IDKDI",
      CITY_NAME: "KENDARI",
      DPA_PORT_CODE: "KDI",
    },
    {
      CITY_CODE: "IDKID",
      CITY_NAME: "KIDJANG",
      DPA_PORT_CODE: "KID",
    },
    {
      CITY_CODE: "IDKMA",
      CITY_NAME: "KUALA MANDAH",
      DPA_PORT_CODE: "KMA",
    },
    {
      CITY_CODE: "IDKOE",
      CITY_NAME: "KUPANG",
      DPA_PORT_CODE: "KOE",
    },
    {
      CITY_CODE: "IDKTJ",
      CITY_NAME: "KUALATANJUNG",
      DPA_PORT_CODE: "KTJ",
    },
    {
      CITY_CODE: "IDLSW",
      CITY_NAME: "LHOKSUMAWE",
      DPA_PORT_CODE: "LSW",
    },
    {
      CITY_CODE: "IDMAK",
      CITY_NAME: "MAKASSAR",
      DPA_PORT_CODE: "MAK",
    },
    {
      CITY_CODE: "IDMES",
      CITY_NAME: "MEDAN",
      DPA_PORT_CODE: "MES",
    },
    {
      CITY_CODE: "IDMJU",
      CITY_NAME: "MAMUJU",
      DPA_PORT_CODE: "MJU",
    },
    {
      CITY_CODE: "IDMKQ",
      CITY_NAME: "MERAUKE",
      DPA_PORT_CODE: "MKQ",
    },
    {
      CITY_CODE: "IDMKW",
      CITY_NAME: "MANOKWARI",
      DPA_PORT_CODE: "MKW",
    },
    {
      CITY_CODE: "IDMLI",
      CITY_NAME: "MALILI",
      DPA_PORT_CODE: "MLI",
    },
    {
      CITY_CODE: "IDMOF",
      CITY_NAME: "MAUMERE",
      DPA_PORT_CODE: "MOF",
    },
    {
      CITY_CODE: "IDMRK",
      CITY_NAME: "MERAK",
      DPA_PORT_CODE: "MRK",
    },
    {
      CITY_CODE: "IDMTU",
      CITY_NAME: "MUTURI",
      DPA_PORT_CODE: "MTU",
    },
    {
      CITY_CODE: "IDMUD",
      CITY_NAME: "MUARA DJAWA",
      DPA_PORT_CODE: "MUD",
    },
    {
      CITY_CODE: "IDNNX",
      CITY_NAME: "NUNUKAN",
      DPA_PORT_CODE: "NNX",
    },
    {
      CITY_CODE: "IDOBI",
      CITY_NAME: "OBI ISLAND",
      DPA_PORT_CODE: "OBI",
    },
    {
      CITY_CODE: "IDOLO",
      CITY_NAME: "OLEE LHEUE",
      DPA_PORT_CODE: "OLO",
    },
    {
      CITY_CODE: "IDPAS",
      CITY_NAME: "PASIR PANJANG",
      DPA_PORT_CODE: "PAS",
    },
    {
      CITY_CODE: "IDPAZ",
      CITY_NAME: "PASURUAN",
      DPA_PORT_CODE: "PAZ",
    },
    {
      CITY_CODE: "IDPBJ",
      CITY_NAME: "PULO BUNJU",
      DPA_PORT_CODE: "PBJ",
    },
    {
      CITY_CODE: "IDPDG",
      CITY_NAME: "PADANG",
      DPA_PORT_CODE: "ANG",
    },
    {
      CITY_CODE: "IDPDJ",
      CITY_NAME: "TANDJANG",
      DPA_PORT_CODE: "PDJ",
    },
    {
      CITY_CODE: "IDPER",
      CITY_NAME: "PERAWANG",
      DPA_PORT_CODE: "PER",
    },
    {
      CITY_CODE: "IDPEX",
      CITY_NAME: "PEKALONGAN",
      DPA_PORT_CODE: "PEX",
    },
    {
      CITY_CODE: "IDPGK",
      CITY_NAME: "PANGKALPINANG",
      DPA_PORT_CODE: "PGK",
    },
    {
      CITY_CODE: "IDPGX",
      CITY_NAME: "PANGKAL BALAM",
      DPA_PORT_CODE: "PGX",
    },
    {
      CITY_CODE: "IDPJM",
      CITY_NAME: "PANDJUNG MANI",
      DPA_PORT_CODE: "PJM",
    },
    {
      CITY_CODE: "IDPKR",
      CITY_NAME: "PANGKALAN BRANDAN",
      DPA_PORT_CODE: "PKR",
    },
    {
      CITY_CODE: "IDPLM",
      CITY_NAME: "PALEMBANG",
      DPA_PORT_CODE: "PLM",
    },
    {
      CITY_CODE: "IDPMG",
      CITY_NAME: "PORT MENENG",
      DPA_PORT_CODE: "PMG",
    },
    {
      CITY_CODE: "IDPNJ",
      CITY_NAME: "PANJANG",
      DPA_PORT_CODE: "PNJ",
    },
    {
      CITY_CODE: "IDPNK",
      CITY_NAME: "PONTIANAK KALIMANTAN",
      DPA_PORT_CODE: "PNK",
    },
    {
      CITY_CODE: "IDPPO",
      CITY_NAME: "PALAPO",
      DPA_PORT_CODE: "PPO",
    },
    {
      CITY_CODE: "IDPSJ",
      CITY_NAME: "POSO",
      DPA_PORT_CODE: "PSJ",
    },
    {
      CITY_CODE: "IDPSS",
      CITY_NAME: "PULAU SAMBU",
      DPA_PORT_CODE: "PSS",
    },
    {
      CITY_CODE: "IDPSU",
      CITY_NAME: "PANGKALAN SUSU",
      DPA_PORT_CODE: "PSU",
    },
    {
      CITY_CODE: "IDPWG",
      CITY_NAME: "PERAWANG",
      DPA_PORT_CODE: "PWG",
    },
    {
      CITY_CODE: "IDSEQ",
      CITY_NAME: "SUNGAI PAKNING",
      DPA_PORT_CODE: "SEQ",
    },
    {
      CITY_CODE: "IDSKW",
      CITY_NAME: "SINGKAWANG",
      DPA_PORT_CODE: "SKW",
    },
    {
      CITY_CODE: "IDSRG",
      CITY_NAME: "SEMARANG",
      DPA_PORT_CODE: "SRG",
    },
    {
      CITY_CODE: "IDSRI",
      CITY_NAME: "SAMARINDA",
      DPA_PORT_CODE: "SRI",
    },
    {
      CITY_CODE: "IDSSO",
      CITY_NAME: "SUSU",
      DPA_PORT_CODE: "SSO",
    },
    {
      CITY_CODE: "IDSUB",
      CITY_NAME: "SURABAYA (TANJUNG PERAK)",
      DPA_PORT_CODE: "SUB",
    },
    {
      CITY_CODE: "IDSUP",
      CITY_NAME: "SUMENEP",
      DPA_PORT_CODE: "SUP",
    },
    {
      CITY_CODE: "IDSUQ",
      CITY_NAME: "SUNGAI GUNTUNG",
      DPA_PORT_CODE: "SUQ",
    },
    {
      CITY_CODE: "IDSWQ",
      CITY_NAME: "SUMBAWA",
      DPA_PORT_CODE: "SWQ",
    },
    {
      CITY_CODE: "IDTAX",
      CITY_NAME: "TALIABU",
      DPA_PORT_CODE: "TAX",
    },
    {
      CITY_CODE: "IDTHB",
      CITY_NAME: "TJERIBON",
      DPA_PORT_CODE: "THB",
    },
    {
      CITY_CODE: "IDTJB",
      CITY_NAME: "TANJUNG BALAI",
      DPA_PORT_CODE: "TJB",
    },
    {
      CITY_CODE: "IDTJQ",
      CITY_NAME: "TANJUNG PANDAN",
      DPA_PORT_CODE: "TJQ",
    },
    {
      CITY_CODE: "IDTKG",
      CITY_NAME: "TELUK BETUNG",
      DPA_PORT_CODE: "TKG",
    },
    {
      CITY_CODE: "IDTLI",
      CITY_NAME: "TOLITOLI",
      DPA_PORT_CODE: "TLI",
    },
    {
      CITY_CODE: "IDTMO",
      CITY_NAME: "TELOK MELANO",
      DPA_PORT_CODE: "TMO",
    },
    {
      CITY_CODE: "IDTNJ",
      CITY_NAME: "TANJUNG PINANG",
      DPA_PORT_CODE: "TNJ",
    },
    {
      CITY_CODE: "IDTPP",
      CITY_NAME: "TANJUNG PRIOK",
      DPA_PORT_CODE: "TPP",
    },
    {
      CITY_CODE: "IDTTE",
      CITY_NAME: "TERNATE",
      DPA_PORT_CODE: "TTE",
    },
    {
      CITY_CODE: "IDUPG",
      CITY_NAME: "UJUNG PANDANG",
      DPA_PORT_CODE: "UPG",
    },
    {
      CITY_CODE: "IDWGP",
      CITY_NAME: "WAINGAPU",
      DPA_PORT_CODE: "WGP",
    },
    {
      CITY_CODE: "IEARK",
      CITY_NAME: "ARKLOW",
      DPA_PORT_CODE: "ARK",
    },
    {
      CITY_CODE: "IEBLK",
      CITY_NAME: "BALLYKISSANE",
      DPA_PORT_CODE: "BLK",
    },
    {
      CITY_CODE: "IEBTM",
      CITY_NAME: "BALTIMORE",
      DPA_PORT_CODE: "BTR",
    },
    {
      CITY_CODE: "IECFN",
      CITY_NAME: "DONEGAL",
      DPA_PORT_CODE: "CFN",
    },
    {
      CITY_CODE: "IECOB",
      CITY_NAME: "COBH",
      DPA_PORT_CODE: "COB",
    },
    {
      CITY_CODE: "IEDDK",
      CITY_NAME: "DUNDALK",
      DPA_PORT_CODE: "DDK",
    },
    {
      CITY_CODE: "IEDGV",
      CITY_NAME: "DUNGARVAN",
      DPA_PORT_CODE: "DGV",
    },
    {
      CITY_CODE: "IEDNM",
      CITY_NAME: "DUNMORE EAST",
      DPA_PORT_CODE: "DNM",
    },
    {
      CITY_CODE: "IEDUB",
      CITY_NAME: "DUBLIN",
      DPA_PORT_CODE: "DUB",
    },
    {
      CITY_CODE: "IEFAS",
      CITY_NAME: "FASTNET",
      DPA_PORT_CODE: "FAS",
    },
    {
      CITY_CODE: "IEFOV",
      CITY_NAME: "FOYNES",
      DPA_PORT_CODE: "FOV",
    },
    {
      CITY_CODE: "IEGRY",
      CITY_NAME: "GREYSTONES",
      DPA_PORT_CODE: "GRY",
    },
    {
      CITY_CODE: "IEHOW",
      CITY_NAME: "HOWTH",
      DPA_PORT_CODE: "HOW",
    },
    {
      CITY_CODE: "IEIIT",
      CITY_NAME: "INISHTRAHULL",
      DPA_PORT_CODE: "IIT",
    },
    {
      CITY_CODE: "IEKBS",
      CITY_NAME: "KILLYBEGS",
      DPA_PORT_CODE: "KBS",
    },
    {
      CITY_CODE: "IEKLN",
      CITY_NAME: "KINSALE",
      DPA_PORT_CODE: "KLN",
    },
    {
      CITY_CODE: "IEKLR",
      CITY_NAME: "KILRUSH",
      DPA_PORT_CODE: "KLR",
    },
    {
      CITY_CODE: "IELMK",
      CITY_NAME: "LIMERICK",
      DPA_PORT_CODE: "LMK",
    },
    {
      CITY_CODE: "IEORK",
      CITY_NAME: "CORK",
      DPA_PORT_CODE: "ORK",
    },
    {
      CITY_CODE: "IERAT",
      CITY_NAME: "RATHMULLEN",
      DPA_PORT_CODE: "RAT",
    },
    {
      CITY_CODE: "IESNN",
      CITY_NAME: "SHANNON",
      DPA_PORT_CODE: "NAE",
    },
    {
      CITY_CODE: "IESXL",
      CITY_NAME: "SLIGO",
      DPA_PORT_CODE: "SXL",
    },
    {
      CITY_CODE: "IETPY",
      CITY_NAME: "TIPPERARY",
      DPA_PORT_CODE: "TPY",
    },
    {
      CITY_CODE: "IEWAT",
      CITY_NAME: "WATERFORD",
      DPA_PORT_CODE: "WAF",
    },
    {
      CITY_CODE: "IEWES",
      CITY_NAME: "WESTPORT",
      DPA_PORT_CODE: "WES",
    },
    {
      CITY_CODE: "IEWEX",
      CITY_NAME: "WEXFORD",
      DPA_PORT_CODE: "WEX",
    },
    {
      CITY_CODE: "IEWIC",
      CITY_NAME: "WICKLOW",
      DPA_PORT_CODE: "WIC",
    },
    {
      CITY_CODE: "ILASH",
      CITY_NAME: "ASHDOD",
      DPA_PORT_CODE: "ASH",
    },
    {
      CITY_CODE: "ILETH",
      CITY_NAME: "ELAT",
      DPA_PORT_CODE: "ETH",
    },
    {
      CITY_CODE: "ILHFA",
      CITY_NAME: "HAIFA",
      DPA_PORT_CODE: "HFA",
    },
    {
      CITY_CODE: "ILJFA",
      CITY_NAME: "JAFFA",
      DPA_PORT_CODE: "JFA",
    },
    {
      CITY_CODE: "INAGR",
      CITY_NAME: "AGRA",
      DPA_PORT_CODE: "AGR",
    },
    {
      CITY_CODE: "INAKV",
      CITY_NAME: "ANKLESHWAR",
      DPA_PORT_CODE: "AKW",
    },
    {
      CITY_CODE: "INALF",
      CITY_NAME: "ALLEPPEY",
      DPA_PORT_CODE: "ALF",
    },
    {
      CITY_CODE: "INAMD",
      CITY_NAME: "AHMEDABAD",
      DPA_PORT_CODE: "AMD",
    },
    {
      CITY_CODE: "INAMG",
      CITY_NAME: "AMINGAON (GAUHATI)",
      DPA_PORT_CODE: "AMG",
    },
    {
      CITY_CODE: "INBDQ",
      CITY_NAME: "VADODARA",
      DPA_PORT_CODE: "BDQ",
    },
    {
      CITY_CODE: "INBHA",
      CITY_NAME: "BHARATPUR",
      DPA_PORT_CODE: "BHA",
    },
    {
      CITY_CODE: "INBHU",
      CITY_NAME: "BHAVNAGAR",
      DPA_PORT_CODE: "BHU",
    },
    {
      CITY_CODE: "INBLR",
      CITY_NAME: "BANGALORE",
      DPA_PORT_CODE: "BLR",
    },
    {
      CITY_CODE: "INBOM",
      CITY_NAME: "MUMBAI (EX BOMBAY)",
      DPA_PORT_CODE: "BOM",
    },
    {
      CITY_CODE: "INBRC",
      CITY_NAME: "BARODA",
      DPA_PORT_CODE: "BRC",
    },
    {
      CITY_CODE: "INBSL",
      CITY_NAME: "BHUSAWAL",
      DPA_PORT_CODE: "BSV",
    },
    {
      CITY_CODE: "INCCU",
      CITY_NAME: "CALCUTTA",
      DPA_PORT_CODE: "CCU",
    },
    {
      CITY_CODE: "INCLC",
      CITY_NAME: "CALICUT",
      DPA_PORT_CODE: "CLC",
    },
    {
      CITY_CODE: "INCOK",
      CITY_NAME: "COCHIN",
      DPA_PORT_CODE: "COK",
    },
    {
      CITY_CODE: "INCUM",
      CITY_NAME: "CUTCH MANDVI",
      DPA_PORT_CODE: "CUM",
    },
    {
      CITY_CODE: "INDAH",
      CITY_NAME: "DAHEJ",
      DPA_PORT_CODE: "DAH",
    },
    {
      CITY_CODE: "INDAM",
      CITY_NAME: "DAMAN",
      DPA_PORT_CODE: "DMA",
    },
    {
      CITY_CODE: "INDEL",
      CITY_NAME: "DELHI (New Delhi)",
      DPA_PORT_CODE: "DEL",
    },
    {
      CITY_CODE: "INDER",
      CITY_NAME: "DADRI ICD/NOIDA",
      DPA_PORT_CODE: "DFJ",
    },
    {
      CITY_CODE: "INDHA",
      CITY_NAME: "DHANNAD INDORE",
      DPA_PORT_CODE: "DDN",
    },
    {
      CITY_CODE: "INDHP",
      CITY_NAME: "DABHOL PORT",
      DPA_PORT_CODE: "DHP",
    },
    {
      CITY_CODE: "INDRI",
      CITY_NAME: "DADRI",
      DPA_PORT_CODE: "DRI",
    },
    {
      CITY_CODE: "INFBD",
      CITY_NAME: "FARIDABAD",
      DPA_PORT_CODE: "FBD",
    },
    {
      CITY_CODE: "INGAU",
      CITY_NAME: "GAUHATI",
      DPA_PORT_CODE: "GAU",
    },
    {
      CITY_CODE: "INGGN",
      CITY_NAME: "GURGOAN",
      DPA_PORT_CODE: "GGN",
    },
    {
      CITY_CODE: "INGHR",
      CITY_NAME: "GARHI HARSARU",
      DPA_PORT_CODE: "GHR",
    },
    {
      CITY_CODE: "INGNO",
      CITY_NAME: "GREATER NOIDA",
      DPA_PORT_CODE: "GBU",
    },
    {
      CITY_CODE: "INGOI",
      CITY_NAME: "GOA",
      DPA_PORT_CODE: "GOX",
    },
    {
      CITY_CODE: "INGTI",
      CITY_NAME: "GATEWAY TERMINAL OF INDIA-NHS",
      DPA_PORT_CODE: "GTI",
    },
    {
      CITY_CODE: "INHAL",
      CITY_NAME: "HALDIA",
      DPA_PORT_CODE: "HFD",
    },
    {
      CITY_CODE: "INHYD",
      CITY_NAME: "HYDERABAD",
      DPA_PORT_CODE: "HYD",
    },
    {
      CITY_CODE: "INHZA",
      CITY_NAME: "HAZIRA PORT SURAT",
      DPA_PORT_CODE: "HZP",
    },
    {
      CITY_CODE: "INHZR",
      CITY_NAME: "HAZIRA",
      DPA_PORT_CODE: "HZA",
    },
    {
      CITY_CODE: "INIXE",
      CITY_NAME: "MANGALORE",
      DPA_PORT_CODE: "IXE",
    },
    {
      CITY_CODE: "INIXY",
      CITY_NAME: "KANDLA",
      DPA_PORT_CODE: "IXY",
    },
    {
      CITY_CODE: "INJAI",
      CITY_NAME: "JAIPUR",
      DPA_PORT_CODE: "JAI",
    },
    {
      CITY_CODE: "INJGA",
      CITY_NAME: "JAMNAGAR",
      DPA_PORT_CODE: "JGA",
    },
    {
      CITY_CODE: "INJUC",
      CITY_NAME: "JALANDHAR",
      DPA_PORT_CODE: "JUC",
    },
    {
      CITY_CODE: "INKAK",
      CITY_NAME: "KAKINADA",
      DPA_PORT_CODE: "KAK",
    },
    {
      CITY_CODE: "INKAT",
      CITY_NAME: "KATTUPPALI",
      DPA_PORT_CODE: "KCD",
    },
    {
      CITY_CODE: "INKBT",
      CITY_NAME: "KHAMBHAT",
      DPA_PORT_CODE: "KBT",
    },
    {
      CITY_CODE: "INKHD",
      CITY_NAME: "KHEDA",
      DPA_PORT_CODE: "KMD",
    },
    {
      CITY_CODE: "INKNU",
      CITY_NAME: "KANPUR",
      DPA_PORT_CODE: "KNU",
    },
    {
      CITY_CODE: "INKOD",
      CITY_NAME: "KODINAR",
      DPA_PORT_CODE: "KOD",
    },
    {
      CITY_CODE: "INKOI",
      CITY_NAME: "KOILTHOLTAM",
      DPA_PORT_CODE: "KOI",
    },
    {
      CITY_CODE: "INKRI",
      CITY_NAME: "KRISHNAPATAM",
      DPA_PORT_CODE: "KRI",
    },
    {
      CITY_CODE: "INKRW",
      CITY_NAME: "KARWAR",
      DPA_PORT_CODE: "KRW",
    },
    {
      CITY_CODE: "INKSG",
      CITY_NAME: "KASARGOD",
      DPA_PORT_CODE: "KSG",
    },
    {
      CITY_CODE: "INKTP",
      CITY_NAME: "KATTUPALLI PORT",
      DPA_PORT_CODE: "KTS",
    },
    {
      CITY_CODE: "INLDA",
      CITY_NAME: "MALDA",
      DPA_PORT_CODE: "LDA",
    },
    {
      CITY_CODE: "INLON",
      CITY_NAME: "LONI",
      DPA_PORT_CODE: "LNI",
    },
    {
      CITY_CODE: "INLUH",
      CITY_NAME: "LUDHIANA",
      DPA_PORT_CODE: "LUH",
    },
    {
      CITY_CODE: "INMAA",
      CITY_NAME: "MADRAS",
      DPA_PORT_CODE: "MAA",
    },
    {
      CITY_CODE: "INMAL",
      CITY_NAME: "MALPE",
      DPA_PORT_CODE: "MPE",
    },
    {
      CITY_CODE: "INMAP",
      CITY_NAME: "MASULIPATNAM",
      DPA_PORT_CODE: "MAP",
    },
    {
      CITY_CODE: "INMDD",
      CITY_NAME: "MANDIDEEP",
      DPA_PORT_CODE: "MDD",
    },
    {
      CITY_CODE: "INMOR",
      CITY_NAME: "MORADABAD",
      DPA_PORT_CODE: "MOR",
    },
    {
      CITY_CODE: "INMPR",
      CITY_NAME: "MALANPUR",
      DPA_PORT_CODE: "MPR",
    },
    {
      CITY_CODE: "INMRA",
      CITY_NAME: "MORA",
      DPA_PORT_CODE: "MKR",
    },
    {
      CITY_CODE: "INMRM",
      CITY_NAME: "MORMUGAO",
      DPA_PORT_CODE: "MRM",
    },
    {
      CITY_CODE: "INMUN",
      CITY_NAME: "MUNDRA - INDIA",
      DPA_PORT_CODE: "MUN",
    },
    {
      CITY_CODE: "INMUR",
      CITY_NAME: "MURAD",
      DPA_PORT_CODE: "MRD",
    },
    {
      CITY_CODE: "INNAG",
      CITY_NAME: "NAGPUR",
      DPA_PORT_CODE: "NAG",
    },
    {
      CITY_CODE: "INNAV",
      CITY_NAME: "NAVALAKHI",
      DPA_PORT_CODE: "NAV",
    },
    {
      CITY_CODE: "INNDA",
      CITY_NAME: "NOIDA",
      DPA_PORT_CODE: "NDA",
    },
    {
      CITY_CODE: "INNEE",
      CITY_NAME: "NEENDHAKARA",
      DPA_PORT_CODE: "NEE",
    },
    {
      CITY_CODE: "INNEL",
      CITY_NAME: "NELLORE",
      DPA_PORT_CODE: "NEL",
    },
    {
      CITY_CODE: "INNML",
      CITY_NAME: "NEW MANGALORE",
      DPA_PORT_CODE: "NML",
    },
    {
      CITY_CODE: "INNPT",
      CITY_NAME: "NAGAPPATTINAM",
      DPA_PORT_CODE: "NGP",
    },
    {
      CITY_CODE: "INNSA",
      CITY_NAME: "NHAVA SHEVA",
      DPA_PORT_CODE: "NHS",
    },
    {
      CITY_CODE: "INOKH",
      CITY_NAME: "OKHA",
      DPA_PORT_CODE: "OKH",
    },
    {
      CITY_CODE: "INPAI",
      CITY_NAME: "PALI",
      DPA_PORT_CODE: "PII",
    },
    {
      CITY_CODE: "INPAP",
      CITY_NAME: "PANIPAT",
      DPA_PORT_CODE: "PAJ",
    },
    {
      CITY_CODE: "INPAT",
      CITY_NAME: "PATNA",
      DPA_PORT_CODE: "PNX",
    },
    {
      CITY_CODE: "INPAV",
      CITY_NAME: "PIPAVAV",
      DPA_PORT_CODE: "PAV",
    },
    {
      CITY_CODE: "INPBD",
      CITY_NAME: "PORBANDAR",
      DPA_PORT_CODE: "PBD",
    },
    {
      CITY_CODE: "INPID",
      CITY_NAME: "PORT INDAI",
      DPA_PORT_CODE: "PID",
    },
    {
      CITY_CODE: "INPIR",
      CITY_NAME: "PITHAMPUR",
      DPA_PORT_CODE: "PIP",
    },
    {
      CITY_CODE: "INPMP",
      CITY_NAME: "PIMPRI",
      DPA_PORT_CODE: "IRI",
    },
    {
      CITY_CODE: "INPNM",
      CITY_NAME: "PANAMBUR",
      DPA_PORT_CODE: "PNM",
    },
    {
      CITY_CODE: "INPNY",
      CITY_NAME: "PONDICHERRY",
      DPA_PORT_CODE: "PNY",
    },
    {
      CITY_CODE: "INPPG",
      CITY_NAME: "PATPARGANJ",
      DPA_PORT_CODE: "PPG",
    },
    {
      CITY_CODE: "INPRT",
      CITY_NAME: "PARADIP",
      DPA_PORT_CODE: "PRT",
    },
    {
      CITY_CODE: "INPTL",
      CITY_NAME: "PATLI",
      DPA_PORT_CODE: "PJL",
    },
    {
      CITY_CODE: "INPUR",
      CITY_NAME: "PURI",
      DPA_PORT_CODE: "PUR",
    },
    {
      CITY_CODE: "INREA",
      CITY_NAME: "REWARI",
      DPA_PORT_CODE: "REA",
    },
    {
      CITY_CODE: "INREW",
      CITY_NAME: "REWA",
      DPA_PORT_CODE: "RAW",
    },
    {
      CITY_CODE: "INRPR",
      CITY_NAME: "RAIPUR",
      DPA_PORT_CODE: "RPR",
    },
    {
      CITY_CODE: "INRRI",
      CITY_NAME: "RAIRI",
      DPA_PORT_CODE: "RRI",
    },
    {
      CITY_CODE: "INSAA",
      CITY_NAME: "SANAND",
      DPA_PORT_CODE: "SXA",
    },
    {
      CITY_CODE: "INSIK",
      CITY_NAME: "SIKA",
      DPA_PORT_CODE: "SIK",
    },
    {
      CITY_CODE: "INSTV",
      CITY_NAME: "SURAT",
      DPA_PORT_CODE: "STV",
    },
    {
      CITY_CODE: "INSWA",
      CITY_NAME: "SAHNEWAL",
      DPA_PORT_CODE: "HLC",
    },
    {
      CITY_CODE: "INTAD",
      CITY_NAME: "TADRIP",
      DPA_PORT_CODE: "TAD",
    },
    {
      CITY_CODE: "INTIV",
      CITY_NAME: "TIVIRI",
      DPA_PORT_CODE: "TIV",
    },
    {
      CITY_CODE: "INTKD",
      CITY_NAME: "TUGHLAKABAD",
      DPA_PORT_CODE: "TGD",
    },
    {
      CITY_CODE: "INTRP",
      CITY_NAME: "TARAPUR",
      DPA_PORT_CODE: "TCR",
    },
    {
      CITY_CODE: "INTUT",
      CITY_NAME: "TUTICORIN",
      DPA_PORT_CODE: "TUT",
    },
    {
      CITY_CODE: "INUMB",
      CITY_NAME: "UMBERGAON",
      DPA_PORT_CODE: "UMB",
    },
    {
      CITY_CODE: "INVAD",
      CITY_NAME: "VADINAR",
      DPA_PORT_CODE: "VAD",
    },
    {
      CITY_CODE: "INVAL",
      CITY_NAME: "VAPI",
      DPA_PORT_CODE: "VAL",
    },
    {
      CITY_CODE: "INVEP",
      CITY_NAME: "VEPPALODAI",
      DPA_PORT_CODE: "VEP",
    },
    {
      CITY_CODE: "INVPI",
      CITY_NAME: "VAPI",
      DPA_PORT_CODE: "VPI",
    },
    {
      CITY_CODE: "INVSI",
      CITY_NAME: "VANSI BORSI",
      DPA_PORT_CODE: "VNI",
    },
    {
      CITY_CODE: "INVTZ",
      CITY_NAME: "VISHAKHAPATNAM",
      DPA_PORT_CODE: "VTZ",
    },
    {
      CITY_CODE: "INVVA",
      CITY_NAME: "VERAVAL",
      DPA_PORT_CODE: "VVA",
    },
    {
      CITY_CODE: "INVYD",
      CITY_NAME: "VIJAYDURG",
      DPA_PORT_CODE: "VAY",
    },
    {
      CITY_CODE: "IODGA",
      CITY_NAME: "DIEGO GARCIA",
      DPA_PORT_CODE: "DGC",
    },
    {
      CITY_CODE: "IQALF",
      CITY_NAME: "ABU AL FULUS",
      DPA_PORT_CODE: "FLN",
    },
    {
      CITY_CODE: "IQBSR",
      CITY_NAME: "BASRA",
      DPA_PORT_CODE: "BSR",
    },
    {
      CITY_CODE: "IQICT",
      CITY_NAME: "IRAQ CONTAINER TERMINAL",
      DPA_PORT_CODE: "ICX",
    },
    {
      CITY_CODE: "IQIQS",
      CITY_NAME: "SOUTH PORT OF UMM QASR",
      DPA_PORT_CODE: "IQS",
    },
    {
      CITY_CODE: "IQKAZ",
      CITY_NAME: "KHOR AL ZUBAIR",
      DPA_PORT_CODE: "KAZ",
    },
    {
      CITY_CODE: "IQUQR",
      CITY_NAME: "UMM QASR",
      DPA_PORT_CODE: "UQR",
    },
    {
      CITY_CODE: "IQZUB",
      CITY_NAME: "AL ZUWAYR",
      DPA_PORT_CODE: "ZUB",
    },
    {
      CITY_CODE: "IRABD",
      CITY_NAME: "ABADAN",
      DPA_PORT_CODE: "ABN",
    },
    {
      CITY_CODE: "IRASA",
      CITY_NAME: "ASALOYEH",
      DPA_PORT_CODE: "ASL",
    },
    {
      CITY_CODE: "IRAZD",
      CITY_NAME: "YAZD",
      DPA_PORT_CODE: "AZD",
    },
    {
      CITY_CODE: "IRBAH",
      CITY_NAME: "BANDAR ASSALUYEH",
      DPA_PORT_CODE: "BCP",
    },
    {
      CITY_CODE: "IRBAZ",
      CITY_NAME: "BANDAR E ANZALI",
      DPA_PORT_CODE: "BAZ",
    },
    {
      CITY_CODE: "IRBIK",
      CITY_NAME: "IMAM KHOMEINI",
      DPA_PORT_CODE: "BXK",
    },
    {
      CITY_CODE: "IRBKM",
      CITY_NAME: "BANDAR KHOMEINI",
      DPA_PORT_CODE: "BKM",
    },
    {
      CITY_CODE: "IRBMR",
      CITY_NAME: "BANDAR MASHUR",
      DPA_PORT_CODE: "BMR",
    },
    {
      CITY_CODE: "IRBND",
      CITY_NAME: "BANDAR ABBAS",
      DPA_PORT_CODE: "BND",
    },
    {
      CITY_CODE: "IRBSR",
      CITY_NAME: "BANDAR SHAJID REJAIE",
      DPA_PORT_CODE: "IRB",
    },
    {
      CITY_CODE: "IRBUZ",
      CITY_NAME: "BUSHEHR",
      DPA_PORT_CODE: "BUZ",
    },
    {
      CITY_CODE: "IRDJU",
      CITY_NAME: "JOLFA",
      DPA_PORT_CODE: "DJU",
    },
    {
      CITY_CODE: "IRESF",
      CITY_NAME: "ESFAHAN",
      DPA_PORT_CODE: "EFN",
    },
    {
      CITY_CODE: "IRGNH",
      CITY_NAME: "GANAVEH",
      DPA_PORT_CODE: "GNJ",
    },
    {
      CITY_CODE: "IRIFN",
      CITY_NAME: "ISFAHAN",
      DPA_PORT_CODE: "IFN",
    },
    {
      CITY_CODE: "IRKHK",
      CITY_NAME: "KHARK ISLAND",
      DPA_PORT_CODE: "KHK",
    },
    {
      CITY_CODE: "IRKHO",
      CITY_NAME: "KHORRAMSHAHR",
      DPA_PORT_CODE: "KHO",
    },
    {
      CITY_CODE: "IRKHS",
      CITY_NAME: "KHOSRAVI",
      DPA_PORT_CODE: "KHS",
    },
    {
      CITY_CODE: "IRKIH",
      CITY_NAME: "KISH ISLAND",
      DPA_PORT_CODE: "KIH",
    },
    {
      CITY_CODE: "IRKSH",
      CITY_NAME: "KERMANSHAH",
      DPA_PORT_CODE: "KSH",
    },
    {
      CITY_CODE: "IRLVP",
      CITY_NAME: "LAVAN",
      DPA_PORT_CODE: "LVP",
    },
    {
      CITY_CODE: "IRQSH",
      CITY_NAME: "QESHIM ISLAND",
      DPA_PORT_CODE: "QSH",
    },
    {
      CITY_CODE: "IRQSM",
      CITY_NAME: "QESHM",
      DPA_PORT_CODE: "QSM",
    },
    {
      CITY_CODE: "IRSEM",
      CITY_NAME: "SEMNAN",
      DPA_PORT_CODE: "SEM",
    },
    {
      CITY_CODE: "IRSXI",
      CITY_NAME: "SIRRI ISLAND",
      DPA_PORT_CODE: "SXI",
    },
    {
      CITY_CODE: "IRSYZ",
      CITY_NAME: "SHIRAZ",
      DPA_PORT_CODE: "SYZ",
    },
    {
      CITY_CODE: "IRTBZ",
      CITY_NAME: "TABRIZ",
      DPA_PORT_CODE: "TBZ",
    },
    {
      CITY_CODE: "IRTHR",
      CITY_NAME: "TEHRAN",
      DPA_PORT_CODE: "THR",
    },
    {
      CITY_CODE: "IRZBR",
      CITY_NAME: "CHA-BAHAR",
      DPA_PORT_CODE: "ZBR",
    },
    {
      CITY_CODE: "ISAEY",
      CITY_NAME: "AKUREYRI",
      DPA_PORT_CODE: "AEY",
    },
    {
      CITY_CODE: "ISBLO",
      CITY_NAME: "BLONDUOS",
      DPA_PORT_CODE: "BLO",
    },
    {
      CITY_CODE: "ISEFJ",
      CITY_NAME: "ESKIFJORDUR",
      DPA_PORT_CODE: "EFJ",
    },
    {
      CITY_CODE: "ISGRT",
      CITY_NAME: "GRUNDARTANGI",
      DPA_PORT_CODE: "GRT",
    },
    {
      CITY_CODE: "ISHVK",
      CITY_NAME: "HOLMAVIK",
      DPA_PORT_CODE: "HVK",
    },
    {
      CITY_CODE: "ISHZK",
      CITY_NAME: "HUSAVIK",
      DPA_PORT_CODE: "HZK",
    },
    {
      CITY_CODE: "ISIFJ",
      CITY_NAME: "ISAFJORDUR",
      DPA_PORT_CODE: "IFJ",
    },
    {
      CITY_CODE: "ISKEF",
      CITY_NAME: "KEFLAVIK",
      DPA_PORT_CODE: "KEF",
    },
    {
      CITY_CODE: "ISOFJ",
      CITY_NAME: "OLAFSFJORDUR",
      DPA_PORT_CODE: "OFJ",
    },
    {
      CITY_CODE: "ISOLI",
      CITY_NAME: "OLAFSVIK",
      DPA_PORT_CODE: "OLI",
    },
    {
      CITY_CODE: "ISREK",
      CITY_NAME: "REYKJAVIK",
      DPA_PORT_CODE: "REK",
    },
    {
      CITY_CODE: "ISRFJ",
      CITY_NAME: "REYDARFJORDUR",
      DPA_PORT_CODE: "RFJ",
    },
    {
      CITY_CODE: "ISTEY",
      CITY_NAME: "THINGEYRI",
      DPA_PORT_CODE: "TEY",
    },
    {
      CITY_CODE: "ISTHH",
      CITY_NAME: "THORLAKSHOFN",
      DPA_PORT_CODE: "THH",
    },
    {
      CITY_CODE: "ISVEY",
      CITY_NAME: "VESTMANNAEYJAR",
      DPA_PORT_CODE: "VEY",
    },
    {
      CITY_CODE: "ISVPN",
      CITY_NAME: "VOPNAFJORDUR",
      DPA_PORT_CODE: "VPN",
    },
    {
      CITY_CODE: "ITABL",
      CITY_NAME: "ALBANO LAZIALE",
      DPA_PORT_CODE: "ABK",
    },
    {
      CITY_CODE: "ITABN",
      CITY_NAME: "ALBINO",
      DPA_PORT_CODE: "ABO",
    },
    {
      CITY_CODE: "ITADO",
      CITY_NAME: "MADONE",
      DPA_PORT_CODE: "ADI",
    },
    {
      CITY_CODE: "ITAFR",
      CITY_NAME: "AFRAGOLA",
      DPA_PORT_CODE: "AFR",
    },
    {
      CITY_CODE: "ITAGB",
      CITY_NAME: "AGRATE BRIANZA",
      DPA_PORT_CODE: "AGT",
    },
    {
      CITY_CODE: "ITAHO",
      CITY_NAME: "ALGHERO",
      DPA_PORT_CODE: "AHO",
    },
    {
      CITY_CODE: "ITAKA",
      CITY_NAME: "ARLUNO",
      DPA_PORT_CODE: "RLU",
    },
    {
      CITY_CODE: "ITALT",
      CITY_NAME: "ALTARE",
      DPA_PORT_CODE: "ALT",
    },
    {
      CITY_CODE: "ITANA",
      CITY_NAME: "ANAGNI",
      DPA_PORT_CODE: "ANA",
    },
    {
      CITY_CODE: "ITANJ",
      CITY_NAME: "SANT ANGELO DI PIOVE DI SACCO",
      DPA_PORT_CODE: "ANS",
    },
    {
      CITY_CODE: "ITANZ",
      CITY_NAME: "ANZIO",
      DPA_PORT_CODE: "ANZ",
    },
    {
      CITY_CODE: "ITAOI",
      CITY_NAME: "ANCONA",
      DPA_PORT_CODE: "AOI",
    },
    {
      CITY_CODE: "ITARI",
      CITY_NAME: "ARZIGNANO",
      DPA_PORT_CODE: "ARZ",
    },
    {
      CITY_CODE: "ITARO",
      CITY_NAME: "ADRO",
      DPA_PORT_CODE: "OAD",
    },
    {
      CITY_CODE: "ITASA",
      CITY_NAME: "ALBANO SANT ALESSANDRO",
      DPA_PORT_CODE: "AOS",
    },
    {
      CITY_CODE: "ITASS",
      CITY_NAME: "ASSAGO",
      DPA_PORT_CODE: "ASY",
    },
    {
      CITY_CODE: "ITATE",
      CITY_NAME: "ATESSA",
      DPA_PORT_CODE: "ATE",
    },
    {
      CITY_CODE: "ITATX",
      CITY_NAME: "ARBATAX",
      DPA_PORT_CODE: "ATX",
    },
    {
      CITY_CODE: "ITBBB",
      CITY_NAME: "BIBBIENA",
      DPA_PORT_CODE: "BBB",
    },
    {
      CITY_CODE: "ITBDS",
      CITY_NAME: "BRINDISI",
      DPA_PORT_CODE: "BDS",
    },
    {
      CITY_CODE: "ITBED",
      CITY_NAME: "BEDIZZOLE",
      DPA_PORT_CODE: "BZQ",
    },
    {
      CITY_CODE: "ITBFR",
      CITY_NAME: "BONFERRARO",
      DPA_PORT_CODE: "BFR",
    },
    {
      CITY_CODE: "ITBGG",
      CITY_NAME: "BAGGIOVARA",
      DPA_PORT_CODE: "BGG",
    },
    {
      CITY_CODE: "ITBGN",
      CITY_NAME: "BRUGNERA",
      DPA_PORT_CODE: "BGN",
    },
    {
      CITY_CODE: "ITBHR",
      CITY_NAME: "BRUGHERIO",
      DPA_PORT_CODE: "BHC",
    },
    {
      CITY_CODE: "ITBIE",
      CITY_NAME: "BIELLA",
      DPA_PORT_CODE: "BIE",
    },
    {
      CITY_CODE: "ITBIN",
      CITY_NAME: "BERTINORO",
      DPA_PORT_CODE: "BIN",
    },
    {
      CITY_CODE: "ITBLL",
      CITY_NAME: "BELLUSCO",
      DPA_PORT_CODE: "BLL",
    },
    {
      CITY_CODE: "ITBLQ",
      CITY_NAME: "BOLOGNA",
      DPA_PORT_CODE: "BLQ",
    },
    {
      CITY_CODE: "ITBLT",
      CITY_NAME: "BARLETTA",
      DPA_PORT_CODE: "RTT",
    },
    {
      CITY_CODE: "ITBMG",
      CITY_NAME: "BURAGO DI MOLGORA",
      DPA_PORT_CODE: "BMN",
    },
    {
      CITY_CODE: "ITBMO",
      CITY_NAME: "BOVISIO MASCIAGO",
      DPA_PORT_CODE: "BVC",
    },
    {
      CITY_CODE: "ITBNO",
      CITY_NAME: "BREGNANO",
      DPA_PORT_CODE: "BZX",
    },
    {
      CITY_CODE: "ITBON",
      CITY_NAME: "BONDENO",
      DPA_PORT_CODE: "BDN",
    },
    {
      CITY_CODE: "ITBRC",
      CITY_NAME: "BRESCIA",
      DPA_PORT_CODE: "BRA",
    },
    {
      CITY_CODE: "ITBRI",
      CITY_NAME: "BARI",
      DPA_PORT_CODE: "BRI",
    },
    {
      CITY_CODE: "ITBSO",
      CITY_NAME: "BRESSO",
      DPA_PORT_CODE: "BSO",
    },
    {
      CITY_CODE: "ITBSP",
      CITY_NAME: "BOSISIO PARINI",
      DPA_PORT_CODE: "OOP",
    },
    {
      CITY_CODE: "ITBVD",
      CITY_NAME: "BARBERINO VAL DELSA",
      DPA_PORT_CODE: "BVD",
    },
    {
      CITY_CODE: "ITBVG",
      CITY_NAME: "BENTIVOGLIO",
      DPA_PORT_CODE: "BVG",
    },
    {
      CITY_CODE: "ITCAG",
      CITY_NAME: "CAGLIARI",
      DPA_PORT_CODE: "CAG",
    },
    {
      CITY_CODE: "ITCAN",
      CITY_NAME: "CANELLI",
      DPA_PORT_CODE: "CNI",
    },
    {
      CITY_CODE: "ITCAP",
      CITY_NAME: "CAMPODARSEGO",
      DPA_PORT_CODE: "CDK",
    },
    {
      CITY_CODE: "ITCAU",
      CITY_NAME: "CANTU",
      DPA_PORT_CODE: "CUQ",
    },
    {
      CITY_CODE: "ITCAX",
      CITY_NAME: "CASELLA",
      DPA_PORT_CODE: "CSJ",
    },
    {
      CITY_CODE: "ITCBG",
      CITY_NAME: "CISANO BERGAMASCO",
      DPA_PORT_CODE: "CBG",
    },
    {
      CITY_CODE: "ITCCA",
      CITY_NAME: "CIVITA CASTELLANA",
      DPA_PORT_CODE: "IIA",
    },
    {
      CITY_CODE: "ITCCN",
      CITY_NAME: "CASCINA",
      DPA_PORT_CODE: "CCN",
    },
    {
      CITY_CODE: "ITCCT",
      CITY_NAME: "CALCINATE",
      DPA_PORT_CODE: "CFF",
    },
    {
      CITY_CODE: "ITCCZ",
      CITY_NAME: "CONCOREZZO",
      DPA_PORT_CODE: "CGS",
    },
    {
      CITY_CODE: "ITCDS",
      CITY_NAME: "CALENDASCO",
      DPA_PORT_CODE: "AEN",
    },
    {
      CITY_CODE: "ITCEB",
      CITY_NAME: "CESANA BRIANZA",
      DPA_PORT_CODE: "CEA",
    },
    {
      CITY_CODE: "ITCEE",
      CITY_NAME: "CERMENATE",
      DPA_PORT_CODE: "CMV",
    },
    {
      CITY_CODE: "ITCEM",
      CITY_NAME: "CESANO MADERNO",
      DPA_PORT_CODE: "CMX",
    },
    {
      CITY_CODE: "ITCET",
      CITY_NAME: "CERTALDO",
      DPA_PORT_CODE: "CRF",
    },
    {
      CITY_CODE: "ITCEZ",
      CITY_NAME: "CESENA",
      DPA_PORT_CODE: "CZZ",
    },
    {
      CITY_CODE: "ITCFE",
      CITY_NAME: "CASTELFRANCO EMILIA",
      DPA_PORT_CODE: "CFE",
    },
    {
      CITY_CODE: "ITCFT",
      CITY_NAME: "CASALE MONFERRATO",
      DPA_PORT_CODE: "CFQ",
    },
    {
      CITY_CODE: "ITCGG",
      CITY_NAME: "CORREGGIO",
      DPA_PORT_CODE: "CGF",
    },
    {
      CITY_CODE: "ITCGT",
      CITY_NAME: "COGLIATE",
      DPA_PORT_CODE: "CGT",
    },
    {
      CITY_CODE: "ITCIB",
      CITY_NAME: "CINISELLO BALSAMO",
      DPA_PORT_CODE: "CIE",
    },
    {
      CITY_CODE: "ITCLL",
      CITY_NAME: "COLLEBEATO",
      DPA_PORT_CODE: "CIW",
    },
    {
      CITY_CODE: "ITCLV",
      CITY_NAME: "CONSELVE",
      DPA_PORT_CODE: "CLV",
    },
    {
      CITY_CODE: "ITCMN",
      CITY_NAME: "CORMANO (DUPLICATE CODE)",
      DPA_PORT_CODE: "CMN",
    },
    {
      CITY_CODE: "ITCMR",
      CITY_NAME: "CREMA",
      DPA_PORT_CODE: "CMA",
    },
    {
      CITY_CODE: "ITCNE",
      CITY_NAME: "CASNATE",
      DPA_PORT_CODE: "CNE",
    },
    {
      CITY_CODE: "ITCNG",
      CITY_NAME: "CASTEL SAN GIOVANNI",
      DPA_PORT_CODE: "CNG",
    },
    {
      CITY_CODE: "ITCNL",
      CITY_NAME: "COLOGNOLA AI COLLI",
      DPA_PORT_CODE: "CLA",
    },
    {
      CITY_CODE: "ITCOA",
      CITY_NAME: "CONTRADA TDRREMOZZA",
      DPA_PORT_CODE: "CFL",
    },
    {
      CITY_CODE: "ITCOD",
      CITY_NAME: "CALDAROLA",
      DPA_PORT_CODE: "COD",
    },
    {
      CITY_CODE: "ITCPC",
      CITY_NAME: "CASSINA DE PECCHI",
      DPA_PORT_CODE: "CPC",
    },
    {
      CITY_CODE: "ITCPI",
      CITY_NAME: "CAPRIATE SAN GERVASIO",
      DPA_PORT_CODE: "CJE",
    },
    {
      CITY_CODE: "ITCPO",
      CITY_NAME: "CRESPELLANO",
      DPA_PORT_CODE: "CPO",
    },
    {
      CITY_CODE: "ITCPP",
      CITY_NAME: "COPPARO",
      DPA_PORT_CODE: "CPP",
    },
    {
      CITY_CODE: "ITCQQ",
      CITY_NAME: "CISERANO",
      DPA_PORT_CODE: "CQO",
    },
    {
      CITY_CODE: "ITCRP",
      CITY_NAME: "CARPENEDOLO",
      DPA_PORT_CODE: "CRD",
    },
    {
      CITY_CODE: "ITCRT",
      CITY_NAME: "CORTENUOVA",
      DPA_PORT_CODE: "CRT",
    },
    {
      CITY_CODE: "ITCRX",
      CITY_NAME: "CEREA",
      DPA_PORT_CODE: "CRX",
    },
    {
      CITY_CODE: "ITCTA",
      CITY_NAME: "CATANIA",
      DPA_PORT_CODE: "CTA",
    },
    {
      CITY_CODE: "ITCTI",
      CITY_NAME: "CASELLE TORINESE",
      DPA_PORT_CODE: "CTQ",
    },
    {
      CITY_CODE: "ITCTL",
      CITY_NAME: "CASTELLETTO",
      DPA_PORT_CODE: "CJI",
    },
    {
      CITY_CODE: "ITCTM",
      CITY_NAME: "CASTELMINIO",
      DPA_PORT_CODE: "CTM",
    },
    {
      CITY_CODE: "ITCTO",
      CITY_NAME: "CASTELLARANO",
      DPA_PORT_CODE: "CTO",
    },
    {
      CITY_CODE: "ITCUI",
      CITY_NAME: "CUVIO",
      DPA_PORT_CODE: "CUZ",
    },
    {
      CITY_CODE: "ITCUN",
      CITY_NAME: "CUNEO",
      DPA_PORT_CODE: "CUN",
    },
    {
      CITY_CODE: "ITCVI",
      CITY_NAME: "CAVRIAGO",
      DPA_PORT_CODE: "CVI",
    },
    {
      CITY_CODE: "ITCVV",
      CITY_NAME: "CIVITAVECCHIA",
      DPA_PORT_CODE: "CVV",
    },
    {
      CITY_CODE: "ITCXA",
      CITY_NAME: "CARUGO",
      DPA_PORT_CODE: "CXA",
    },
    {
      CITY_CODE: "ITCZM",
      CITY_NAME: "CAZZAGO SAN MARTINO",
      DPA_PORT_CODE: "CZI",
    },
    {
      CITY_CODE: "ITDEL",
      CITY_NAME: "DELLO",
      DPA_PORT_CODE: "DLE",
    },
    {
      CITY_CODE: "ITDME",
      CITY_NAME: "DALMINE",
      DPA_PORT_CODE: "DMB",
    },
    {
      CITY_CODE: "ITELV",
      CITY_NAME: "SALVATERRA",
      DPA_PORT_CODE: "ELV",
    },
    {
      CITY_CODE: "ITERA",
      CITY_NAME: "MERATE",
      DPA_PORT_CODE: "ERJ",
    },
    {
      CITY_CODE: "ITERB",
      CITY_NAME: "ERBA",
      DPA_PORT_CODE: "ERR",
    },
    {
      CITY_CODE: "ITEST",
      CITY_NAME: "ESTE",
      DPA_PORT_CODE: "ESZ",
    },
    {
      CITY_CODE: "ITFAG",
      CITY_NAME: "FAGNANO OLONA",
      DPA_PORT_CODE: "FAG",
    },
    {
      CITY_CODE: "ITFAO",
      CITY_NAME: "FANO",
      DPA_PORT_CODE: "FBO",
    },
    {
      CITY_CODE: "ITFDR",
      CITY_NAME: "FABRICA DI ROMA",
      DPA_PORT_CODE: "FDI",
    },
    {
      CITY_CODE: "ITFEO",
      CITY_NAME: "FLERO",
      DPA_PORT_CODE: "FEO",
    },
    {
      CITY_CODE: "ITFGD",
      CITY_NAME: "FARA GERA DADDA",
      DPA_PORT_CODE: "FGD",
    },
    {
      CITY_CODE: "ITFIL",
      CITY_NAME: "FILAGO",
      DPA_PORT_CODE: "FIB",
    },
    {
      CITY_CODE: "ITFMD",
      CITY_NAME: "FLORANO MODENESE",
      DPA_PORT_CODE: "FMD",
    },
    {
      CITY_CODE: "ITFNZ",
      CITY_NAME: "FIORENZUOLA DARDA",
      DPA_PORT_CODE: "FNZ",
    },
    {
      CITY_CODE: "ITFRO",
      CITY_NAME: "FROSINONE",
      DPA_PORT_CODE: "FBV",
    },
    {
      CITY_CODE: "ITFTE",
      CITY_NAME: "FELTRE",
      DPA_PORT_CODE: "FSX",
    },
    {
      CITY_CODE: "ITGAE",
      CITY_NAME: "GAETA",
      DPA_PORT_CODE: "GTA",
    },
    {
      CITY_CODE: "ITGAL",
      CITY_NAME: "GALLIPOLI",
      DPA_PORT_CODE: "GAL",
    },
    {
      CITY_CODE: "ITGAT",
      CITY_NAME: "GATTEO",
      DPA_PORT_CODE: "GTQ",
    },
    {
      CITY_CODE: "ITGEA",
      CITY_NAME: "GELA",
      DPA_PORT_CODE: "GCH",
    },
    {
      CITY_CODE: "ITGIP",
      CITY_NAME: "SAN GIOVANNI IN PERSICETO",
      DPA_PORT_CODE: "GIP",
    },
    {
      CITY_CODE: "ITGIT",
      CITY_NAME: "GIO TAURO",
      DPA_PORT_CODE: "GIT",
    },
    {
      CITY_CODE: "ITGLO",
      CITY_NAME: "GERA LARIO",
      DPA_PORT_CODE: "GCR",
    },
    {
      CITY_CODE: "ITGLT",
      CITY_NAME: "GUALTIERI",
      DPA_PORT_CODE: "GLB",
    },
    {
      CITY_CODE: "ITGME",
      CITY_NAME: "GORLA MINORE",
      DPA_PORT_CODE: "GMO",
    },
    {
      CITY_CODE: "ITGND",
      CITY_NAME: "GANDINO",
      DPA_PORT_CODE: "GCB",
    },
    {
      CITY_CODE: "ITGOA",
      CITY_NAME: "GENOA",
      DPA_PORT_CODE: "GOA",
    },
    {
      CITY_CODE: "ITGTC",
      CITY_NAME: "GATTATICO",
      DPA_PORT_CODE: "GTC",
    },
    {
      CITY_CODE: "ITGVE",
      CITY_NAME: "GALLIERA VENETA",
      DPA_PORT_CODE: "GVE",
    },
    {
      CITY_CODE: "ITICE",
      CITY_NAME: "CONSELICE",
      DPA_PORT_CODE: "ICE",
    },
    {
      CITY_CODE: "ITIML",
      CITY_NAME: "IMOLA",
      DPA_PORT_CODE: "IML",
    },
    {
      CITY_CODE: "ITIMP",
      CITY_NAME: "IMPERIA",
      DPA_PORT_CODE: "IMP",
    },
    {
      CITY_CODE: "ITLAR",
      CITY_NAME: "LARDERELLO",
      DPA_PORT_CODE: "LAR",
    },
    {
      CITY_CODE: "ITLCV",
      CITY_NAME: "LUCCA",
      DPA_PORT_CODE: "LCV",
    },
    {
      CITY_CODE: "ITLDC",
      CITY_NAME: "SOLIGNANO NUOVO CASTELVETRO",
      DPA_PORT_CODE: "LDO",
    },
    {
      CITY_CODE: "ITLDI",
      CITY_NAME: "LODI",
      DPA_PORT_CODE: "LOI",
    },
    {
      CITY_CODE: "ITLDS",
      CITY_NAME: "LIMIDI DI SOLIERA",
      DPA_PORT_CODE: "LDR",
    },
    {
      CITY_CODE: "ITLEE",
      CITY_NAME: "LEVATE",
      DPA_PORT_CODE: "LEP",
    },
    {
      CITY_CODE: "ITLEV",
      CITY_NAME: "LEVANZO",
      DPA_PORT_CODE: "LEZ",
    },
    {
      CITY_CODE: "ITLIC",
      CITY_NAME: "LICATA",
      DPA_PORT_CODE: "LIC",
    },
    {
      CITY_CODE: "ITLIV",
      CITY_NAME: "LIVORNO",
      DPA_PORT_CODE: "LIV",
    },
    {
      CITY_CODE: "ITLSO",
      CITY_NAME: "LISSOVE",
      DPA_PORT_CODE: "LSG",
    },
    {
      CITY_CODE: "ITLSS",
      CITY_NAME: "LENTATE SUL SEVESO",
      DPA_PORT_CODE: "LSJ",
    },
    {
      CITY_CODE: "ITLTE",
      CITY_NAME: "LAINATE",
      DPA_PORT_CODE: "LTE",
    },
    {
      CITY_CODE: "ITLTN",
      CITY_NAME: "LATINA",
      DPA_PORT_CODE: "LTM",
    },
    {
      CITY_CODE: "ITMAR",
      CITY_NAME: "MARANELLO",
      DPA_PORT_CODE: "MEO",
    },
    {
      CITY_CODE: "ITMAU",
      CITY_NAME: "MARSAIU",
      DPA_PORT_CODE: "MAU",
    },
    {
      CITY_CODE: "ITMDC",
      CITY_NAME: "MARINA DI CARRARA",
      DPA_PORT_CODE: "MDC",
    },
    {
      CITY_CODE: "ITMDE",
      CITY_NAME: "MADONNA",
      DPA_PORT_CODE: "AMM",
    },
    {
      CITY_CODE: "ITMDI",
      CITY_NAME: "MEDICINA",
      DPA_PORT_CODE: "MDI",
    },
    {
      CITY_CODE: "ITMED",
      CITY_NAME: "MEDA",
      DPA_PORT_CODE: "MED",
    },
    {
      CITY_CODE: "ITMET",
      CITY_NAME: "MESTRE",
      DPA_PORT_CODE: "MET",
    },
    {
      CITY_CODE: "ITMFS",
      CITY_NAME: "MASSAFRA",
      DPA_PORT_CODE: "MFS",
    },
    {
      CITY_CODE: "ITMGN",
      CITY_NAME: "MAGNISI",
      DPA_PORT_CODE: "MGI",
    },
    {
      CITY_CODE: "ITMII",
      CITY_NAME: "MISINTO",
      DPA_PORT_CODE: "MXS",
    },
    {
      CITY_CODE: "ITMIL",
      CITY_NAME: "MILANO",
      DPA_PORT_CODE: "MIL",
    },
    {
      CITY_CODE: "ITMLG",
      CITY_NAME: "MELEGNANO",
      DPA_PORT_CODE: "MLG",
    },
    {
      CITY_CODE: "ITMLL",
      CITY_NAME: "MANDELLO",
      DPA_PORT_CODE: "MLO",
    },
    {
      CITY_CODE: "ITMMG",
      CITY_NAME: "MONTECCHIO MAGGIORE",
      DPA_PORT_CODE: "MMG",
    },
    {
      CITY_CODE: "ITMNB",
      CITY_NAME: "MONTELLABBATE",
      DPA_PORT_CODE: "MNB",
    },
    {
      CITY_CODE: "ITMNE",
      CITY_NAME: "MARNATE",
      DPA_PORT_CODE: "MZF",
    },
    {
      CITY_CODE: "ITMNF",
      CITY_NAME: "MONFALCONE",
      DPA_PORT_CODE: "MNF",
    },
    {
      CITY_CODE: "ITMOE",
      CITY_NAME: "MONSELICE",
      DPA_PORT_CODE: "MZV",
    },
    {
      CITY_CODE: "ITMPO",
      CITY_NAME: "MONTELUPO FIORENTIN",
      DPA_PORT_CODE: "MPO",
    },
    {
      CITY_CODE: "ITMPP",
      CITY_NAME: "MAPPANO",
      DPA_PORT_CODE: "MNW",
    },
    {
      CITY_CODE: "ITMRB",
      CITY_NAME: "MANERBIO",
      DPA_PORT_CODE: "MBZ",
    },
    {
      CITY_CODE: "ITMRG",
      CITY_NAME: "MORNAGO",
      DPA_PORT_CODE: "MOA",
    },
    {
      CITY_CODE: "ITMRO",
      CITY_NAME: "MONTIRONE",
      DPA_PORT_CODE: "MRO",
    },
    {
      CITY_CODE: "ITMRZ",
      CITY_NAME: "MARZANO",
      DPA_PORT_CODE: "MRZ",
    },
    {
      CITY_CODE: "ITMSN",
      CITY_NAME: "MESSINA",
      DPA_PORT_CODE: "MVL",
    },
    {
      CITY_CODE: "ITMTL",
      CITY_NAME: "MOTTA DI LIVENZA",
      DPA_PORT_CODE: "MTZ",
    },
    {
      CITY_CODE: "ITMUO",
      CITY_NAME: "MUDUGNO",
      DPA_PORT_CODE: "MVU",
    },
    {
      CITY_CODE: "ITMXG",
      CITY_NAME: "MUGGIO",
      DPA_PORT_CODE: "MQI",
    },
    {
      CITY_CODE: "ITMZO",
      CITY_NAME: "MELZO",
      DPA_PORT_CODE: "MZO",
    },
    {
      CITY_CODE: "ITNAP",
      CITY_NAME: "NAPLES",
      DPA_PORT_CODE: "NAP",
    },
    {
      CITY_CODE: "ITNDL",
      CITY_NAME: "NIGOLINE BONOMELLI",
      DPA_PORT_CODE: "NDL",
    },
    {
      CITY_CODE: "ITNER",
      CITY_NAME: "NERVIANO",
      DPA_PORT_CODE: "NEO",
    },
    {
      CITY_CODE: "ITNLO",
      CITY_NAME: "NICHELINO",
      DPA_PORT_CODE: "NLO",
    },
    {
      CITY_CODE: "ITNML",
      CITY_NAME: "NOVATE MILANESE",
      DPA_PORT_CODE: "NME",
    },
    {
      CITY_CODE: "ITNNT",
      CITY_NAME: "NANTO",
      DPA_PORT_CODE: "NNT",
    },
    {
      CITY_CODE: "ITNOL",
      CITY_NAME: "NOLA",
      DPA_PORT_CODE: "NOL",
    },
    {
      CITY_CODE: "ITNOM",
      CITY_NAME: "NOVA MILANESE",
      DPA_PORT_CODE: "NOE",
    },
    {
      CITY_CODE: "ITNON",
      CITY_NAME: "NONANTOLA",
      DPA_PORT_CODE: "NON",
    },
    {
      CITY_CODE: "ITNVL",
      CITY_NAME: "NUVOLERA",
      DPA_PORT_CODE: "OLR",
    },
    {
      CITY_CODE: "ITNVR",
      CITY_NAME: "NOVARA",
      DPA_PORT_CODE: "NVR",
    },
    {
      CITY_CODE: "ITOBS",
      CITY_NAME: "ORBASSANO",
      DPA_PORT_CODE: "OBS",
    },
    {
      CITY_CODE: "ITODZ",
      CITY_NAME: "ODERZO",
      DPA_PORT_CODE: "ODZ",
    },
    {
      CITY_CODE: "ITOGG",
      CITY_NAME: "OGGIONO",
      DPA_PORT_CODE: "OGO",
    },
    {
      CITY_CODE: "ITOLB",
      CITY_NAME: "OLBIA",
      DPA_PORT_CODE: "OLB",
    },
    {
      CITY_CODE: "ITOMO",
      CITY_NAME: "COMO",
      DPA_PORT_CODE: "OMO",
    },
    {
      CITY_CODE: "ITONE",
      CITY_NAME: "ONEGLIA",
      DPA_PORT_CODE: "ONE",
    },
    {
      CITY_CODE: "ITOPP",
      CITY_NAME: "OPPEANO",
      DPA_PORT_CODE: "OPN",
    },
    {
      CITY_CODE: "ITORG",
      CITY_NAME: "ORIGGIO",
      DPA_PORT_CODE: "ORI",
    },
    {
      CITY_CODE: "ITOSI",
      CITY_NAME: "OSIO DI SOPRA",
      DPA_PORT_CODE: "OSI",
    },
    {
      CITY_CODE: "ITOSS",
      CITY_NAME: "OSIO SOTTO",
      DPA_PORT_CODE: "OSO",
    },
    {
      CITY_CODE: "ITOZE",
      CITY_NAME: "OZZANO DELLEMILA",
      DPA_PORT_CODE: "OZE",
    },
    {
      CITY_CODE: "ITPAO",
      CITY_NAME: "PASSIRANO",
      DPA_PORT_CODE: "IAN",
    },
    {
      CITY_CODE: "ITPAV",
      CITY_NAME: "PAVIA",
      DPA_PORT_CODE: "PIA",
    },
    {
      CITY_CODE: "ITPBA",
      CITY_NAME: "SANTA PALOMBA",
      DPA_PORT_CODE: "PBB",
    },
    {
      CITY_CODE: "ITPCA",
      CITY_NAME: "PESCIA",
      DPA_PORT_CODE: "PSQ",
    },
    {
      CITY_CODE: "ITPCZ",
      CITY_NAME: "PIACENZA",
      DPA_PORT_CODE: "PCZ",
    },
    {
      CITY_CODE: "ITPES",
      CITY_NAME: "PESARO",
      DPA_PORT_CODE: "PES",
    },
    {
      CITY_CODE: "ITPFE",
      CITY_NAME: "PORTOFERRAIO",
      DPA_PORT_CODE: "PFE",
    },
    {
      CITY_CODE: "ITPGL",
      CITY_NAME: "PORTIGLIONI",
      DPA_PORT_CODE: "PGL",
    },
    {
      CITY_CODE: "ITPGR",
      CITY_NAME: "PORTOGRUARO",
      DPA_PORT_CODE: "PGR",
    },
    {
      CITY_CODE: "ITPGT",
      CITY_NAME: "PANTIGLIATE",
      DPA_PORT_CODE: "PGT",
    },
    {
      CITY_CODE: "ITPIE",
      CITY_NAME: "PIETRASANTA",
      DPA_PORT_CODE: "PTA",
    },
    {
      CITY_CODE: "ITPIO",
      CITY_NAME: "PIOMBINO",
      DPA_PORT_CODE: "PIO",
    },
    {
      CITY_CODE: "ITPLM",
      CITY_NAME: "PALAZZOLO MILANESE",
      DPA_PORT_CODE: "PAM",
    },
    {
      CITY_CODE: "ITPMA",
      CITY_NAME: "PORTO MARGHERA",
      DPA_PORT_CODE: "PMA",
    },
    {
      CITY_CODE: "ITPME",
      CITY_NAME: "POGLIANO MILANESE",
      DPA_PORT_CODE: "PMU",
    },
    {
      CITY_CODE: "ITPMN",
      CITY_NAME: " PRIMALUNA, LECCO",
      DPA_PORT_CODE: "PFM",
    },
    {
      CITY_CODE: "ITPMO",
      CITY_NAME: "PALERMO",
      DPA_PORT_CODE: "PMO",
    },
    {
      CITY_CODE: "ITPOC",
      CITY_NAME: "PORCARI",
      DPA_PORT_CODE: "OCA",
    },
    {
      CITY_CODE: "ITPOM",
      CITY_NAME: "POMEZIA",
      DPA_PORT_CODE: "PZA",
    },
    {
      CITY_CODE: "ITPOZ",
      CITY_NAME: "POZZUOLI",
      DPA_PORT_CODE: "POZ",
    },
    {
      CITY_CODE: "ITPSB",
      CITY_NAME: "PESCHIERA BORROMEO",
      DPA_PORT_CODE: "PSB",
    },
    {
      CITY_CODE: "ITPSO",
      CITY_NAME: "PALAZZOLA SULL OGILO",
      DPA_PORT_CODE: "PSO",
    },
    {
      CITY_CODE: "ITPTO",
      CITY_NAME: "PORTO TORRES",
      DPA_PORT_CODE: "PTO",
    },
    {
      CITY_CODE: "ITPVO",
      CITY_NAME: "PAVULLO NEL FRIGNANO",
      DPA_PORT_CODE: "PVO",
    },
    {
      CITY_CODE: "ITPZE",
      CITY_NAME: "PONZONE",
      DPA_PORT_CODE: "PZE",
    },
    {
      CITY_CODE: "ITPZN",
      CITY_NAME: "PIANZANO",
      DPA_PORT_CODE: "PDO",
    },
    {
      CITY_CODE: "ITQCZ",
      CITY_NAME: "CATANZARO",
      DPA_PORT_CODE: "QCZ",
    },
    {
      CITY_CODE: "ITQTT",
      CITY_NAME: "QUATTORDIO",
      DPA_PORT_CODE: "QTT",
    },
    {
      CITY_CODE: "ITQUA",
      CITY_NAME: "QUARRATA",
      DPA_PORT_CODE: "QUA",
    },
    {
      CITY_CODE: "ITRAN",
      CITY_NAME: "RAVENNA",
      DPA_PORT_CODE: "RAN",
    },
    {
      CITY_CODE: "ITRBN",
      CITY_NAME: "ROBBIANO",
      DPA_PORT_CODE: "RBT",
    },
    {
      CITY_CODE: "ITRBS",
      CITY_NAME: "ROBASSOMERO",
      DPA_PORT_CODE: "RBS",
    },
    {
      CITY_CODE: "ITRCL",
      CITY_NAME: "RONCELLO",
      DPA_PORT_CODE: "RGK",
    },
    {
      CITY_CODE: "ITRDC",
      CITY_NAME: "REDECESIO",
      DPA_PORT_CODE: "RCK",
    },
    {
      CITY_CODE: "ITRDN",
      CITY_NAME: "RODANO",
      DPA_PORT_CODE: "RDN",
    },
    {
      CITY_CODE: "ITREG",
      CITY_NAME: "REGGIO CALABRIA",
      DPA_PORT_CODE: "REZ",
    },
    {
      CITY_CODE: "ITRGO",
      CITY_NAME: "REGGIOLO",
      DPA_PORT_CODE: "RGS",
    },
    {
      CITY_CODE: "ITRHO",
      CITY_NAME: "RHO",
      DPA_PORT_CODE: "RHO",
    },
    {
      CITY_CODE: "ITRIO",
      CITY_NAME: "RIVOLI VERONESE",
      DPA_PORT_CODE: "RCD",
    },
    {
      CITY_CODE: "ITRIV",
      CITY_NAME: "RIVALTA SCRIVIA",
      DPA_PORT_CODE: "RFE",
    },
    {
      CITY_CODE: "ITRLL",
      CITY_NAME: "RUTIGLIANO",
      DPA_PORT_CODE: "RLL",
    },
    {
      CITY_CODE: "ITRMA",
      CITY_NAME: "RIO MARINA",
      DPA_PORT_CODE: "RMA",
    },
    {
      CITY_CODE: "ITROB",
      CITY_NAME: "ROBECCO SUL NAVIGLO",
      DPA_PORT_CODE: "ROB",
    },
    {
      CITY_CODE: "ITROM",
      CITY_NAME: "ROMA",
      DPA_PORT_CODE: "ROM",
    },
    {
      CITY_CODE: "ITRPI",
      CITY_NAME: "CARPI",
      DPA_PORT_CODE: "RPI",
    },
    {
      CITY_CODE: "ITRRO",
      CITY_NAME: "SORRENTO",
      DPA_PORT_CODE: "RRO",
    },
    {
      CITY_CODE: "ITRTO",
      CITY_NAME: "ROVERETO",
      DPA_PORT_CODE: "RTO",
    },
    {
      CITY_CODE: "ITRVB",
      CITY_NAME: "CODOGNE",
      DPA_PORT_CODE: "RVB",
    },
    {
      CITY_CODE: "ITRVL",
      CITY_NAME: "RIVOLI",
      DPA_PORT_CODE: "RVL",
    },
    {
      CITY_CODE: "ITRZO",
      CITY_NAME: "ROZZANO",
      DPA_PORT_CODE: "RZO",
    },
    {
      CITY_CODE: "ITSAL",
      CITY_NAME: "SALERNO",
      DPA_PORT_CODE: "SAL",
    },
    {
      CITY_CODE: "ITSAO",
      CITY_NAME: "SANTAGOSTINO",
      DPA_PORT_CODE: "SAG",
    },
    {
      CITY_CODE: "ITSBF",
      CITY_NAME: "SAN BONIFACIO",
      DPA_PORT_CODE: "SBF",
    },
    {
      CITY_CODE: "ITSBT",
      CITY_NAME: "SOLBIATE",
      DPA_PORT_CODE: "SYT",
    },
    {
      CITY_CODE: "ITSCG",
      CITY_NAME: "SOMMACAMPAGNA",
      DPA_PORT_CODE: "OMM",
    },
    {
      CITY_CODE: "ITSCH",
      CITY_NAME: "SCHIO",
      DPA_PORT_CODE: "SCH",
    },
    {
      CITY_CODE: "ITSCS",
      CITY_NAME: "SAN CESARIO SUL PANARO",
      DPA_PORT_CODE: "SCS",
    },
    {
      CITY_CODE: "ITSED",
      CITY_NAME: "SEDRIANO",
      DPA_PORT_CODE: "SDO",
    },
    {
      CITY_CODE: "ITSEI",
      CITY_NAME: "SERRAVALLE PISTOIESE",
      DPA_PORT_CODE: "SYG",
    },
    {
      CITY_CODE: "ITSEN",
      CITY_NAME: "SENAGO",
      DPA_PORT_CODE: "SYQ",
    },
    {
      CITY_CODE: "ITSGE",
      CITY_NAME: "SEGRATE",
      DPA_PORT_CODE: "SGE",
    },
    {
      CITY_CODE: "ITSGM",
      CITY_NAME: "SAN GIOVANNI IN MARIGNANO",
      DPA_PORT_CODE: "SGM",
    },
    {
      CITY_CODE: "ITSIR",
      CITY_NAME: "SIRACUSA",
      DPA_PORT_CODE: "SIR",
    },
    {
      CITY_CODE: "ITSLT",
      CITY_NAME: "SPOLETO",
      DPA_PORT_CODE: "OOL",
    },
    {
      CITY_CODE: "ITSMS",
      CITY_NAME: "SANTA MARIA DI SALA",
      DPA_PORT_CODE: "WDZ",
    },
    {
      CITY_CODE: "ITSOM",
      CITY_NAME: "SAN PIETRO MOSEZZO",
      DPA_PORT_CODE: "SOM",
    },
    {
      CITY_CODE: "ITSOT",
      CITY_NAME: "CENATE SOTTO",
      DPA_PORT_CODE: "SOO",
    },
    {
      CITY_CODE: "ITSPD",
      CITY_NAME: "SAN POLO DI TORRILE",
      DPA_PORT_CODE: "SZW",
    },
    {
      CITY_CODE: "ITSPE",
      CITY_NAME: "LA SPEZIA",
      DPA_PORT_CODE: "SPE",
    },
    {
      CITY_CODE: "ITSRE",
      CITY_NAME: "SAN REMO",
      DPA_PORT_CODE: "SRE",
    },
    {
      CITY_CODE: "ITSSD",
      CITY_NAME: "SAN MARTINO IN STRADA",
      DPA_PORT_CODE: "SMV",
    },
    {
      CITY_CODE: "ITSSG",
      CITY_NAME: "SESTO SAN GIOVANNI",
      DPA_PORT_CODE: "SSI",
    },
    {
      CITY_CODE: "ITSSU",
      CITY_NAME: "SASSUOLO",
      DPA_PORT_CODE: "SSU",
    },
    {
      CITY_CODE: "ITSTI",
      CITY_NAME: "SETTIMO MILANESE",
      DPA_PORT_CODE: "SQQ",
    },
    {
      CITY_CODE: "ITSTZ",
      CITY_NAME: "STEZZANO",
      DPA_PORT_CODE: "STZ",
    },
    {
      CITY_CODE: "ITSVL",
      CITY_NAME: "SAN VITO DI LEGUZZANO",
      DPA_PORT_CODE: "SVO",
    },
    {
      CITY_CODE: "ITSVN",
      CITY_NAME: "SAVONA",
      DPA_PORT_CODE: "SVN",
    },
    {
      CITY_CODE: "ITSVZ",
      CITY_NAME: "SERAVEZZA",
      DPA_PORT_CODE: "SVZ",
    },
    {
      CITY_CODE: "ITTAR",
      CITY_NAME: "TARANTO",
      DPA_PORT_CODE: "TAN",
    },
    {
      CITY_CODE: "ITTCZ",
      CITY_NAME: "TRUCCAZZANO",
      DPA_PORT_CODE: "TCZ",
    },
    {
      CITY_CODE: "ITTEL",
      CITY_NAME: "TELGATE",
      DPA_PORT_CODE: "TEG",
    },
    {
      CITY_CODE: "ITTHE",
      CITY_NAME: "THIENE",
      DPA_PORT_CODE: "THE",
    },
    {
      CITY_CODE: "ITTLA",
      CITY_NAME: "SETTALA",
      DPA_PORT_CODE: "TLA",
    },
    {
      CITY_CODE: "ITTOM",
      CITY_NAME: "TOMBOLO",
      DPA_PORT_CODE: "TOM",
    },
    {
      CITY_CODE: "ITTPS",
      CITY_NAME: "TRAPANI",
      DPA_PORT_CODE: "TPS",
    },
    {
      CITY_CODE: "ITTRA",
      CITY_NAME: "TURATE",
      DPA_PORT_CODE: "URT",
    },
    {
      CITY_CODE: "ITTRE",
      CITY_NAME: "TRECATE",
      DPA_PORT_CODE: "TRE",
    },
    {
      CITY_CODE: "ITTRR",
      CITY_NAME: "TERRUGGIA",
      DPA_PORT_CODE: "TRH",
    },
    {
      CITY_CODE: "ITTRS",
      CITY_NAME: "TRIESTE",
      DPA_PORT_CODE: "TRS",
    },
    {
      CITY_CODE: "ITTRT",
      CITY_NAME: "TRENTO",
      DPA_PORT_CODE: "TRT",
    },
    {
      CITY_CODE: "ITTRV",
      CITY_NAME: "TREVISO",
      DPA_PORT_CODE: "TRV",
    },
    {
      CITY_CODE: "ITTRZ",
      CITY_NAME: "TREZZO SULLADDA",
      DPA_PORT_CODE: "TRZ",
    },
    {
      CITY_CODE: "ITTSB",
      CITY_NAME: "TEZZE SUL BRENTA",
      DPA_PORT_CODE: "TCX",
    },
    {
      CITY_CODE: "ITUBE",
      CITY_NAME: "UBERSETTO",
      DPA_PORT_CODE: "UOT",
    },
    {
      CITY_CODE: "ITUID",
      CITY_NAME: "GUIDONA",
      DPA_PORT_CODE: "UID",
    },
    {
      CITY_CODE: "ITVAI",
      CITY_NAME: "VAIANO CREMASCO",
      DPA_PORT_CODE: "VAQ",
    },
    {
      CITY_CODE: "ITVAR",
      CITY_NAME: "VARESE",
      DPA_PORT_CODE: "VAE",
    },
    {
      CITY_CODE: "ITVCE",
      CITY_NAME: "VENICE",
      DPA_PORT_CODE: "VCE",
    },
    {
      CITY_CODE: "ITVDO",
      CITY_NAME: "VADO",
      DPA_PORT_CODE: "VDO",
    },
    {
      CITY_CODE: "ITVDZ",
      CITY_NAME: "VIGODARZERE",
      DPA_PORT_CODE: "VBD",
    },
    {
      CITY_CODE: "ITVGH",
      CITY_NAME: "VOGHERA",
      DPA_PORT_CODE: "VGH",
    },
    {
      CITY_CODE: "ITVGV",
      CITY_NAME: "VIGEVANO",
      DPA_PORT_CODE: "VGV",
    },
    {
      CITY_CODE: "ITVIA",
      CITY_NAME: "VIAREGGIO",
      DPA_PORT_CODE: "VIA",
    },
    {
      CITY_CODE: "ITVLB",
      CITY_NAME: "VILLALBA",
      DPA_PORT_CODE: "VLK",
    },
    {
      CITY_CODE: "ITVLG",
      CITY_NAME: "VOLARGNE",
      DPA_PORT_CODE: "VLG",
    },
    {
      CITY_CODE: "ITVPI",
      CITY_NAME: "VOLPIANO",
      DPA_PORT_CODE: "VPO",
    },
    {
      CITY_CODE: "ITVRC",
      CITY_NAME: "VILLA VERUCCHIO",
      DPA_PORT_CODE: "VRC",
    },
    {
      CITY_CODE: "ITVSN",
      CITY_NAME: "VILLASANTA",
      DPA_PORT_CODE: "VSN",
    },
    {
      CITY_CODE: "ITVTA",
      CITY_NAME: "VILLOTA DI CHIONS",
      DPA_PORT_CODE: "VTA",
    },
    {
      CITY_CODE: "ITVTN",
      CITY_NAME: "VESTONE",
      DPA_PORT_CODE: "VTN",
    },
    {
      CITY_CODE: "ITVVE",
      CITY_NAME: "VALVASONE",
      DPA_PORT_CODE: "VVE",
    },
    {
      CITY_CODE: "ITZAN",
      CITY_NAME: "ZANDOBBIO",
      DPA_PORT_CODE: "ZAN",
    },
    {
      CITY_CODE: "ITZIN",
      CITY_NAME: "ZINGONIA",
      DPA_PORT_CODE: "ZIN",
    },
    {
      CITY_CODE: "ITZOL",
      CITY_NAME: "ZOLA PREDOSA",
      DPA_PORT_CODE: "ZOL",
    },
    {
      CITY_CODE: "JMALP",
      CITY_NAME: "ALLIGATOR POND",
      DPA_PORT_CODE: "ALP",
    },
    {
      CITY_CODE: "JMFMH",
      CITY_NAME: "FALMOUTH",
      DPA_PORT_CODE: "FMH",
    },
    {
      CITY_CODE: "JMGGT",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GGT",
    },
    {
      CITY_CODE: "JMKIN",
      CITY_NAME: "KINGSTON",
      DPA_PORT_CODE: "KIN",
    },
    {
      CITY_CODE: "JMOCJ",
      CITY_NAME: "OCHO RIOS",
      DPA_PORT_CODE: "OCJ",
    },
    {
      CITY_CODE: "JMPEV",
      CITY_NAME: "PORT ESQUIVEL",
      DPA_PORT_CODE: "PEV",
    },
    {
      CITY_CODE: "JMPKS",
      CITY_NAME: "PORT KAISER",
      DPA_PORT_CODE: "PKS",
    },
    {
      CITY_CODE: "JMPOT",
      CITY_NAME: "PORT ANTONIO",
      DPA_PORT_CODE: "POT",
    },
    {
      CITY_CODE: "JMPRO",
      CITY_NAME: "PORT ROYAL",
      DPA_PORT_CODE: "PRO",
    },
    {
      CITY_CODE: "JOAQB",
      CITY_NAME: "AQABA FREE ZONE",
      DPA_PORT_CODE: "AQB",
    },
    {
      CITY_CODE: "JOAQJ",
      CITY_NAME: "AQABA",
      DPA_PORT_CODE: "AQJ",
    },
    {
      CITY_CODE: "JOQIR",
      CITY_NAME: "IRBID",
      DPA_PORT_CODE: "QIR",
    },
    {
      CITY_CODE: "JPABU",
      CITY_NAME: "ABURATSU",
      DPA_PORT_CODE: "ABU",
    },
    {
      CITY_CODE: "JPAIN",
      CITY_NAME: "AINOURA",
      DPA_PORT_CODE: "AIN",
    },
    {
      CITY_CODE: "JPAIO",
      CITY_NAME: "AIOI",
      DPA_PORT_CODE: "AIO",
    },
    {
      CITY_CODE: "JPAJX",
      CITY_NAME: "AJA",
      DPA_PORT_CODE: "AJX",
    },
    {
      CITY_CODE: "JPAOJ",
      CITY_NAME: "AOMORI",
      DPA_PORT_CODE: "AOJ",
    },
    {
      CITY_CODE: "JPAON",
      CITY_NAME: "AONAE",
      DPA_PORT_CODE: "AON",
    },
    {
      CITY_CODE: "JPAXT",
      CITY_NAME: "AKITA",
      DPA_PORT_CODE: "AXT",
    },
    {
      CITY_CODE: "JPBPU",
      CITY_NAME: "BEPPU",
      DPA_PORT_CODE: "BPU",
    },
    {
      CITY_CODE: "JPCHB",
      CITY_NAME: "CHIBA",
      DPA_PORT_CODE: "CHJ",
    },
    {
      CITY_CODE: "JPEMU",
      CITY_NAME: "EMUKAI",
      DPA_PORT_CODE: "EMU",
    },
    {
      CITY_CODE: "JPENO",
      CITY_NAME: "ENOURA",
      DPA_PORT_CODE: "ENO",
    },
    {
      CITY_CODE: "JPFHR",
      CITY_NAME: "FURUHIRA",
      DPA_PORT_CODE: "FHR",
    },
    {
      CITY_CODE: "JPFKJ",
      CITY_NAME: "FUKUI",
      DPA_PORT_CODE: "FKJ",
    },
    {
      CITY_CODE: "JPFKM",
      CITY_NAME: "FUKUSHIMA",
      DPA_PORT_CODE: "FKM",
    },
    {
      CITY_CODE: "JPFKS",
      CITY_NAME: "FUKUSHIMA",
      DPA_PORT_CODE: "FKS",
    },
    {
      CITY_CODE: "JPFKY",
      CITY_NAME: "FUKUYAMA",
      DPA_PORT_CODE: "FKY",
    },
    {
      CITY_CODE: "JPFNB",
      CITY_NAME: "FUNABASHI",
      DPA_PORT_CODE: "FNB",
    },
    {
      CITY_CODE: "JPFNK",
      CITY_NAME: "FUNAKAWA",
      DPA_PORT_CODE: "FNK",
    },
    {
      CITY_CODE: "JPFUJ",
      CITY_NAME: "FUKUE",
      DPA_PORT_CODE: "FUK",
    },
    {
      CITY_CODE: "JPFUT",
      CITY_NAME: "FUTAJIMA",
      DPA_PORT_CODE: "FUT",
    },
    {
      CITY_CODE: "JPHBK",
      CITY_NAME: "HIBIKINADA",
      DPA_PORT_CODE: "HBK",
    },
    {
      CITY_CODE: "JPHDA",
      CITY_NAME: "HANDA",
      DPA_PORT_CODE: "HDA",
    },
    {
      CITY_CODE: "JPHHE",
      CITY_NAME: "HACHINOHE",
      DPA_PORT_CODE: "HHE",
    },
    {
      CITY_CODE: "JPHIB",
      CITY_NAME: "HIBI",
      DPA_PORT_CODE: "HIB",
    },
    {
      CITY_CODE: "JPHIJ",
      CITY_NAME: "HIROSHIMA",
      DPA_PORT_CODE: "HIJ",
    },
    {
      CITY_CODE: "JPHIK",
      CITY_NAME: "HIKI",
      DPA_PORT_CODE: "HIK",
    },
    {
      CITY_CODE: "JPHIM",
      CITY_NAME: "HIMEJI",
      DPA_PORT_CODE: "HIM",
    },
    {
      CITY_CODE: "JPHKD",
      CITY_NAME: "HAKODATE",
      DPA_PORT_CODE: "HKD",
    },
    {
      CITY_CODE: "JPHKN",
      CITY_NAME: "HEKINAN",
      DPA_PORT_CODE: "HKN",
    },
    {
      CITY_CODE: "JPHKR",
      CITY_NAME: "HIKARI",
      DPA_PORT_CODE: "HKR",
    },
    {
      CITY_CODE: "JPHKS",
      CITY_NAME: "HAKATASHIMA",
      DPA_PORT_CODE: "HKS",
    },
    {
      CITY_CODE: "JPHKT",
      CITY_NAME: "HAKATA",
      DPA_PORT_CODE: "HKT",
    },
    {
      CITY_CODE: "JPHMD",
      CITY_NAME: "HAMADA",
      DPA_PORT_CODE: "HMD",
    },
    {
      CITY_CODE: "JPHRA",
      CITY_NAME: "HIRAO",
      DPA_PORT_CODE: "HRA",
    },
    {
      CITY_CODE: "JPHSM",
      CITY_NAME: "HOSOJIMA",
      DPA_PORT_CODE: "HSM",
    },
    {
      CITY_CODE: "JPHTC",
      CITY_NAME: "HITACHI",
      DPA_PORT_CODE: "HTC",
    },
    {
      CITY_CODE: "JPIEJ",
      CITY_NAME: "IEJIMA",
      DPA_PORT_CODE: "IEJ",
    },
    {
      CITY_CODE: "JPIHA",
      CITY_NAME: "NIIHAMA",
      DPA_PORT_CODE: "IHA",
    },
    {
      CITY_CODE: "JPIIN",
      CITY_NAME: "NISHINOOMOTE",
      DPA_PORT_CODE: "IIN",
    },
    {
      CITY_CODE: "JPIKE",
      CITY_NAME: "IKEJIMA",
      DPA_PORT_CODE: "IKE",
    },
    {
      CITY_CODE: "JPIMB",
      CITY_NAME: "IMABARI",
      DPA_PORT_CODE: "IMB",
    },
    {
      CITY_CODE: "JPIMI",
      CITY_NAME: "IMARI",
      DPA_PORT_CODE: "IMI",
    },
    {
      CITY_CODE: "JPIRK",
      CITY_NAME: "IRAGO",
      DPA_PORT_CODE: "IRK",
    },
    {
      CITY_CODE: "JPISG",
      CITY_NAME: "ISHIGAKI",
      DPA_PORT_CODE: "ISG",
    },
    {
      CITY_CODE: "JPISI",
      CITY_NAME: "ISHIKARI",
      DPA_PORT_CODE: "ISI",
    },
    {
      CITY_CODE: "JPISS",
      CITY_NAME: "ISHIKARIWANSHINKO",
      DPA_PORT_CODE: "ISH",
    },
    {
      CITY_CODE: "JPIWJ",
      CITY_NAME: "IWO JIMA",
      DPA_PORT_CODE: "IWJ",
    },
    {
      CITY_CODE: "JPIWK",
      CITY_NAME: "IWAKUNI",
      DPA_PORT_CODE: "IWK",
    },
    {
      CITY_CODE: "JPIWN",
      CITY_NAME: "IWANAI",
      DPA_PORT_CODE: "IWN",
    },
    {
      CITY_CODE: "JPIYM",
      CITY_NAME: "IYOMISHIMA",
      DPA_PORT_CODE: "IYM",
    },
    {
      CITY_CODE: "JPIZH",
      CITY_NAME: "IZUHARA",
      DPA_PORT_CODE: "IZH",
    },
    {
      CITY_CODE: "JPIZO",
      CITY_NAME: "IZUMO",
      DPA_PORT_CODE: "IZO",
    },
    {
      CITY_CODE: "JPIZS",
      CITY_NAME: "IZUMISANO",
      DPA_PORT_CODE: "IZS",
    },
    {
      CITY_CODE: "JPKAW",
      CITY_NAME: "KAWAUCHI",
      DPA_PORT_CODE: "KAW",
    },
    {
      CITY_CODE: "JPKCZ",
      CITY_NAME: "KOCHI",
      DPA_PORT_CODE: "KCZ",
    },
    {
      CITY_CODE: "JPKHR",
      CITY_NAME: "KIHARA",
      DPA_PORT_CODE: "KHR",
    },
    {
      CITY_CODE: "JPKII",
      CITY_NAME: "KIIRE",
      DPA_PORT_CODE: "KII",
    },
    {
      CITY_CODE: "JPKIJ",
      CITY_NAME: "NIIGATA",
      DPA_PORT_CODE: "KIJ",
    },
    {
      CITY_CODE: "JPKJI",
      CITY_NAME: "KUJI",
      DPA_PORT_CODE: "KJI",
    },
    {
      CITY_CODE: "JPKKJ",
      CITY_NAME: "KITA KYUSHU",
      DPA_PORT_CODE: "KKJ",
    },
    {
      CITY_CODE: "JPKKM",
      CITY_NAME: "KATAKAMI",
      DPA_PORT_CODE: "KKM",
    },
    {
      CITY_CODE: "JPKKW",
      CITY_NAME: "KANOKAWA",
      DPA_PORT_CODE: "KKW",
    },
    {
      CITY_CODE: "JPKMJ",
      CITY_NAME: "KUMAMOTO",
      DPA_PORT_CODE: "KMJ",
    },
    {
      CITY_CODE: "JPKMS",
      CITY_NAME: "KUMESHIMA",
      DPA_PORT_CODE: "KMS",
    },
    {
      CITY_CODE: "JPKND",
      CITY_NAME: "KANDA",
      DPA_PORT_CODE: "KND",
    },
    {
      CITY_CODE: "JPKNY",
      CITY_NAME: "KONIYA",
      DPA_PORT_CODE: "KNY",
    },
    {
      CITY_CODE: "JPKNZ",
      CITY_NAME: "KANAZAWA",
      DPA_PORT_CODE: "KNZ",
    },
    {
      CITY_CODE: "JPKOJ",
      CITY_NAME: "KAGOSHIMA",
      DPA_PORT_CODE: "KOJ",
    },
    {
      CITY_CODE: "JPKOK",
      CITY_NAME: "KOKURA",
      DPA_PORT_CODE: "KOK",
    },
    {
      CITY_CODE: "JPKRH",
      CITY_NAME: "KURIHAMA",
      DPA_PORT_CODE: "KRH",
    },
    {
      CITY_CODE: "JPKSA",
      CITY_NAME: "KASAOKA",
      DPA_PORT_CODE: "KSA",
    },
    {
      CITY_CODE: "JPKSM",
      CITY_NAME: "KASHIMA",
      DPA_PORT_CODE: "KSM",
    },
    {
      CITY_CODE: "JPKTI",
      CITY_NAME: "KATAICHI",
      DPA_PORT_CODE: "KTI",
    },
    {
      CITY_CODE: "JPKUC",
      CITY_NAME: "KUCHINOTSU",
      DPA_PORT_CODE: "KUC",
    },
    {
      CITY_CODE: "JPKUH",
      CITY_NAME: "KUSHIRO",
      DPA_PORT_CODE: "KUH",
    },
    {
      CITY_CODE: "JPKWS",
      CITY_NAME: "KAWASAKI",
      DPA_PORT_CODE: "KWS",
    },
    {
      CITY_CODE: "JPKWZ",
      CITY_NAME: "KASHIWAZAKI",
      DPA_PORT_CODE: "KWZ",
    },
    {
      CITY_CODE: "JPKZU",
      CITY_NAME: "KISARAZU",
      DPA_PORT_CODE: "KZU",
    },
    {
      CITY_CODE: "JPMAI",
      CITY_NAME: "MAIZURU",
      DPA_PORT_CODE: "MAZ",
    },
    {
      CITY_CODE: "JPMBE",
      CITY_NAME: "MONBETSU",
      DPA_PORT_CODE: "MBE",
    },
    {
      CITY_CODE: "JPMHR",
      CITY_NAME: "MIHARA",
      DPA_PORT_CODE: "MHR",
    },
    {
      CITY_CODE: "JPMIY",
      CITY_NAME: "MIYAZU",
      DPA_PORT_CODE: "MIY",
    },
    {
      CITY_CODE: "JPMIZ",
      CITY_NAME: "MIZUSHIMA",
      DPA_PORT_CODE: "MIZ",
    },
    {
      CITY_CODE: "JPMKS",
      CITY_NAME: "MITSUKOSHIMA",
      DPA_PORT_CODE: "MKS",
    },
    {
      CITY_CODE: "JPMMD",
      CITY_NAME: "MINAMI DAITO",
      DPA_PORT_CODE: "MMD",
    },
    {
      CITY_CODE: "JPMOJ",
      CITY_NAME: "MOJI",
      DPA_PORT_CODE: "MOJ",
    },
    {
      CITY_CODE: "JPMSK",
      CITY_NAME: "MASHIKE",
      DPA_PORT_CODE: "MSK",
    },
    {
      CITY_CODE: "JPMUR",
      CITY_NAME: "MURORAN",
      DPA_PORT_CODE: "MUR",
    },
    {
      CITY_CODE: "JPMYJ",
      CITY_NAME: "MATSUYAMA",
      DPA_PORT_CODE: "MYJ",
    },
    {
      CITY_CODE: "JPMYK",
      CITY_NAME: "MIYAKO",
      DPA_PORT_CODE: "MYK",
    },
    {
      CITY_CODE: "JPNAG",
      CITY_NAME: "NAGAHAMA",
      DPA_PORT_CODE: "NHG",
    },
    {
      CITY_CODE: "JPNAH",
      CITY_NAME: "NAHA",
      DPA_PORT_CODE: "NHA",
    },
    {
      CITY_CODE: "JPNAO",
      CITY_NAME: "NAOETSU",
      DPA_PORT_CODE: "NAO",
    },
    {
      CITY_CODE: "JPNGO",
      CITY_NAME: "NAGOYA",
      DPA_PORT_CODE: "NGO",
    },
    {
      CITY_CODE: "JPNGS",
      CITY_NAME: "NAGASAKI",
      DPA_PORT_CODE: "NGS",
    },
    {
      CITY_CODE: "JPNHJ",
      CITY_NAME: "NOHEJI",
      DPA_PORT_CODE: "NHJ",
    },
    {
      CITY_CODE: "JPNHR",
      CITY_NAME: "NISHIHARA",
      DPA_PORT_CODE: "NHR",
    },
    {
      CITY_CODE: "JPNIC",
      CITY_NAME: "NICHINAN",
      DPA_PORT_CODE: "NIC",
    },
    {
      CITY_CODE: "JPNOB",
      CITY_NAME: "NOBEOKA",
      DPA_PORT_CODE: "NOB",
    },
    {
      CITY_CODE: "JPNRU",
      CITY_NAME: "NARUTO",
      DPA_PORT_CODE: "NRU",
    },
    {
      CITY_CODE: "JPNSR",
      CITY_NAME: "NOSHIRO",
      DPA_PORT_CODE: "NSR",
    },
    {
      CITY_CODE: "JPNSU",
      CITY_NAME: "NAGASU",
      DPA_PORT_CODE: "NSU",
    },
    {
      CITY_CODE: "JPNYO",
      CITY_NAME: "NANYO",
      DPA_PORT_CODE: "NYO",
    },
    {
      CITY_CODE: "JPOBM",
      CITY_NAME: "OBAMA",
      DPA_PORT_CODE: "OBM",
    },
    {
      CITY_CODE: "JPOFT",
      CITY_NAME: "OFUNATO",
      DPA_PORT_CODE: "OFT",
    },
    {
      CITY_CODE: "JPOGA",
      CITY_NAME: "OGASAWARA",
      DPA_PORT_CODE: "OGA",
    },
    {
      CITY_CODE: "JPOGF",
      CITY_NAME: "OGIFUSHI",
      DPA_PORT_CODE: "OGF",
    },
    {
      CITY_CODE: "JPOIG",
      CITY_NAME: "OHIGAWA",
      DPA_PORT_CODE: "OIG",
    },
    {
      CITY_CODE: "JPOIT",
      CITY_NAME: "OITA",
      DPA_PORT_CODE: "OIT",
    },
    {
      CITY_CODE: "JPOKA",
      CITY_NAME: "OKINAWA",
      DPA_PORT_CODE: "OKA",
    },
    {
      CITY_CODE: "JPOKI",
      CITY_NAME: "OKI",
      DPA_PORT_CODE: "OKI",
    },
    {
      CITY_CODE: "JPOMT",
      CITY_NAME: "OMINATO",
      DPA_PORT_CODE: "OMT",
    },
    {
      CITY_CODE: "JPOMU",
      CITY_NAME: "OMUTA",
      DPA_PORT_CODE: "OMU",
    },
    {
      CITY_CODE: "JPOMZ",
      CITY_NAME: "OMAEZAKI",
      DPA_PORT_CODE: "OMZ",
    },
    {
      CITY_CODE: "JPONA",
      CITY_NAME: "ONAHAMA",
      DPA_PORT_CODE: "ONA",
    },
    {
      CITY_CODE: "JPONB",
      CITY_NAME: "ONNEBETSU",
      DPA_PORT_CODE: "ONB",
    },
    {
      CITY_CODE: "JPONG",
      CITY_NAME: "ONAGAWA",
      DPA_PORT_CODE: "ONG",
    },
    {
      CITY_CODE: "JPONU",
      CITY_NAME: "ONUKI",
      DPA_PORT_CODE: "ONU",
    },
    {
      CITY_CODE: "JPOSA",
      CITY_NAME: "OSAKA",
      DPA_PORT_CODE: "OSA",
    },
    {
      CITY_CODE: "JPOTK",
      CITY_NAME: "OTAKE",
      DPA_PORT_CODE: "OTK",
    },
    {
      CITY_CODE: "JPOTU",
      CITY_NAME: "OTSU",
      DPA_PORT_CODE: "OTU",
    },
    {
      CITY_CODE: "JPRMI",
      CITY_NAME: "RUMOI",
      DPA_PORT_CODE: "RMI",
    },
    {
      CITY_CODE: "JPSBS",
      CITY_NAME: "SHIBUSHI",
      DPA_PORT_CODE: "SBS",
    },
    {
      CITY_CODE: "JPSDJ",
      CITY_NAME: "SENDAI",
      DPA_PORT_CODE: "SDJ",
    },
    {
      CITY_CODE: "JPSDS",
      CITY_NAME: "SENDAISHINKO",
      DPA_PORT_CODE: "SDS",
    },
    {
      CITY_CODE: "JPSEN",
      CITY_NAME: "SENDAI",
      DPA_PORT_CODE: "SEN",
    },
    {
      CITY_CODE: "JPSHS",
      CITY_NAME: "SHIMONOSEKI",
      DPA_PORT_CODE: "SHS",
    },
    {
      CITY_CODE: "JPSKM",
      CITY_NAME: "SHIKAMA",
      DPA_PORT_CODE: "SKM",
    },
    {
      CITY_CODE: "JPSKT",
      CITY_NAME: "SAKATA",
      DPA_PORT_CODE: "SKA",
    },
    {
      CITY_CODE: "JPSMB",
      CITY_NAME: "SHIMABARA",
      DPA_PORT_CODE: "SMB",
    },
    {
      CITY_CODE: "JPSMK",
      CITY_NAME: "SAMUKAWA",
      DPA_PORT_CODE: "SMK",
    },
    {
      CITY_CODE: "JPSMN",
      CITY_NAME: "SAKAIMINATO",
      DPA_PORT_CODE: "SMN",
    },
    {
      CITY_CODE: "JPSMT",
      CITY_NAME: "SHIMOTSU",
      DPA_PORT_CODE: "SMT",
    },
    {
      CITY_CODE: "JPSMZ",
      CITY_NAME: "SHIMIZU",
      DPA_PORT_CODE: "SMZ",
    },
    {
      CITY_CODE: "JPSSB",
      CITY_NAME: "SASEBO",
      DPA_PORT_CODE: "SSB",
    },
    {
      CITY_CODE: "JPSZU",
      CITY_NAME: "SHIZUURA",
      DPA_PORT_CODE: "SZU",
    },
    {
      CITY_CODE: "JPTAK",
      CITY_NAME: "TAKAMATSU",
      DPA_PORT_CODE: "TAK",
    },
    {
      CITY_CODE: "JPTBN",
      CITY_NAME: "TACHIBANA",
      DPA_PORT_CODE: "TBN",
    },
    {
      CITY_CODE: "JPTBT",
      CITY_NAME: "TOBATA",
      DPA_PORT_CODE: "TBT",
    },
    {
      CITY_CODE: "JPTGM",
      CITY_NAME: "TAGUMA",
      DPA_PORT_CODE: "TGM",
    },
    {
      CITY_CODE: "JPTHS",
      CITY_NAME: "TOYOHASHI",
      DPA_PORT_CODE: "THI",
    },
    {
      CITY_CODE: "JPTKM",
      CITY_NAME: "TAKUMA",
      DPA_PORT_CODE: "TKM",
    },
    {
      CITY_CODE: "JPTKO",
      CITY_NAME: "TOKONAMI",
      DPA_PORT_CODE: "TKO",
    },
    {
      CITY_CODE: "JPTKR",
      CITY_NAME: "TOKORO",
      DPA_PORT_CODE: "TKR",
    },
    {
      CITY_CODE: "JPTKS",
      CITY_NAME: "TOKUSHIMA",
      DPA_PORT_CODE: "TKS",
    },
    {
      CITY_CODE: "JPTKY",
      CITY_NAME: "TOKUYAMA",
      DPA_PORT_CODE: "TKY",
    },
    {
      CITY_CODE: "JPTMI",
      CITY_NAME: "TSUKUMI",
      DPA_PORT_CODE: "TMI",
    },
    {
      CITY_CODE: "JPTMK",
      CITY_NAME: "TOMAKOMAI",
      DPA_PORT_CODE: "TMK",
    },
    {
      CITY_CODE: "JPTMR",
      CITY_NAME: "TOMARI",
      DPA_PORT_CODE: "TMR",
    },
    {
      CITY_CODE: "JPTND",
      CITY_NAME: "TONDA",
      DPA_PORT_CODE: "TND",
    },
    {
      CITY_CODE: "JPTNI",
      CITY_NAME: "TSUNEISHI",
      DPA_PORT_CODE: "TNI",
    },
    {
      CITY_CODE: "JPTOK",
      CITY_NAME: "TOKACHI",
      DPA_PORT_CODE: "TOK",
    },
    {
      CITY_CODE: "JPTOY",
      CITY_NAME: "TOYAMA",
      DPA_PORT_CODE: "TOY",
    },
    {
      CITY_CODE: "JPTRG",
      CITY_NAME: "TSURUGA",
      DPA_PORT_CODE: "TRA",
    },
    {
      CITY_CODE: "JPTRM",
      CITY_NAME: "TSURUMI",
      DPA_PORT_CODE: "TRM",
    },
    {
      CITY_CODE: "JPTSG",
      CITY_NAME: "TAKASAGO",
      DPA_PORT_CODE: "TSG",
    },
    {
      CITY_CODE: "JPTSM",
      CITY_NAME: "TAKASHIMA",
      DPA_PORT_CODE: "TSM",
    },
    {
      CITY_CODE: "JPTSZ",
      CITY_NAME: "TOSASHIMIZU",
      DPA_PORT_CODE: "TSZ",
    },
    {
      CITY_CODE: "JPTTJ",
      CITY_NAME: "TOTTORI",
      DPA_PORT_CODE: "TTJ",
    },
    {
      CITY_CODE: "JPTYM",
      CITY_NAME: "TSUIYAMA",
      DPA_PORT_CODE: "TYM",
    },
    {
      CITY_CODE: "JPTYO",
      CITY_NAME: "TOKYO",
      DPA_PORT_CODE: "TYO",
    },
    {
      CITY_CODE: "JPTZK",
      CITY_NAME: "TSUCHIZAKI",
      DPA_PORT_CODE: "TZK",
    },
    {
      CITY_CODE: "JPUBJ",
      CITY_NAME: "UBE",
      DPA_PORT_CODE: "UBJ",
    },
    {
      CITY_CODE: "JPUCH",
      CITY_NAME: "UCHIUMI",
      DPA_PORT_CODE: "UCH",
    },
    {
      CITY_CODE: "JPUCR",
      CITY_NAME: "UCHIURA",
      DPA_PORT_CODE: "UCR",
    },
    {
      CITY_CODE: "JPUCU",
      CITY_NAME: "UCHINOURA",
      DPA_PORT_CODE: "UCU",
    },
    {
      CITY_CODE: "JPUGU",
      CITY_NAME: "UGUSU",
      DPA_PORT_CODE: "UGU",
    },
    {
      CITY_CODE: "JPUKB",
      CITY_NAME: "KOBE",
      DPA_PORT_CODE: "UKB",
    },
    {
      CITY_CODE: "JPUKY",
      CITY_NAME: "KYOTO",
      DPA_PORT_CODE: "UKY",
    },
    {
      CITY_CODE: "JPUNT",
      CITY_NAME: "UNTEN",
      DPA_PORT_CODE: "UNT",
    },
    {
      CITY_CODE: "JPUOZ",
      CITY_NAME: "UOZU",
      DPA_PORT_CODE: "UOZ",
    },
    {
      CITY_CODE: "JPURK",
      CITY_NAME: "URAKAWA",
      DPA_PORT_CODE: "URK",
    },
    {
      CITY_CODE: "JPUSJ",
      CITY_NAME: "USUJIRI",
      DPA_PORT_CODE: "USJ",
    },
    {
      CITY_CODE: "JPWAK",
      CITY_NAME: "WAKAYAMA",
      DPA_PORT_CODE: "WAK",
    },
    {
      CITY_CODE: "JPWJM",
      CITY_NAME: "WAJIMA",
      DPA_PORT_CODE: "WJM",
    },
    {
      CITY_CODE: "JPYAE",
      CITY_NAME: "YAEYAMA",
      DPA_PORT_CODE: "YAE",
    },
    {
      CITY_CODE: "JPYAT",
      CITY_NAME: "YATSUSHIRO",
      DPA_PORT_CODE: "YAT",
    },
    {
      CITY_CODE: "JPYBT",
      CITY_NAME: "YUBETSU",
      DPA_PORT_CODE: "YBT",
    },
    {
      CITY_CODE: "JPYKK",
      CITY_NAME: "YOKKAICHI",
      DPA_PORT_CODE: "YKK",
    },
    {
      CITY_CODE: "JPYKS",
      CITY_NAME: "YOKOSHIMA",
      DPA_PORT_CODE: "YKS",
    },
    {
      CITY_CODE: "JPYMG",
      CITY_NAME: "YAMAGUCHI",
      DPA_PORT_CODE: "YMG",
    },
    {
      CITY_CODE: "JPYOK",
      CITY_NAME: "YOKOHAMA",
      DPA_PORT_CODE: "YOK",
    },
    {
      CITY_CODE: "JPYOS",
      CITY_NAME: "YOKOSUKA",
      DPA_PORT_CODE: "YOS",
    },
    {
      CITY_CODE: "JPYUR",
      CITY_NAME: "YURA",
      DPA_PORT_CODE: "YUR",
    },
    {
      CITY_CODE: "JPYWH",
      CITY_NAME: "YAWATAHAMA",
      DPA_PORT_CODE: "YWH",
    },
    {
      CITY_CODE: "JPYWT",
      CITY_NAME: "YAWATA",
      DPA_PORT_CODE: "YWT",
    },
    {
      CITY_CODE: "JPYZU",
      CITY_NAME: "YAIZU",
      DPA_PORT_CODE: "YZU",
    },
    {
      CITY_CODE: "KEKIS",
      CITY_NAME: "KISUMU",
      DPA_PORT_CODE: "KIS",
    },
    {
      CITY_CODE: "KEMBA",
      CITY_NAME: "MOMBASA",
      DPA_PORT_CODE: "MBA",
    },
    {
      CITY_CODE: "KHKKO",
      CITY_NAME: "KAS KONG",
      DPA_PORT_CODE: "KKO",
    },
    {
      CITY_CODE: "KHKMT",
      CITY_NAME: "KAMPOT",
      DPA_PORT_CODE: "KMT",
    },
    {
      CITY_CODE: "KHKOS",
      CITY_NAME: "KAMPONG SAOM",
      DPA_PORT_CODE: "KOS",
    },
    {
      CITY_CODE: "KHPNH",
      CITY_NAME: "PHNOM PENH",
      DPA_PORT_CODE: "PNH",
    },
    {
      CITY_CODE: "KHSHV",
      CITY_NAME: "SIHANOUKVILLE",
      DPA_PORT_CODE: "SHV",
    },
    {
      CITY_CODE: "KIPHO",
      CITY_NAME: "PHOENIX ISLANDS",
      DPA_PORT_CODE: "PHO",
    },
    {
      CITY_CODE: "KITRW",
      CITY_NAME: "TARAWA",
      DPA_PORT_CODE: "TRW",
    },
    {
      CITY_CODE: "KIWNI",
      CITY_NAME: "WASHINGTON ISLANDS",
      DPA_PORT_CODE: "WNI",
    },
    {
      CITY_CODE: "KMDZA",
      CITY_NAME: "DZAOUDZI",
      DPA_PORT_CODE: "DZA",
    },
    {
      CITY_CODE: "KMMUT",
      CITY_NAME: "MUTSAMUDU",
      DPA_PORT_CODE: "MUT",
    },
    {
      CITY_CODE: "KMYVA",
      CITY_NAME: "MORONI",
      DPA_PORT_CODE: "YVA",
    },
    {
      CITY_CODE: "KNBAS",
      CITY_NAME: "BASSETERRE",
      DPA_PORT_CODE: "BAS",
    },
    {
      CITY_CODE: "KNNEV",
      CITY_NAME: "CHARLESTON",
      DPA_PORT_CODE: "NEV",
    },
    {
      CITY_CODE: "KPNAM",
      CITY_NAME: "NAMPO",
      DPA_PORT_CODE: "NAM",
    },
    {
      CITY_CODE: "KRCHU",
      CITY_NAME: "CHUNGJU",
      DPA_PORT_CODE: "CHU",
    },
    {
      CITY_CODE: "KRCJU",
      CITY_NAME: "CHEJU",
      DPA_PORT_CODE: "CJU",
    },
    {
      CITY_CODE: "KRICE",
      CITY_NAME: "ICHEON",
      DPA_PORT_CODE: "IAC",
    },
    {
      CITY_CODE: "KRINC",
      CITY_NAME: "INCHEON",
      DPA_PORT_CODE: "INC",
    },
    {
      CITY_CODE: "KRKAN",
      CITY_NAME: "GWANGYANG",
      DPA_PORT_CODE: "KAN",
    },
    {
      CITY_CODE: "KRKPO",
      CITY_NAME: "POHANG",
      DPA_PORT_CODE: "KPO",
    },
    {
      CITY_CODE: "KRKUV",
      CITY_NAME: "KUNSAN",
      DPA_PORT_CODE: "KUV",
    },
    {
      CITY_CODE: "KRKWA",
      CITY_NAME: "KWANGYANG",
      DPA_PORT_CODE: "KWG",
    },
    {
      CITY_CODE: "KRKWJ",
      CITY_NAME: "KWANGJU",
      DPA_PORT_CODE: "KWJ",
    },
    {
      CITY_CODE: "KRKWU",
      CITY_NAME: "KWANGJU",
      DPA_PORT_CODE: "KWU",
    },
    {
      CITY_CODE: "KRMAS",
      CITY_NAME: "MASAN",
      DPA_PORT_CODE: "MAS",
    },
    {
      CITY_CODE: "KRPTK",
      CITY_NAME: "PYEONGTAEK",
      DPA_PORT_CODE: "PKV",
    },
    {
      CITY_CODE: "KRPUS",
      CITY_NAME: "PUSAN",
      DPA_PORT_CODE: "PUS",
    },
    {
      CITY_CODE: "KRRSU",
      CITY_NAME: "YEOSU APT",
      DPA_PORT_CODE: "OSU",
    },
    {
      CITY_CODE: "KRSHO",
      CITY_NAME: "SOKCHO",
      DPA_PORT_CODE: "SHO",
    },
    {
      CITY_CODE: "KRSUK",
      CITY_NAME: "SAMCHOK",
      DPA_PORT_CODE: "SUK",
    },
    {
      CITY_CODE: "KRSUO",
      CITY_NAME: "SUWON",
      DPA_PORT_CODE: "SUO",
    },
    {
      CITY_CODE: "KRTJI",
      CITY_NAME: "DANGJIN GUN",
      DPA_PORT_CODE: "TJI",
    },
    {
      CITY_CODE: "KRUSN",
      CITY_NAME: "ULSAN",
      DPA_PORT_CODE: "USN",
    },
    {
      CITY_CODE: "KRYEC",
      CITY_NAME: "YECHEON",
      DPA_PORT_CODE: "YEC",
    },
    {
      CITY_CODE: "KRYNG",
      CITY_NAME: "YONGIN",
      DPA_PORT_CODE: "YNG",
    },
    {
      CITY_CODE: "KRYOC",
      CITY_NAME: "YOCHON",
      DPA_PORT_CODE: "YOC",
    },
    {
      CITY_CODE: "KRYOS",
      CITY_NAME: "YEOSU",
      DPA_PORT_CODE: "YSU",
    },
    {
      CITY_CODE: "KWCAJ",
      CITY_NAME: "CAMP ARIFJAN",
      DPA_PORT_CODE: "CAJ",
    },
    {
      CITY_CODE: "KWCBG",
      CITY_NAME: "CAMP BUEHRING",
      DPA_PORT_CODE: "CBV",
    },
    {
      CITY_CODE: "KWCDH",
      CITY_NAME: "CAMP DOHA",
      DPA_PORT_CODE: "CDH",
    },
    {
      CITY_CODE: "KWJAH",
      CITY_NAME: "JAHRAN",
      DPA_PORT_CODE: "JAH",
    },
    {
      CITY_CODE: "KWJBD",
      CITY_NAME: "JEBEL DHANA",
      DPA_PORT_CODE: "JBD",
    },
    {
      CITY_CODE: "KWJUA",
      CITY_NAME: "JUAYMAH",
      DPA_PORT_CODE: "JUA",
    },
    {
      CITY_CODE: "KWKWI",
      CITY_NAME: "KUWAIT",
      DPA_PORT_CODE: "KWI",
    },
    {
      CITY_CODE: "KWMIB",
      CITY_NAME: "MINA ABD ALLAH",
      DPA_PORT_CODE: "MIB",
    },
    {
      CITY_CODE: "KWSAA",
      CITY_NAME: "SHUAIBA",
      DPA_PORT_CODE: "SAA",
    },
    {
      CITY_CODE: "KWSWK",
      CITY_NAME: "SHUWAIKH",
      DPA_PORT_CODE: "SWK",
    },
    {
      CITY_CODE: "KYGEC",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GEC",
    },
    {
      CITY_CODE: "KZAKX",
      CITY_NAME: "AKHTUBINSK",
      DPA_PORT_CODE: "AKX",
    },
    {
      CITY_CODE: "KZBXH",
      CITY_NAME: "BALHASH",
      DPA_PORT_CODE: "BZT",
    },
    {
      CITY_CODE: "KZGUW",
      CITY_NAME: "ATYRAU",
      DPA_PORT_CODE: "GUU",
    },
    {
      CITY_CODE: "LBBEY",
      CITY_NAME: "BEIRUT",
      DPA_PORT_CODE: "BEY",
    },
    {
      CITY_CODE: "LBBFZ",
      CITY_NAME: "BEIRUT FREE ZONE",
      DPA_PORT_CODE: "BFZ",
    },
    {
      CITY_CODE: "LBKYE",
      CITY_NAME: "TRIPOLI",
      DPA_PORT_CODE: "KYE",
    },
    {
      CITY_CODE: "LBOUZ",
      CITY_NAME: "OUZAI",
      DPA_PORT_CODE: "OUZ",
    },
    {
      CITY_CODE: "LBSAY",
      CITY_NAME: "SAYDA",
      DPA_PORT_CODE: "SAY",
    },
    {
      CITY_CODE: "LBSUR",
      CITY_NAME: "SUR",
      DPA_PORT_CODE: "SUR",
    },
    {
      CITY_CODE: "LCSLU",
      CITY_NAME: "CASTRIES",
      DPA_PORT_CODE: "SLU",
    },
    {
      CITY_CODE: "LKCMB",
      CITY_NAME: "COLOMBO",
      DPA_PORT_CODE: "CMB",
    },
    {
      CITY_CODE: "LKJAF",
      CITY_NAME: "JAFFNA",
      DPA_PORT_CODE: "JAF",
    },
    {
      CITY_CODE: "LKJCT",
      CITY_NAME: "JAYA CONTAINER TERMINAL",
      DPA_PORT_CODE: "JCM",
    },
    {
      CITY_CODE: "LKKAY",
      CITY_NAME: "KAYTS",
      DPA_PORT_CODE: "KAY",
    },
    {
      CITY_CODE: "LKKNK",
      CITY_NAME: "KANKESANTURAI",
      DPA_PORT_CODE: "KNK",
    },
    {
      CITY_CODE: "LKTRR",
      CITY_NAME: "TRINCOMALEE",
      DPA_PORT_CODE: "TRR",
    },
    {
      CITY_CODE: "LRCPA",
      CITY_NAME: "CAPE PALMAS",
      DPA_PORT_CODE: "CPA",
    },
    {
      CITY_CODE: "LRFIM",
      CITY_NAME: "FIMIBO",
      DPA_PORT_CODE: "FIM",
    },
    {
      CITY_CODE: "LRGBS",
      CITY_NAME: "GRAND BASSA",
      DPA_PORT_CODE: "GBS",
    },
    {
      CITY_CODE: "LRHBL",
      CITY_NAME: "HARBEL",
      DPA_PORT_CODE: "HBL",
    },
    {
      CITY_CODE: "LRMLW",
      CITY_NAME: "MONROVIA",
      DPA_PORT_CODE: "MLW",
    },
    {
      CITY_CODE: "LRRVC",
      CITY_NAME: "RIVERCESS",
      DPA_PORT_CODE: "RVC",
    },
    {
      CITY_CODE: "LRUCN",
      CITY_NAME: "BUCHANAN",
      DPA_PORT_CODE: "UCN",
    },
    {
      CITY_CODE: "LTKLJ",
      CITY_NAME: "KLAIPEDA",
      DPA_PORT_CODE: "KLJ",
    },
    {
      CITY_CODE: "LTKUN",
      CITY_NAME: "KAUNAS",
      DPA_PORT_CODE: "KUN",
    },
    {
      CITY_CODE: "LUBSC",
      CITY_NAME: "BASCHARAGE",
      DPA_PORT_CODE: "BSC",
    },
    {
      CITY_CODE: "LULUX",
      CITY_NAME: "LUXEMBOURG",
      DPA_PORT_CODE: "LUX",
    },
    {
      CITY_CODE: "LVLPX",
      CITY_NAME: "LIEPAYA",
      DPA_PORT_CODE: "LPX",
    },
    {
      CITY_CODE: "LVRIX",
      CITY_NAME: "RIGA",
      DPA_PORT_CODE: "RIX",
    },
    {
      CITY_CODE: "LVVNT",
      CITY_NAME: "VENTSPILS",
      DPA_PORT_CODE: "VNT",
    },
    {
      CITY_CODE: "LYABA",
      CITY_NAME: "AL BAYDA",
      DPA_PORT_CODE: "ABA",
    },
    {
      CITY_CODE: "LYBEN",
      CITY_NAME: "BINGAZI",
      DPA_PORT_CODE: "BEN",
    },
    {
      CITY_CODE: "LYDRX",
      CITY_NAME: "DARNAH",
      DPA_PORT_CODE: "DRX",
    },
    {
      CITY_CODE: "LYESI",
      CITY_NAME: "ES SIDER",
      DPA_PORT_CODE: "ESI",
    },
    {
      CITY_CODE: "LYKHM",
      CITY_NAME: "KHOMS",
      DPA_PORT_CODE: "KHD",
    },
    {
      CITY_CODE: "LYKHO",
      CITY_NAME: "AL KHUMS",
      DPA_PORT_CODE: "KHM",
    },
    {
      CITY_CODE: "LYLMQ",
      CITY_NAME: "MARSA BREGA",
      DPA_PORT_CODE: "MBR",
    },
    {
      CITY_CODE: "LYMRA",
      CITY_NAME: "MISURATA",
      DPA_PORT_CODE: "MRA",
    },
    {
      CITY_CODE: "LYRLA",
      CITY_NAME: "RAS LANUF",
      DPA_PORT_CODE: "RLA",
    },
    {
      CITY_CODE: "LYTIP",
      CITY_NAME: "TRIPOLI",
      DPA_PORT_CODE: "TIP",
    },
    {
      CITY_CODE: "LYTOB",
      CITY_NAME: "TOBRUK",
      DPA_PORT_CODE: "TOB",
    },
    {
      CITY_CODE: "LYZLI",
      CITY_NAME: "ZLITEN",
      DPA_PORT_CODE: "ZLI",
    },
    {
      CITY_CODE: "LYZUA",
      CITY_NAME: "ZUARA",
      DPA_PORT_CODE: "ZUA",
    },
    {
      CITY_CODE: "MAAGA",
      CITY_NAME: "AGADIR",
      DPA_PORT_CODE: "AGA",
    },
    {
      CITY_CODE: "MAAZE",
      CITY_NAME: "AZEMMOUR",
      DPA_PORT_CODE: "AZE",
    },
    {
      CITY_CODE: "MACAS",
      CITY_NAME: "CASABLANCA",
      DPA_PORT_CODE: "CAS",
    },
    {
      CITY_CODE: "MAELJ",
      CITY_NAME: "EL JADIDA",
      DPA_PORT_CODE: "ELJ",
    },
    {
      CITY_CODE: "MAMOH",
      CITY_NAME: "MOHAMMEDIA",
      DPA_PORT_CODE: "MOH",
    },
    {
      CITY_CODE: "MANDR",
      CITY_NAME: "NADOR",
      DPA_PORT_CODE: "NDR",
    },
    {
      CITY_CODE: "MAPTM",
      CITY_NAME: "PORT TANGIER MEDITERRANEE",
      DPA_PORT_CODE: "PTN",
    },
    {
      CITY_CODE: "MARBA",
      CITY_NAME: "RABAT",
      DPA_PORT_CODE: "RBA",
    },
    {
      CITY_CODE: "MATNG",
      CITY_NAME: "TANGIER",
      DPA_PORT_CODE: "TNG",
    },
    {
      CITY_CODE: "MATTU",
      CITY_NAME: "TETUAN",
      DPA_PORT_CODE: "TTU",
    },
    {
      CITY_CODE: "MCMON",
      CITY_NAME: "MONACO",
      DPA_PORT_CODE: "MCO",
    },
    {
      CITY_CODE: "MDGIU",
      CITY_NAME: "GIURGIULESTI",
      DPA_PORT_CODE: "GIU",
    },
    {
      CITY_CODE: "MEBAR",
      CITY_NAME: "BAR",
      DPA_PORT_CODE: "BAY",
    },
    {
      CITY_CODE: "MGBMD",
      CITY_NAME: "BELO",
      DPA_PORT_CODE: "BMD",
    },
    {
      CITY_CODE: "MGBPY",
      CITY_NAME: "BESALAMPY",
      DPA_PORT_CODE: "BPY",
    },
    {
      CITY_CODE: "MGDIE",
      CITY_NAME: "DIEGO SUAREZ",
      DPA_PORT_CODE: "DIE",
    },
    {
      CITY_CODE: "MGDWB",
      CITY_NAME: "SOALALA",
      DPA_PORT_CODE: "DWB",
    },
    {
      CITY_CODE: "MGEHL",
      CITY_NAME: "EHOALA",
      DPA_PORT_CODE: "EHL",
    },
    {
      CITY_CODE: "MGFTU",
      CITY_NAME: "FORT DAUPHIN",
      DPA_PORT_CODE: "FTU",
    },
    {
      CITY_CODE: "MGHLV",
      CITY_NAME: "HELLVILLE",
      DPA_PORT_CODE: "HLV",
    },
    {
      CITY_CODE: "MGIVA",
      CITY_NAME: "AMBANJA",
      DPA_PORT_CODE: "IVA",
    },
    {
      CITY_CODE: "MGMJN",
      CITY_NAME: "MAJUNGA",
      DPA_PORT_CODE: "MJN",
    },
    {
      CITY_CODE: "MGMOQ",
      CITY_NAME: "MORONDAVA",
      DPA_PORT_CODE: "MOQ",
    },
    {
      CITY_CODE: "MGMXM",
      CITY_NAME: "MOROMBE",
      DPA_PORT_CODE: "MXM",
    },
    {
      CITY_CODE: "MGNOS",
      CITY_NAME: "NOSSIBE",
      DPA_PORT_CODE: "NOS",
    },
    {
      CITY_CODE: "MGNOV",
      CITY_NAME: "NOSYVARIKA",
      DPA_PORT_CODE: "NOV",
    },
    {
      CITY_CODE: "MGRVA",
      CITY_NAME: "FARAFANGANA",
      DPA_PORT_CODE: "RVA",
    },
    {
      CITY_CODE: "MGSVB",
      CITY_NAME: "SAMBAVA",
      DPA_PORT_CODE: "SVB",
    },
    {
      CITY_CODE: "MGTLE",
      CITY_NAME: "TULEAR",
      DPA_PORT_CODE: "TLE",
    },
    {
      CITY_CODE: "MGTMM",
      CITY_NAME: "TAMATAVE",
      DPA_PORT_CODE: "TMM",
    },
    {
      CITY_CODE: "MGTOA",
      CITY_NAME: "TOAMASINA",
      DPA_PORT_CODE: "TOA",
    },
    {
      CITY_CODE: "MGVEH",
      CITY_NAME: "VEHIPERRO",
      DPA_PORT_CODE: "VEH",
    },
    {
      CITY_CODE: "MGVOH",
      CITY_NAME: "VOHEMAR",
      DPA_PORT_CODE: "VOH",
    },
    {
      CITY_CODE: "MGVVB",
      CITY_NAME: "MAHANORO",
      DPA_PORT_CODE: "VVB",
    },
    {
      CITY_CODE: "MGWVK",
      CITY_NAME: "MANAKARA",
      DPA_PORT_CODE: "WVK",
    },
    {
      CITY_CODE: "MHMAJ",
      CITY_NAME: "MAJURO",
      DPA_PORT_CODE: "MAJ",
    },
    {
      CITY_CODE: "MLSIK",
      CITY_NAME: "SIKASSO",
      DPA_PORT_CODE: "SKS",
    },
    {
      CITY_CODE: "MMBIL",
      CITY_NAME: "BILIN",
      DPA_PORT_CODE: "BIL",
    },
    {
      CITY_CODE: "MMBSX",
      CITY_NAME: "BASSEIN",
      DPA_PORT_CODE: "BSX",
    },
    {
      CITY_CODE: "MMHEB",
      CITY_NAME: "HENZADA",
      DPA_PORT_CODE: "HEB",
    },
    {
      CITY_CODE: "MMINS",
      CITY_NAME: "INSEIN",
      DPA_PORT_CODE: "INS",
    },
    {
      CITY_CODE: "MMKYA",
      CITY_NAME: "KYAIKIAT",
      DPA_PORT_CODE: "KYA",
    },
    {
      CITY_CODE: "MMKYP",
      CITY_NAME: "KYAUKPYU",
      DPA_PORT_CODE: "KYP",
    },
    {
      CITY_CODE: "MMMNU",
      CITY_NAME: "MOULMEIN",
      DPA_PORT_CODE: "MNU",
    },
    {
      CITY_CODE: "MMMXE",
      CITY_NAME: "MALE",
      DPA_PORT_CODE: "MXE",
    },
    {
      CITY_CODE: "MMPEG",
      CITY_NAME: "PEGU",
      DPA_PORT_CODE: "PEG",
    },
    {
      CITY_CODE: "MMRGN",
      CITY_NAME: "YANGON",
      DPA_PORT_CODE: "RGN",
    },
    {
      CITY_CODE: "MMTAV",
      CITY_NAME: "TAVOY",
      DPA_PORT_CODE: "TAV",
    },
    {
      CITY_CODE: "MMTHA",
      CITY_NAME: "THATON",
      DPA_PORT_CODE: "THA",
    },
    {
      CITY_CODE: "MMXYE",
      CITY_NAME: "YE",
      DPA_PORT_CODE: "XYE",
    },
    {
      CITY_CODE: "MOMFM",
      CITY_NAME: "MACAU",
      DPA_PORT_CODE: "MFM",
    },
    {
      CITY_CODE: "MPSPN",
      CITY_NAME: "SAIPAN",
      DPA_PORT_CODE: "SPN",
    },
    {
      CITY_CODE: "MQFDF",
      CITY_NAME: "FORT DE FRANCE",
      DPA_PORT_CODE: "FDF",
    },
    {
      CITY_CODE: "MRNDB",
      CITY_NAME: "NOUADHIBOU",
      DPA_PORT_CODE: "NDB",
    },
    {
      CITY_CODE: "MRNKC",
      CITY_NAME: "NOUAKCHOTT",
      DPA_PORT_CODE: "NKC",
    },
    {
      CITY_CODE: "MSLTB",
      CITY_NAME: "LITTLE BAY",
      DPA_PORT_CODE: "LLB",
    },
    {
      CITY_CODE: "MSMNI",
      CITY_NAME: "MONTSERRAT",
      DPA_PORT_CODE: "MVM",
    },
    {
      CITY_CODE: "MTMAR",
      CITY_NAME: "MARSAXLOKK",
      DPA_PORT_CODE: "MAX",
    },
    {
      CITY_CODE: "MTMLA",
      CITY_NAME: "MALTA",
      DPA_PORT_CODE: "MLA",
    },
    {
      CITY_CODE: "MUPLU",
      CITY_NAME: "PORT LOUIS",
      DPA_PORT_CODE: "PLU",
    },
    {
      CITY_CODE: "MVMLE",
      CITY_NAME: "MALE",
      DPA_PORT_CODE: "MLE",
    },
    {
      CITY_CODE: "MWBLZ",
      CITY_NAME: "BLANTYRE",
      DPA_PORT_CODE: "BLZ",
    },
    {
      CITY_CODE: "MXAOB",
      CITY_NAME: "ALVARO OBREGON",
      DPA_PORT_CODE: "AOB",
    },
    {
      CITY_CODE: "MXAPI",
      CITY_NAME: "APIZACO",
      DPA_PORT_CODE: "ZAC",
    },
    {
      CITY_CODE: "MXATM",
      CITY_NAME: "ALTAMIRA",
      DPA_PORT_CODE: "ATM",
    },
    {
      CITY_CODE: "MXCPE",
      CITY_NAME: "CAMPECHE",
      DPA_PORT_CODE: "CPE",
    },
    {
      CITY_CODE: "MXDBT",
      CITY_NAME: "DOS BOCAS TERMINAL",
      DPA_PORT_CODE: "DBD",
    },
    {
      CITY_CODE: "MXELD",
      CITY_NAME: "ELDORADO",
      DPA_PORT_CODE: "ELD",
    },
    {
      CITY_CODE: "MXESE",
      CITY_NAME: "ENSENADA",
      DPA_PORT_CODE: "ESE",
    },
    {
      CITY_CODE: "MXGYM",
      CITY_NAME: "GUAYMAS",
      DPA_PORT_CODE: "GYM",
    },
    {
      CITY_CODE: "MXHMO",
      CITY_NAME: "HERMOSILLO",
      DPA_PORT_CODE: "HGI",
    },
    {
      CITY_CODE: "MXISD",
      CITY_NAME: "ISLA CEDROS",
      DPA_PORT_CODE: "ISD",
    },
    {
      CITY_CODE: "MXISJ",
      CITY_NAME: "ISLA MUJERES",
      DPA_PORT_CODE: "ISJ",
    },
    {
      CITY_CODE: "MXJAL",
      CITY_NAME: "JALAPA",
      DPA_PORT_CODE: "JAL",
    },
    {
      CITY_CODE: "MXLZC",
      CITY_NAME: "LAZARO CARDENAS",
      DPA_PORT_CODE: "LZC",
    },
    {
      CITY_CODE: "MXMZT",
      CITY_NAME: "MAZATLAN",
      DPA_PORT_CODE: "MZT",
    },
    {
      CITY_CODE: "MXNDJ",
      CITY_NAME: "NAUCALPAN DE JUAREZ",
      DPA_PORT_CODE: "NDZ",
    },
    {
      CITY_CODE: "MXPGO",
      CITY_NAME: "PROGRESO",
      DPA_PORT_CODE: "PGO",
    },
    {
      CITY_CODE: "MXPMD",
      CITY_NAME: "PUERTO MADERO",
      DPA_PORT_CODE: "PMD",
    },
    {
      CITY_CODE: "MXPMS",
      CITY_NAME: "PUERTO MORELOS",
      DPA_PORT_CODE: "PMS",
    },
    {
      CITY_CODE: "MXPMX",
      CITY_NAME: "PUERTO MEXICO",
      DPA_PORT_CODE: "PMX",
    },
    {
      CITY_CODE: "MXPPE",
      CITY_NAME: "PUERTO PENASCO",
      DPA_PORT_CODE: "PPE",
    },
    {
      CITY_CODE: "MXSLO",
      CITY_NAME: "SAN LORENZO",
      DPA_PORT_CODE: "SLO",
    },
    {
      CITY_CODE: "MXTAM",
      CITY_NAME: "TAMPICO",
      DPA_PORT_CODE: "TAM",
    },
    {
      CITY_CODE: "MXTCU",
      CITY_NAME: "TECUALA",
      DPA_PORT_CODE: "TCU",
    },
    {
      CITY_CODE: "MXTIJ",
      CITY_NAME: "TIJUANA",
      DPA_PORT_CODE: "TIJ",
    },
    {
      CITY_CODE: "MXTPB",
      CITY_NAME: "TOPOLOBAMPO",
      DPA_PORT_CODE: "TPB",
    },
    {
      CITY_CODE: "MXTUX",
      CITY_NAME: "TUXPAN",
      DPA_PORT_CODE: "TUX",
    },
    {
      CITY_CODE: "MXVER",
      CITY_NAME: "VERACRUZ",
      DPA_PORT_CODE: "VER",
    },
    {
      CITY_CODE: "MXYUK",
      CITY_NAME: "YUKALPETEN",
      DPA_PORT_CODE: "YUK",
    },
    {
      CITY_CODE: "MXZLO",
      CITY_NAME: "MANZANILLO",
      DPA_PORT_CODE: "ZLO",
    },
    {
      CITY_CODE: "MYBAU",
      CITY_NAME: "BAU",
      DPA_PORT_CODE: "BAU",
    },
    {
      CITY_CODE: "MYBDV",
      CITY_NAME: "BANDAU",
      DPA_PORT_CODE: "BDV",
    },
    {
      CITY_CODE: "MYBGU",
      CITY_NAME: "BANGGAU",
      DPA_PORT_CODE: "BGU",
    },
    {
      CITY_CODE: "MYBKI",
      CITY_NAME: "KOTA KINABALU",
      DPA_PORT_CODE: "BKI",
    },
    {
      CITY_CODE: "MYBNT",
      CITY_NAME: "BENUT",
      DPA_PORT_CODE: "BNT",
    },
    {
      CITY_CODE: "MYBTU",
      CITY_NAME: "BINTULU",
      DPA_PORT_CODE: "BTU",
    },
    {
      CITY_CODE: "MYBWH",
      CITY_NAME: "BAGAN LUAR (BUTTERWORTH)",
      DPA_PORT_CODE: "BWH",
    },
    {
      CITY_CODE: "MYDGN",
      CITY_NAME: "DUNGUN",
      DPA_PORT_CODE: "DGN",
    },
    {
      CITY_CODE: "MYEND",
      CITY_NAME: "ENDAU",
      DPA_PORT_CODE: "END",
    },
    {
      CITY_CODE: "MYHUM",
      CITY_NAME: "HUTAN MELINGTANG",
      DPA_PORT_CODE: "HUM",
    },
    {
      CITY_CODE: "MYIPH",
      CITY_NAME: "IPOH",
      DPA_PORT_CODE: "IPH",
    },
    {
      CITY_CODE: "MYJAM",
      CITY_NAME: "JAMBONGAN",
      DPA_PORT_CODE: "JAM",
    },
    {
      CITY_CODE: "MYJHB",
      CITY_NAME: "JOHOR BAHRU",
      DPA_PORT_CODE: "JHB",
    },
    {
      CITY_CODE: "MYKBA",
      CITY_NAME: "KUALA BARAM",
      DPA_PORT_CODE: "KBA",
    },
    {
      CITY_CODE: "MYKBD",
      CITY_NAME: "KOTA BELUD",
      DPA_PORT_CODE: "KBD",
    },
    {
      CITY_CODE: "MYKBE",
      CITY_NAME: "KUALA BELUD",
      DPA_PORT_CODE: "KBE",
    },
    {
      CITY_CODE: "MYKBR",
      CITY_NAME: "KOTA BHARU",
      DPA_PORT_CODE: "KBR",
    },
    {
      CITY_CODE: "MYKCH",
      CITY_NAME: "KUCHING",
      DPA_PORT_CODE: "KCH",
    },
    {
      CITY_CODE: "MYKEL",
      CITY_NAME: "KELANG",
      DPA_PORT_CODE: "KNG",
    },
    {
      CITY_CODE: "MYKES",
      CITY_NAME: "KEJIT",
      DPA_PORT_CODE: "KES",
    },
    {
      CITY_CODE: "MYKGU",
      CITY_NAME: "KUALA GULA",
      DPA_PORT_CODE: "KGU",
    },
    {
      CITY_CODE: "MYKIM",
      CITY_NAME: "KIMANIS",
      DPA_PORT_CODE: "KIM",
    },
    {
      CITY_CODE: "MYKKH",
      CITY_NAME: "KUALA KEDAH",
      DPA_PORT_CODE: "KKH",
    },
    {
      CITY_CODE: "MYKKP",
      CITY_NAME: "KUKUP",
      DPA_PORT_CODE: "KKP",
    },
    {
      CITY_CODE: "MYKPS",
      CITY_NAME: "KUALA PERLIS",
      DPA_PORT_CODE: "KPS",
    },
    {
      CITY_CODE: "MYKPU",
      CITY_NAME: "KUALA PENYU",
      DPA_PORT_CODE: "KPU",
    },
    {
      CITY_CODE: "MYKSD",
      CITY_NAME: "KUALA SEDILI",
      DPA_PORT_CODE: "KSD",
    },
    {
      CITY_CODE: "MYKSL",
      CITY_NAME: "KUALA SELANGOR",
      DPA_PORT_CODE: "KSL",
    },
    {
      CITY_CODE: "MYKUA",
      CITY_NAME: "KUANTAN",
      DPA_PORT_CODE: "KUA",
    },
    {
      CITY_CODE: "MYLBH",
      CITY_NAME: "LABUAN HADJI",
      DPA_PORT_CODE: "LBH",
    },
    {
      CITY_CODE: "MYLBU",
      CITY_NAME: "LABUAN",
      DPA_PORT_CODE: "LBU",
    },
    {
      CITY_CODE: "MYLDU",
      CITY_NAME: "LAHAD DATU",
      DPA_PORT_CODE: "LDU",
    },
    {
      CITY_CODE: "MYLGG",
      CITY_NAME: "LINGGA",
      DPA_PORT_CODE: "LGG",
    },
    {
      CITY_CODE: "MYLGK",
      CITY_NAME: "LANGKAWI",
      DPA_PORT_CODE: "LGK",
    },
    {
      CITY_CODE: "MYLMN",
      CITY_NAME: "LIMBANG",
      DPA_PORT_CODE: "LMN",
    },
    {
      CITY_CODE: "MYLPK",
      CITY_NAME: "NORTHPORT/PORT KLANG",
      DPA_PORT_CODE: "LPQ",
    },
    {
      CITY_CODE: "MYLUM",
      CITY_NAME: "LUMUT",
      DPA_PORT_CODE: "LUM",
    },
    {
      CITY_CODE: "MYLWY",
      CITY_NAME: "LAWAS",
      DPA_PORT_CODE: "LWY",
    },
    {
      CITY_CODE: "MYMEN",
      CITY_NAME: "MENASI",
      DPA_PORT_CODE: "MEN",
    },
    {
      CITY_CODE: "MYMEP",
      CITY_NAME: "MERSING",
      DPA_PORT_CODE: "MEP",
    },
    {
      CITY_CODE: "MYMKZ",
      CITY_NAME: "MALACCA",
      DPA_PORT_CODE: "MKZ",
    },
    {
      CITY_CODE: "MYMUA",
      CITY_NAME: "MUAR",
      DPA_PORT_CODE: "UAR",
    },
    {
      CITY_CODE: "MYMYY",
      CITY_NAME: "MIRI",
      DPA_PORT_CODE: "MYY",
    },
    {
      CITY_CODE: "MYPAL",
      CITY_NAME: "PALOH",
      DPA_PORT_CODE: "PAL",
    },
    {
      CITY_CODE: "MYPDI",
      CITY_NAME: "PORT DICKSON",
      DPA_PORT_CODE: "PDI",
    },
    {
      CITY_CODE: "MYPED",
      CITY_NAME: "PEDENA",
      DPA_PORT_CODE: "PED",
    },
    {
      CITY_CODE: "MYPEN",
      CITY_NAME: "PENANG",
      DPA_PORT_CODE: "PEN",
    },
    {
      CITY_CODE: "MYPGU",
      CITY_NAME: "PASIR GUDANG",
      DPA_PORT_CODE: "PGU",
    },
    {
      CITY_CODE: "MYPKG",
      CITY_NAME: "PORT KLANG",
      DPA_PORT_CODE: "PKG",
    },
    {
      CITY_CODE: "MYPKK",
      CITY_NAME: "PENGKALAN KUBOR",
      DPA_PORT_CODE: "PKK",
    },
    {
      CITY_CODE: "MYPPI",
      CITY_NAME: "PULAU PISANG",
      DPA_PORT_CODE: "PPI",
    },
    {
      CITY_CODE: "MYPRA",
      CITY_NAME: "PRAI",
      DPA_PORT_CODE: "PYY",
    },
    {
      CITY_CODE: "MYPTB",
      CITY_NAME: "PULAU TAMBISAN",
      DPA_PORT_CODE: "PTB",
    },
    {
      CITY_CODE: "MYPUN",
      CITY_NAME: "PUNANG",
      DPA_PORT_CODE: "PUN",
    },
    {
      CITY_CODE: "MYREJ",
      CITY_NAME: "REJANG",
      DPA_PORT_CODE: "REJ",
    },
    {
      CITY_CODE: "MYSBW",
      CITY_NAME: "SIBU",
      DPA_PORT_CODE: "SBW",
    },
    {
      CITY_CODE: "MYSDK",
      CITY_NAME: "SANDAKAN",
      DPA_PORT_CODE: "SDK",
    },
    {
      CITY_CODE: "MYSDM",
      CITY_NAME: "SINDUMIN",
      DPA_PORT_CODE: "SDM",
    },
    {
      CITY_CODE: "MYSEJ",
      CITY_NAME: "SEJINGKAT",
      DPA_PORT_CODE: "SEJ",
    },
    {
      CITY_CODE: "MYSME",
      CITY_NAME: "SRI MEDAN",
      DPA_PORT_CODE: "SME",
    },
    {
      CITY_CODE: "MYSMG",
      CITY_NAME: "SIMANGGANG",
      DPA_PORT_CODE: "SMG",
    },
    {
      CITY_CODE: "MYSMM",
      CITY_NAME: "SEMPORNA",
      DPA_PORT_CODE: "SMM",
    },
    {
      CITY_CODE: "MYSPT",
      CITY_NAME: "SIPITANG",
      DPA_PORT_CODE: "SPT",
    },
    {
      CITY_CODE: "MYSRK",
      CITY_NAME: "SIRIKIN",
      DPA_PORT_CODE: "SRK",
    },
    {
      CITY_CODE: "MYTAS",
      CITY_NAME: "TELOK INTAN",
      DPA_PORT_CODE: "TEL",
    },
    {
      CITY_CODE: "MYTDA",
      CITY_NAME: "TANJONG DAWAI",
      DPA_PORT_CODE: "TDA",
    },
    {
      CITY_CODE: "MYTEN",
      CITY_NAME: "TENGKU",
      DPA_PORT_CODE: "TEN",
    },
    {
      CITY_CODE: "MYTGG",
      CITY_NAME: "KUALA TERENGGANU",
      DPA_PORT_CODE: "TGG",
    },
    {
      CITY_CODE: "MYTKP",
      CITY_NAME: "TANJONG KUPANG",
      DPA_PORT_CODE: "TKP",
    },
    {
      CITY_CODE: "MYTMB",
      CITY_NAME: "TUMBUM",
      DPA_PORT_CODE: "TMB",
    },
    {
      CITY_CODE: "MYTMG",
      CITY_NAME: "TOMANGGONG",
      DPA_PORT_CODE: "TMG",
    },
    {
      CITY_CODE: "MYTPP",
      CITY_NAME: "TANJONG PELEPAS",
      DPA_PORT_CODE: "TPL",
    },
    {
      CITY_CODE: "MYTWU",
      CITY_NAME: "TAWAU",
      DPA_PORT_CODE: "TWU",
    },
    {
      CITY_CODE: "MYWSP",
      CITY_NAME: "WESTPORT/PORT KLANG",
      DPA_PORT_CODE: "WSP",
    },
    {
      CITY_CODE: "MZANO",
      CITY_NAME: "ANGOCHE",
      DPA_PORT_CODE: "ANO",
    },
    {
      CITY_CODE: "MZBEW",
      CITY_NAME: "BEIRA",
      DPA_PORT_CODE: "BEW",
    },
    {
      CITY_CODE: "MZIBO",
      CITY_NAME: "IBO",
      DPA_PORT_CODE: "IBO",
    },
    {
      CITY_CODE: "MZINE",
      CITY_NAME: "CHINDE",
      DPA_PORT_CODE: "INE",
    },
    {
      CITY_CODE: "MZMMW",
      CITY_NAME: "MOMA",
      DPA_PORT_CODE: "MMW",
    },
    {
      CITY_CODE: "MZMNC",
      CITY_NAME: "NACALA",
      DPA_PORT_CODE: "MNC",
    },
    {
      CITY_CODE: "MZMPM",
      CITY_NAME: "MAPUTO",
      DPA_PORT_CODE: "MPM",
    },
    {
      CITY_CODE: "MZMZB",
      CITY_NAME: "MOCIMBOA DA PRAIA",
      DPA_PORT_CODE: "MVZ",
    },
    {
      CITY_CODE: "MZNSO",
      CITY_NAME: "NOVA SOFALA",
      DPA_PORT_CODE: "NSO",
    },
    {
      CITY_CODE: "MZPOL",
      CITY_NAME: "PEMBA",
      DPA_PORT_CODE: "POL",
    },
    {
      CITY_CODE: "MZUEL",
      CITY_NAME: "QUELIMANE",
      DPA_PORT_CODE: "UEL",
    },
    {
      CITY_CODE: "NALUD",
      CITY_NAME: "LUDERITZ",
      DPA_PORT_CODE: "LUD",
    },
    {
      CITY_CODE: "NAWVB",
      CITY_NAME: "WALVIS BAY",
      DPA_PORT_CODE: "WVB",
    },
    {
      CITY_CODE: "NCNOU",
      CITY_NAME: "NOUMEA",
      DPA_PORT_CODE: "NOU",
    },
    {
      CITY_CODE: "NCVAV",
      CITY_NAME: "VAVOUTO",
      DPA_PORT_CODE: "VAU",
    },
    {
      CITY_CODE: "NGADO",
      CITY_NAME: "ADO",
      DPA_PORT_CODE: "ADO",
    },
    {
      CITY_CODE: "NGAPP",
      CITY_NAME: "APAPA",
      DPA_PORT_CODE: "APP",
    },
    {
      CITY_CODE: "NGBAD",
      CITY_NAME: "BADAGRI",
      DPA_PORT_CODE: "BAD",
    },
    {
      CITY_CODE: "NGBON",
      CITY_NAME: "BONNY",
      DPA_PORT_CODE: "BON",
    },
    {
      CITY_CODE: "NGCBQ",
      CITY_NAME: "CALABAR",
      DPA_PORT_CODE: "CBQ",
    },
    {
      CITY_CODE: "NGEKE",
      CITY_NAME: "EKET",
      DPA_PORT_CODE: "EKE",
    },
    {
      CITY_CODE: "NGESC",
      CITY_NAME: "ESCRAVOS",
      DPA_PORT_CODE: "ESC",
    },
    {
      CITY_CODE: "NGGRP",
      CITY_NAME: "GREEK PORT",
      DPA_PORT_CODE: "GRP",
    },
    {
      CITY_CODE: "NGLOS",
      CITY_NAME: "LAGOS",
      DPA_PORT_CODE: "LOS",
    },
    {
      CITY_CODE: "NGOKR",
      CITY_NAME: "OKRIKA",
      DPA_PORT_CODE: "OKR",
    },
    {
      CITY_CODE: "NGONN",
      CITY_NAME: "ONNE",
      DPA_PORT_CODE: "ONN",
    },
    {
      CITY_CODE: "NGORO",
      CITY_NAME: "ORON",
      DPA_PORT_CODE: "ORO",
    },
    {
      CITY_CODE: "NGPHC",
      CITY_NAME: "PORT HARCOURT",
      DPA_PORT_CODE: "PHC",
    },
    {
      CITY_CODE: "NGSPL",
      CITY_NAME: "SAPELE",
      DPA_PORT_CODE: "SPL",
    },
    {
      CITY_CODE: "NGTIN",
      CITY_NAME: "TINCAN",
      DPA_PORT_CODE: "TIN",
    },
    {
      CITY_CODE: "NGWAR",
      CITY_NAME: "WARRI",
      DPA_PORT_CODE: "WAR",
    },
    {
      CITY_CODE: "NICIO",
      CITY_NAME: "CORINTO",
      DPA_PORT_CODE: "CIO",
    },
    {
      CITY_CODE: "NIPSN",
      CITY_NAME: "PUERTO SANDINO",
      DPA_PORT_CODE: "PSN",
    },
    {
      CITY_CODE: "NIPUZ",
      CITY_NAME: "PUERTO CABEZAS",
      DPA_PORT_CODE: "PUZ",
    },
    {
      CITY_CODE: "NLAAM",
      CITY_NAME: "AALSMEER",
      DPA_PORT_CODE: "AAM",
    },
    {
      CITY_CODE: "NLAMS",
      CITY_NAME: "AMSTERDAM",
      DPA_PORT_CODE: "AMS",
    },
    {
      CITY_CODE: "NLAMV",
      CITY_NAME: "AMSTELVEEN",
      DPA_PORT_CODE: "AMV",
    },
    {
      CITY_CODE: "NLBEV",
      CITY_NAME: "BEVERWIJK",
      DPA_PORT_CODE: "BEV",
    },
    {
      CITY_CODE: "NLDHR",
      CITY_NAME: "DEN HELDER",
      DPA_PORT_CODE: "DHR",
    },
    {
      CITY_CODE: "NLDOR",
      CITY_NAME: "DORDRECHT",
      DPA_PORT_CODE: "DOR",
    },
    {
      CITY_CODE: "NLDZL",
      CITY_NAME: "DELFZIJL",
      DPA_PORT_CODE: "DZL",
    },
    {
      CITY_CODE: "NLEEM",
      CITY_NAME: "EEMSHAVEN",
      DPA_PORT_CODE: "EEM",
    },
    {
      CITY_CODE: "NLGRQ",
      CITY_NAME: "GRONINGEN",
      DPA_PORT_CODE: "GRQ",
    },
    {
      CITY_CODE: "NLHAR",
      CITY_NAME: "HARLINGEN",
      DPA_PORT_CODE: "HAR",
    },
    {
      CITY_CODE: "NLHSL",
      CITY_NAME: "HELLEVOETSLUIS",
      DPA_PORT_CODE: "HSL",
    },
    {
      CITY_CODE: "NLHVH",
      CITY_NAME: "HOEK VAN HOLLAND",
      DPA_PORT_CODE: "HVH",
    },
    {
      CITY_CODE: "NLIJM",
      CITY_NAME: "IJMUIDEN",
      DPA_PORT_CODE: "IJM",
    },
    {
      CITY_CODE: "NLLWR",
      CITY_NAME: "LEEUWARDEN",
      DPA_PORT_CODE: "LWR",
    },
    {
      CITY_CODE: "NLMOE",
      CITY_NAME: "MOERDIJK",
      DPA_PORT_CODE: "MOE",
    },
    {
      CITY_CODE: "NLMSV",
      CITY_NAME: "MAASVLAKTE",
      DPA_PORT_CODE: "KTE",
    },
    {
      CITY_CODE: "NLNVP",
      CITY_NAME: "NIEUW-VENNEP",
      DPA_PORT_CODE: "IEU",
    },
    {
      CITY_CODE: "NLOOS",
      CITY_NAME: "OOSTERHOUT",
      DPA_PORT_CODE: "OOS",
    },
    {
      CITY_CODE: "NLOST",
      CITY_NAME: "OOSTRUM",
      DPA_PORT_CODE: "ORM",
    },
    {
      CITY_CODE: "NLRTM",
      CITY_NAME: "ROTTERDAM",
      DPA_PORT_CODE: "RTM",
    },
    {
      CITY_CODE: "NLSPI",
      CITY_NAME: "SPIJKENISSE",
      DPA_PORT_CODE: "SKQ",
    },
    {
      CITY_CODE: "NLTNZ",
      CITY_NAME: "TERNEUZEN",
      DPA_PORT_CODE: "TNZ",
    },
    {
      CITY_CODE: "NLVDH",
      CITY_NAME: "VELDHOVEN",
      DPA_PORT_CODE: "VDH",
    },
    {
      CITY_CODE: "NLVDN",
      CITY_NAME: "VREDENDUIN",
      DPA_PORT_CODE: "VDN",
    },
    {
      CITY_CODE: "NLVEE",
      CITY_NAME: "VEENENDAAL",
      DPA_PORT_CODE: "VEE",
    },
    {
      CITY_CODE: "NLVEL",
      CITY_NAME: "VELSEN",
      DPA_PORT_CODE: "VEL",
    },
    {
      CITY_CODE: "NLVLI",
      CITY_NAME: "VLISSINGEN",
      DPA_PORT_CODE: "VLI",
    },
    {
      CITY_CODE: "NLWIS",
      CITY_NAME: "WILLEMSTAD",
      DPA_PORT_CODE: "WIS",
    },
    {
      CITY_CODE: "NLWTE",
      CITY_NAME: "WESTTERSCHELLING",
      DPA_PORT_CODE: "WTE",
    },
    {
      CITY_CODE: "NLWTZ",
      CITY_NAME: "WESTZAAN",
      DPA_PORT_CODE: "WTZ",
    },
    {
      CITY_CODE: "NLZAA",
      CITY_NAME: "ZAANDAM",
      DPA_PORT_CODE: "ZAA",
    },
    {
      CITY_CODE: "NLZIE",
      CITY_NAME: "ZIERIKZEE",
      DPA_PORT_CODE: "ZIE",
    },
    {
      CITY_CODE: "NLZOU",
      CITY_NAME: "ZOETERWOUDE",
      DPA_PORT_CODE: "ZOU",
    },
    {
      CITY_CODE: "NLZWI",
      CITY_NAME: "ZWIJNDRECHT",
      DPA_PORT_CODE: "ZWI",
    },
    {
      CITY_CODE: "NOAAV",
      CITY_NAME: "ALVIK",
      DPA_PORT_CODE: "AAV",
    },
    {
      CITY_CODE: "NOAES",
      CITY_NAME: "ALESUND",
      DPA_PORT_CODE: "AES",
    },
    {
      CITY_CODE: "NOBGO",
      CITY_NAME: "BERGEN",
      DPA_PORT_CODE: "BGO",
    },
    {
      CITY_CODE: "NOBJF",
      CITY_NAME: "BATSFJORD",
      DPA_PORT_CODE: "BJF",
    },
    {
      CITY_CODE: "NOBJX",
      CITY_NAME: "BJORKAS",
      DPA_PORT_CODE: "BJX",
    },
    {
      CITY_CODE: "NOBKD",
      CITY_NAME: "BREKSTAD",
      DPA_PORT_CODE: "BKD",
    },
    {
      CITY_CODE: "NOBUV",
      CITY_NAME: "BUVIKEN",
      DPA_PORT_CODE: "BUV",
    },
    {
      CITY_CODE: "NOBVK",
      CITY_NAME: "BREVIK",
      DPA_PORT_CODE: "BVK",
    },
    {
      CITY_CODE: "NODRM",
      CITY_NAME: "DRAMMEN",
      DPA_PORT_CODE: "DRG",
    },
    {
      CITY_CODE: "NOEGD",
      CITY_NAME: "EGERSUND",
      DPA_PORT_CODE: "EGD",
    },
    {
      CITY_CODE: "NOEYD",
      CITY_NAME: "EYDEHAVN",
      DPA_PORT_CODE: "EYD",
    },
    {
      CITY_CODE: "NOFAU",
      CITY_NAME: "FAUSKE",
      DPA_PORT_CODE: "FAU",
    },
    {
      CITY_CODE: "NOFDE",
      CITY_NAME: "FORDE",
      DPA_PORT_CODE: "FDE",
    },
    {
      CITY_CODE: "NOFFD",
      CITY_NAME: "FLEKKEFJORD",
      DPA_PORT_CODE: "FFD",
    },
    {
      CITY_CODE: "NOFRK",
      CITY_NAME: "FREDRIKSTAD",
      DPA_PORT_CODE: "FRK",
    },
    {
      CITY_CODE: "NOFRO",
      CITY_NAME: "FLORO",
      DPA_PORT_CODE: "FRO",
    },
    {
      CITY_CODE: "NOGJM",
      CITY_NAME: "GJEMNES",
      DPA_PORT_CODE: "GJS",
    },
    {
      CITY_CODE: "NOGTD",
      CITY_NAME: "GRIMSTAD",
      DPA_PORT_CODE: "GTD",
    },
    {
      CITY_CODE: "NOHAL",
      CITY_NAME: "HALDEN",
      DPA_PORT_CODE: "HLN",
    },
    {
      CITY_CODE: "NOHAU",
      CITY_NAME: "HAUGESUND",
      DPA_PORT_CODE: "HAU",
    },
    {
      CITY_CODE: "NOHFT",
      CITY_NAME: "HAMMERFEST",
      DPA_PORT_CODE: "HFT",
    },
    {
      CITY_CODE: "NOHRY",
      CITY_NAME: "HEROYA",
      DPA_PORT_CODE: "HRY",
    },
    {
      CITY_CODE: "NOHVG",
      CITY_NAME: "HONNINGSVAG",
      DPA_PORT_CODE: "HVG",
    },
    {
      CITY_CODE: "NOHVI",
      CITY_NAME: "HAVIK",
      DPA_PORT_CODE: "HVI",
    },
    {
      CITY_CODE: "NOIKR",
      CITY_NAME: "IKORNNES",
      DPA_PORT_CODE: "IKR",
    },
    {
      CITY_CODE: "NOJSS",
      CITY_NAME: "JOSSINGHAMN",
      DPA_PORT_CODE: "JSS",
    },
    {
      CITY_CODE: "NOKKN",
      CITY_NAME: "KIRKENES",
      DPA_PORT_CODE: "KKN",
    },
    {
      CITY_CODE: "NOKMO",
      CITY_NAME: "KAMBO",
      DPA_PORT_CODE: "KMO",
    },
    {
      CITY_CODE: "NOKMY",
      CITY_NAME: "KARMOY",
      DPA_PORT_CODE: "KMY",
    },
    {
      CITY_CODE: "NOKRS",
      CITY_NAME: "KRISTIANSAND",
      DPA_PORT_CODE: "KRS",
    },
    {
      CITY_CODE: "NOKSU",
      CITY_NAME: "KRISTIANSUND",
      DPA_PORT_CODE: "KSU",
    },
    {
      CITY_CODE: "NOLAR",
      CITY_NAME: "LARVIK",
      DPA_PORT_CODE: "LVK",
    },
    {
      CITY_CODE: "NOLIL",
      CITY_NAME: "LILLESAND",
      DPA_PORT_CODE: "LIL",
    },
    {
      CITY_CODE: "NOLRI",
      CITY_NAME: "LEIRVIK",
      DPA_PORT_CODE: "LRI",
    },
    {
      CITY_CODE: "NOLYR",
      CITY_NAME: "LONGYEARBYEN",
      DPA_PORT_CODE: "LYR",
    },
    {
      CITY_CODE: "NOMAY",
      CITY_NAME: "MALOY",
      DPA_PORT_CODE: "MAY",
    },
    {
      CITY_CODE: "NOMOL",
      CITY_NAME: "MOLDE",
      DPA_PORT_CODE: "MOL",
    },
    {
      CITY_CODE: "NOMQN",
      CITY_NAME: "MO I RANA",
      DPA_PORT_CODE: "MQN",
    },
    {
      CITY_CODE: "NOMRV",
      CITY_NAME: "MURVIK",
      DPA_PORT_CODE: "MRV",
    },
    {
      CITY_CODE: "NOMSS",
      CITY_NAME: "MOSS",
      DPA_PORT_CODE: "MSS",
    },
    {
      CITY_CODE: "NOOSL",
      CITY_NAME: "OSLO",
      DPA_PORT_CODE: "OSL",
    },
    {
      CITY_CODE: "NOPOR",
      CITY_NAME: "PORSGRUNN",
      DPA_PORT_CODE: "POR",
    },
    {
      CITY_CODE: "NORVK",
      CITY_NAME: "RORVIK",
      DPA_PORT_CODE: "RVK",
    },
    {
      CITY_CODE: "NOSAD",
      CITY_NAME: "SANDEFJORD",
      DPA_PORT_CODE: "SAD",
    },
    {
      CITY_CODE: "NOSAS",
      CITY_NAME: "SANDNES",
      DPA_PORT_CODE: "SAS",
    },
    {
      CITY_CODE: "NOSAT",
      CITY_NAME: "SALTEN",
      DPA_PORT_CODE: "SQB",
    },
    {
      CITY_CODE: "NOSAU",
      CITY_NAME: "SAUDA",
      DPA_PORT_CODE: "NNU",
    },
    {
      CITY_CODE: "NOSKE",
      CITY_NAME: "SKIEN",
      DPA_PORT_CODE: "SKN",
    },
    {
      CITY_CODE: "NOSKX",
      CITY_NAME: "SKOGN",
      DPA_PORT_CODE: "SKX",
    },
    {
      CITY_CODE: "NOSVE",
      CITY_NAME: "SVELGEN",
      DPA_PORT_CODE: "SVL",
    },
    {
      CITY_CODE: "NOSVG",
      CITY_NAME: "STAVANGER",
      DPA_PORT_CODE: "SVG",
    },
    {
      CITY_CODE: "NOSVV",
      CITY_NAME: "SVELVIK",
      DPA_PORT_CODE: "SVV",
    },
    {
      CITY_CODE: "NOTAE",
      CITY_NAME: "TANANGER",
      DPA_PORT_CODE: "TAE",
    },
    {
      CITY_CODE: "NOTJR",
      CITY_NAME: "TJORVAG",
      DPA_PORT_CODE: "TJR",
    },
    {
      CITY_CODE: "NOTOF",
      CITY_NAME: "TOFTE I HURUM",
      DPA_PORT_CODE: "TOF",
    },
    {
      CITY_CODE: "NOTRD",
      CITY_NAME: "TRONDHEIM",
      DPA_PORT_CODE: "TRD",
    },
    {
      CITY_CODE: "NOTSX",
      CITY_NAME: "TYSSEDAL",
      DPA_PORT_CODE: "TSX",
    },
    {
      CITY_CODE: "NOTVE",
      CITY_NAME: "TVEDESTRAND",
      DPA_PORT_CODE: "TVE",
    },
    {
      CITY_CODE: "NOULS",
      CITY_NAME: "ULSTEINVIK",
      DPA_PORT_CODE: "ULS",
    },
    {
      CITY_CODE: "NOUVK",
      CITY_NAME: "NOUVK",
      DPA_PORT_CODE: "UVK",
    },
    {
      CITY_CODE: "NOVAK",
      CITY_NAME: "VAKSDAL",
      DPA_PORT_CODE: "VAK",
    },
    {
      CITY_CODE: "NOVAO",
      CITY_NAME: "VARDO",
      DPA_PORT_CODE: "VAO",
    },
    {
      CITY_CODE: "NOVDS",
      CITY_NAME: "VADSO",
      DPA_PORT_CODE: "VDS",
    },
    {
      CITY_CODE: "NUIUE",
      CITY_NAME: "NIUE ISLAND",
      DPA_PORT_CODE: "IUE",
    },
    {
      CITY_CODE: "NZAKA",
      CITY_NAME: "AKARON",
      DPA_PORT_CODE: "AKA",
    },
    {
      CITY_CODE: "NZAKL",
      CITY_NAME: "AUCKLAND",
      DPA_PORT_CODE: "AKL",
    },
    {
      CITY_CODE: "NZASG",
      CITY_NAME: "ASHBURTON",
      DPA_PORT_CODE: "ASG",
    },
    {
      CITY_CODE: "NZBHE",
      CITY_NAME: "BLENHEIM",
      DPA_PORT_CODE: "BHE",
    },
    {
      CITY_CODE: "NZBLU",
      CITY_NAME: "BLUFF HARBOUR",
      DPA_PORT_CODE: "BLU",
    },
    {
      CITY_CODE: "NZCGE",
      CITY_NAME: "CAMBRIDGE",
      DPA_PORT_CODE: "CGE",
    },
    {
      CITY_CODE: "NZCHC",
      CITY_NAME: "CHRISTCHURCH",
      DPA_PORT_CODE: "LYT",
    },
    {
      CITY_CODE: "NZDPC",
      CITY_NAME: "DEEP COVE",
      DPA_PORT_CODE: "DPC",
    },
    {
      CITY_CODE: "NZDUD",
      CITY_NAME: "DUNEDIN",
      DPA_PORT_CODE: "DUD",
    },
    {
      CITY_CODE: "NZFOX",
      CITY_NAME: "FOXTON",
      DPA_PORT_CODE: "FOX",
    },
    {
      CITY_CODE: "NZGMN",
      CITY_NAME: "GREYMOUTH",
      DPA_PORT_CODE: "GMN",
    },
    {
      CITY_CODE: "NZHAS",
      CITY_NAME: "HASTINGS",
      DPA_PORT_CODE: "HAX",
    },
    {
      CITY_CODE: "NZIVC",
      CITY_NAME: "INVERCARGILL",
      DPA_PORT_CODE: "IVC",
    },
    {
      CITY_CODE: "NZKTF",
      CITY_NAME: "TAKAKA",
      DPA_PORT_CODE: "KTF",
    },
    {
      CITY_CODE: "NZLYT",
      CITY_NAME: "LYTTELTON",
      DPA_PORT_CODE: "LYN",
    },
    {
      CITY_CODE: "NZMAP",
      CITY_NAME: "MARSDEN POINT",
      DPA_PORT_CODE: "MMP",
    },
    {
      CITY_CODE: "NZMKL",
      CITY_NAME: "METROPORT/AUCKLAND",
      DPA_PORT_CODE: "MQV",
    },
    {
      CITY_CODE: "NZMZP",
      CITY_NAME: "MOTUEKA",
      DPA_PORT_CODE: "MZP",
    },
    {
      CITY_CODE: "NZNPE",
      CITY_NAME: "NAPIER",
      DPA_PORT_CODE: "NPE",
    },
    {
      CITY_CODE: "NZNPL",
      CITY_NAME: "NEW PLYMOUTH",
      DPA_PORT_CODE: "NPL",
    },
    {
      CITY_CODE: "NZNSN",
      CITY_NAME: "NELSON",
      DPA_PORT_CODE: "NSN",
    },
    {
      CITY_CODE: "NZOAM",
      CITY_NAME: "OAMARU",
      DPA_PORT_CODE: "OAM",
    },
    {
      CITY_CODE: "NZOPX",
      CITY_NAME: "OPUA",
      DPA_PORT_CODE: "OPX",
    },
    {
      CITY_CODE: "NZPCN",
      CITY_NAME: "PICTON",
      DPA_PORT_CODE: "PCO",
    },
    {
      CITY_CODE: "NZPMR",
      CITY_NAME: "PALMERSTON",
      DPA_PORT_CODE: "PMR",
    },
    {
      CITY_CODE: "NZPOE",
      CITY_NAME: "PORT CHALMERS",
      DPA_PORT_CODE: "POE",
    },
    {
      CITY_CODE: "NZSXO",
      CITY_NAME: "SANTO",
      DPA_PORT_CODE: "SXO",
    },
    {
      CITY_CODE: "NZTIU",
      CITY_NAME: "TIMARU",
      DPA_PORT_CODE: "TIU",
    },
    {
      CITY_CODE: "NZTKA",
      CITY_NAME: "TEMUKA",
      DPA_PORT_CODE: "TKB",
    },
    {
      CITY_CODE: "NZTRG",
      CITY_NAME: "TAURANGA",
      DPA_PORT_CODE: "TRG",
    },
    {
      CITY_CODE: "NZWKA",
      CITY_NAME: "WAIKAWA",
      DPA_PORT_CODE: "WKA",
    },
    {
      CITY_CODE: "NZWLG",
      CITY_NAME: "WELLINGTON",
      DPA_PORT_CODE: "WLG",
    },
    {
      CITY_CODE: "OMFAH",
      CITY_NAME: "FAHAL",
      DPA_PORT_CODE: "FAH",
    },
    {
      CITY_CODE: "OMMCT",
      CITY_NAME: "MUSCAT",
      DPA_PORT_CODE: "MCT",
    },
    {
      CITY_CODE: "OMMNQ",
      CITY_NAME: "MINA QABOOS",
      DPA_PORT_CODE: "MNQ",
    },
    {
      CITY_CODE: "OMOPQ",
      CITY_NAME: "PORT QABAS",
      DPA_PORT_CODE: "OPQ",
    },
    {
      CITY_CODE: "OMSLL",
      CITY_NAME: "SALALAH",
      DPA_PORT_CODE: "SLL",
    },
    {
      CITY_CODE: "OMSOH",
      CITY_NAME: "SOHAR",
      DPA_PORT_CODE: "SOH",
    },
    {
      CITY_CODE: "OMSUL",
      CITY_NAME: "PORT SULTAN",
      DPA_PORT_CODE: "SUN",
    },
    {
      CITY_CODE: "OMTTH",
      CITY_NAME: "THUMRAIT",
      DPA_PORT_CODE: "TTH",
    },
    {
      CITY_CODE: "PAAGD",
      CITY_NAME: "AGUADULCE",
      DPA_PORT_CODE: "AGD",
    },
    {
      CITY_CODE: "PABLB",
      CITY_NAME: "BALBOA",
      DPA_PORT_CODE: "BLB",
    },
    {
      CITY_CODE: "PACFZ",
      CITY_NAME: "COLON FREEZONE",
      DPA_PORT_CODE: "CFZ",
    },
    {
      CITY_CODE: "PACTB",
      CITY_NAME: "CRISTOBAL",
      DPA_PORT_CODE: "CTB",
    },
    {
      CITY_CODE: "PAELE",
      CITY_NAME: "EL REAL",
      DPA_PORT_CODE: "ELE",
    },
    {
      CITY_CODE: "PAMIT",
      CITY_NAME: "MANZANILLO",
      DPA_PORT_CODE: "MIT",
    },
    {
      CITY_CODE: "PAONX",
      CITY_NAME: "COLON",
      DPA_PORT_CODE: "ONX",
    },
    {
      CITY_CODE: "PAPTY",
      CITY_NAME: "PANAMA CITY",
      DPA_PORT_CODE: "PTY",
    },
    {
      CITY_CODE: "PECHM",
      CITY_NAME: "CHIMBOTE",
      DPA_PORT_CODE: "CHM",
    },
    {
      CITY_CODE: "PECLL",
      CITY_NAME: "CALLAO",
      DPA_PORT_CODE: "CLL",
    },
    {
      CITY_CODE: "PEEEN",
      CITY_NAME: "ETEN",
      DPA_PORT_CODE: "EEN",
    },
    {
      CITY_CODE: "PEHCO",
      CITY_NAME: "HUACHO",
      DPA_PORT_CODE: "HCO",
    },
    {
      CITY_CODE: "PEHUY",
      CITY_NAME: "HUARMEY",
      DPA_PORT_CODE: "HUY",
    },
    {
      CITY_CODE: "PEIQT",
      CITY_NAME: "IQUITOS",
      DPA_PORT_CODE: "IQT",
    },
    {
      CITY_CODE: "PELIM",
      CITY_NAME: "LIMA",
      DPA_PORT_CODE: "LIM",
    },
    {
      CITY_CODE: "PEMLQ",
      CITY_NAME: "MOLLENDO",
      DPA_PORT_CODE: "MLQ",
    },
    {
      CITY_CODE: "PEMRI",
      CITY_NAME: "MATARANI",
      DPA_PORT_CODE: "MRI",
    },
    {
      CITY_CODE: "PEPAI",
      CITY_NAME: "PAITA",
      DPA_PORT_CODE: "PAI",
    },
    {
      CITY_CODE: "PEPCH",
      CITY_NAME: "PUERTO CHICAMA",
      DPA_PORT_CODE: "PCH",
    },
    {
      CITY_CODE: "PEPCL",
      CITY_NAME: "PUCALLPA",
      DPA_PORT_CODE: "PCL",
    },
    {
      CITY_CODE: "PETDM",
      CITY_NAME: "TAMBO DE MORA",
      DPA_PORT_CODE: "TDM",
    },
    {
      CITY_CODE: "PETRU",
      CITY_NAME: "TRUJILLO",
      DPA_PORT_CODE: "TRU",
    },
    {
      CITY_CODE: "PETYL",
      CITY_NAME: "TALARA",
      DPA_PORT_CODE: "TYL",
    },
    {
      CITY_CODE: "PEYMS",
      CITY_NAME: "YURIMAGUAS",
      DPA_PORT_CODE: "YMS",
    },
    {
      CITY_CODE: "PFBOB",
      CITY_NAME: "BORA BORA",
      DPA_PORT_CODE: "BOB",
    },
    {
      CITY_CODE: "PFPPT",
      CITY_NAME: "PAPEETE",
      DPA_PORT_CODE: "PPT",
    },
    {
      CITY_CODE: "PGABW",
      CITY_NAME: "ABAU",
      DPA_PORT_CODE: "ABW",
    },
    {
      CITY_CODE: "PGAKI",
      CITY_NAME: "AKINUM",
      DPA_PORT_CODE: "AKI",
    },
    {
      CITY_CODE: "PGDAU",
      CITY_NAME: "DARU",
      DPA_PORT_CODE: "DAU",
    },
    {
      CITY_CODE: "PGFIN",
      CITY_NAME: "FINSCHHAFEN",
      DPA_PORT_CODE: "FIN",
    },
    {
      CITY_CODE: "PGFUB",
      CITY_NAME: "FULLEBORN",
      DPA_PORT_CODE: "FUB",
    },
    {
      CITY_CODE: "PGGMI",
      CITY_NAME: "GASMATA ISLAND",
      DPA_PORT_CODE: "GMI",
    },
    {
      CITY_CODE: "PGGUR",
      CITY_NAME: "ALOTAU",
      DPA_PORT_CODE: "GUR",
    },
    {
      CITY_CODE: "PGKLO",
      CITY_NAME: "KALO",
      DPA_PORT_CODE: "KLO",
    },
    {
      CITY_CODE: "PGKPP",
      CITY_NAME: "KAKAPO",
      DPA_PORT_CODE: "KPP",
    },
    {
      CITY_CODE: "PGKRX",
      CITY_NAME: "KAR KAR",
      DPA_PORT_CODE: "KRX",
    },
    {
      CITY_CODE: "PGKVG",
      CITY_NAME: "KAVIENG",
      DPA_PORT_CODE: "KVG",
    },
    {
      CITY_CODE: "PGLAE",
      CITY_NAME: "LAE",
      DPA_PORT_CODE: "LAE",
    },
    {
      CITY_CODE: "PGLOL",
      CITY_NAME: "LOLOHO",
      DPA_PORT_CODE: "LOL",
    },
    {
      CITY_CODE: "PGLOR",
      CITY_NAME: "LORENGAU",
      DPA_PORT_CODE: "LQR",
    },
    {
      CITY_CODE: "PGMAG",
      CITY_NAME: "MADANG",
      DPA_PORT_CODE: "MAG",
    },
    {
      CITY_CODE: "PGPOM",
      CITY_NAME: "PORT MORESBY",
      DPA_PORT_CODE: "POM",
    },
    {
      CITY_CODE: "PGRAB",
      CITY_NAME: "RABAUL",
      DPA_PORT_CODE: "RBU",
    },
    {
      CITY_CODE: "PGRKU",
      CITY_NAME: "YULE ISLAND",
      DPA_PORT_CODE: "RKU",
    },
    {
      CITY_CODE: "PGRNR",
      CITY_NAME: "ROBINSON RIVER",
      DPA_PORT_CODE: "RNR",
    },
    {
      CITY_CODE: "PGROR",
      CITY_NAME: "OROBAY",
      DPA_PORT_CODE: "ROR",
    },
    {
      CITY_CODE: "PGRVI",
      CITY_NAME: "RUSSEL ISLAND",
      DPA_PORT_CODE: "RVI",
    },
    {
      CITY_CODE: "PGSMU",
      CITY_NAME: "SALAMAU",
      DPA_PORT_CODE: "SMU",
    },
    {
      CITY_CODE: "PGSOI",
      CITY_NAME: "SOKAR ISLAND",
      DPA_PORT_CODE: "SOI",
    },
    {
      CITY_CODE: "PGTAU",
      CITY_NAME: "TAUGU ISLAND",
      DPA_PORT_CODE: "TAU",
    },
    {
      CITY_CODE: "PGTFI",
      CITY_NAME: "TUFI",
      DPA_PORT_CODE: "TFI",
    },
    {
      CITY_CODE: "PGTLW",
      CITY_NAME: "TALASEA",
      DPA_PORT_CODE: "TLW",
    },
    {
      CITY_CODE: "PGTOI",
      CITY_NAME: "TOLOKIWA ISLAND",
      DPA_PORT_CODE: "TOI",
    },
    {
      CITY_CODE: "PHALE",
      CITY_NAME: "ALERAN",
      DPA_PORT_CODE: "ALE",
    },
    {
      CITY_CODE: "PHBCD",
      CITY_NAME: "BACOLOD",
      DPA_PORT_CODE: "BCD",
    },
    {
      CITY_CODE: "PHBGC",
      CITY_NAME: "BAGAC",
      DPA_PORT_CODE: "BGC",
    },
    {
      CITY_CODE: "PHBIY",
      CITY_NAME: "BIYAWA",
      DPA_PORT_CODE: "BIY",
    },
    {
      CITY_CODE: "PHBNQ",
      CITY_NAME: "BAGANGA",
      DPA_PORT_CODE: "BNQ",
    },
    {
      CITY_CODE: "PHBPH",
      CITY_NAME: "BISLIG",
      DPA_PORT_CODE: "BPH",
    },
    {
      CITY_CODE: "PHBQA",
      CITY_NAME: "BALER",
      DPA_PORT_CODE: "BQA",
    },
    {
      CITY_CODE: "PHBRT",
      CITY_NAME: "BARTON",
      DPA_PORT_CODE: "BRT",
    },
    {
      CITY_CODE: "PHBTG",
      CITY_NAME: "BATANGAS",
      DPA_PORT_CODE: "BTG",
    },
    {
      CITY_CODE: "PHBUG",
      CITY_NAME: "BUGO",
      DPA_PORT_CODE: "BUG",
    },
    {
      CITY_CODE: "PHBYB",
      CITY_NAME: "BAYBAY",
      DPA_PORT_CODE: "BYB",
    },
    {
      CITY_CODE: "PHBYP",
      CITY_NAME: "BAYANG POINT",
      DPA_PORT_CODE: "BYP",
    },
    {
      CITY_CODE: "PHCBO",
      CITY_NAME: "COTABATO",
      DPA_PORT_CODE: "CBO",
    },
    {
      CITY_CODE: "PHCCG",
      CITY_NAME: "CALAGCALAG",
      DPA_PORT_CODE: "CCG",
    },
    {
      CITY_CODE: "PHCDB",
      CITY_NAME: "CALDERA BAY",
      DPA_PORT_CODE: "CDB",
    },
    {
      CITY_CODE: "PHCEB",
      CITY_NAME: "CEBU",
      DPA_PORT_CODE: "CEB",
    },
    {
      CITY_CODE: "PHCGG",
      CITY_NAME: "CASIGURAN",
      DPA_PORT_CODE: "CGG",
    },
    {
      CITY_CODE: "PHCGM",
      CITY_NAME: "CAMIGUIN ISLAND",
      DPA_PORT_CODE: "CGM",
    },
    {
      CITY_CODE: "PHCGY",
      CITY_NAME: "CAGAYAN DE ORO",
      DPA_PORT_CODE: "CGY",
    },
    {
      CITY_CODE: "PHCNT",
      CITY_NAME: "CANTILAN",
      DPA_PORT_CODE: "CNT",
    },
    {
      CITY_CODE: "PHCNY",
      CITY_NAME: "CANAYBON",
      DPA_PORT_CODE: "CNY",
    },
    {
      CITY_CODE: "PHCPL",
      CITY_NAME: "CAPALONGA",
      DPA_PORT_CODE: "CPL",
    },
    {
      CITY_CODE: "PHCRG",
      CITY_NAME: "CARAGA",
      DPA_PORT_CODE: "CRG",
    },
    {
      CITY_CODE: "PHCUG",
      CITY_NAME: "CALUGIAN",
      DPA_PORT_CODE: "CUG",
    },
    {
      CITY_CODE: "PHCUJ",
      CITY_NAME: "CULION",
      DPA_PORT_CODE: "CUJ",
    },
    {
      CITY_CODE: "PHCVE",
      CITY_NAME: "CAVITE",
      DPA_PORT_CODE: "CVE",
    },
    {
      CITY_CODE: "PHCWY",
      CITY_NAME: "CALOWAYAN",
      DPA_PORT_CODE: "CWY",
    },
    {
      CITY_CODE: "PHDAD",
      CITY_NAME: "DADIANGAS",
      DPA_PORT_CODE: "DAG",
    },
    {
      CITY_CODE: "PHDDW",
      CITY_NAME: "DINADIAWAN",
      DPA_PORT_CODE: "DDW",
    },
    {
      CITY_CODE: "PHDGL",
      CITY_NAME: "DINGALAN",
      DPA_PORT_CODE: "DGL",
    },
    {
      CITY_CODE: "PHDIC",
      CITY_NAME: "DICULUM",
      DPA_PORT_CODE: "DIC",
    },
    {
      CITY_CODE: "PHDID",
      CITY_NAME: "DIDADONGAN",
      DPA_PORT_CODE: "DID",
    },
    {
      CITY_CODE: "PHDIO",
      CITY_NAME: "DIOTORIN",
      DPA_PORT_CODE: "DIO",
    },
    {
      CITY_CODE: "PHDIV",
      CITY_NAME: "DIVILACAN",
      DPA_PORT_CODE: "DIV",
    },
    {
      CITY_CODE: "PHDNG",
      CITY_NAME: "DINAGAT ISLAND",
      DPA_PORT_CODE: "DNG",
    },
    {
      CITY_CODE: "PHDNL",
      CITY_NAME: "DINALONGAN",
      DPA_PORT_CODE: "DNL",
    },
    {
      CITY_CODE: "PHDPL",
      CITY_NAME: "DIPOLOG",
      DPA_PORT_CODE: "DPL",
    },
    {
      CITY_CODE: "PHDPT",
      CITY_NAME: "DIAPITAN",
      DPA_PORT_CODE: "DPT",
    },
    {
      CITY_CODE: "PHDRB",
      CITY_NAME: "DIARABOAN",
      DPA_PORT_CODE: "DRB",
    },
    {
      CITY_CODE: "PHDVO",
      CITY_NAME: "DAVAO",
      DPA_PORT_CODE: "DVO",
    },
    {
      CITY_CODE: "PHENI",
      CITY_NAME: "EL NIDO",
      DPA_PORT_CODE: "ENI",
    },
    {
      CITY_CODE: "PHGES",
      CITY_NAME: "GENERAL SANTOS",
      DPA_PORT_CODE: "GES",
    },
    {
      CITY_CODE: "PHGGG",
      CITY_NAME: "GIGAGUIT",
      DPA_PORT_CODE: "GGG",
    },
    {
      CITY_CODE: "PHGGM",
      CITY_NAME: "GIGMOTE",
      DPA_PORT_CODE: "GGM",
    },
    {
      CITY_CODE: "PHGMS",
      CITY_NAME: "GUIMARAS",
      DPA_PORT_CODE: "GMS",
    },
    {
      CITY_CODE: "PHHNB",
      CITY_NAME: "HINOBAAN",
      DPA_PORT_CODE: "HNB",
    },
    {
      CITY_CODE: "PHHNT",
      CITY_NAME: "HINATUAN",
      DPA_PORT_CODE: "HNT",
    },
    {
      CITY_CODE: "PHHON",
      CITY_NAME: "HONDAGUA",
      DPA_PORT_CODE: "HON",
    },
    {
      CITY_CODE: "PHHYB",
      CITY_NAME: "HAGNAYA BAY",
      DPA_PORT_CODE: "HYB",
    },
    {
      CITY_CODE: "PHIGN",
      CITY_NAME: "ILIGAN",
      DPA_PORT_CODE: "IGN",
    },
    {
      CITY_CODE: "PHILO",
      CITY_NAME: "ILOILO",
      DPA_PORT_CODE: "ILO",
    },
    {
      CITY_CODE: "PHINP",
      CITY_NAME: "INAMPULUGAN ISLANDS",
      DPA_PORT_CODE: "INP",
    },
    {
      CITY_CODE: "PHIPE",
      CITY_NAME: "IPIL",
      DPA_PORT_CODE: "IPE",
    },
    {
      CITY_CODE: "PHJAS",
      CITY_NAME: "JASAAN",
      DPA_PORT_CODE: "JAS",
    },
    {
      CITY_CODE: "PHJOL",
      CITY_NAME: "JOLO",
      DPA_PORT_CODE: "JOL",
    },
    {
      CITY_CODE: "PHJPM",
      CITY_NAME: "JOSE PANGANIBAN",
      DPA_PORT_CODE: "JPM",
    },
    {
      CITY_CODE: "PHKAT",
      CITY_NAME: "KATIPUNAN",
      DPA_PORT_CODE: "KAT",
    },
    {
      CITY_CODE: "PHKIA",
      CITY_NAME: "KIAMBA",
      DPA_PORT_CODE: "KIA",
    },
    {
      CITY_CODE: "PHKIW",
      CITY_NAME: "KIWALAN",
      DPA_PORT_CODE: "KIW",
    },
    {
      CITY_CODE: "PHLAY",
      CITY_NAME: "LAGONDY RIVER",
      DPA_PORT_CODE: "LAY",
    },
    {
      CITY_CODE: "PHLEY",
      CITY_NAME: "LEYTE",
      DPA_PORT_CODE: "LEY",
    },
    {
      CITY_CODE: "PHLGP",
      CITY_NAME: "LEGASPI",
      DPA_PORT_CODE: "LGP",
    },
    {
      CITY_CODE: "PHLIA",
      CITY_NAME: "LIANGA",
      DPA_PORT_CODE: "LIA",
    },
    {
      CITY_CODE: "PHLIB",
      CITY_NAME: "LIBJO",
      DPA_PORT_CODE: "LIB",
    },
    {
      CITY_CODE: "PHLNO",
      CITY_NAME: "LUNAO",
      DPA_PORT_CODE: "LNO",
    },
    {
      CITY_CODE: "PHLOM",
      CITY_NAME: "LOMUYON",
      DPA_PORT_CODE: "LOM",
    },
    {
      CITY_CODE: "PHLOO",
      CITY_NAME: "LOOC BAY",
      DPA_PORT_CODE: "LOO",
    },
    {
      CITY_CODE: "PHLPZ",
      CITY_NAME: "LA PAZ",
      DPA_PORT_CODE: "LPZ",
    },
    {
      CITY_CODE: "PHLRP",
      CITY_NAME: "LA RAP",
      DPA_PORT_CODE: "LRP",
    },
    {
      CITY_CODE: "PHMAB",
      CITY_NAME: "MAIBINI",
      DPA_PORT_CODE: "MAB",
    },
    {
      CITY_CODE: "PHMCD",
      CITY_NAME: "MERCEDES",
      DPA_PORT_CODE: "MCD",
    },
    {
      CITY_CODE: "PHMCJ",
      CITY_NAME: "MACOJALAN",
      DPA_PORT_CODE: "MCJ",
    },
    {
      CITY_CODE: "PHMGB",
      CITY_NAME: "MAGUEDA BAY",
      DPA_PORT_CODE: "MGB",
    },
    {
      CITY_CODE: "PHMIC",
      CITY_NAME: "MISAMIS OR.",
      DPA_PORT_CODE: "MIC",
    },
    {
      CITY_CODE: "PHMNL",
      CITY_NAME: "MANILA",
      DPA_PORT_CODE: "MNL",
    },
    {
      CITY_CODE: "PHMNN",
      CITY_NAME: "MANILA NORTH HARBOUR",
      DPA_PORT_CODE: "MNN",
    },
    {
      CITY_CODE: "PHMNS",
      CITY_NAME: "MANILA SOUTH HARBOUR",
      DPA_PORT_CODE: "MNS",
    },
    {
      CITY_CODE: "PHMSB",
      CITY_NAME: "MALASUGAT BAY",
      DPA_PORT_CODE: "MSB",
    },
    {
      CITY_CODE: "PHNAB",
      CITY_NAME: "NABULAO BAY",
      DPA_PORT_CODE: "NAB",
    },
    {
      CITY_CODE: "PHNAL",
      CITY_NAME: "NALUNGGA",
      DPA_PORT_CODE: "NAL",
    },
    {
      CITY_CODE: "PHNAR",
      CITY_NAME: "NARIS POINT",
      DPA_PORT_CODE: "NAR",
    },
    {
      CITY_CODE: "PHNGH",
      CITY_NAME: "NAGH",
      DPA_PORT_CODE: "NGH",
    },
    {
      CITY_CODE: "PHNSP",
      CITY_NAME: "NASIPIT",
      DPA_PORT_CODE: "NSP",
    },
    {
      CITY_CODE: "PHNTO",
      CITY_NAME: "NATO",
      DPA_PORT_CODE: "NTO",
    },
    {
      CITY_CODE: "PHNUG",
      CITY_NAME: "NASUGBU",
      DPA_PORT_CODE: "NUG",
    },
    {
      CITY_CODE: "PHORS",
      CITY_NAME: "ORAS",
      DPA_PORT_CODE: "ORS",
    },
    {
      CITY_CODE: "PHOTE",
      CITY_NAME: "OTEIZA",
      DPA_PORT_CODE: "OTE",
    },
    {
      CITY_CODE: "PHOZC",
      CITY_NAME: "OZAMIS",
      DPA_PORT_CODE: "OZC",
    },
    {
      CITY_CODE: "PHPAG",
      CITY_NAME: "PAGADIAN",
      DPA_PORT_CODE: "PAG",
    },
    {
      CITY_CODE: "PHPDR",
      CITY_NAME: "PACIO DE ROZAS",
      DPA_PORT_CODE: "PDR",
    },
    {
      CITY_CODE: "PHPHD",
      CITY_NAME: "PORT HOLLAND",
      DPA_PORT_CODE: "PHD",
    },
    {
      CITY_CODE: "PHPLB",
      CITY_NAME: "PALAMIT BAY",
      DPA_PORT_CODE: "PLB",
    },
    {
      CITY_CODE: "PHPLW",
      CITY_NAME: "PALAWAN",
      DPA_PORT_CODE: "PLW",
    },
    {
      CITY_CODE: "PHPNB",
      CITY_NAME: "PANABUTAN",
      DPA_PORT_CODE: "PNB",
    },
    {
      CITY_CODE: "PHPNN",
      CITY_NAME: "PANINIRONGAN",
      DPA_PORT_CODE: "PNN",
    },
    {
      CITY_CODE: "PHPPL",
      CITY_NAME: "PAMPLONA",
      DPA_PORT_CODE: "PPL",
    },
    {
      CITY_CODE: "PHPPP",
      CITY_NAME: "PASELANG PAGUDPUD",
      DPA_PORT_CODE: "PPP",
    },
    {
      CITY_CODE: "PHPPS",
      CITY_NAME: "PUERTO PRINCESA",
      DPA_PORT_CODE: "PPS",
    },
    {
      CITY_CODE: "PHPUG",
      CITY_NAME: "PUGAD",
      DPA_PORT_CODE: "PUG",
    },
    {
      CITY_CODE: "PHPUL",
      CITY_NAME: "PULUPANDAN",
      DPA_PORT_CODE: "PUL",
    },
    {
      CITY_CODE: "PHQBI",
      CITY_NAME: "QUINABIGAN",
      DPA_PORT_CODE: "QBI",
    },
    {
      CITY_CODE: "PHQBL",
      CITY_NAME: "QUINABLAGAN",
      DPA_PORT_CODE: "QBL",
    },
    {
      CITY_CODE: "PHQPT",
      CITY_NAME: "QUIPIT BAY",
      DPA_PORT_CODE: "QPT",
    },
    {
      CITY_CODE: "PHRXS",
      CITY_NAME: "ROXAS",
      DPA_PORT_CODE: "RXS",
    },
    {
      CITY_CODE: "PHSBB",
      CITY_NAME: "SABBOT",
      DPA_PORT_CODE: "SBB",
    },
    {
      CITY_CODE: "PHSBY",
      CITY_NAME: "SABLAYAN",
      DPA_PORT_CODE: "SBY",
    },
    {
      CITY_CODE: "PHSFE",
      CITY_NAME: "SAN FERNANDO",
      DPA_PORT_CODE: "SFE",
    },
    {
      CITY_CODE: "PHSFS",
      CITY_NAME: "SUBIC BAY",
      DPA_PORT_CODE: "SFS",
    },
    {
      CITY_CODE: "PHSGD",
      CITY_NAME: "SOGOD",
      DPA_PORT_CODE: "SGD",
    },
    {
      CITY_CODE: "PHSJI",
      CITY_NAME: "SAN JOSE",
      DPA_PORT_CODE: "SJI",
    },
    {
      CITY_CODE: "PHSKU",
      CITY_NAME: "SULTAN KUDARAT",
      DPA_PORT_CODE: "SKU",
    },
    {
      CITY_CODE: "PHSTE",
      CITY_NAME: "SAN TEODORO",
      DPA_PORT_CODE: "STE",
    },
    {
      CITY_CODE: "PHSUA",
      CITY_NAME: "SUAL",
      DPA_PORT_CODE: "SUL",
    },
    {
      CITY_CODE: "PHSUG",
      CITY_NAME: "SURIGAO",
      DPA_PORT_CODE: "SUG",
    },
    {
      CITY_CODE: "PHTAC",
      CITY_NAME: "TACLOBAN",
      DPA_PORT_CODE: "TAC",
    },
    {
      CITY_CODE: "PHTAY",
      CITY_NAME: "TAYTAY",
      DPA_PORT_CODE: "TAY",
    },
    {
      CITY_CODE: "PHTBC",
      CITY_NAME: "TABACO",
      DPA_PORT_CODE: "TBC",
    },
    {
      CITY_CODE: "PHTDC",
      CITY_NAME: "TANDOC",
      DPA_PORT_CODE: "TDC",
    },
    {
      CITY_CODE: "PHTDG",
      CITY_NAME: "TANDAG",
      DPA_PORT_CODE: "TDG",
    },
    {
      CITY_CODE: "PHTGO",
      CITY_NAME: "TAGOLOAN MIS. OR",
      DPA_PORT_CODE: "TGL",
    },
    {
      CITY_CODE: "PHTGT",
      CITY_NAME: "TAGUITE BAY",
      DPA_PORT_CODE: "TGE",
    },
    {
      CITY_CODE: "PHTGW",
      CITY_NAME: "TUNGAWAN BAY",
      DPA_PORT_CODE: "TGW",
    },
    {
      CITY_CODE: "PHTIB",
      CITY_NAME: "TIBUNGCO",
      DPA_PORT_CODE: "TIB",
    },
    {
      CITY_CODE: "PHTKW",
      CITY_NAME: "TAGKAWAYAN",
      DPA_PORT_CODE: "TKW",
    },
    {
      CITY_CODE: "PHTLG",
      CITY_NAME: "TOLONG",
      DPA_PORT_CODE: "TLG",
    },
    {
      CITY_CODE: "PHTLM",
      CITY_NAME: "TALOMO",
      DPA_PORT_CODE: "TLM",
    },
    {
      CITY_CODE: "PHTLP",
      CITY_NAME: "TOLOPO",
      DPA_PORT_CODE: "TLP",
    },
    {
      CITY_CODE: "PHTLS",
      CITY_NAME: "TOLOSA",
      DPA_PORT_CODE: "TLS",
    },
    {
      CITY_CODE: "PHULY",
      CITY_NAME: "ULUNGAN BAY",
      DPA_PORT_CODE: "ULY",
    },
    {
      CITY_CODE: "PHURR",
      CITY_NAME: "UMRUR",
      DPA_PORT_CODE: "URR",
    },
    {
      CITY_CODE: "PHVCS",
      CITY_NAME: "VICTORIAS",
      DPA_PORT_CODE: "VCS",
    },
    {
      CITY_CODE: "PHVTL",
      CITY_NAME: "VITALI",
      DPA_PORT_CODE: "VTL",
    },
    {
      CITY_CODE: "PHVTO",
      CITY_NAME: "VITO",
      DPA_PORT_CODE: "VTO",
    },
    {
      CITY_CODE: "PHXSO",
      CITY_NAME: "SIOCON",
      DPA_PORT_CODE: "XSO",
    },
    {
      CITY_CODE: "PHZAM",
      CITY_NAME: "ZAMBOANGA",
      DPA_PORT_CODE: "ZMB",
    },
    {
      CITY_CODE: "PKBQM",
      CITY_NAME: "MUHAMMAD BIN QASIM",
      DPA_PORT_CODE: "BQM",
    },
    {
      CITY_CODE: "PKGWD",
      CITY_NAME: "GWADAR",
      DPA_PORT_CODE: "GWD",
    },
    {
      CITY_CODE: "PKISB",
      CITY_NAME: "ISLAMABAD",
      DPA_PORT_CODE: "ISB",
    },
    {
      CITY_CODE: "PKJIW",
      CITY_NAME: "JIWANI",
      DPA_PORT_CODE: "JIW",
    },
    {
      CITY_CODE: "PKKBU",
      CITY_NAME: "KETI BUNDER",
      DPA_PORT_CODE: "KBU",
    },
    {
      CITY_CODE: "PKKCT",
      CITY_NAME: "KARACHI CONTAINER TERMINAL",
      DPA_PORT_CODE: "KCT",
    },
    {
      CITY_CODE: "PKKHI",
      CITY_NAME: "KARACHI",
      DPA_PORT_CODE: "KHI",
    },
    {
      CITY_CODE: "PKORW",
      CITY_NAME: "ORMARA",
      DPA_PORT_CODE: "ORW",
    },
    {
      CITY_CODE: "PKQCT",
      CITY_NAME: "QASIM INTERNATIONAL CONTAINER",
      DPA_PORT_CODE: "QCT",
    },
    {
      CITY_CODE: "PKRWP",
      CITY_NAME: "RAWALPINDI",
      DPA_PORT_CODE: "RWP",
    },
    {
      CITY_CODE: "PLBIA",
      CITY_NAME: "BIALYSTOK",
      DPA_PORT_CODE: "BIA",
    },
    {
      CITY_CODE: "PLGDN",
      CITY_NAME: "GDANSK",
      DPA_PORT_CODE: "GDN",
    },
    {
      CITY_CODE: "PLGDY",
      CITY_NAME: "GDYNIA",
      DPA_PORT_CODE: "GDY",
    },
    {
      CITY_CODE: "PLKLC",
      CITY_NAME: "KIELCE",
      DPA_PORT_CODE: "KLC",
    },
    {
      CITY_CODE: "PLKLQ",
      CITY_NAME: "KOLO",
      DPA_PORT_CODE: "KLQ",
    },
    {
      CITY_CODE: "PLKOL",
      CITY_NAME: "KOLOBRZEG",
      DPA_PORT_CODE: "KOL",
    },
    {
      CITY_CODE: "PLKTW",
      CITY_NAME: "KATOWICE",
      DPA_PORT_CODE: "KVW",
    },
    {
      CITY_CODE: "PLKTY",
      CITY_NAME: "KETY",
      DPA_PORT_CODE: "KTZ",
    },
    {
      CITY_CODE: "PLKUT",
      CITY_NAME: "KUTNO",
      DPA_PORT_CODE: "KUT",
    },
    {
      CITY_CODE: "PLLOD",
      CITY_NAME: "LODZ",
      DPA_PORT_CODE: "LOD",
    },
    {
      CITY_CODE: "PLNFW",
      CITY_NAME: "NEUFAHRWASSER",
      DPA_PORT_CODE: "NFW",
    },
    {
      CITY_CODE: "PLNOW",
      CITY_NAME: "NOWY PORT",
      DPA_PORT_CODE: "NOW",
    },
    {
      CITY_CODE: "PLRAD",
      CITY_NAME: "RADOMSKO",
      DPA_PORT_CODE: "RAD",
    },
    {
      CITY_CODE: "PLSLP",
      CITY_NAME: "SLUPSK",
      DPA_PORT_CODE: "SLP",
    },
    {
      CITY_CODE: "PLSWI",
      CITY_NAME: "SWINOUJSCIE",
      DPA_PORT_CODE: "SWI",
    },
    {
      CITY_CODE: "PLSZZ",
      CITY_NAME: "SZCZECIN",
      DPA_PORT_CODE: "SZZ",
    },
    {
      CITY_CODE: "PLUST",
      CITY_NAME: "USTKA",
      DPA_PORT_CODE: "UST",
    },
    {
      CITY_CODE: "PLWLA",
      CITY_NAME: "WLADYSLAWOWO",
      DPA_PORT_CODE: "WLA",
    },
    {
      CITY_CODE: "PLWLO",
      CITY_NAME: "WLOCLAWEK",
      DPA_PORT_CODE: "WLO",
    },
    {
      CITY_CODE: "PLZAM",
      CITY_NAME: "ZAMOSC",
      DPA_PORT_CODE: "ZAM",
    },
    {
      CITY_CODE: "PLZWR",
      CITY_NAME: "ZAWIERCIE",
      DPA_PORT_CODE: "ZWR",
    },
    {
      CITY_CODE: "PLZYR",
      CITY_NAME: "ZYRARDOW",
      DPA_PORT_CODE: "ZYR",
    },
    {
      CITY_CODE: "PMFSP",
      CITY_NAME: "ST PIERRE",
      DPA_PORT_CODE: "FSP",
    },
    {
      CITY_CODE: "PRBQN",
      CITY_NAME: "AGUADILLA",
      DPA_PORT_CODE: "BQN",
    },
    {
      CITY_CODE: "PRGUX",
      CITY_NAME: "GUANICA HARBOUR",
      DPA_PORT_CODE: "GUX",
    },
    {
      CITY_CODE: "PRGUY",
      CITY_NAME: "GUAYANILLA",
      DPA_PORT_CODE: "GUY",
    },
    {
      CITY_CODE: "PRPJO",
      CITY_NAME: "PUERTO JOBOS",
      DPA_PORT_CODE: "PJO",
    },
    {
      CITY_CODE: "PRPSE",
      CITY_NAME: "PONCE",
      DPA_PORT_CODE: "PSE",
    },
    {
      CITY_CODE: "PRPYA",
      CITY_NAME: "PORT YABUCOA",
      DPA_PORT_CODE: "PYA",
    },
    {
      CITY_CODE: "PRSJU",
      CITY_NAME: "SAN JUAN",
      DPA_PORT_CODE: "SJU",
    },
    {
      CITY_CODE: "PTADH",
      CITY_NAME: "ANGRA DO HEROISMO",
      DPA_PORT_CODE: "ADH",
    },
    {
      CITY_CODE: "PTBBL",
      CITY_NAME: "BOBADELA",
      DPA_PORT_CODE: "BBL",
    },
    {
      CITY_CODE: "PTCAC",
      CITY_NAME: "CACEM",
      DPA_PORT_CODE: "CAC",
    },
    {
      CITY_CODE: "PTCNL",
      CITY_NAME: "CANICAL",
      DPA_PORT_CODE: "CXX",
    },
    {
      CITY_CODE: "PTCON",
      CITY_NAME: "CONSTANCIA",
      DPA_PORT_CODE: "CON",
    },
    {
      CITY_CODE: "PTENT",
      CITY_NAME: "ENTRONCAMENTO",
      DPA_PORT_CODE: "ENT",
    },
    {
      CITY_CODE: "PTFNC",
      CITY_NAME: "FUNCHAL",
      DPA_PORT_CODE: "FNC",
    },
    {
      CITY_CODE: "PTLEI",
      CITY_NAME: "LEIXOES",
      DPA_PORT_CODE: "LEI",
    },
    {
      CITY_CODE: "PTLIS",
      CITY_NAME: "LISBOA",
      DPA_PORT_CODE: "LIS",
    },
    {
      CITY_CODE: "PTOLH",
      CITY_NAME: "OLHAO",
      DPA_PORT_CODE: "OLH",
    },
    {
      CITY_CODE: "PTOPO",
      CITY_NAME: "PORTO",
      DPA_PORT_CODE: "OPO",
    },
    {
      CITY_CODE: "PTPDL",
      CITY_NAME: "PONTA DELGADA",
      DPA_PORT_CODE: "PDL",
    },
    {
      CITY_CODE: "PTPOA",
      CITY_NAME: "PORTO ALTO",
      DPA_PORT_CODE: "POA",
    },
    {
      CITY_CODE: "PTPRM",
      CITY_NAME: "PORTIMAO",
      DPA_PORT_CODE: "PRJ",
    },
    {
      CITY_CODE: "PTRIA",
      CITY_NAME: "RIACHOS",
      DPA_PORT_CODE: "RIA",
    },
    {
      CITY_CODE: "PTSET",
      CITY_NAME: "SETUBAL",
      DPA_PORT_CODE: "EKL",
    },
    {
      CITY_CODE: "PTTER",
      CITY_NAME: "TERCEIRA ISLAND",
      DPA_PORT_CODE: "TER",
    },
    {
      CITY_CODE: "PTVDC",
      CITY_NAME: "VIANA DO CASTELO",
      DPA_PORT_CODE: "VDC",
    },
    {
      CITY_CODE: "PYASU",
      CITY_NAME: "ASUNCION",
      DPA_PORT_CODE: "ASU",
    },
    {
      CITY_CODE: "PYBCM",
      CITY_NAME: "BARRIO CAACUPE MI",
      DPA_PORT_CODE: "BCX",
    },
    {
      CITY_CODE: "PYCAA",
      CITY_NAME: "CAACUPE",
      DPA_PORT_CODE: "CKD",
    },
    {
      CITY_CODE: "PYCNP",
      CITY_NAME: "CONCEPCION",
      DPA_PORT_CODE: "CNP",
    },
    {
      CITY_CODE: "PYITE",
      CITY_NAME: "ITA ENRAMADA",
      DPA_PORT_CODE: "ITE",
    },
    {
      CITY_CODE: "PYPSE",
      CITY_NAME: "PUERTO SEGURO",
      DPA_PORT_CODE: "PGE",
    },
    {
      CITY_CODE: "PYTER",
      CITY_NAME: "TERPORT",
      DPA_PORT_CODE: "TPR",
    },
    {
      CITY_CODE: "PYVLL",
      CITY_NAME: "VILLETA",
      DPA_PORT_CODE: "VLJ",
    },
    {
      CITY_CODE: "QADOH",
      CITY_NAME: "DOHA",
      DPA_PORT_CODE: "DOH",
    },
    {
      CITY_CODE: "QAPCC",
      CITY_NAME: "QAPCO",
      DPA_PORT_CODE: "PCC",
    },
    {
      CITY_CODE: "QAQCH",
      CITY_NAME: "QCHEM",
      DPA_PORT_CODE: "QCH",
    },
    {
      CITY_CODE: "QAUMS",
      CITY_NAME: "MESAIEED (UMMSAID)",
      DPA_PORT_CODE: "UMS",
    },
    {
      CITY_CODE: "REPDG",
      CITY_NAME: "Pointe des Galets",
      DPA_PORT_CODE: "PDG",
    },
    {
      CITY_CODE: "REREU",
      CITY_NAME: "REUNION",
      DPA_PORT_CODE: "REU",
    },
    {
      CITY_CODE: "RERUN",
      CITY_NAME: "ST DENIS",
      DPA_PORT_CODE: "RUN",
    },
    {
      CITY_CODE: "ROAGI",
      CITY_NAME: "AGIGEA",
      DPA_PORT_CODE: "AGI",
    },
    {
      CITY_CODE: "ROBCA",
      CITY_NAME: "BORCA",
      DPA_PORT_CODE: "BCA",
    },
    {
      CITY_CODE: "ROBRA",
      CITY_NAME: "BRAILA",
      DPA_PORT_CODE: "BII",
    },
    {
      CITY_CODE: "ROCDA",
      CITY_NAME: "CURTEA DE ARGES",
      DPA_PORT_CODE: "CDA",
    },
    {
      CITY_CODE: "ROCND",
      CITY_NAME: "CONSTANTA",
      DPA_PORT_CODE: "CND",
    },
    {
      CITY_CODE: "RODVA",
      CITY_NAME: "DEVA",
      DPA_PORT_CODE: "DEJ",
    },
    {
      CITY_CODE: "ROOSI",
      CITY_NAME: "ONESTI BACAU",
      DPA_PORT_CODE: "OST",
    },
    {
      CITY_CODE: "ROSFG",
      CITY_NAME: "SFANTUL GHEORGHE",
      DPA_PORT_CODE: "SFG",
    },
    {
      CITY_CODE: "ROSLA",
      CITY_NAME: "SLATINA",
      DPA_PORT_CODE: "STA",
    },
    {
      CITY_CODE: "ROTAR",
      CITY_NAME: "TARGOVISTE",
      DPA_PORT_CODE: "TGO",
    },
    {
      CITY_CODE: "ROTJU",
      CITY_NAME: "TIRGU JIU",
      DPA_PORT_CODE: "TJU",
    },
    {
      CITY_CODE: "RUARM",
      CITY_NAME: "ARMAN (MAGADAN)",
      DPA_PORT_CODE: "ARM",
    },
    {
      CITY_CODE: "RUASF",
      CITY_NAME: "ASTRAKHAN",
      DPA_PORT_CODE: "ASF",
    },
    {
      CITY_CODE: "RUAZO",
      CITY_NAME: "AZOV",
      DPA_PORT_CODE: "AZO",
    },
    {
      CITY_CODE: "RUBEM",
      CITY_NAME: "BELOMORSK",
      DPA_PORT_CODE: "BEM",
    },
    {
      CITY_CODE: "RUDKA",
      CITY_NAME: "DEKASTRI",
      DPA_PORT_CODE: "DKA",
    },
    {
      CITY_CODE: "RUGOJ",
      CITY_NAME: "NIZHNIY NOVGOROD",
      DPA_PORT_CODE: "GOJ",
    },
    {
      CITY_CODE: "RUIAA",
      CITY_NAME: "IGARKA",
      DPA_PORT_CODE: "IAA",
    },
    {
      CITY_CODE: "RUIAR",
      CITY_NAME: "YAROSLAVL",
      DPA_PORT_CODE: "IAR",
    },
    {
      CITY_CODE: "RUKDT",
      CITY_NAME: "KRONSHTADT",
      DPA_PORT_CODE: "KDT",
    },
    {
      CITY_CODE: "RUKGD",
      CITY_NAME: "KALININGRAD",
      DPA_PORT_CODE: "KGD",
    },
    {
      CITY_CODE: "RUKHO",
      CITY_NAME: "KHOLMSK",
      DPA_PORT_CODE: "KHL",
    },
    {
      CITY_CODE: "RUKHV",
      CITY_NAME: "KHABAROVSK",
      DPA_PORT_CODE: "KHV",
    },
    {
      CITY_CODE: "RUKMU",
      CITY_NAME: "KAMSKOYE USTYE",
      DPA_PORT_CODE: "KMU",
    },
    {
      CITY_CODE: "RUKOR",
      CITY_NAME: "KORSAKOV",
      DPA_PORT_CODE: "KOR",
    },
    {
      CITY_CODE: "RUKRR",
      CITY_NAME: "KRASNODAR",
      DPA_PORT_CODE: "KRR",
    },
    {
      CITY_CODE: "RUKUF",
      CITY_NAME: "SAMARA",
      DPA_PORT_CODE: "KUF",
    },
    {
      CITY_CODE: "RULED",
      CITY_NAME: "ST. PETERSBURG",
      DPA_PORT_CODE: "LED",
    },
    {
      CITY_CODE: "RUMAG",
      CITY_NAME: "MAGADANSKY",
      DPA_PORT_CODE: "MHZ",
    },
    {
      CITY_CODE: "RUMOW",
      CITY_NAME: "MOSCOW",
      DPA_PORT_CODE: "MOW",
    },
    {
      CITY_CODE: "RUNJK",
      CITY_NAME: "NAKHODKA",
      DPA_PORT_CODE: "NJK",
    },
    {
      CITY_CODE: "RUNNM",
      CITY_NAME: "NARYAN MAR",
      DPA_PORT_CODE: "NNM",
    },
    {
      CITY_CODE: "RUNOZ",
      CITY_NAME: "NOVAYA ZEMLYA",
      DPA_PORT_CODE: "NOZ",
    },
    {
      CITY_CODE: "RUNVS",
      CITY_NAME: "NOVOROSSIYSK",
      DPA_PORT_CODE: "NVS",
    },
    {
      CITY_CODE: "RUOHO",
      CITY_NAME: "OKHOTSK",
      DPA_PORT_CODE: "OHO",
    },
    {
      CITY_CODE: "RUOKT",
      CITY_NAME: "OKTIABRSKIJ",
      DPA_PORT_CODE: "OKT",
    },
    {
      CITY_CODE: "RUOLG",
      CITY_NAME: "OLENYA",
      DPA_PORT_CODE: "OLG",
    },
    {
      CITY_CODE: "RUPET",
      CITY_NAME: "PESTOVO",
      DPA_PORT_CODE: "PQT",
    },
    {
      CITY_CODE: "RUPKC",
      CITY_NAME: "PETROPAVLOVSKKAMCHATSKIY",
      DPA_PORT_CODE: "PKC",
    },
    {
      CITY_CODE: "RUPVS",
      CITY_NAME: "PROVIDENIYA",
      DPA_PORT_CODE: "PVS",
    },
    {
      CITY_CODE: "RURDP",
      CITY_NAME: "RUDNAYA PRISTAN",
      DPA_PORT_CODE: "RDP",
    },
    {
      CITY_CODE: "RURND",
      CITY_NAME: "ROSTOV NA DONU",
      DPA_PORT_CODE: "RNQ",
    },
    {
      CITY_CODE: "RUROV",
      CITY_NAME: "ROSTOV",
      DPA_PORT_CODE: "ROV",
    },
    {
      CITY_CODE: "RURTW",
      CITY_NAME: "SARATOV",
      DPA_PORT_CODE: "RTW",
    },
    {
      CITY_CODE: "RURYB",
      CITY_NAME: "RYBINSK",
      DPA_PORT_CODE: "RYB",
    },
    {
      CITY_CODE: "RUSPP",
      CITY_NAME: "SAINT PETERSBURG PETROLESPORT",
      DPA_PORT_CODE: "RJP",
    },
    {
      CITY_CODE: "RUSRM",
      CITY_NAME: "SEVEROMORSK",
      DPA_PORT_CODE: "SRM",
    },
    {
      CITY_CODE: "RUTAG",
      CITY_NAME: "TAGANROG",
      DPA_PORT_CODE: "TAJ",
    },
    {
      CITY_CODE: "RUTTI",
      CITY_NAME: "TOLYATTI",
      DPA_PORT_CODE: "TTI",
    },
    {
      CITY_CODE: "RUTUA",
      CITY_NAME: "TUAPSE",
      DPA_PORT_CODE: "TUA",
    },
    {
      CITY_CODE: "RUULU",
      CITY_NAME: "UST LUGA",
      DPA_PORT_CODE: "ULU",
    },
    {
      CITY_CODE: "RUURA",
      CITY_NAME: "URA GUBA",
      DPA_PORT_CODE: "URA",
    },
    {
      CITY_CODE: "RUVVO",
      CITY_NAME: "VLADIVOSTOK",
      DPA_PORT_CODE: "VVO",
    },
    {
      CITY_CODE: "RUVYG",
      CITY_NAME: "VYBORG",
      DPA_PORT_CODE: "VYG",
    },
    {
      CITY_CODE: "RUVYP",
      CITY_NAME: "VOSTOCHNIY",
      DPA_PORT_CODE: "VYP",
    },
    {
      CITY_CODE: "RUVZB",
      CITY_NAME: "VOZROZHDENIYA",
      DPA_PORT_CODE: "VZB",
    },
    {
      CITY_CODE: "RUYAB",
      CITY_NAME: "YAGELNAYA BUKHTA",
      DPA_PORT_CODE: "YAB",
    },
    {
      CITY_CODE: "RUZHD",
      CITY_NAME: "ZHDANOV",
      DPA_PORT_CODE: "ZHD",
    },
    {
      CITY_CODE: "SAAKH",
      CITY_NAME: "AL KHARJ",
      DPA_PORT_CODE: "AKH",
    },
    {
      CITY_CODE: "SADFZ",
      CITY_NAME: "DAMMAM FREE ZONE",
      DPA_PORT_CODE: "DFZ",
    },
    {
      CITY_CODE: "SADHA",
      CITY_NAME: "DHAHRAN",
      DPA_PORT_CODE: "DHA",
    },
    {
      CITY_CODE: "SADMM",
      CITY_NAME: "DAMMAM",
      DPA_PORT_CODE: "DMM",
    },
    {
      CITY_CODE: "SAEJH",
      CITY_NAME: "WEDJH",
      DPA_PORT_CODE: "EJH",
    },
    {
      CITY_CODE: "SAIEB",
      CITY_NAME: "IENBO",
      DPA_PORT_CODE: "IEB",
    },
    {
      CITY_CODE: "SAJED",
      CITY_NAME: "JEDDAH",
      DPA_PORT_CODE: "JED",
    },
    {
      CITY_CODE: "SAJUB",
      CITY_NAME: "JUBAIL",
      DPA_PORT_CODE: "JUB",
    },
    {
      CITY_CODE: "SAKAC",
      CITY_NAME: "KING ABDULLAH CITY",
      DPA_PORT_CODE: "KAQ",
    },
    {
      CITY_CODE: "SAKMX",
      CITY_NAME: "KHAMIS MUSHAYT",
      DPA_PORT_CODE: "KCX",
    },
    {
      CITY_CODE: "SAQAL",
      CITY_NAME: "QALSN",
      DPA_PORT_CODE: "QAL",
    },
    {
      CITY_CODE: "SAQUR",
      CITY_NAME: "QURAYYAH",
      DPA_PORT_CODE: "QUR",
    },
    {
      CITY_CODE: "SARAB",
      CITY_NAME: "RABIGH",
      DPA_PORT_CODE: "RAB",
    },
    {
      CITY_CODE: "SARAM",
      CITY_NAME: "RAS AL MISH AB",
      DPA_PORT_CODE: "RAM",
    },
    {
      CITY_CODE: "SARTA",
      CITY_NAME: "RAS TANURA",
      DPA_PORT_CODE: "RTA",
    },
    {
      CITY_CODE: "SARUH",
      CITY_NAME: "RIYADH",
      DPA_PORT_CODE: "RUH",
    },
    {
      CITY_CODE: "SATFZ",
      CITY_NAME: "TUSDEER FREEZONE",
      DPA_PORT_CODE: "TFZ",
    },
    {
      CITY_CODE: "SAYNB",
      CITY_NAME: "YANBO",
      DPA_PORT_CODE: "YNB",
    },
    {
      CITY_CODE: "SAZUY",
      CITY_NAME: "ZULAYFAYN",
      DPA_PORT_CODE: "ZUY",
    },
    {
      CITY_CODE: "SBGZO",
      CITY_NAME: "GIZO IS",
      DPA_PORT_CODE: "GZO",
    },
    {
      CITY_CODE: "SBHIR",
      CITY_NAME: "HONIARA",
      DPA_PORT_CODE: "HIR",
    },
    {
      CITY_CODE: "SBIRA",
      CITY_NAME: "KIRAKIRA",
      DPA_PORT_CODE: "IRA",
    },
    {
      CITY_CODE: "SBXYA",
      CITY_NAME: "YANDINA",
      DPA_PORT_CODE: "XYA",
    },
    {
      CITY_CODE: "SCMAW",
      CITY_NAME: "MAHE",
      DPA_PORT_CODE: "MAW",
    },
    {
      CITY_CODE: "SCPOV",
      CITY_NAME: "PORT VICTORIA",
      DPA_PORT_CODE: "POV",
    },
    {
      CITY_CODE: "SCVIC",
      CITY_NAME: "VICTORIA",
      DPA_PORT_CODE: "VCR",
    },
    {
      CITY_CODE: "SDPZU",
      CITY_NAME: "PORT SUDAN",
      DPA_PORT_CODE: "PZU",
    },
    {
      CITY_CODE: "SDSOB",
      CITY_NAME: "SOBA",
      DPA_PORT_CODE: "SOB",
    },
    {
      CITY_CODE: "SEAHU",
      CITY_NAME: "AHUS",
      DPA_PORT_CODE: "AHU",
    },
    {
      CITY_CODE: "SEDOM",
      CITY_NAME: "DOMSJO",
      DPA_PORT_CODE: "DOM",
    },
    {
      CITY_CODE: "SEDYN",
      CITY_NAME: "DYNAS",
      DPA_PORT_CODE: "DYN",
    },
    {
      CITY_CODE: "SEFAK",
      CITY_NAME: "FAGERVIK",
      DPA_PORT_CODE: "FAK",
    },
    {
      CITY_CODE: "SEFJA",
      CITY_NAME: "FJALLBACKA",
      DPA_PORT_CODE: "FJA",
    },
    {
      CITY_CODE: "SEGKS",
      CITY_NAME: "GUSTAFSVIK",
      DPA_PORT_CODE: "GKS",
    },
    {
      CITY_CODE: "SEGOT",
      CITY_NAME: "GOTHENBURG",
      DPA_PORT_CODE: "GOT",
    },
    {
      CITY_CODE: "SEGVX",
      CITY_NAME: "GAVLE",
      DPA_PORT_CODE: "GVX",
    },
    {
      CITY_CODE: "SEHAD",
      CITY_NAME: "HALMSTAD",
      DPA_PORT_CODE: "HAD",
    },
    {
      CITY_CODE: "SEHEL",
      CITY_NAME: "HELSINGBORG",
      DPA_PORT_CODE: "HBG",
    },
    {
      CITY_CODE: "SEIGG",
      CITY_NAME: "IGGESUND",
      DPA_PORT_CODE: "IGG",
    },
    {
      CITY_CODE: "SEKAX",
      CITY_NAME: "KALIX",
      DPA_PORT_CODE: "KAX",
    },
    {
      CITY_CODE: "SEKBK",
      CITY_NAME: "KARLSHOLMSBRUK",
      DPA_PORT_CODE: "KBK",
    },
    {
      CITY_CODE: "SEKRF",
      CITY_NAME: "KRAMFORS",
      DPA_PORT_CODE: "KRF",
    },
    {
      CITY_CODE: "SEKXV",
      CITY_NAME: "KARLSBORG AXELVIK",
      DPA_PORT_CODE: "KXV",
    },
    {
      CITY_CODE: "SELDK",
      CITY_NAME: "LIDKOPING",
      DPA_PORT_CODE: "LDK",
    },
    {
      CITY_CODE: "SEMAR",
      CITY_NAME: "MARIEBERG",
      DPA_PORT_CODE: "MIG",
    },
    {
      CITY_CODE: "SEMMA",
      CITY_NAME: "MALMO",
      DPA_PORT_CODE: "MMA",
    },
    {
      CITY_CODE: "SENHN",
      CITY_NAME: "NYHAMN",
      DPA_PORT_CODE: "NHN",
    },
    {
      CITY_CODE: "SENJA",
      CITY_NAME: "NORRBOTTENS JARNVERK",
      DPA_PORT_CODE: "NJA",
    },
    {
      CITY_CODE: "SENRK",
      CITY_NAME: "NORRKOPING",
      DPA_PORT_CODE: "NRK",
    },
    {
      CITY_CODE: "SEOBB",
      CITY_NAME: "OBBOLA",
      DPA_PORT_CODE: "OBB",
    },
    {
      CITY_CODE: "SEOER",
      CITY_NAME: "ORNSKOLDSVIK",
      DPA_PORT_CODE: "OER",
    },
    {
      CITY_CODE: "SEORV",
      CITY_NAME: "ORVIKEN",
      DPA_PORT_CODE: "ORV",
    },
    {
      CITY_CODE: "SEOSK",
      CITY_NAME: "OSKARSHAMN",
      DPA_PORT_CODE: "OSK",
    },
    {
      CITY_CODE: "SEOXE",
      CITY_NAME: "OXELOSUND",
      DPA_PORT_CODE: "OXE",
    },
    {
      CITY_CODE: "SERNB",
      CITY_NAME: "RONNEBY",
      DPA_PORT_CODE: "RNB",
    },
    {
      CITY_CODE: "SESDL",
      CITY_NAME: "SUNDSVALL",
      DPA_PORT_CODE: "SDL",
    },
    {
      CITY_CODE: "SESFT",
      CITY_NAME: "SKELLEFTEA",
      DPA_PORT_CODE: "SFT",
    },
    {
      CITY_CODE: "SESOE",
      CITY_NAME: "SODERTALJE",
      DPA_PORT_CODE: "SOE",
    },
    {
      CITY_CODE: "SESRA",
      CITY_NAME: "SKARA",
      DPA_PORT_CODE: "RRK",
    },
    {
      CITY_CODE: "SESTO",
      CITY_NAME: "STOCKHOLM",
      DPA_PORT_CODE: "STO",
    },
    {
      CITY_CODE: "SETHN",
      CITY_NAME: "TROLLHATTAN",
      DPA_PORT_CODE: "THN",
    },
    {
      CITY_CODE: "SEULF",
      CITY_NAME: "ULFVIK",
      DPA_PORT_CODE: "ULF",
    },
    {
      CITY_CODE: "SEUME",
      CITY_NAME: "UMEA",
      DPA_PORT_CODE: "UME",
    },
    {
      CITY_CODE: "SEURS",
      CITY_NAME: "URSVIKEN",
      DPA_PORT_CODE: "URS",
    },
    {
      CITY_CODE: "SEVAG",
      CITY_NAME: "VARBERG",
      DPA_PORT_CODE: "VAG",
    },
    {
      CITY_CODE: "SEVAJ",
      CITY_NAME: "VAJA",
      DPA_PORT_CODE: "VAJ",
    },
    {
      CITY_CODE: "SEVBY",
      CITY_NAME: "VISBY",
      DPA_PORT_CODE: "VBY",
    },
    {
      CITY_CODE: "SEVST",
      CITY_NAME: "VASTERAS",
      DPA_PORT_CODE: "VST",
    },
    {
      CITY_CODE: "SEVVK",
      CITY_NAME: "VASTERVIK",
      DPA_PORT_CODE: "VVK",
    },
    {
      CITY_CODE: "SEWAL",
      CITY_NAME: "WALLHAMN",
      DPA_PORT_CODE: "WAL",
    },
    {
      CITY_CODE: "SEYST",
      CITY_NAME: "YSTAD",
      DPA_PORT_CODE: "YST",
    },
    {
      CITY_CODE: "SGAYC",
      CITY_NAME: "PULAN AYER CHAWAN",
      DPA_PORT_CODE: "AYC",
    },
    {
      CITY_CODE: "SGJUR",
      CITY_NAME: "JURONG",
      DPA_PORT_CODE: "JUR",
    },
    {
      CITY_CODE: "SGKEP",
      CITY_NAME: "KEPPEL WHARVES",
      DPA_PORT_CODE: "KEP",
    },
    {
      CITY_CODE: "SGSCT",
      CITY_NAME: "SINGAPORE CONTAINER TERMINAL",
      DPA_PORT_CODE: "SCT",
    },
    {
      CITY_CODE: "SGSIN",
      CITY_NAME: "SINGAPORE",
      DPA_PORT_CODE: "SIN",
    },
    {
      CITY_CODE: "SHSHN",
      CITY_NAME: "JAMESTOWN",
      DPA_PORT_CODE: "SHN",
    },
    {
      CITY_CODE: "SIKOP",
      CITY_NAME: "KOPER",
      DPA_PORT_CODE: "KOP",
    },
    {
      CITY_CODE: "SKBAB",
      CITY_NAME: "BRATISLAVA PORT",
      DPA_PORT_CODE: "BAB",
    },
    {
      CITY_CODE: "SKDJA",
      CITY_NAME: "DUNAJSKA STREDA",
      DPA_PORT_CODE: "DCX",
    },
    {
      CITY_CODE: "SKPBY",
      CITY_NAME: "POVAZSKA BYSTRICA",
      DPA_PORT_CODE: "PBY",
    },
    {
      CITY_CODE: "SKPCV",
      CITY_NAME: "PUCHOV",
      DPA_PORT_CODE: "PCV",
    },
    {
      CITY_CODE: "SKSVT",
      CITY_NAME: "SVIT",
      DPA_PORT_CODE: "SQI",
    },
    {
      CITY_CODE: "SLFNA",
      CITY_NAME: "FREETOWN",
      DPA_PORT_CODE: "FNA",
    },
    {
      CITY_CODE: "SLSBO",
      CITY_NAME: "SHERBRO",
      DPA_PORT_CODE: "SBO",
    },
    {
      CITY_CODE: "SNDKR",
      CITY_NAME: "DAKAR",
      DPA_PORT_CODE: "DKR",
    },
    {
      CITY_CODE: "SNZIG",
      CITY_NAME: "ZIGUINCHOR",
      DPA_PORT_CODE: "ZIG",
    },
    {
      CITY_CODE: "SOBBO",
      CITY_NAME: "BERBERA",
      DPA_PORT_CODE: "BBO",
    },
    {
      CITY_CODE: "SOKMU",
      CITY_NAME: "KISMAYU",
      DPA_PORT_CODE: "AYU",
    },
    {
      CITY_CODE: "SOMGQ",
      CITY_NAME: "MOGADISHU",
      DPA_PORT_CODE: "MGQ",
    },
    {
      CITY_CODE: "SRICK",
      CITY_NAME: "NIEUW NICKERIE",
      DPA_PORT_CODE: "ICK",
    },
    {
      CITY_CODE: "SRPBM",
      CITY_NAME: "PARAMARIBO",
      DPA_PORT_CODE: "PBM",
    },
    {
      CITY_CODE: "STPCP",
      CITY_NAME: "PRINCIPE",
      DPA_PORT_CODE: "PCP",
    },
    {
      CITY_CODE: "STTMS",
      CITY_NAME: "SAO TOME ISLAND",
      DPA_PORT_CODE: "TMS",
    },
    {
      CITY_CODE: "SUHLM",
      CITY_NAME: "KHELTERMA",
      DPA_PORT_CODE: "HLM",
    },
    {
      CITY_CODE: "SULNR",
      CITY_NAME: "LINAKHAMARI",
      DPA_PORT_CODE: "LNR",
    },
    {
      CITY_CODE: "SURMS",
      CITY_NAME: "ROMASSARE",
      DPA_PORT_CODE: "RMS",
    },
    {
      CITY_CODE: "SUZEM",
      CITY_NAME: "ZELENIY MYS",
      DPA_PORT_CODE: "ZEM",
    },
    {
      CITY_CODE: "SVAQJ",
      CITY_NAME: "ACAJUTLA",
      DPA_PORT_CODE: "ACJ",
    },
    {
      CITY_CODE: "SVBCH",
      CITY_NAME: "BOCA CHICA",
      DPA_PORT_CODE: "BCH",
    },
    {
      CITY_CODE: "SVCJO",
      CITY_NAME: "CABO JOJO",
      DPA_PORT_CODE: "CJO",
    },
    {
      CITY_CODE: "SVSAL",
      CITY_NAME: "SAN SALVADOR",
      DPA_PORT_CODE: "SAN",
    },
    {
      CITY_CODE: "SYDAM",
      CITY_NAME: "DAMASCUS",
      DPA_PORT_CODE: "DCS",
    },
    {
      CITY_CODE: "SYLTK",
      CITY_NAME: "LATAKIA",
      DPA_PORT_CODE: "LTK",
    },
    {
      CITY_CODE: "SYTTS",
      CITY_NAME: "TARTOUS",
      DPA_PORT_CODE: "TTS",
    },
    {
      CITY_CODE: "SZQMN",
      CITY_NAME: "MBABANE",
      DPA_PORT_CODE: "QMN",
    },
    {
      CITY_CODE: "TCGDT",
      CITY_NAME: "GRAND TURK ISLAND",
      DPA_PORT_CODE: "GDT",
    },
    {
      CITY_CODE: "TCNCA",
      CITY_NAME: "NORTH CAICOS",
      DPA_PORT_CODE: "NCA",
    },
    {
      CITY_CODE: "TCXSC",
      CITY_NAME: "SOUTH CAICOS",
      DPA_PORT_CODE: "XSC",
    },
    {
      CITY_CODE: "TGKPE",
      CITY_NAME: "KPEME",
      DPA_PORT_CODE: "KPE",
    },
    {
      CITY_CODE: "TGLFW",
      CITY_NAME: "LOME",
      DPA_PORT_CODE: "LFW",
    },
    {
      CITY_CODE: "THBKK",
      CITY_NAME: "BANGKOK",
      DPA_PORT_CODE: "BKK",
    },
    {
      CITY_CODE: "THCHA",
      CITY_NAME: "CHANTABUN",
      DPA_PORT_CODE: "CHA",
    },
    {
      CITY_CODE: "THHDY",
      CITY_NAME: "HAT YAI",
      DPA_PORT_CODE: "HDY",
    },
    {
      CITY_CODE: "THHKT",
      CITY_NAME: "PHUKET",
      DPA_PORT_CODE: "PKT",
    },
    {
      CITY_CODE: "THKBV",
      CITY_NAME: "KRABI",
      DPA_PORT_CODE: "KBV",
    },
    {
      CITY_CODE: "THKLJ",
      CITY_NAME: "KLONG TOEY",
      DPA_PORT_CODE: "KLG",
    },
    {
      CITY_CODE: "THKLY",
      CITY_NAME: "KLONGYAI",
      DPA_PORT_CODE: "KLY",
    },
    {
      CITY_CODE: "THKNO",
      CITY_NAME: "KOH NOK",
      DPA_PORT_CODE: "KNO",
    },
    {
      CITY_CODE: "THKSI",
      CITY_NAME: "KOH SICHANG",
      DPA_PORT_CODE: "KSI",
    },
    {
      CITY_CODE: "THKYO",
      CITY_NAME: "KOH YAO",
      DPA_PORT_CODE: "KYO",
    },
    {
      CITY_CODE: "THLCH",
      CITY_NAME: "Laem Chabang",
      DPA_PORT_CODE: "LCH",
    },
    {
      CITY_CODE: "THLKR",
      CITY_NAME: "LAT KRABANG",
      DPA_PORT_CODE: "LKR",
    },
    {
      CITY_CODE: "THMAT",
      CITY_NAME: "MAP TAPUD",
      DPA_PORT_CODE: "MXJ",
    },
    {
      CITY_CODE: "THMEK",
      CITY_NAME: "MEKLONG",
      DPA_PORT_CODE: "MEK",
    },
    {
      CITY_CODE: "THNAW",
      CITY_NAME: "NARATHIWAT",
      DPA_PORT_CODE: "NAW",
    },
    {
      CITY_CODE: "THPAT",
      CITY_NAME: "PAT BANGKOK",
      DPA_PORT_CODE: "PAT",
    },
    {
      CITY_CODE: "THPKN",
      CITY_NAME: "PAKNAM",
      DPA_PORT_CODE: "PKN",
    },
    {
      CITY_CODE: "THSAT",
      CITY_NAME: "SATTAHIP",
      DPA_PORT_CODE: "SAP",
    },
    {
      CITY_CODE: "THSBP",
      CITY_NAME: "SIAM BANGKOK PORT",
      DPA_PORT_CODE: "SBP",
    },
    {
      CITY_CODE: "THSGZ",
      CITY_NAME: "SONGKHLA",
      DPA_PORT_CODE: "SGZ",
    },
    {
      CITY_CODE: "THSPR",
      CITY_NAME: "SAMUT PRAKAN",
      DPA_PORT_CODE: "SPR",
    },
    {
      CITY_CODE: "THSPS",
      CITY_NAME: "SAMUT PRAKAN SAHATHAI TERMINAL",
      DPA_PORT_CODE: "PSX",
    },
    {
      CITY_CODE: "THTST",
      CITY_NAME: "TRANG",
      DPA_PORT_CODE: "TST",
    },
    {
      CITY_CODE: "THUSM",
      CITY_NAME: "KOH SAMUI",
      DPA_PORT_CODE: "USM",
    },
    {
      CITY_CODE: "TJDYU",
      CITY_NAME: "DUSHANBE",
      DPA_PORT_CODE: "DYU",
    },
    {
      CITY_CODE: "TLDIL",
      CITY_NAME: "DILI",
      DPA_PORT_CODE: "DSL",
    },
    {
      CITY_CODE: "TMASB",
      CITY_NAME: "ASHKHABAD",
      DPA_PORT_CODE: "ASB",
    },
    {
      CITY_CODE: "TMKRW",
      CITY_NAME: "TURKMENBASHI",
      DPA_PORT_CODE: "KQW",
    },
    {
      CITY_CODE: "TMTMZ",
      CITY_NAME: "TERMEZ",
      DPA_PORT_CODE: "TMZ",
    },
    {
      CITY_CODE: "TNBIZ",
      CITY_NAME: "BIZERTE",
      DPA_PORT_CODE: "BCZ",
    },
    {
      CITY_CODE: "TNDJE",
      CITY_NAME: "DJERBA",
      DPA_PORT_CODE: "DJE",
    },
    {
      CITY_CODE: "TNGAE",
      CITY_NAME: "GABES",
      DPA_PORT_CODE: "GAE",
    },
    {
      CITY_CODE: "TNLSK",
      CITY_NAME: "LA SKHIRRA",
      DPA_PORT_CODE: "LSK",
    },
    {
      CITY_CODE: "TNMBA",
      CITY_NAME: "MENZEL BOURGUIBA",
      DPA_PORT_CODE: "MZB",
    },
    {
      CITY_CODE: "TNRDS",
      CITY_NAME: "RADES",
      DPA_PORT_CODE: "RDS",
    },
    {
      CITY_CODE: "TNSFA",
      CITY_NAME: "SFAX",
      DPA_PORT_CODE: "SFA",
    },
    {
      CITY_CODE: "TNSUS",
      CITY_NAME: "SOUSSE",
      DPA_PORT_CODE: "SUS",
    },
    {
      CITY_CODE: "TNTUN",
      CITY_NAME: "TUNIS",
      DPA_PORT_CODE: "TUN",
    },
    {
      CITY_CODE: "TOHPA",
      CITY_NAME: "HA'APAI",
      DPA_PORT_CODE: "HPA",
    },
    {
      CITY_CODE: "TONEI",
      CITY_NAME: "NEIAFU",
      DPA_PORT_CODE: "NEI",
    },
    {
      CITY_CODE: "TOTBU",
      CITY_NAME: "TONGATAPU",
      DPA_PORT_CODE: "TBU",
    },
    {
      CITY_CODE: "TRAKB",
      CITY_NAME: "AKCAABAT",
      DPA_PORT_CODE: "AKB",
    },
    {
      CITY_CODE: "TRALI",
      CITY_NAME: "ALIAGA",
      DPA_PORT_CODE: "ALI",
    },
    {
      CITY_CODE: "TRAMB",
      CITY_NAME: "AMBARLI",
      DPA_PORT_CODE: "AMB",
    },
    {
      CITY_CODE: "TRAMR",
      CITY_NAME: "AMBARLI",
      DPA_PORT_CODE: "AMC",
    },
    {
      CITY_CODE: "TRAVC",
      CITY_NAME: "AVCILAR",
      DPA_PORT_CODE: "AVC",
    },
    {
      CITY_CODE: "TRAYD",
      CITY_NAME: "AYDIN ALTINKUM",
      DPA_PORT_CODE: "AYD",
    },
    {
      CITY_CODE: "TRAYT",
      CITY_NAME: "ANTALYA",
      DPA_PORT_CODE: "AYT",
    },
    {
      CITY_CODE: "TRAYV",
      CITY_NAME: "AYVALIK",
      DPA_PORT_CODE: "AYV",
    },
    {
      CITY_CODE: "TRBAH",
      CITY_NAME: "BAHCELIEVLER",
      DPA_PORT_CODE: "AEV",
    },
    {
      CITY_CODE: "TRBTZ",
      CITY_NAME: "BURSA",
      DPA_PORT_CODE: "BTZ",
    },
    {
      CITY_CODE: "TRBYL",
      CITY_NAME: "BEYLIKDUZU",
      DPA_PORT_CODE: "EYL",
    },
    {
      CITY_CODE: "TRBZB",
      CITY_NAME: "BOZBURUN",
      DPA_PORT_CODE: "BZB",
    },
    {
      CITY_CODE: "TRCKY",
      CITY_NAME: "CERKEZKOY",
      DPA_PORT_CODE: "CEY",
    },
    {
      CITY_CODE: "TRCKZ",
      CITY_NAME: "CANAKKALE",
      DPA_PORT_CODE: "CKZ",
    },
    {
      CITY_CODE: "TRCUB",
      CITY_NAME: "CUBUKLU",
      DPA_PORT_CODE: "CUB",
    },
    {
      CITY_CODE: "TRDAT",
      CITY_NAME: "DATCA",
      DPA_PORT_CODE: "DAT",
    },
    {
      CITY_CODE: "TRDIL",
      CITY_NAME: "DILISKELESI",
      DPA_PORT_CODE: "DII",
    },
    {
      CITY_CODE: "TRDLV",
      CITY_NAME: "DILOVASI",
      DPA_PORT_CODE: "DLI",
    },
    {
      CITY_CODE: "TRDNZ",
      CITY_NAME: "DENIZILI",
      DPA_PORT_CODE: "DNZ",
    },
    {
      CITY_CODE: "TRDRC",
      CITY_NAME: "DERINCE",
      DPA_PORT_CODE: "DRC",
    },
    {
      CITY_CODE: "TRESY",
      CITY_NAME: "ESENYURT",
      DPA_PORT_CODE: "ESY",
    },
    {
      CITY_CODE: "TREYP",
      CITY_NAME: "EVYAP PORT",
      DPA_PORT_CODE: "EPY",
    },
    {
      CITY_CODE: "TRFET",
      CITY_NAME: "FETHIYE",
      DPA_PORT_CODE: "FET",
    },
    {
      CITY_CODE: "TRGCA",
      CITY_NAME: "GOKCEADA",
      DPA_PORT_CODE: "GCA",
    },
    {
      CITY_CODE: "TRGEM",
      CITY_NAME: "GEMLIK",
      DPA_PORT_CODE: "GEM",
    },
    {
      CITY_CODE: "TRGKV",
      CITY_NAME: "GOKOVA",
      DPA_PORT_CODE: "GKV",
    },
    {
      CITY_CODE: "TRHAR",
      CITY_NAME: "HARAMIDERE",
      DPA_PORT_CODE: "HDE",
    },
    {
      CITY_CODE: "TRHAY",
      CITY_NAME: "HAYDARPASA",
      DPA_PORT_CODE: "HAY",
    },
    {
      CITY_CODE: "TRHOP",
      CITY_NAME: "HOPA",
      DPA_PORT_CODE: "HOZ",
    },
    {
      CITY_CODE: "TRIKI",
      CITY_NAME: "IKTELLI",
      DPA_PORT_CODE: "IKI",
    },
    {
      CITY_CODE: "TRISK",
      CITY_NAME: "ISKENDERUN",
      DPA_PORT_CODE: "ISK",
    },
    {
      CITY_CODE: "TRIST",
      CITY_NAME: "ISTANBUL",
      DPA_PORT_CODE: "IST",
    },
    {
      CITY_CODE: "TRIZM",
      CITY_NAME: "IZMIR",
      DPA_PORT_CODE: "IZM",
    },
    {
      CITY_CODE: "TRIZT",
      CITY_NAME: "IZMIT",
      DPA_PORT_CODE: "IZT",
    },
    {
      CITY_CODE: "TRKFZ",
      CITY_NAME: "KORFEZ",
      DPA_PORT_CODE: "KFZ",
    },
    {
      CITY_CODE: "TRKGC",
      CITY_NAME: "KARAGAC",
      DPA_PORT_CODE: "KGC",
    },
    {
      CITY_CODE: "TRKMX",
      CITY_NAME: "KUMPORT",
      DPA_PORT_CODE: "KMX",
    },
    {
      CITY_CODE: "TRMAR",
      CITY_NAME: "MARMARA EREGLISI",
      DPA_PORT_CODE: "MQR",
    },
    {
      CITY_CODE: "TRMER",
      CITY_NAME: "MERSIN",
      DPA_PORT_CODE: "MER",
    },
    {
      CITY_CODE: "TRMPT",
      CITY_NAME: "MARPORT",
      DPA_PORT_CODE: "MPT",
    },
    {
      CITY_CODE: "TRNEM",
      CITY_NAME: "NEMRUT BAY",
      DPA_PORT_CODE: "NMB",
    },
    {
      CITY_CODE: "TRODU",
      CITY_NAME: "ODUNLUK",
      DPA_PORT_CODE: "ODU",
    },
    {
      CITY_CODE: "TRORD",
      CITY_NAME: "ORDU",
      DPA_PORT_CODE: "ORD",
    },
    {
      CITY_CODE: "TRPAM",
      CITY_NAME: "AMBARLI",
      DPA_PORT_CODE: "PFS",
    },
    {
      CITY_CODE: "TRSAM",
      CITY_NAME: "SAMANDIRA",
      DPA_PORT_CODE: "AIR",
    },
    {
      CITY_CODE: "TRSIL",
      CITY_NAME: "SILIVRI",
      DPA_PORT_CODE: "SIL",
    },
    {
      CITY_CODE: "TRSSX",
      CITY_NAME: "SAMSUN",
      DPA_PORT_CODE: "AMJ",
    },
    {
      CITY_CODE: "TRTEK",
      CITY_NAME: "TEKIRDAG",
      DPA_PORT_CODE: "KDA",
    },
    {
      CITY_CODE: "TRTOP",
      CITY_NAME: "TOPKAPI",
      DPA_PORT_CODE: "TOX",
    },
    {
      CITY_CODE: "TRTOR",
      CITY_NAME: "TORBALI",
      DPA_PORT_CODE: "TBI",
    },
    {
      CITY_CODE: "TRTUT",
      CITY_NAME: "TUTUNCIFTLIK",
      DPA_PORT_CODE: "TUV",
    },
    {
      CITY_CODE: "TRTUZ",
      CITY_NAME: "TUZLA",
      DPA_PORT_CODE: "TUZ",
    },
    {
      CITY_CODE: "TRTZX",
      CITY_NAME: "TRABZON",
      DPA_PORT_CODE: "TZX",
    },
    {
      CITY_CODE: "TRUNY",
      CITY_NAME: "UNYE",
      DPA_PORT_CODE: "UNY",
    },
    {
      CITY_CODE: "TRZON",
      CITY_NAME: "ZONGULDAK",
      DPA_PORT_CODE: "ZON",
    },
    {
      CITY_CODE: "TTPOS",
      CITY_NAME: "PORT OF SPAIN",
      DPA_PORT_CODE: "POS",
    },
    {
      CITY_CODE: "TTPTF",
      CITY_NAME: "POINT FORTIN",
      DPA_PORT_CODE: "PTF",
    },
    {
      CITY_CODE: "TTPTS",
      CITY_NAME: "POINT LISAS",
      DPA_PORT_CODE: "PNT",
    },
    {
      CITY_CODE: "TWHSZ",
      CITY_NAME: "HSIN CHU",
      DPA_PORT_CODE: "HSZ",
    },
    {
      CITY_CODE: "TWKEL",
      CITY_NAME: "KEELUNG",
      DPA_PORT_CODE: "KEL",
    },
    {
      CITY_CODE: "TWKHH",
      CITY_NAME: "KAOHSIUNG",
      DPA_PORT_CODE: "KHH",
    },
    {
      CITY_CODE: "TWTPE",
      CITY_NAME: "TAIPEI",
      DPA_PORT_CODE: "TPE",
    },
    {
      CITY_CODE: "TWTTT",
      CITY_NAME: "TAITUNG",
      DPA_PORT_CODE: "TTT",
    },
    {
      CITY_CODE: "TWTXG",
      CITY_NAME: "TAICHUNG",
      DPA_PORT_CODE: "TXG",
    },
    {
      CITY_CODE: "TWTYN",
      CITY_NAME: "TAOYUAN",
      DPA_PORT_CODE: "TYN",
    },
    {
      CITY_CODE: "TZDAR",
      CITY_NAME: "DAR ES SALAAM",
      DPA_PORT_CODE: "DAR",
    },
    {
      CITY_CODE: "TZMWZ",
      CITY_NAME: "MWANZA",
      DPA_PORT_CODE: "MWZ",
    },
    {
      CITY_CODE: "TZMYW",
      CITY_NAME: "MTWARA",
      DPA_PORT_CODE: "MYW",
    },
    {
      CITY_CODE: "TZPMA",
      CITY_NAME: "PEMBA",
      DPA_PORT_CODE: "PBA",
    },
    {
      CITY_CODE: "TZRIJ",
      CITY_NAME: "RIJIJU",
      DPA_PORT_CODE: "RIJ",
    },
    {
      CITY_CODE: "TZTGT",
      CITY_NAME: "TANGA",
      DPA_PORT_CODE: "TGT",
    },
    {
      CITY_CODE: "TZTKQ",
      CITY_NAME: "KIGOMA",
      DPA_PORT_CODE: "TKQ",
    },
    {
      CITY_CODE: "TZZNZ",
      CITY_NAME: "ZANZIBAR",
      DPA_PORT_CODE: "ZNZ",
    },
    {
      CITY_CODE: "UADNK",
      CITY_NAME: "DNEPROPETROVSK",
      DPA_PORT_CODE: "DNS",
    },
    {
      CITY_CODE: "UAIEV",
      CITY_NAME: "KIEV",
      DPA_PORT_CODE: "IEV",
    },
    {
      CITY_CODE: "UAILK",
      CITY_NAME: "ILYICHEVSK",
      DPA_PORT_CODE: "ILK",
    },
    {
      CITY_CODE: "UAKHE",
      CITY_NAME: "KHERSON",
      DPA_PORT_CODE: "KHE",
    },
    {
      CITY_CODE: "UALVI",
      CITY_NAME: "L VIV",
      DPA_PORT_CODE: "LVI",
    },
    {
      CITY_CODE: "UAMPW",
      CITY_NAME: "MARIUPOL",
      DPA_PORT_CODE: "MPW",
    },
    {
      CITY_CODE: "UANIK",
      CITY_NAME: "NIKOLAYEV",
      DPA_PORT_CODE: "NIK",
    },
    {
      CITY_CODE: "UAOCT",
      CITY_NAME: "OKTYABRSK",
      DPA_PORT_CODE: "OCT",
    },
    {
      CITY_CODE: "UAODS",
      CITY_NAME: "ODESSA",
      DPA_PORT_CODE: "ODS",
    },
    {
      CITY_CODE: "UAPLK",
      CITY_NAME: "PRYLUKY",
      DPA_PORT_CODE: "PLK",
    },
    {
      CITY_CODE: "UAPLV",
      CITY_NAME: "POLTAVA",
      DPA_PORT_CODE: "PLV",
    },
    {
      CITY_CODE: "UAUGL",
      CITY_NAME: "UGLEGORSK",
      DPA_PORT_CODE: "UGL",
    },
    {
      CITY_CODE: "UAUSD",
      CITY_NAME: "USTDONETSKIY",
      DPA_PORT_CODE: "USD",
    },
    {
      CITY_CODE: "UGJIN",
      CITY_NAME: "JINJA",
      DPA_PORT_CODE: "JIN",
    },
    {
      CITY_CODE: "UGKLA",
      CITY_NAME: "KAMPALA",
      DPA_PORT_CODE: "KLA",
    },
    {
      CITY_CODE: "UGLIR",
      CITY_NAME: "LIR",
      DPA_PORT_CODE: "LIR",
    },
    {
      CITY_CODE: "UGSRT",
      CITY_NAME: "SOROTI",
      DPA_PORT_CODE: "TDE",
    },
    {
      CITY_CODE: "UMAWK",
      CITY_NAME: "WAKE ISLAND",
      DPA_PORT_CODE: "AWK",
    },
    {
      CITY_CODE: "UMJON",
      CITY_NAME: "JOHNSTON ATOLL",
      DPA_PORT_CODE: "JON",
    },
    {
      CITY_CODE: "USAAF",
      CITY_NAME: "APALACHICOLA",
      DPA_PORT_CODE: "AAF",
    },
    {
      CITY_CODE: "USABI",
      CITY_NAME: "ABILENE",
      DPA_PORT_CODE: "ABI",
    },
    {
      CITY_CODE: "USADN",
      CITY_NAME: "HOQUIAM APT",
      DPA_PORT_CODE: "ADN",
    },
    {
      CITY_CODE: "USAKH",
      CITY_NAME: "AKRON",
      DPA_PORT_CODE: "RRN",
    },
    {
      CITY_CODE: "USAKL",
      CITY_NAME: "PATASKALA",
      DPA_PORT_CODE: "AKK",
    },
    {
      CITY_CODE: "USALB",
      CITY_NAME: "ALBURG APT",
      DPA_PORT_CODE: "ABG",
    },
    {
      CITY_CODE: "USAME",
      CITY_NAME: "ARDMORE",
      DPA_PORT_CODE: "RRE",
    },
    {
      CITY_CODE: "USANA",
      CITY_NAME: "ANAHEIM",
      DPA_PORT_CODE: "ANH",
    },
    {
      CITY_CODE: "USANC",
      CITY_NAME: "ANCHORAGE",
      DPA_PORT_CODE: "ANC",
    },
    {
      CITY_CODE: "USAND",
      CITY_NAME: "ANDRADE",
      DPA_PORT_CODE: "ANE",
    },
    {
      CITY_CODE: "USANE",
      CITY_NAME: "ANDERSON",
      DPA_PORT_CODE: "EAV",
    },
    {
      CITY_CODE: "USANP",
      CITY_NAME: "ANNAPOLIS",
      DPA_PORT_CODE: "ANP",
    },
    {
      CITY_CODE: "USAOB",
      CITY_NAME: "ALBION",
      DPA_PORT_CODE: "AEZ",
    },
    {
      CITY_CODE: "USAPK",
      CITY_NAME: "ALLEN PARK",
      DPA_PORT_CODE: "APK",
    },
    {
      CITY_CODE: "USARJ",
      CITY_NAME: "ARLINGTON",
      DPA_PORT_CODE: "ARJ",
    },
    {
      CITY_CODE: "USASV",
      CITY_NAME: "ASHTON",
      DPA_PORT_CODE: "ASV",
    },
    {
      CITY_CODE: "USASX",
      CITY_NAME: "ASHLAND",
      DPA_PORT_CODE: "ASX",
    },
    {
      CITY_CODE: "USATR",
      CITY_NAME: "ATRECO",
      DPA_PORT_CODE: "ATR",
    },
    {
      CITY_CODE: "USATT",
      CITY_NAME: "ANACORTES",
      DPA_PORT_CODE: "ATT",
    },
    {
      CITY_CODE: "USATW",
      CITY_NAME: "APPLETON",
      DPA_PORT_CODE: "ATW",
    },
    {
      CITY_CODE: "USAVD",
      CITY_NAME: "AVONDALE",
      DPA_PORT_CODE: "AVD",
    },
    {
      CITY_CODE: "USAWR",
      CITY_NAME: "ACWORTH",
      DPA_PORT_CODE: "WRH",
    },
    {
      CITY_CODE: "USAXB",
      CITY_NAME: "ALEXANDRIA BAY",
      DPA_PORT_CODE: "AXB",
    },
    {
      CITY_CODE: "USAXR",
      CITY_NAME: "ALEXANDRIA",
      DPA_PORT_CODE: "AXR",
    },
    {
      CITY_CODE: "USBAL",
      CITY_NAME: "BALTIMORE",
      DPA_PORT_CODE: "BAL",
    },
    {
      CITY_CODE: "USBBB",
      CITY_NAME: "BALDWIN",
      DPA_PORT_CODE: "BBJ",
    },
    {
      CITY_CODE: "USBBP",
      CITY_NAME: "BARTLETT",
      DPA_PORT_CODE: "BBP",
    },
    {
      CITY_CODE: "USBCC",
      CITY_NAME: "VANCOUVER",
      DPA_PORT_CODE: "BCC",
    },
    {
      CITY_CODE: "USBCG",
      CITY_NAME: "BOCAGRANDE",
      DPA_PORT_CODE: "BCG",
    },
    {
      CITY_CODE: "USBCS",
      CITY_NAME: "BELLE CHASSE",
      DPA_PORT_CODE: "BCS",
    },
    {
      CITY_CODE: "USBCV",
      CITY_NAME: "BREVARD",
      DPA_PORT_CODE: "BCV",
    },
    {
      CITY_CODE: "USBDE",
      CITY_NAME: "BAUDETTE",
      DPA_PORT_CODE: "BDE",
    },
    {
      CITY_CODE: "USBDR",
      CITY_NAME: "BRIDGEPORT",
      DPA_PORT_CODE: "BDR",
    },
    {
      CITY_CODE: "USBEX",
      CITY_NAME: "BETHLEHEM",
      DPA_PORT_CODE: "BEX",
    },
    {
      CITY_CODE: "USBFM",
      CITY_NAME: "BEAUFORT MOREHEAD CITY",
      DPA_PORT_CODE: "BFM",
    },
    {
      CITY_CODE: "USBFS",
      CITY_NAME: "BRISTOL",
      DPA_PORT_CODE: "BST",
    },
    {
      CITY_CODE: "USBGO",
      CITY_NAME: "BUFFALO GROVE",
      DPA_PORT_CODE: "UFL",
    },
    {
      CITY_CODE: "USBGR",
      CITY_NAME: "BANGOR",
      DPA_PORT_CODE: "BNR",
    },
    {
      CITY_CODE: "USBHB",
      CITY_NAME: "BAR HARBOR",
      DPA_PORT_CODE: "BHB",
    },
    {
      CITY_CODE: "USBIL",
      CITY_NAME: "BILLINGS",
      DPA_PORT_CODE: "BPL",
    },
    {
      CITY_CODE: "USBLH",
      CITY_NAME: "BLAIR",
      DPA_PORT_CODE: "BLH",
    },
    {
      CITY_CODE: "USBND",
      CITY_NAME: "BURLESON",
      DPA_PORT_CODE: "BQQ",
    },
    {
      CITY_CODE: "USBNK",
      CITY_NAME: "BOLINGROOK  USA",
      DPA_PORT_CODE: "BNK",
    },
    {
      CITY_CODE: "USBOJ",
      CITY_NAME: "BORDENTOWN",
      DPA_PORT_CODE: "BDT",
    },
    {
      CITY_CODE: "USBOS",
      CITY_NAME: "BOSTON",
      DPA_PORT_CODE: "BOS",
    },
    {
      CITY_CODE: "USBOY",
      CITY_NAME: "BROOKLYN",
      DPA_PORT_CODE: "BOY",
    },
    {
      CITY_CODE: "USBPT",
      CITY_NAME: "BEAUMONT",
      DPA_PORT_CODE: "BPT",
    },
    {
      CITY_CODE: "USBRO",
      CITY_NAME: "BROWNSVILLE",
      DPA_PORT_CODE: "BRO",
    },
    {
      CITY_CODE: "USBRW",
      CITY_NAME: "BRIDGEWATER",
      DPA_PORT_CODE: "BRW",
    },
    {
      CITY_CODE: "USBTA",
      CITY_NAME: "BATAVIA",
      DPA_PORT_CODE: "BTA",
    },
    {
      CITY_CODE: "USBTG",
      CITY_NAME: "BETHEL",
      DPA_PORT_CODE: "BJE",
    },
    {
      CITY_CODE: "USBTV",
      CITY_NAME: "BURLINGTON",
      DPA_PORT_CODE: "BTV",
    },
    {
      CITY_CODE: "USBUF",
      CITY_NAME: "BUFFALO",
      DPA_PORT_CODE: "BFU",
    },
    {
      CITY_CODE: "USBUP",
      CITY_NAME: "BUCKSPORT",
      DPA_PORT_CODE: "BUP",
    },
    {
      CITY_CODE: "USBVE",
      CITY_NAME: "BELLEVUE",
      DPA_PORT_CODE: "BVU",
    },
    {
      CITY_CODE: "USBZZ",
      CITY_NAME: "BLACKLICK",
      DPA_PORT_CODE: "BZZ",
    },
    {
      CITY_CODE: "USCBB",
      CITY_NAME: "CHAMBERSBURG",
      DPA_PORT_CODE: "CBB",
    },
    {
      CITY_CODE: "USCCA",
      CITY_NAME: "CALVERT CITY",
      DPA_PORT_CODE: "CCA",
    },
    {
      CITY_CODE: "USCCC",
      CITY_NAME: "CAPE CHARLES CITY",
      DPA_PORT_CODE: "CCC",
    },
    {
      CITY_CODE: "USCDE",
      CITY_NAME: "CAMDEN",
      DPA_PORT_CODE: "CDE",
    },
    {
      CITY_CODE: "USCDV",
      CITY_NAME: "CORDOVA",
      DPA_PORT_CODE: "CDV",
    },
    {
      CITY_CODE: "USCEC",
      CITY_NAME: "CRESCENT CITY",
      DPA_PORT_CODE: "CEC",
    },
    {
      CITY_CODE: "USCEX",
      CITY_NAME: "CLEBURNE",
      DPA_PORT_CODE: "CEX",
    },
    {
      CITY_CODE: "USCFZ",
      CITY_NAME: "FORT CARSON",
      DPA_PORT_CODE: "FCS",
    },
    {
      CITY_CODE: "USCGH",
      CITY_NAME: "CHICAGO HEIGHTS",
      DPA_PORT_CODE: "CGH",
    },
    {
      CITY_CODE: "USCHI",
      CITY_NAME: "CHICAGO",
      DPA_PORT_CODE: "CHI",
    },
    {
      CITY_CODE: "USCHS",
      CITY_NAME: "CHARLESTON",
      DPA_PORT_CODE: "CHS",
    },
    {
      CITY_CODE: "USCHT",
      CITY_NAME: "CHESTER",
      DPA_PORT_CODE: "CHT",
    },
    {
      CITY_CODE: "USCID",
      CITY_NAME: "CEDAR RAPIDS",
      DPA_PORT_CODE: "CID",
    },
    {
      CITY_CODE: "USCIS",
      CITY_NAME: "CISCO",
      DPA_PORT_CODE: "CIS",
    },
    {
      CITY_CODE: "USCLM",
      CITY_NAME: "PORT ANGELES",
      DPA_PORT_CODE: "CLM",
    },
    {
      CITY_CODE: "USCLQ",
      CITY_NAME: "CHALMETTE",
      DPA_PORT_CODE: "CLQ",
    },
    {
      CITY_CODE: "USCMG",
      CITY_NAME: "COMMERCE",
      DPA_PORT_CODE: "CMG",
    },
    {
      CITY_CODE: "USCMM",
      CITY_NAME: "COLUMBUS",
      DPA_PORT_CODE: "CMM",
    },
    {
      CITY_CODE: "USCOV",
      CITY_NAME: "COLUMBIA",
      DPA_PORT_CODE: "COV",
    },
    {
      CITY_CODE: "USCQM",
      CITY_NAME: "CLOQUET",
      DPA_PORT_CODE: "CQM",
    },
    {
      CITY_CODE: "USCRF",
      CITY_NAME: "CROYDON",
      DPA_PORT_CODE: "CYE",
    },
    {
      CITY_CODE: "USCRM",
      CITY_NAME: "CROCKETT",
      DPA_PORT_CODE: "CRM",
    },
    {
      CITY_CODE: "USCRP",
      CITY_NAME: "CORPUS CHRISTI",
      DPA_PORT_CODE: "CRP",
    },
    {
      CITY_CODE: "USCRS",
      CITY_NAME: "CORSICANA",
      DPA_PORT_CODE: "CDU",
    },
    {
      CITY_CODE: "USCRU",
      CITY_NAME: "CARSON",
      DPA_PORT_CODE: "CRU",
    },
    {
      CITY_CODE: "USCSF",
      CITY_NAME: "CARTERET",
      DPA_PORT_CODE: "CSF",
    },
    {
      CITY_CODE: "USCSR",
      CITY_NAME: "CHESTER",
      DPA_PORT_CODE: "CSR",
    },
    {
      CITY_CODE: "USCTY",
      CITY_NAME: "CHATEAUGAY",
      DPA_PORT_CODE: "CTY",
    },
    {
      CITY_CODE: "USCUN",
      CITY_NAME: "CONOVER",
      DPA_PORT_CODE: "CVR",
    },
    {
      CITY_CODE: "USCUS",
      CITY_NAME: "CUT SHOOT",
      DPA_PORT_CODE: "CUS",
    },
    {
      CITY_CODE: "USCUV",
      CITY_NAME: "CHULA VISTA",
      DPA_PORT_CODE: "CUV",
    },
    {
      CITY_CODE: "USCVF",
      CITY_NAME: "COVINGTON",
      DPA_PORT_CODE: "CVU",
    },
    {
      CITY_CODE: "USCVG",
      CITY_NAME: "CINCINNATI",
      DPA_PORT_CODE: "CVG",
    },
    {
      CITY_CODE: "USCXL",
      CITY_NAME: "CALEXICO",
      DPA_PORT_CODE: "CXL",
    },
    {
      CITY_CODE: "USCYL",
      CITY_NAME: "CHANTILLY",
      DPA_PORT_CODE: "CYL",
    },
    {
      CITY_CODE: "USDAL",
      CITY_NAME: "DALLAS",
      DPA_PORT_CODE: "DAL",
    },
    {
      CITY_CODE: "USDBY",
      CITY_NAME: "DERBY LINE",
      DPA_PORT_CODE: "DBY",
    },
    {
      CITY_CODE: "USDDR",
      CITY_NAME: "DARIEN",
      DPA_PORT_CODE: "DDR",
    },
    {
      CITY_CODE: "USDEC",
      CITY_NAME: "DECATUR",
      DPA_PORT_CODE: "DEC",
    },
    {
      CITY_CODE: "USDET",
      CITY_NAME: "DETROIT",
      DPA_PORT_CODE: "DET",
    },
    {
      CITY_CODE: "USDHX",
      CITY_NAME: "DELHI",
      DPA_PORT_CODE: "DHX",
    },
    {
      CITY_CODE: "USDLH",
      CITY_NAME: "DULUTH",
      DPA_PORT_CODE: "DLH",
    },
    {
      CITY_CODE: "USDOR",
      CITY_NAME: "DOVER",
      DPA_PORT_CODE: "DRR",
    },
    {
      CITY_CODE: "USDOV",
      CITY_NAME: "DOUGLAS",
      DPA_PORT_CODE: "DOV",
    },
    {
      CITY_CODE: "USDSM",
      CITY_NAME: "DES MOINES",
      DPA_PORT_CODE: "DSM",
    },
    {
      CITY_CODE: "USDTN",
      CITY_NAME: "DENTON",
      DPA_PORT_CODE: "DTN",
    },
    {
      CITY_CODE: "USDTO",
      CITY_NAME: "DETOUR",
      DPA_PORT_CODE: "DTO",
    },
    {
      CITY_CODE: "USDUR",
      CITY_NAME: "DURHAM",
      DPA_PORT_CODE: "DUH",
    },
    {
      CITY_CODE: "USDUT",
      CITY_NAME: "DUTCH HARBOR",
      DPA_PORT_CODE: "DUT",
    },
    {
      CITY_CODE: "USDVK",
      CITY_NAME: "DANVILLE",
      DPA_PORT_CODE: "DVU",
    },
    {
      CITY_CODE: "USDVN",
      CITY_NAME: "DAVENPORT",
      DPA_PORT_CODE: "DVN",
    },
    {
      CITY_CODE: "USDVT",
      CITY_NAME: "DAVANT",
      DPA_PORT_CODE: "DVT",
    },
    {
      CITY_CODE: "USDVV",
      CITY_NAME: "DAVISVILLE",
      DPA_PORT_CODE: "DVV",
    },
    {
      CITY_CODE: "USECH",
      CITY_NAME: "EAST CHICAGO",
      DPA_PORT_CODE: "ECH",
    },
    {
      CITY_CODE: "USEGP",
      CITY_NAME: "EAGLE PASS",
      DPA_PORT_CODE: "EGP",
    },
    {
      CITY_CODE: "USEKA",
      CITY_NAME: "EUREKA",
      DPA_PORT_CODE: "EKA",
    },
    {
      CITY_CODE: "USEKI",
      CITY_NAME: "ELHART",
      DPA_PORT_CODE: "ELX",
    },
    {
      CITY_CODE: "USELM",
      CITY_NAME: "ELMIRA",
      DPA_PORT_CODE: "EBM",
    },
    {
      CITY_CODE: "USENE",
      CITY_NAME: "ENNIS",
      DPA_PORT_CODE: "EAY",
    },
    {
      CITY_CODE: "USENW",
      CITY_NAME: "KENOSHA",
      DPA_PORT_CODE: "ENW",
    },
    {
      CITY_CODE: "USEPM",
      CITY_NAME: "EASTPORT",
      DPA_PORT_CODE: "EPM",
    },
    {
      CITY_CODE: "USERW",
      CITY_NAME: "ERWIN",
      DPA_PORT_CODE: "ERW",
    },
    {
      CITY_CODE: "USESE",
      CITY_NAME: "ESSEX",
      DPA_PORT_CODE: "ESU",
    },
    {
      CITY_CODE: "USESN",
      CITY_NAME: "EASTON",
      DPA_PORT_CODE: "EAA",
    },
    {
      CITY_CODE: "USETL",
      CITY_NAME: "EAST LONGMEADOW",
      DPA_PORT_CODE: "ETL",
    },
    {
      CITY_CODE: "USETN",
      CITY_NAME: "EASTLAND",
      DPA_PORT_CODE: "ETN",
    },
    {
      CITY_CODE: "USEVE",
      CITY_NAME: "EVERETT",
      DPA_PORT_CODE: "EVE",
    },
    {
      CITY_CODE: "USEVT",
      CITY_NAME: "EVERETT",
      DPA_PORT_CODE: "EVT",
    },
    {
      CITY_CODE: "USEWB",
      CITY_NAME: "NEW BEDFORD APT",
      DPA_PORT_CODE: "EWB",
    },
    {
      CITY_CODE: "USEWR",
      CITY_NAME: "NEWARK",
      DPA_PORT_CODE: "EWR",
    },
    {
      CITY_CODE: "USEYW",
      CITY_NAME: "KEY WEST",
      DPA_PORT_CODE: "EYW",
    },
    {
      CITY_CODE: "USEZA",
      CITY_NAME: "ELIZABETH",
      DPA_PORT_CODE: "EZA",
    },
    {
      CITY_CODE: "USFAB",
      CITY_NAME: "FABENS",
      DPA_PORT_CODE: "FAB",
    },
    {
      CITY_CODE: "USFAI",
      CITY_NAME: "FAIRBANKS",
      DPA_PORT_CODE: "FAI",
    },
    {
      CITY_CODE: "USFBG",
      CITY_NAME: "FORT BRAGG",
      DPA_PORT_CODE: "FBG",
    },
    {
      CITY_CODE: "USFCP",
      CITY_NAME: "FRENCH CAMP",
      DPA_PORT_CODE: "FCP",
    },
    {
      CITY_CODE: "USFEB",
      CITY_NAME: "FERNANDINA BEACH",
      DPA_PORT_CODE: "FEB",
    },
    {
      CITY_CODE: "USFED",
      CITY_NAME: "FERNDALE",
      DPA_PORT_CODE: "FED",
    },
    {
      CITY_CODE: "USFFA",
      CITY_NAME: "FORT FAIRFIELD",
      DPA_PORT_CODE: "FFA",
    },
    {
      CITY_CODE: "USFHO",
      CITY_NAME: "FORT HOOD",
      DPA_PORT_CODE: "FHO",
    },
    {
      CITY_CODE: "USFIK",
      CITY_NAME: "FISHKILL",
      DPA_PORT_CODE: "FIK",
    },
    {
      CITY_CODE: "USFKT",
      CITY_NAME: "FORT KENT",
      DPA_PORT_CODE: "FKT",
    },
    {
      CITY_CODE: "USFLR",
      CITY_NAME: "FLORENCE",
      DPA_PORT_CODE: "FLJ",
    },
    {
      CITY_CODE: "USFML",
      CITY_NAME: "FORT MILL",
      DPA_PORT_CODE: "FML",
    },
    {
      CITY_CODE: "USFOB",
      CITY_NAME: "FORT BRAGG",
      DPA_PORT_CODE: "FOB",
    },
    {
      CITY_CODE: "USFOL",
      CITY_NAME: "FORT LEE",
      DPA_PORT_CODE: "FOL",
    },
    {
      CITY_CODE: "USFPO",
      CITY_NAME: "FREEPORT",
      DPA_PORT_CODE: "EEP",
    },
    {
      CITY_CODE: "USFPR",
      CITY_NAME: "FORT PIERCE",
      DPA_PORT_CODE: "FPR",
    },
    {
      CITY_CODE: "USFRP",
      CITY_NAME: "FREEPORT",
      DPA_PORT_CODE: "FRP",
    },
    {
      CITY_CODE: "USFRY",
      CITY_NAME: "FERRY",
      DPA_PORT_CODE: "FRY",
    },
    {
      CITY_CODE: "USFWA",
      CITY_NAME: "FORT WAYNE",
      DPA_PORT_CODE: "FWA",
    },
    {
      CITY_CODE: "USFWO",
      CITY_NAME: "FORT WORTH",
      DPA_PORT_CODE: "FWO",
    },
    {
      CITY_CODE: "USFWT",
      CITY_NAME: "FORT WORTH",
      DPA_PORT_CODE: "FWT",
    },
    {
      CITY_CODE: "USFYE",
      CITY_NAME: "FAYETTEVILLE",
      DPA_PORT_CODE: "FYL",
    },
    {
      CITY_CODE: "USGAN",
      CITY_NAME: "GALLATIN",
      DPA_PORT_CODE: "GAN",
    },
    {
      CITY_CODE: "USGAQ",
      CITY_NAME: "GALT",
      DPA_PORT_CODE: "GTB",
    },
    {
      CITY_CODE: "USGBO",
      CITY_NAME: "GREENSBORO",
      DPA_PORT_CODE: "EES",
    },
    {
      CITY_CODE: "USGDH",
      CITY_NAME: "GOOD HOPE",
      DPA_PORT_CODE: "GDH",
    },
    {
      CITY_CODE: "USGDR",
      CITY_NAME: "GARDINER",
      DPA_PORT_CODE: "GDR",
    },
    {
      CITY_CODE: "USGED",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GED",
    },
    {
      CITY_CODE: "USGGE",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GGE",
    },
    {
      CITY_CODE: "USGGR",
      CITY_NAME: "GARDEN GROVE",
      DPA_PORT_CODE: "GGR",
    },
    {
      CITY_CODE: "USGIF",
      CITY_NAME: "WINTER HAVEN",
      DPA_PORT_CODE: "GCO",
    },
    {
      CITY_CODE: "USGLC",
      CITY_NAME: "GLOUCESTER CITY",
      DPA_PORT_CODE: "GLC",
    },
    {
      CITY_CODE: "USGLH",
      CITY_NAME: "GREENVILLE",
      DPA_PORT_CODE: "GLH",
    },
    {
      CITY_CODE: "USGLO",
      CITY_NAME: "GLOUCESTER",
      DPA_PORT_CODE: "GLO",
    },
    {
      CITY_CODE: "USGLS",
      CITY_NAME: "GALVESTON",
      DPA_PORT_CODE: "GLS",
    },
    {
      CITY_CODE: "USGPT",
      CITY_NAME: "GULFPORT",
      DPA_PORT_CODE: "GPT",
    },
    {
      CITY_CODE: "USGQQ",
      CITY_NAME: "GALION",
      DPA_PORT_CODE: "GQQ",
    },
    {
      CITY_CODE: "USGRR",
      CITY_NAME: "GRAND RAPIDS",
      DPA_PORT_CODE: "GRR",
    },
    {
      CITY_CODE: "USGRV",
      CITY_NAME: "GRAYS RIVER",
      DPA_PORT_CODE: "GRV",
    },
    {
      CITY_CODE: "USGRW",
      CITY_NAME: "GREENWICH",
      DPA_PORT_CODE: "GRW",
    },
    {
      CITY_CODE: "USGSP",
      CITY_NAME: "GREENVILLE-GREER APT",
      DPA_PORT_CODE: "GSP",
    },
    {
      CITY_CODE: "USGTW",
      CITY_NAME: "GEORGETOWN",
      DPA_PORT_CODE: "GTG",
    },
    {
      CITY_CODE: "USGXN",
      CITY_NAME: "GENEVA",
      DPA_PORT_CODE: "GXN",
    },
    {
      CITY_CODE: "USGYR",
      CITY_NAME: "GOODYEAR",
      DPA_PORT_CODE: "GYR",
    },
    {
      CITY_CODE: "USGYY",
      CITY_NAME: "GARY",
      DPA_PORT_CODE: "GYY",
    },
    {
      CITY_CODE: "USHBY",
      CITY_NAME: "HUMBOLDT BAY",
      DPA_PORT_CODE: "HBY",
    },
    {
      CITY_CODE: "USHDD",
      CITY_NAME: "HIDDENITE",
      DPA_PORT_CODE: "HDH",
    },
    {
      CITY_CODE: "USHID",
      CITY_NAME: "HIDALGO",
      DPA_PORT_CODE: "HID",
    },
    {
      CITY_CODE: "USHIS",
      CITY_NAME: "HIGHGATE SPRINGS",
      DPA_PORT_CODE: "HIS",
    },
    {
      CITY_CODE: "USHLF",
      CITY_NAME: "HIALEAH",
      DPA_PORT_CODE: "HCG",
    },
    {
      CITY_CODE: "USHMY",
      CITY_NAME: "HARMONY",
      DPA_PORT_CODE: "HNY",
    },
    {
      CITY_CODE: "USHNL",
      CITY_NAME: "HONOLULU",
      DPA_PORT_CODE: "HNL",
    },
    {
      CITY_CODE: "USHNS",
      CITY_NAME: "HAINES",
      DPA_PORT_CODE: "HNS",
    },
    {
      CITY_CODE: "USHOB",
      CITY_NAME: "HOBOKEN",
      DPA_PORT_CODE: "HOB",
    },
    {
      CITY_CODE: "USHOI",
      CITY_NAME: "HOMER",
      DPA_PORT_CODE: "HOI",
    },
    {
      CITY_CODE: "USHOU",
      CITY_NAME: "HOUSTON",
      DPA_PORT_CODE: "HOU",
    },
    {
      CITY_CODE: "USHPW",
      CITY_NAME: "HOPEWELL",
      DPA_PORT_CODE: "HPW",
    },
    {
      CITY_CODE: "USHPY",
      CITY_NAME: "BAYTOWN",
      DPA_PORT_CODE: "HPY",
    },
    {
      CITY_CODE: "USHPZ",
      CITY_NAME: "HIGH POINT",
      DPA_PORT_CODE: "HPZ",
    },
    {
      CITY_CODE: "USHRY",
      CITY_NAME: "HARVEY",
      DPA_PORT_CODE: "HRZ",
    },
    {
      CITY_CODE: "USHSM",
      CITY_NAME: "HOUSTON",
      DPA_PORT_CODE: "HST",
    },
    {
      CITY_CODE: "USHUB",
      CITY_NAME: "HURON",
      DPA_PORT_CODE: "HUB",
    },
    {
      CITY_CODE: "USHVN",
      CITY_NAME: "NEW HAVEN",
      DPA_PORT_CODE: "NHE",
    },
    {
      CITY_CODE: "USICC",
      CITY_NAME: "CLINTON",
      DPA_PORT_CODE: "ICC",
    },
    {
      CITY_CODE: "USICT",
      CITY_NAME: "WICHITA",
      DPA_PORT_CODE: "ICT",
    },
    {
      CITY_CODE: "USIFE",
      CITY_NAME: "MIDFIELD",
      DPA_PORT_CODE: "IFE",
    },
    {
      CITY_CODE: "USIFR",
      CITY_NAME: "INTERNATIONAL FALLS",
      DPA_PORT_CODE: "IFR",
    },
    {
      CITY_CODE: "USILG",
      CITY_NAME: "WILMINGTON",
      DPA_PORT_CODE: "ILG",
    },
    {
      CITY_CODE: "USILM",
      CITY_NAME: "WILMINGTON",
      DPA_PORT_CODE: "ILM",
    },
    {
      CITY_CODE: "USINP",
      CITY_NAME: "INDEPENDENCE",
      DPA_PORT_CODE: "INN",
    },
    {
      CITY_CODE: "USITO",
      CITY_NAME: "HILO",
      DPA_PORT_CODE: "ITO",
    },
    {
      CITY_CODE: "USJAX",
      CITY_NAME: "JACKSONVILLE",
      DPA_PORT_CODE: "JAX",
    },
    {
      CITY_CODE: "USJBK",
      CITY_NAME: "BERKELEY",
      DPA_PORT_CODE: "JBK",
    },
    {
      CITY_CODE: "USJEC",
      CITY_NAME: "JERSEY CITY",
      DPA_PORT_CODE: "JEC",
    },
    {
      CITY_CODE: "USJGC",
      CITY_NAME: "GROVE CITY",
      DPA_PORT_CODE: "JGC",
    },
    {
      CITY_CODE: "USJHI",
      CITY_NAME: "JENKINS TOWNSHIP",
      DPA_PORT_CODE: "JHI",
    },
    {
      CITY_CODE: "USJMC",
      CITY_NAME: "SAUSALITO",
      DPA_PORT_CODE: "JMC",
    },
    {
      CITY_CODE: "USJNP",
      CITY_NAME: "NEWPORT BEACH",
      DPA_PORT_CODE: "JNP",
    },
    {
      CITY_CODE: "USJNU",
      CITY_NAME: "JUNEAU",
      DPA_PORT_CODE: "JNU",
    },
    {
      CITY_CODE: "USJOB",
      CITY_NAME: "JOBOS",
      DPA_PORT_CODE: "JOB",
    },
    {
      CITY_CODE: "USKAN",
      CITY_NAME: "KANE",
      DPA_PORT_CODE: "KNE",
    },
    {
      CITY_CODE: "USKCK",
      CITY_NAME: "KANSAS CITY",
      DPA_PORT_CODE: "KCK",
    },
    {
      CITY_CODE: "USKIN",
      CITY_NAME: "KINGS MOUNTAIN",
      DPA_PORT_CODE: "KNL",
    },
    {
      CITY_CODE: "USKLS",
      CITY_NAME: "KELSO",
      DPA_PORT_CODE: "KLS",
    },
    {
      CITY_CODE: "USKLZ",
      CITY_NAME: "KALAMAZOO",
      DPA_PORT_CODE: "KLZ",
    },
    {
      CITY_CODE: "USKWM",
      CITY_NAME: "KING WILLIAM",
      DPA_PORT_CODE: "KWM",
    },
    {
      CITY_CODE: "USKWO",
      CITY_NAME: "KENILWORTH",
      DPA_PORT_CODE: "KWO",
    },
    {
      CITY_CODE: "USLAX",
      CITY_NAME: "LOS ANGELES",
      DPA_PORT_CODE: "LAX",
    },
    {
      CITY_CODE: "USLCH",
      CITY_NAME: "LAKE CHARLES",
      DPA_PORT_CODE: "LKC",
    },
    {
      CITY_CODE: "USLFC",
      CITY_NAME: "LAKE FOREST",
      DPA_PORT_CODE: "LFC",
    },
    {
      CITY_CODE: "USLGB",
      CITY_NAME: "LONG BEACH",
      DPA_PORT_CODE: "LGB",
    },
    {
      CITY_CODE: "USLGE",
      CITY_NAME: "LOGANVILLE",
      DPA_PORT_CODE: "LGS",
    },
    {
      CITY_CODE: "USLGV",
      CITY_NAME: "LONGVIEW",
      DPA_PORT_CODE: "LGV",
    },
    {
      CITY_CODE: "USLGZ",
      CITY_NAME: "LOGAN AIRPORT",
      DPA_PORT_CODE: "LGZ",
    },
    {
      CITY_CODE: "USLIE",
      CITY_NAME: "LINDSIDE",
      DPA_PORT_CODE: "LIE",
    },
    {
      CITY_CODE: "USLIZ",
      CITY_NAME: "LIMESTONE",
      DPA_PORT_CODE: "LIZ",
    },
    {
      CITY_CODE: "USLLH",
      CITY_NAME: "DULLES",
      DPA_PORT_CODE: "LLH",
    },
    {
      CITY_CODE: "USLOG",
      CITY_NAME: "LONGVIEW",
      DPA_PORT_CODE: "IEW",
    },
    {
      CITY_CODE: "USLRW",
      CITY_NAME: "LAWRENCEVILLE",
      DPA_PORT_CODE: "LRW",
    },
    {
      CITY_CODE: "USLSF",
      CITY_NAME: "FORT BENNING",
      DPA_PORT_CODE: "LSF",
    },
    {
      CITY_CODE: "USLSL",
      CITY_NAME: "LEETSDALE",
      DPA_PORT_CODE: "LPS",
    },
    {
      CITY_CODE: "USLTE",
      CITY_NAME: "LANETT",
      DPA_PORT_CODE: "LTV",
    },
    {
      CITY_CODE: "USLUI",
      CITY_NAME: "LOUISVILLE",
      DPA_PORT_CODE: "LUI",
    },
    {
      CITY_CODE: "USLUS",
      CITY_NAME: "LAURENS",
      DPA_PORT_CODE: "LUS",
    },
    {
      CITY_CODE: "USLVK",
      CITY_NAME: "LIVERMORE",
      DPA_PORT_CODE: "LVM",
    },
    {
      CITY_CODE: "USLVU",
      CITY_NAME: "LAWRENCEBURG",
      DPA_PORT_CODE: "LVU",
    },
    {
      CITY_CODE: "USLWE",
      CITY_NAME: "LAWRENCE",
      DPA_PORT_CODE: "LWE",
    },
    {
      CITY_CODE: "USMAN",
      CITY_NAME: "MARTIN",
      DPA_PORT_CODE: "MTN",
    },
    {
      CITY_CODE: "USMBA",
      CITY_NAME: "MILLBRAE",
      DPA_PORT_CODE: "MLV",
    },
    {
      CITY_CODE: "USMBS",
      CITY_NAME: "BAY CITY",
      DPA_PORT_CODE: "MBS",
    },
    {
      CITY_CODE: "USMCN",
      CITY_NAME: "MACON",
      DPA_PORT_CODE: "MCG",
    },
    {
      CITY_CODE: "USMD3",
      CITY_NAME: "MCDADE",
      DPA_PORT_CODE: "MD3",
    },
    {
      CITY_CODE: "USMDQ",
      CITY_NAME: "MEDINA",
      DPA_PORT_CODE: "MCM",
    },
    {
      CITY_CODE: "USMED",
      CITY_NAME: "MIDLOTHIAN",
      DPA_PORT_CODE: "MDO",
    },
    {
      CITY_CODE: "USMEG",
      CITY_NAME: "MECHANICSBURG",
      DPA_PORT_CODE: "MEG",
    },
    {
      CITY_CODE: "USMGZ",
      CITY_NAME: "MANGUEZ",
      DPA_PORT_CODE: "MGZ",
    },
    {
      CITY_CODE: "USMHQ",
      CITY_NAME: "MURRIETA",
      DPA_PORT_CODE: "MHS",
    },
    {
      CITY_CODE: "USMIA",
      CITY_NAME: "MIAMI",
      DPA_PORT_CODE: "MIA",
    },
    {
      CITY_CODE: "USMKC",
      CITY_NAME: "KANSAS CITY",
      DPA_PORT_CODE: "MKC",
    },
    {
      CITY_CODE: "USMKE",
      CITY_NAME: "MILWAUKEE",
      DPA_PORT_CODE: "MKE",
    },
    {
      CITY_CODE: "USMKG",
      CITY_NAME: "MUSKEGON",
      DPA_PORT_CODE: "MKG",
    },
    {
      CITY_CODE: "USMNP",
      CITY_NAME: "MONACA",
      DPA_PORT_CODE: "MNP",
    },
    {
      CITY_CODE: "USMOB",
      CITY_NAME: "MOBILE",
      DPA_PORT_CODE: "MOB",
    },
    {
      CITY_CODE: "USMOR",
      CITY_NAME: "MORRISTOWN",
      DPA_PORT_CODE: "MWX",
    },
    {
      CITY_CODE: "USMOY",
      CITY_NAME: "MARINETTE",
      DPA_PORT_CODE: "MOY",
    },
    {
      CITY_CODE: "USMPI",
      CITY_NAME: "MOUNTPLIER",
      DPA_PORT_CODE: "MPI",
    },
    {
      CITY_CODE: "USMRH",
      CITY_NAME: "MOREHEAD CITY",
      DPA_PORT_CODE: "MRH",
    },
    {
      CITY_CODE: "USMRQ",
      CITY_NAME: "MILFORD",
      DPA_PORT_CODE: "MFO",
    },
    {
      CITY_CODE: "USMRR",
      CITY_NAME: "MORRO",
      DPA_PORT_CODE: "MRR",
    },
    {
      CITY_CODE: "USMRT",
      CITY_NAME: "MORRISTON",
      DPA_PORT_CODE: "MRT",
    },
    {
      CITY_CODE: "USMSY",
      CITY_NAME: "NEW ORLEANS INTERNATIONAL APT",
      DPA_PORT_CODE: "MSY",
    },
    {
      CITY_CODE: "USMTF",
      CITY_NAME: "METALINE FALLS",
      DPA_PORT_CODE: "MTF",
    },
    {
      CITY_CODE: "USMTO",
      CITY_NAME: "MATTOON",
      DPA_PORT_CODE: "MWT",
    },
    {
      CITY_CODE: "USMVI",
      CITY_NAME: "MELLVILLE",
      DPA_PORT_CODE: "MVI",
    },
    {
      CITY_CODE: "USMVN",
      CITY_NAME: "MOUNT VERNON",
      DPA_PORT_CODE: "MVN",
    },
    {
      CITY_CODE: "USMXG",
      CITY_NAME: "MARLBORO",
      DPA_PORT_CODE: "MXG",
    },
    {
      CITY_CODE: "USMZZ",
      CITY_NAME: "MARION",
      DPA_PORT_CODE: "MZZ",
    },
    {
      CITY_CODE: "USNAC",
      CITY_NAME: "NACO",
      DPA_PORT_CODE: "NAC",
    },
    {
      CITY_CODE: "USNBD",
      CITY_NAME: "NEW BEDFORD",
      DPA_PORT_CODE: "NBD",
    },
    {
      CITY_CODE: "USNBN",
      CITY_NAME: "NORTH BERGEN",
      DPA_PORT_CODE: "NBN",
    },
    {
      CITY_CODE: "USNBU",
      CITY_NAME: "NEWBURY",
      DPA_PORT_CODE: "NBU",
    },
    {
      CITY_CODE: "USNCM",
      CITY_NAME: "NORCO",
      DPA_PORT_CODE: "NCM",
    },
    {
      CITY_CODE: "USNCS",
      CITY_NAME: "CHARLESTON",
      DPA_PORT_CODE: "NCS",
    },
    {
      CITY_CODE: "USNEA",
      CITY_NAME: "GLYNCO",
      DPA_PORT_CODE: "NAY",
    },
    {
      CITY_CODE: "USNEB",
      CITY_NAME: "NEAH BAY",
      DPA_PORT_CODE: "NEB",
    },
    {
      CITY_CODE: "USNEN",
      CITY_NAME: "NORFOLKNEWPORT NEWS",
      DPA_PORT_CODE: "NEN",
    },
    {
      CITY_CODE: "USNEW",
      CITY_NAME: "DESTREHAN",
      DPA_PORT_CODE: "NEW",
    },
    {
      CITY_CODE: "USNFC",
      CITY_NAME: "NEVADA CITY",
      DPA_PORT_CODE: "NFC",
    },
    {
      CITY_CODE: "USNFK",
      CITY_NAME: "NORFOLK",
      DPA_PORT_CODE: "NFK",
    },
    {
      CITY_CODE: "USNGT",
      CITY_NAME: "NORTHGATE",
      DPA_PORT_CODE: "NGT",
    },
    {
      CITY_CODE: "USNGZ",
      CITY_NAME: "ALAMEDA",
      DPA_PORT_CODE: "NGZ",
    },
    {
      CITY_CODE: "USNNS",
      CITY_NAME: "NEWPORT NEWS",
      DPA_PORT_CODE: "NNS",
    },
    {
      CITY_CODE: "USNOO",
      CITY_NAME: "NOONAN",
      DPA_PORT_CODE: "NOO",
    },
    {
      CITY_CODE: "USNOR",
      CITY_NAME: "NORTH",
      DPA_PORT_CODE: "NOR",
    },
    {
      CITY_CODE: "USNOY",
      CITY_NAME: "NOYES",
      DPA_PORT_CODE: "NOY",
    },
    {
      CITY_CODE: "USNPO",
      CITY_NAME: "NEWPORT",
      DPA_PORT_CODE: "NPO",
    },
    {
      CITY_CODE: "USNPV",
      CITY_NAME: "NAPERVILLE",
      DPA_PORT_CODE: "NPV",
    },
    {
      CITY_CODE: "USNRY",
      CITY_NAME: "NORTH LIBERTY",
      DPA_PORT_CODE: "NRY",
    },
    {
      CITY_CODE: "USNTD",
      CITY_NAME: "PORT HUENEME",
      DPA_PORT_CODE: "NTD",
    },
    {
      CITY_CODE: "USNTS",
      CITY_NAME: "NORTH CHARLESTON",
      DPA_PORT_CODE: "NTS",
    },
    {
      CITY_CODE: "USNTY",
      CITY_NAME: "NORTH TROY",
      DPA_PORT_CODE: "NTY",
    },
    {
      CITY_CODE: "USNVS",
      CITY_NAME: "NORTH LAS VEGAS",
      DPA_PORT_CODE: "NVE",
    },
    {
      CITY_CODE: "USNW1",
      CITY_NAME: "NEWARK DELAWRE 1 MILITARY",
      DPA_PORT_CODE: "NW1",
    },
    {
      CITY_CODE: "USNW2",
      CITY_NAME: "NEWARK DELAWRE 2 MILITARY",
      DPA_PORT_CODE: "NW2",
    },
    {
      CITY_CODE: "USNWK",
      CITY_NAME: "NEWARK DELAWRE",
      DPA_PORT_CODE: "NWK",
    },
    {
      CITY_CODE: "USNWL",
      CITY_NAME: "NAWILIWILI",
      DPA_PORT_CODE: "NWL",
    },
    {
      CITY_CODE: "USNYC",
      CITY_NAME: "NEW YORK",
      DPA_PORT_CODE: "NYC",
    },
    {
      CITY_CODE: "USOAK",
      CITY_NAME: "OAKLAND",
      DPA_PORT_CODE: "OAK",
    },
    {
      CITY_CODE: "USOBO",
      CITY_NAME: "OBISPO",
      DPA_PORT_CODE: "OBO",
    },
    {
      CITY_CODE: "USOFK",
      CITY_NAME: "NORFOLK",
      DPA_PORT_CODE: "OFK",
    },
    {
      CITY_CODE: "USOGS",
      CITY_NAME: "OGDENSBURG",
      DPA_PORT_CODE: "OGS",
    },
    {
      CITY_CODE: "USOKC",
      CITY_NAME: "OKLAHOMA CITY",
      DPA_PORT_CODE: "OKC",
    },
    {
      CITY_CODE: "USOLP",
      CITY_NAME: "OLYMPIA",
      DPA_PORT_CODE: "OLP",
    },
    {
      CITY_CODE: "USOLQ",
      CITY_NAME: "TOLLESON",
      DPA_PORT_CODE: "OLQ",
    },
    {
      CITY_CODE: "USOLS",
      CITY_NAME: "NOGALES",
      DPA_PORT_CODE: "OLS",
    },
    {
      CITY_CODE: "USOME",
      CITY_NAME: "NOME",
      DPA_PORT_CODE: "OME",
    },
    {
      CITY_CODE: "USONR",
      CITY_NAME: "MONROE TOWNSHIP",
      DPA_PORT_CODE: "ONR",
    },
    {
      CITY_CODE: "USOPH",
      CITY_NAME: "OPHEIM",
      DPA_PORT_CODE: "OPH",
    },
    {
      CITY_CODE: "USOPK",
      CITY_NAME: "OPA LOCKA",
      DPA_PORT_CODE: "OPK",
    },
    {
      CITY_CODE: "USORF",
      CITY_NAME: "NORFOLK",
      DPA_PORT_CODE: "ORF",
    },
    {
      CITY_CODE: "USORH",
      CITY_NAME: "WORCESTER",
      DPA_PORT_CODE: "ORH",
    },
    {
      CITY_CODE: "USOSW",
      CITY_NAME: "OSWEGO",
      DPA_PORT_CODE: "OSW",
    },
    {
      CITY_CODE: "USOTH",
      CITY_NAME: "NORTH BEND",
      DPA_PORT_CODE: "OTH",
    },
    {
      CITY_CODE: "USOWB",
      CITY_NAME: "OWENSBORO",
      DPA_PORT_CODE: "OWB",
    },
    {
      CITY_CODE: "USPAS",
      CITY_NAME: "PASADENA",
      DPA_PORT_CODE: "PBC",
    },
    {
      CITY_CODE: "USPAT",
      CITY_NAME: "PATERSON",
      DPA_PORT_CODE: "ETE",
    },
    {
      CITY_CODE: "USPAU",
      CITY_NAME: "PAULSBORO",
      DPA_PORT_CODE: "PJU",
    },
    {
      CITY_CODE: "USPAY",
      CITY_NAME: "PERTH AMBOY",
      DPA_PORT_CODE: "PAY",
    },
    {
      CITY_CODE: "USPBG",
      CITY_NAME: "PITTSBURG",
      DPA_PORT_CODE: "PBG",
    },
    {
      CITY_CODE: "USPBI",
      CITY_NAME: "WEST PALM BEACH",
      DPA_PORT_CODE: "PBI",
    },
    {
      CITY_CODE: "USPDX",
      CITY_NAME: "PORTLAND",
      DPA_PORT_CODE: "PDX",
    },
    {
      CITY_CODE: "USPEA",
      CITY_NAME: "PEARL HARBOR",
      DPA_PORT_CODE: "PEA",
    },
    {
      CITY_CODE: "USPEF",
      CITY_NAME: "PORT EVERGLADES",
      DPA_PORT_CODE: "PEF",
    },
    {
      CITY_CODE: "USPFN",
      CITY_NAME: "PANAMA CITY",
      DPA_PORT_CODE: "PFN",
    },
    {
      CITY_CODE: "USPHI",
      CITY_NAME: "PHILLIPSBURG",
      DPA_PORT_CODE: "PHJ",
    },
    {
      CITY_CODE: "USPHK",
      CITY_NAME: "PAHOKEE",
      DPA_PORT_CODE: "PHK",
    },
    {
      CITY_CODE: "USPHL",
      CITY_NAME: "PHILADELPHIA",
      DPA_PORT_CODE: "PHL",
    },
    {
      CITY_CODE: "USPHN",
      CITY_NAME: "PORT HURON",
      DPA_PORT_CODE: "PHN",
    },
    {
      CITY_CODE: "USPIE",
      CITY_NAME: "ST PETERSBURG",
      DPA_PORT_CODE: "PIE",
    },
    {
      CITY_CODE: "USPLL",
      CITY_NAME: "PORT ALLEN",
      DPA_PORT_CODE: "PGC",
    },
    {
      CITY_CODE: "USPLU",
      CITY_NAME: "PORT LUDLOW",
      DPA_PORT_CODE: "PLL",
    },
    {
      CITY_CODE: "USPMB",
      CITY_NAME: "PEMBINA",
      DPA_PORT_CODE: "PMB",
    },
    {
      CITY_CODE: "USPNJ",
      CITY_NAME: "PORT NEWARK",
      DPA_PORT_CODE: "NYD",
    },
    {
      CITY_CODE: "USPNS",
      CITY_NAME: "PENSACOLA",
      DPA_PORT_CODE: "EAC",
    },
    {
      CITY_CODE: "USPPD",
      CITY_NAME: "CAMP PENDLETON",
      DPA_PORT_CODE: "PPX",
    },
    {
      CITY_CODE: "USPRG",
      CITY_NAME: "PROGRESSO",
      DPA_PORT_CODE: "PRG",
    },
    {
      CITY_CODE: "USPRL",
      CITY_NAME: "PORT ROYAL",
      DPA_PORT_CODE: "PRL",
    },
    {
      CITY_CODE: "USPSM",
      CITY_NAME: "PORTSMOUTH",
      DPA_PORT_CODE: "PSM",
    },
    {
      CITY_CODE: "USPTM",
      CITY_NAME: "PORTSMOUTH",
      DPA_PORT_CODE: "PMH",
    },
    {
      CITY_CODE: "USPTO",
      CITY_NAME: "PITTSTON",
      DPA_PORT_CODE: "PYS",
    },
    {
      CITY_CODE: "USPTW",
      CITY_NAME: "POTTSTOWN",
      DPA_PORT_CODE: "PTW",
    },
    {
      CITY_CODE: "USPVC",
      CITY_NAME: "PROVINCETOWN",
      DPA_PORT_CODE: "PVC",
    },
    {
      CITY_CODE: "USPVD",
      CITY_NAME: "PROVIDENCE",
      DPA_PORT_CODE: "PVD",
    },
    {
      CITY_CODE: "USPWF",
      CITY_NAME: "PORT ORCHARD",
      DPA_PORT_CODE: "PWF",
    },
    {
      CITY_CODE: "USPWM",
      CITY_NAME: "PORTLAND",
      DPA_PORT_CODE: "PWM",
    },
    {
      CITY_CODE: "USPXH",
      CITY_NAME: "POCAHONTAS",
      DPA_PORT_CODE: "PXH",
    },
    {
      CITY_CODE: "USPYM",
      CITY_NAME: "PLYMOUTH",
      DPA_PORT_CODE: "PYM",
    },
    {
      CITY_CODE: "USQAD",
      CITY_NAME: "ST ALBANSQUINCY",
      DPA_PORT_CODE: "QAD",
    },
    {
      CITY_CODE: "USQLO",
      CITY_NAME: "LEONIA",
      DPA_PORT_CODE: "QLO",
    },
    {
      CITY_CODE: "USQUI",
      CITY_NAME: "QUINCY",
      DPA_PORT_CODE: "QUI",
    },
    {
      CITY_CODE: "USRAC",
      CITY_NAME: "RACINE",
      DPA_PORT_CODE: "RAC",
    },
    {
      CITY_CODE: "USRAG",
      CITY_NAME: "RALEIGH",
      DPA_PORT_CODE: "RGH",
    },
    {
      CITY_CODE: "USRCH",
      CITY_NAME: "RICHMOND",
      DPA_PORT_CODE: "RCH",
    },
    {
      CITY_CODE: "USRCY",
      CITY_NAME: "ROGER CIRY",
      DPA_PORT_CODE: "RCY",
    },
    {
      CITY_CODE: "USRDO",
      CITY_NAME: "RANCHO DOMINGUEZ",
      DPA_PORT_CODE: "RDO",
    },
    {
      CITY_CODE: "USREV",
      CITY_NAME: "REEDVILLE",
      DPA_PORT_CODE: "REV",
    },
    {
      CITY_CODE: "USRFR",
      CITY_NAME: "RICHFORD",
      DPA_PORT_CODE: "RFR",
    },
    {
      CITY_CODE: "USRGC",
      CITY_NAME: "RIO GRANDE CITY",
      DPA_PORT_CODE: "RGC",
    },
    {
      CITY_CODE: "USRIC",
      CITY_NAME: "RICHMOND",
      DPA_PORT_CODE: "RIC",
    },
    {
      CITY_CODE: "USRKD",
      CITY_NAME: "ROCKLAND",
      DPA_PORT_CODE: "RKD",
    },
    {
      CITY_CODE: "USRNE",
      CITY_NAME: "ROANOKE",
      DPA_PORT_CODE: "ROE",
    },
    {
      CITY_CODE: "USRNO",
      CITY_NAME: "RENO",
      DPA_PORT_CODE: "RNO",
    },
    {
      CITY_CODE: "USROC",
      CITY_NAME: "ROCHESTER",
      DPA_PORT_CODE: "ROC",
    },
    {
      CITY_CODE: "USROI",
      CITY_NAME: "ROMEOVILLE",
      DPA_PORT_CODE: "ROI",
    },
    {
      CITY_CODE: "USROW",
      CITY_NAME: "ROSWELL",
      DPA_PORT_CODE: "ROW",
    },
    {
      CITY_CODE: "USROX",
      CITY_NAME: "ROSEAU",
      DPA_PORT_CODE: "ROX",
    },
    {
      CITY_CODE: "USRSA",
      CITY_NAME: "ROSE DALE",
      DPA_PORT_CODE: "RSE",
    },
    {
      CITY_CODE: "USRWC",
      CITY_NAME: "REDWOOD CITY",
      DPA_PORT_CODE: "RWC",
    },
    {
      CITY_CODE: "USSAB",
      CITY_NAME: "SABINE",
      DPA_PORT_CODE: "SAB",
    },
    {
      CITY_CODE: "USSAC",
      CITY_NAME: "SACRAMENTO",
      DPA_PORT_CODE: "SAC",
    },
    {
      CITY_CODE: "USSAM",
      CITY_NAME: "SANTA CLARITA",
      DPA_PORT_CODE: "SQU",
    },
    {
      CITY_CODE: "USSAN",
      CITY_NAME: "SAN DIEGO",
      DPA_PORT_CODE: "SDI",
    },
    {
      CITY_CODE: "USSAT",
      CITY_NAME: "SAN ANTONIO",
      DPA_PORT_CODE: "SAT",
    },
    {
      CITY_CODE: "USSAV",
      CITY_NAME: "SAVANNAH",
      DPA_PORT_CODE: "SAV",
    },
    {
      CITY_CODE: "USSAX",
      CITY_NAME: "SANDSTON",
      DPA_PORT_CODE: "NDS",
    },
    {
      CITY_CODE: "USSBA",
      CITY_NAME: "SANTA BARBARA",
      DPA_PORT_CODE: "SBA",
    },
    {
      CITY_CODE: "USSBH",
      CITY_NAME: "STREETSBORO",
      DPA_PORT_CODE: "EET",
    },
    {
      CITY_CODE: "USSBI",
      CITY_NAME: "SHELBY",
      DPA_PORT_CODE: "SBI",
    },
    {
      CITY_CODE: "USSBZ",
      CITY_NAME: "SHEYBOYGAN",
      DPA_PORT_CODE: "SBZ",
    },
    {
      CITY_CODE: "USSCK",
      CITY_NAME: "STOCKTON",
      DPA_PORT_CODE: "SCK",
    },
    {
      CITY_CODE: "USSCO",
      CITY_NAME: "SCOBEY",
      DPA_PORT_CODE: "SCO",
    },
    {
      CITY_CODE: "USSCV",
      CITY_NAME: "SCOTTSVILLE",
      DPA_PORT_CODE: "SCV",
    },
    {
      CITY_CODE: "USSCX",
      CITY_NAME: "SANTA CRUZ",
      DPA_PORT_CODE: "SCX",
    },
    {
      CITY_CODE: "USSDS",
      CITY_NAME: "SWEDESBORO",
      DPA_PORT_CODE: "SDB",
    },
    {
      CITY_CODE: "USSDU",
      CITY_NAME: "SUDAN",
      DPA_PORT_CODE: "SDU",
    },
    {
      CITY_CODE: "USSDY",
      CITY_NAME: "SANDUSKY",
      DPA_PORT_CODE: "SDY",
    },
    {
      CITY_CODE: "USSEA",
      CITY_NAME: "SEATTLE",
      DPA_PORT_CODE: "SEA",
    },
    {
      CITY_CODE: "USSEW",
      CITY_NAME: "SEWARD",
      DPA_PORT_CODE: "SEW",
    },
    {
      CITY_CODE: "USSFN",
      CITY_NAME: "WOONSOCKET",
      DPA_PORT_CODE: "SFN",
    },
    {
      CITY_CODE: "USSFO",
      CITY_NAME: "SAN FRANCISCO",
      DPA_PORT_CODE: "SFO",
    },
    {
      CITY_CODE: "USSGC",
      CITY_NAME: "SOUTH GATE",
      DPA_PORT_CODE: "SGC",
    },
    {
      CITY_CODE: "USSGR",
      CITY_NAME: "SUGAR LAND",
      DPA_PORT_CODE: "SGR",
    },
    {
      CITY_CODE: "USSHW",
      CITY_NAME: "SHERWOOD",
      DPA_PORT_CODE: "SHW",
    },
    {
      CITY_CODE: "USSIB",
      CITY_NAME: "SILVER BAY",
      DPA_PORT_CODE: "SIB",
    },
    {
      CITY_CODE: "USSJN",
      CITY_NAME: "ST JOHN",
      DPA_PORT_CODE: "SJN",
    },
    {
      CITY_CODE: "USSJO",
      CITY_NAME: "SAN JOSE",
      DPA_PORT_CODE: "SJS",
    },
    {
      CITY_CODE: "USSKE",
      CITY_NAME: "SHAKOPEE",
      DPA_PORT_CODE: "SKE",
    },
    {
      CITY_CODE: "USSLI",
      CITY_NAME: "SAN LUIS",
      DPA_PORT_CODE: "SLI",
    },
    {
      CITY_CODE: "USSNP",
      CITY_NAME: "SIDNEY",
      DPA_PORT_CODE: "SNP",
    },
    {
      CITY_CODE: "USSOD",
      CITY_NAME: "SODUS POINT",
      DPA_PORT_CODE: "SOD",
    },
    {
      CITY_CODE: "USSPQ",
      CITY_NAME: "SAN PEDRO",
      DPA_PORT_CODE: "SPO",
    },
    {
      CITY_CODE: "USSRP",
      CITY_NAME: "SEARSPORT",
      DPA_PORT_CODE: "SRP",
    },
    {
      CITY_CODE: "USSSM",
      CITY_NAME: "SAULT STE MARIE",
      DPA_PORT_CODE: "SSM",
    },
    {
      CITY_CODE: "USSTH",
      CITY_NAME: "ST HELENS",
      DPA_PORT_CODE: "STH",
    },
    {
      CITY_CODE: "USSTL",
      CITY_NAME: "ST LOUIS",
      DPA_PORT_CODE: "STL",
    },
    {
      CITY_CODE: "USSTP",
      CITY_NAME: "ST PAUL",
      DPA_PORT_CODE: "STP",
    },
    {
      CITY_CODE: "USSTS",
      CITY_NAME: "SANTA ROSA",
      DPA_PORT_CODE: "STS",
    },
    {
      CITY_CODE: "USSUJ",
      CITY_NAME: "SUFFERN",
      DPA_PORT_CODE: "SUJ",
    },
    {
      CITY_CODE: "USSUM",
      CITY_NAME: "SUMAS",
      DPA_PORT_CODE: "SUM",
    },
    {
      CITY_CODE: "USSUT",
      CITY_NAME: "SUNNY POINT",
      DPA_PORT_CODE: "SUT",
    },
    {
      CITY_CODE: "USSVR",
      CITY_NAME: "SAN JOAQUIN RIV",
      DPA_PORT_CODE: "SVR",
    },
    {
      CITY_CODE: "USSYO",
      CITY_NAME: "SAN YSIDRO",
      DPA_PORT_CODE: "SYO",
    },
    {
      CITY_CODE: "USSYP",
      CITY_NAME: "SANDY POINT",
      DPA_PORT_CODE: "SYP",
    },
    {
      CITY_CODE: "USSYR",
      CITY_NAME: "SYRACUSE",
      DPA_PORT_CODE: "SYR",
    },
    {
      CITY_CODE: "USTCT",
      CITY_NAME: "SILVER BAY",
      DPA_PORT_CODE: "TCT",
    },
    {
      CITY_CODE: "USTEX",
      CITY_NAME: "TEXARKANA",
      DPA_PORT_CODE: "TEX",
    },
    {
      CITY_CODE: "USTIW",
      CITY_NAME: "TACOMA",
      DPA_PORT_CODE: "TIW",
    },
    {
      CITY_CODE: "USTMG",
      CITY_NAME: "TALLMADGE",
      DPA_PORT_CODE: "GME",
    },
    {
      CITY_CODE: "USTOA",
      CITY_NAME: "TORRANCE",
      DPA_PORT_CODE: "TCD",
    },
    {
      CITY_CODE: "USTOB",
      CITY_NAME: "TOMBALL",
      DPA_PORT_CODE: "TOZ",
    },
    {
      CITY_CODE: "USTOL",
      CITY_NAME: "TOLEDO",
      DPA_PORT_CODE: "TOL",
    },
    {
      CITY_CODE: "USTPA",
      CITY_NAME: "TAMPA",
      DPA_PORT_CODE: "TPA",
    },
    {
      CITY_CODE: "USTPL",
      CITY_NAME: "TEMPLE",
      DPA_PORT_CODE: "TPJ",
    },
    {
      CITY_CODE: "USTRL",
      CITY_NAME: "TERRELL",
      DPA_PORT_CODE: "TRL",
    },
    {
      CITY_CODE: "USTUP",
      CITY_NAME: "TUPELO",
      DPA_PORT_CODE: "TVP",
    },
    {
      CITY_CODE: "USTUS",
      CITY_NAME: "TUCSON INTERNATIONAL APT",
      DPA_PORT_CODE: "TFS",
    },
    {
      CITY_CODE: "USTXT",
      CITY_NAME: "TEXAS CITY",
      DPA_PORT_CODE: "TXT",
    },
    {
      CITY_CODE: "USULT",
      CITY_NAME: "SULTANA",
      DPA_PORT_CODE: "ULT",
    },
    {
      CITY_CODE: "USUQK",
      CITY_NAME: "MC ALLEN",
      DPA_PORT_CODE: "UQK",
    },
    {
      CITY_CODE: "USUSG",
      CITY_NAME: "MUSKEGO",
      DPA_PORT_CODE: "MWS",
    },
    {
      CITY_CODE: "USUSH",
      CITY_NAME: "MUSCLE SHOALS",
      DPA_PORT_CODE: "USE",
    },
    {
      CITY_CODE: "USUWB",
      CITY_NAME: "WESTHAMPTON BEACH",
      DPA_PORT_CODE: "UWB",
    },
    {
      CITY_CODE: "USVAN",
      CITY_NAME: "VANCOUVER WA",
      DPA_PORT_CODE: "VUV",
    },
    {
      CITY_CODE: "USVBN",
      CITY_NAME: "VAN BUREN",
      DPA_PORT_CODE: "VBN",
    },
    {
      CITY_CODE: "USVBO",
      CITY_NAME: "VANCEBORO",
      DPA_PORT_CODE: "VBO",
    },
    {
      CITY_CODE: "USVCA",
      CITY_NAME: "VERNON",
      DPA_PORT_CODE: "VCA",
    },
    {
      CITY_CODE: "USVDZ",
      CITY_NAME: "VALDEZ",
      DPA_PORT_CODE: "VDZ",
    },
    {
      CITY_CODE: "USVEC",
      CITY_NAME: "VALENCIA",
      DPA_PORT_CODE: "VEC",
    },
    {
      CITY_CODE: "USVEN",
      CITY_NAME: "VENTURA",
      DPA_PORT_CODE: "VEN",
    },
    {
      CITY_CODE: "USVIG",
      CITY_NAME: "VIENNA",
      DPA_PORT_CODE: "VIG",
    },
    {
      CITY_CODE: "USVLC",
      CITY_NAME: "VALENCIA",
      DPA_PORT_CODE: "VLB",
    },
    {
      CITY_CODE: "USVLO",
      CITY_NAME: "VALLEJO",
      DPA_PORT_CODE: "VLO",
    },
    {
      CITY_CODE: "USWBE",
      CITY_NAME: "SOUTH BEND",
      DPA_PORT_CODE: "WBE",
    },
    {
      CITY_CODE: "USWET",
      CITY_NAME: "WEIRTON",
      DPA_PORT_CODE: "WEC",
    },
    {
      CITY_CODE: "USWHP",
      CITY_NAME: "WESTHOPE",
      DPA_PORT_CODE: "WHP",
    },
    {
      CITY_CODE: "USWHS",
      CITY_NAME: "WHITETAIL",
      DPA_PORT_CODE: "WHS",
    },
    {
      CITY_CODE: "USWIH",
      CITY_NAME: "WILLAPA HARBOUR",
      DPA_PORT_CODE: "WIH",
    },
    {
      CITY_CODE: "USWIP",
      CITY_NAME: "WILLS POINT",
      DPA_PORT_CODE: "WIP",
    },
    {
      CITY_CODE: "USWLC",
      CITY_NAME: "WINDSOR LOCKS",
      DPA_PORT_CODE: "WLC",
    },
    {
      CITY_CODE: "USWPT",
      CITY_NAME: "WESTPORT",
      DPA_PORT_CODE: "WPT",
    },
    {
      CITY_CODE: "USWRD",
      CITY_NAME: "WEST REDDING",
      DPA_PORT_CODE: "WRD",
    },
    {
      CITY_CODE: "USWRG",
      CITY_NAME: "WRANGELL",
      DPA_PORT_CODE: "WRG",
    },
    {
      CITY_CODE: "USWRI",
      CITY_NAME: "WORTH",
      DPA_PORT_CODE: "WRI",
    },
    {
      CITY_CODE: "USWTN",
      CITY_NAME: "WILMINGTON",
      DPA_PORT_CODE: "WTN",
    },
    {
      CITY_CODE: "USWTO",
      CITY_NAME: "WARRENTON",
      DPA_PORT_CODE: "WTO",
    },
    {
      CITY_CODE: "USWUX",
      CITY_NAME: "WOODLAND",
      DPA_PORT_CODE: "WOU",
    },
    {
      CITY_CODE: "USWWO",
      CITY_NAME: "WESTWEGO",
      DPA_PORT_CODE: "WWO",
    },
    {
      CITY_CODE: "USWXD",
      CITY_NAME: "WEXFORD",
      DPA_PORT_CODE: "WXD",
    },
    {
      CITY_CODE: "USXAL",
      CITY_NAME: "ALLEN TX",
      DPA_PORT_CODE: "XAL",
    },
    {
      CITY_CODE: "USXIA",
      CITY_NAME: "LEAVENWORTH",
      DPA_PORT_CODE: "XIA",
    },
    {
      CITY_CODE: "USXMH",
      CITY_NAME: "SUMNER",
      DPA_PORT_CODE: "XMH",
    },
    {
      CITY_CODE: "USXUB",
      CITY_NAME: "AUBURN",
      DPA_PORT_CODE: "XUB",
    },
    {
      CITY_CODE: "USXWV",
      CITY_NAME: "WARRENVILLE",
      DPA_PORT_CODE: "XWV",
    },
    {
      CITY_CODE: "USYAK",
      CITY_NAME: "YAKUTAT",
      DPA_PORT_CODE: "YAK",
    },
    {
      CITY_CODE: "USYCM",
      CITY_NAME: "COMSTOCK PARK",
      DPA_PORT_CODE: "YCM",
    },
    {
      CITY_CODE: "USYMB",
      CITY_NAME: "MABELVALE",
      DPA_PORT_CODE: "YMB",
    },
    {
      CITY_CODE: "USYYT",
      CITY_NAME: "WEST TISBURY",
      DPA_PORT_CODE: "YYY",
    },
    {
      CITY_CODE: "USZBT",
      CITY_NAME: "BUTNER",
      DPA_PORT_CODE: "ZBT",
    },
    {
      CITY_CODE: "USZMP",
      CITY_NAME: "NEWBURY PORT",
      DPA_PORT_CODE: "ZMP",
    },
    {
      CITY_CODE: "USZRN",
      CITY_NAME: "HARRISON",
      DPA_PORT_CODE: "ZRN",
    },
    {
      CITY_CODE: "USZUU",
      CITY_NAME: "LA VERGNE",
      DPA_PORT_CODE: "ZUU",
    },
    {
      CITY_CODE: "UYDZO",
      CITY_NAME: "DURAZNO",
      DPA_PORT_CODE: "DZO",
    },
    {
      CITY_CODE: "UYFZB",
      CITY_NAME: "FRAY BENTOS",
      DPA_PORT_CODE: "FZB",
    },
    {
      CITY_CODE: "UYMVD",
      CITY_NAME: "MONTEVIDEO",
      DPA_PORT_CODE: "MVD",
    },
    {
      CITY_CODE: "UYPDU",
      CITY_NAME: "PAYSANDU",
      DPA_PORT_CODE: "PDU",
    },
    {
      CITY_CODE: "VCCRP",
      CITY_NAME: "CAMDEN PARK",
      DPA_PORT_CODE: "CSY",
    },
    {
      CITY_CODE: "VCKTN",
      CITY_NAME: "KINGSTOWN",
      DPA_PORT_CODE: "KTN",
    },
    {
      CITY_CODE: "VCSVD",
      CITY_NAME: "ST VINCENT",
      DPA_PORT_CODE: "SVD",
    },
    {
      CITY_CODE: "VEBJV",
      CITY_NAME: "BAJO GRANDE",
      DPA_PORT_CODE: "BJV",
    },
    {
      CITY_CODE: "VECBS",
      CITY_NAME: "CABIMAS",
      DPA_PORT_CODE: "CBS",
    },
    {
      CITY_CODE: "VECUP",
      CITY_NAME: "CARUPANO",
      DPA_PORT_CODE: "CUP",
    },
    {
      CITY_CODE: "VEEGU",
      CITY_NAME: "EL GUAMACHE",
      DPA_PORT_CODE: "EGU",
    },
    {
      CITY_CODE: "VEETV",
      CITY_NAME: "EL TABLAZO",
      DPA_PORT_CODE: "ETV",
    },
    {
      CITY_CODE: "VEGUA",
      CITY_NAME: "GUARANAO",
      DPA_PORT_CODE: "GUE",
    },
    {
      CITY_CODE: "VEGUT",
      CITY_NAME: "GUANTA",
      DPA_PORT_CODE: "GUT",
    },
    {
      CITY_CODE: "VELAG",
      CITY_NAME: "LA GUAIRA",
      DPA_PORT_CODE: "LAG",
    },
    {
      CITY_CODE: "VELGY",
      CITY_NAME: "LAGUNILLAS",
      DPA_PORT_CODE: "LGY",
    },
    {
      CITY_CODE: "VELSP",
      CITY_NAME: "LAS PIEDRAS",
      DPA_PORT_CODE: "LSP",
    },
    {
      CITY_CODE: "VELSV",
      CITY_NAME: "LA SALINA",
      DPA_PORT_CODE: "LSV",
    },
    {
      CITY_CODE: "VELVL",
      CITY_NAME: "LA VELA DE CORO",
      DPA_PORT_CODE: "LVL",
    },
    {
      CITY_CODE: "VEMAR",
      CITY_NAME: "MARACAIBO",
      DPA_PORT_CODE: "MAR",
    },
    {
      CITY_CODE: "VEPBL",
      CITY_NAME: "PUERTO CABELLO",
      DPA_PORT_CODE: "PBL",
    },
    {
      CITY_CODE: "VEPCN",
      CITY_NAME: "PUNTA CARDON",
      DPA_PORT_CODE: "PCN",
    },
    {
      CITY_CODE: "VEPFI",
      CITY_NAME: "PUNTO FIJO",
      DPA_PORT_CODE: "PFI",
    },
    {
      CITY_CODE: "VEPMP",
      CITY_NAME: "PAMPATAR",
      DPA_PORT_CODE: "PMP",
    },
    {
      CITY_CODE: "VEPMV",
      CITY_NAME: "PORLAMAR",
      DPA_PORT_CODE: "PMV",
    },
    {
      CITY_CODE: "VEPPD",
      CITY_NAME: "PUNTA DE PIEDRA",
      DPA_PORT_CODE: "PPD",
    },
    {
      CITY_CODE: "VEPPZ",
      CITY_NAME: "PUERTO PAEZ",
      DPA_PORT_CODE: "PPZ",
    },
    {
      CITY_CODE: "VEPZO",
      CITY_NAME: "PUERTO ORDAZ",
      DPA_PORT_CODE: "PZO",
    },
    {
      CITY_CODE: "VESFX",
      CITY_NAME: "SAN FELIX",
      DPA_PORT_CODE: "SFX",
    },
    {
      CITY_CODE: "VESLV",
      CITY_NAME: "SAN LORENZO",
      DPA_PORT_CODE: "SLV",
    },
    {
      CITY_CODE: "VETJV",
      CITY_NAME: "TIA JUANA",
      DPA_PORT_CODE: "TJV",
    },
    {
      CITY_CODE: "VETUR",
      CITY_NAME: "TURIAMO",
      DPA_PORT_CODE: "TUR",
    },
    {
      CITY_CODE: "VEVMY",
      CITY_NAME: "AMUAY",
      DPA_PORT_CODE: "VMY",
    },
    {
      CITY_CODE: "VIFRD",
      CITY_NAME: "FREDERIKSTED",
      DPA_PORT_CODE: "FRD",
    },
    {
      CITY_CODE: "VNCLI",
      CITY_NAME: "CAT LAI",
      DPA_PORT_CODE: "CLI",
    },
    {
      CITY_CODE: "VNCMT",
      CITY_NAME: "CAI MEP INTERNATIONAL TERMINAL",
      DPA_PORT_CODE: "CIK",
    },
    {
      CITY_CODE: "VNCRB",
      CITY_NAME: "CAMRANH",
      DPA_PORT_CODE: "CRB",
    },
    {
      CITY_CODE: "VNDAD",
      CITY_NAME: "DA NANG",
      DPA_PORT_CODE: "DAD",
    },
    {
      CITY_CODE: "VNHAN",
      CITY_NAME: "HANOI",
      DPA_PORT_CODE: "HAN",
    },
    {
      CITY_CODE: "VNHCH",
      CITY_NAME: "HON CHONG",
      DPA_PORT_CODE: "HCH",
    },
    {
      CITY_CODE: "VNHNH",
      CITY_NAME: "HOAI NHON",
      DPA_PORT_CODE: "HNH",
    },
    {
      CITY_CODE: "VNHPH",
      CITY_NAME: "HAIPHONG",
      DPA_PORT_CODE: "HPH",
    },
    {
      CITY_CODE: "VNHPP",
      CITY_NAME: "TAN CANG HIEP PHUOC",
      DPA_PORT_CODE: "HPU",
    },
    {
      CITY_CODE: "VNKHI",
      CITY_NAME: "KHANH HOI",
      DPA_PORT_CODE: "KNH",
    },
    {
      CITY_CODE: "VNKHU",
      CITY_NAME: "KHONH HUNG",
      DPA_PORT_CODE: "KHU",
    },
    {
      CITY_CODE: "VNNGH",
      CITY_NAME: "NGHI SON",
      DPA_PORT_CODE: "NHH",
    },
    {
      CITY_CODE: "VNNMD",
      CITY_NAME: "NAM DINH",
      DPA_PORT_CODE: "NMD",
    },
    {
      CITY_CODE: "VNPHA",
      CITY_NAME: "PHAN RANG",
      DPA_PORT_CODE: "PHA",
    },
    {
      CITY_CODE: "VNPHG",
      CITY_NAME: "PHUOC LONG",
      DPA_PORT_CODE: "PHG",
    },
    {
      CITY_CODE: "VNPHH",
      CITY_NAME: "PHAN THIET",
      DPA_PORT_CODE: "PHH",
    },
    {
      CITY_CODE: "VNPHU",
      CITY_NAME: "PHU MY",
      DPA_PORT_CODE: "PDH",
    },
    {
      CITY_CODE: "VNQUL",
      CITY_NAME: "QUANG LONG",
      DPA_PORT_CODE: "QUL",
    },
    {
      CITY_CODE: "VNQUT",
      CITY_NAME: "QUANG TRI",
      DPA_PORT_CODE: "QUT",
    },
    {
      CITY_CODE: "VNSGN",
      CITY_NAME: "HO CHI MINH CITY (Siagon)",
      DPA_PORT_CODE: "SGN",
    },
    {
      CITY_CODE: "VNSPC",
      CITY_NAME: "HEIP PHOUC PORT",
      DPA_PORT_CODE: "HCP",
    },
    {
      CITY_CODE: "VNTUH",
      CITY_NAME: "TURJ HOA",
      DPA_PORT_CODE: "TUH",
    },
    {
      CITY_CODE: "VNUIH",
      CITY_NAME: "QUI NHON",
      DPA_PORT_CODE: "UIH",
    },
    {
      CITY_CODE: "VNVIC",
      CITY_NAME: "HO CHI MINH,VICT",
      DPA_PORT_CODE: "VIT",
    },
    {
      CITY_CODE: "VNVNH",
      CITY_NAME: "VINH",
      DPA_PORT_CODE: "VNH",
    },
    {
      CITY_CODE: "VNVUT",
      CITY_NAME: "VUNG TAU",
      DPA_PORT_CODE: "VUT",
    },
    {
      CITY_CODE: "VUSAN",
      CITY_NAME: "SANTO",
      DPA_PORT_CODE: "SNT",
    },
    {
      CITY_CODE: "VUVLI",
      CITY_NAME: "PORT VILA",
      DPA_PORT_CODE: "PVL",
    },
    {
      CITY_CODE: "WFSIG",
      CITY_NAME: "SIGAVE",
      DPA_PORT_CODE: "SGW",
    },
    {
      CITY_CODE: "WSAAU",
      CITY_NAME: "ASAU",
      DPA_PORT_CODE: "AAU",
    },
    {
      CITY_CODE: "WSAPW",
      CITY_NAME: "APIA",
      DPA_PORT_CODE: "APW",
    },
    {
      CITY_CODE: "XXEUR",
      CITY_NAME: "EUROPE",
      DPA_PORT_CODE: "EUR",
    },
    {
      CITY_CODE: "YEADE",
      CITY_NAME: "ADEN",
      DPA_PORT_CODE: "ADE",
    },
    {
      CITY_CODE: "YEAHW",
      CITY_NAME: "AHWAR",
      DPA_PORT_CODE: "AHW",
    },
    {
      CITY_CODE: "YEBLH",
      CITY_NAME: "BALHAF",
      DPA_PORT_CODE: "BJH",
    },
    {
      CITY_CODE: "YEELK",
      CITY_NAME: "EL KATIEB",
      DPA_PORT_CODE: "ELK",
    },
    {
      CITY_CODE: "YEHOD",
      CITY_NAME: "HODEIDAH",
      DPA_PORT_CODE: "HOD",
    },
    {
      CITY_CODE: "YEMKX",
      CITY_NAME: "MUKALLA",
      DPA_PORT_CODE: "MKX",
    },
    {
      CITY_CODE: "YEMOK",
      CITY_NAME: "MOKHA",
      DPA_PORT_CODE: "MOK",
    },
    {
      CITY_CODE: "YEMYN",
      CITY_NAME: "MAREB",
      DPA_PORT_CODE: "MYN",
    },
    {
      CITY_CODE: "YERAK",
      CITY_NAME: "RAS AL KALIB",
      DPA_PORT_CODE: "RAK",
    },
    {
      CITY_CODE: "YESYE",
      CITY_NAME: "SADAH",
      DPA_PORT_CODE: "SYE",
    },
    {
      CITY_CODE: "YTLON",
      CITY_NAME: "LONGONI",
      DPA_PORT_CODE: "LOG",
    },
    {
      CITY_CODE: "YUBAR",
      CITY_NAME: "BAR",
      DPA_PORT_CODE: "BAX",
    },
    {
      CITY_CODE: "YUKOT",
      CITY_NAME: "KOTOR",
      DPA_PORT_CODE: "KOT",
    },
    {
      CITY_CODE: "ZAABT",
      CITY_NAME: "ALBERTON",
      DPA_PORT_CODE: "AOT",
    },
    {
      CITY_CODE: "ZAAFD",
      CITY_NAME: "PORT ALFRED",
      DPA_PORT_CODE: "AFD",
    },
    {
      CITY_CODE: "ZACGN",
      CITY_NAME: "CONGELLA",
      DPA_PORT_CODE: "CGA",
    },
    {
      CITY_CODE: "ZACPT",
      CITY_NAME: "CAPE TOWN",
      DPA_PORT_CODE: "CPT",
    },
    {
      CITY_CODE: "ZACTD",
      CITY_NAME: "CITY DEEP",
      DPA_PORT_CODE: "CTD",
    },
    {
      CITY_CODE: "ZADUR",
      CITY_NAME: "DURBAN",
      DPA_PORT_CODE: "DUR",
    },
    {
      CITY_CODE: "ZADVI",
      CITY_NAME: "DURBANVILLE",
      DPA_PORT_CODE: "DVI",
    },
    {
      CITY_CODE: "ZAELA",
      CITY_NAME: "ELANDSFONTEIN",
      DPA_PORT_CODE: "ELA",
    },
    {
      CITY_CODE: "ZAELS",
      CITY_NAME: "EAST LONDON",
      DPA_PORT_CODE: "ELS",
    },
    {
      CITY_CODE: "ZAJNB",
      CITY_NAME: "JOHANNESBURG",
      DPA_PORT_CODE: "JNB",
    },
    {
      CITY_CODE: "ZAJOH",
      CITY_NAME: "PORT SAINT JOHNS",
      DPA_PORT_CODE: "JOH",
    },
    {
      CITY_CODE: "ZAKMP",
      CITY_NAME: "KEMPTON PARK",
      DPA_PORT_CODE: "KMP",
    },
    {
      CITY_CODE: "ZAMEY",
      CITY_NAME: "MEYERTON",
      DPA_PORT_CODE: "MEY",
    },
    {
      CITY_CODE: "ZAMZY",
      CITY_NAME: "MOSSEL BAY",
      DPA_PORT_CODE: "MZY",
    },
    {
      CITY_CODE: "ZAPLZ",
      CITY_NAME: "PORT ELIZABETH",
      DPA_PORT_CODE: "PLZ",
    },
    {
      CITY_CODE: "ZARCB",
      CITY_NAME: "RICHARDS BAY",
      DPA_PORT_CODE: "RCB",
    },
    {
      CITY_CODE: "ZASTN",
      CITY_NAME: "SILVERTON",
      DPA_PORT_CODE: "SRN",
    },
    {
      CITY_CODE: "ZAUHG",
      CITY_NAME: "UITENHAGE",
      DPA_PORT_CODE: "UHG",
    },
    {
      CITY_CODE: "ZAZBA",
      CITY_NAME: "COEGA",
      DPA_PORT_CODE: "ZBA",
    },
    {
      CITY_CODE: "ZAZDR",
      CITY_NAME: "NORTH END",
      DPA_PORT_CODE: "ZDR",
    },
    {
      CITY_CODE: "ZMLUA",
      CITY_NAME: "LUANSHYA",
      DPA_PORT_CODE: "LUJ",
    },
    {
      CITY_CODE: "ZRBOA",
      CITY_NAME: "BOMA",
      DPA_PORT_CODE: "BMA",
    },
    {
      CITY_CODE: "ZWBAT",
      CITY_NAME: "BATA",
      DPA_PORT_CODE: "BAT",
    },
    {
      CITY_CODE: "ZZOPA",
      CITY_NAME: "Port to be Advised",
      DPA_PORT_CODE: "TBA",
    },
    {
      CITY_CODE: "ZZOPE",
      CITY_NAME: "Optional Europe ",
      DPA_PORT_CODE: "OBU",
    },
    {
      CITY_CODE: "ZZOPF",
      CITY_NAME: "Optional  Far East ",
      DPA_PORT_CODE: "OFE",
    },
    {
      CITY_CODE: "ZZOPT",
      CITY_NAME: "Optional PORT",
      DPA_PORT_CODE: "OPT",
    },
    {
      CITY_CODE: "ZZOPU",
      CITY_NAME: "Optional United States",
      DPA_PORT_CODE: "OUS",
    },
  ];