import {Route,Routes} from 'react-router-dom';

import Dashboard from '../pages/dashboard/index'
// Auth
import Login from '../pages/Auth/login'
import Register from '../pages/Auth/register'
import Forgot_password from '../pages/Auth/forgot_password'
// Profile setting
import Profile from '../pages/profileSetting/index'



import VoyageDetails from '../pages/voyagedetails';
import VoyageDetailsTable from '../pages/voyagedetails/list';

import ContainerDetails from '../pages/containerdetails';
import ContainerDetailsTable from '../pages/containerdetails/list';

import BlDetailsForm from '../pages/bldetails';
import BlDetailsTable from '../pages/bldetails/list';

import LineDetails from '../pages/linedetails';
import LineDetailsTable from '../pages/linedetails/list';

import VesselInformation from '../pages/vesselinformation';
import VesselInformationTable from '../pages/vesselinformation/list';
import ExportData from '../pages/exportData/list';

export  function AdminRouteq(){


    return (
        <>  
            <Routes>
                <Route path="/" element={<Dashboard  />} />
                {/* Auth */}
                <Route path="/login" element={<Login  />} />
                <Route path="/register" element={<Register  />} />
                <Route path="/forgot-password" element={<Forgot_password  />} />
                {/* profile */}
                {/* <Route path="/profileSetting" element={<Profile  />} /> */}
                 {/* mester */}    
                <Route path="/voyage-details/:id" element={<VoyageDetails  />} />
                <Route path="/voyage-list" element={<VoyageDetailsTable  />} />

                <Route path="/container-details/:id" element={<ContainerDetails  />} />
                <Route path="/container-list" element={<ContainerDetailsTable  />} />
                <Route path="/line-details/:id" element={<LineDetails  />} />
                <Route path="/line-list" element={<LineDetailsTable  />} />

                <Route path="/bl-details/:id" element={<BlDetailsForm  />} />
                <Route path="/bl-list" element={<BlDetailsTable  />} />
                <Route path="/vessel-info/:id" element={<VesselInformation/>} />
                <Route path="/vessel-list" element={<VesselInformationTable  />} />
                <Route path="/export-data" element={<ExportData  />} />

             


            </Routes>
        </>
    ) 
}