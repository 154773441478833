import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../lib/api";

const ContainerDetails = () => {
  const [formData, setFormData] = useState({
    containerNumber: "",
    containerSize: "",
    containerType: "",
    tareWeight: "",
    maxPayload: "",
    owner: "",
  });
  const [containerNumberError, setContainerNumberError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [tareWeightError, setTareWeightError] = useState("");
  const [maxPayloadError, setMaxPayloadError] = useState("");

  const { id } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "containerNumber") {
      const containerNumberRegex = /^[A-Za-z]{4}\d{7}$/;
      if (!containerNumberRegex.test(value)) {
        setContainerNumberError("Invalid container number format");
      } else {
        setContainerNumberError("");
      }
    }
    if (name === "tareWeight") {
      const tareWeightRegex = /^\d{1,5}$/;
      if (!tareWeightRegex.test(value)) {
        setTareWeightError("Tare weight must be a number with a maximum of 5 digits");
      } else {
        setTareWeightError("");
      }
    }
    
    // Capitalize the first character
    const capitalizedValue = value.toUpperCase();

    setFormData((prevData) => ({
      ...prevData,
      [name]: capitalizedValue,
    }));
  };

  const handleModalClose = () => {
    setShowErrorModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (containerNumberError || tareWeightError ) {
      // Show modal if container number is invalid
      setShowErrorModal(true);
      return;
    }

    try {
      if (id !== "add") {
        await api.postAndUpdate(`/updateContainerDetails`, formData, "put");
      } else {
        await api.postAndUpdate("/createContainerDetails", formData, "post");
      }

      navigate("/container-list");
    } catch (error) {
      alert("container number already exists:", error);
    }
  };

  const fetchSingleContainer = async () => {
    try {
      const response = await api.get(`/getContainerDetailsById/${id}`);
      if (response === null) {
        return;
      }
      setFormData(response);
    } catch (error) {
      console.error("Error fetching container details:", error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (id !== "add") {
      fetchSingleContainer();
    }
  }, [id]);

  return (
    <div className="container">
      <h1 className="text-center mt-5 mb-5">Container Details</h1>
      <Form
        onSubmit={handleSubmit}
        className="container rounded pt-5 pb-5 mb-5 border"
      >
        <Form.Group className="mb-3" controlId="containerNumber">
          <Form.Label>Container Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Container Number"
            name="containerNumber"
            required
            value={formData.containerNumber}
            onChange={handleChange}
            isInvalid={!!containerNumberError}
          />
          <Form.Control.Feedback type="invalid">
            {containerNumberError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="containerSize">
          <Form.Label>Container Size</Form.Label>
          <Form.Control
            as="select"
            name="containerSize"
            required
            value={formData.containerSize}
            onChange={handleChange}
          >
            <option value="">Select Container Size</option>
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="45">45</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId="containerType">
          <Form.Label>ISO Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ISO Code"
            name="containerType"
            required
            value={formData.containerType}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="tareWeight">
          <Form.Label>Tare Weight (KGS)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Tare Weight"
            name="tareWeight"
            required
            value={formData.tareWeight}
            onChange={handleChange}
            isInvalid={!!tareWeightError}
          />
          <Form.Control.Feedback type="invalid">
            {tareWeightError}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="maxPayload">
          <Form.Label>Maximum Payload (KGS)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Maximum Payload"
            name="maxPayload"
            required
            value={formData.maxPayload}
            onChange={handleChange}
          
          />
         
        </Form.Group>

        <Form.Group className="mb-3" controlId="owner">
          <Form.Label>Owner</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Owner"
            name="owner"
            required
            value={formData.owner}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          {formData.id ? "Update" : "Submit"}
        </Button>
      </Form>

      {/* Error Modal */}
      <Modal show={showErrorModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{containerNumberError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContainerDetails;
